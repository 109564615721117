import React, { useState } from "react";

import "./style.css";
import SessionEndModal from "../SessionEndModal";
import RatingInput from "../RatingInput";
import TextInput from "../TextInput";
import Button from "../Button";

import { useTranslation } from "react-i18next";

const ChatFeedback = ({
  sessionFeedback,
  setSessionFeedback,
  sessionFeedbackComment,
  onFieldChange,
  sendSessionFeedback,
  closeFeedback,
}) => {
  const { t } = useTranslation('translation');
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="chat-feedback-container">
      <i className="icon-close" onClick={closeFeedback} />
      <div className="modal-heading">{t('How is your session so far?')}</div>
      <RatingInput
        options={{
          enumOptions: [
            { label: t("1"), value: 1 },
            { label: t("2"), value: 2 },
            { label: t("3"), value: 3 },
            { label: t("4"), value: 4 },
            { label: t("5"), value: 5 },
          ],
        }}
        onChange={setSessionFeedback}
      ></RatingInput>
      {sessionFeedback !== 0 && (
        <>
          <TextInput
            inputType="text"
            name="sessionFeedbackComment"
            placeholder={
              sessionFeedback > 3
                ? t("Tell us how your experience was.")
                : t("Tell us what we could improve.")
            }
            value={sessionFeedbackComment}
            inputChange={(event, name) =>
              onFieldChange(event.target.value, name)
            }
          />

          <Button
            buttonClass="custom-button primary-button mt-20"
            onClick={sendSessionFeedback}
          >
            {t('SEND FEEDBACK')}
          </Button>
        </>
      )}
    </div>
  );
};

export default ChatFeedback;
