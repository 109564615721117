import React from "react";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";

import "./style.css";

const Checkbox = (props) => {
  let extraId = uuidv4();

  return (
    <>
      {props.options &&
        props.options.enumOptions &&
        !props.isSearchable &&
        props.options.enumOptions.map((option, index) => (
          <div className="checkbox-container">
            <input
              // defaultChecked={defaultChecked}
              // defaultValue={defaultValue}
              // disabled={isDisabled}
              id={
                props.addExtraId
                  ? `${option.label}${index}${extraId}`
                  : `${option.label}${index}`
              }
              type="checkbox"
              className={`custom-checkbox ${
                props.isQuiz ? "absolute-quiz-checkbox" : ""
              }`}
              onClick={
                props.isQuiz
                  ? (event) => {
                      props.onChange(event.target.checked, option.value);
                    }
                  : () => props.onChange(!option.value)
              }
              checked={
                props.isQuiz
                  ? props.selectedValues?.includes(option.value)
                  : props.checked
              }
            />
            {!props.isQuiz && (
              <label
                className="checkbox-label"
                for={
                  props.addExtraId
                    ? `${option.label}${index}${extraId}`
                    : `${option.label}${index}`
                }
              >
                {option.label}
              </label>
            )}
            {props.isQuiz && (
              <label
                className={`quiz-checkbox-wrapper ${
                  props.selected === option.value ? "active" : ""
                }`}
                for={`${option.label}${index}`}
              >
                <div
                  className="quiz-checkbox"
                  dangerouslySetInnerHTML={{ __html: option.label }}
                />
              </label>
            )}
          </div>
        ))}
      {props.isSearchable && (
        <div
          className={`dropdown_wrapper `}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ReactSelect
            // isClearable={true}
            classNamePrefix="checkbox-dropdown"
            menuPosition="fixed"
            // styles={customStyles}
            // defaultValue={defaultValue}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            value={props.selectedValues}
            onChange={(item) => props.onChange(true, item.value, item.index)}
            options={props?.options?.enumOptions}
            // isDisabled={isDisabled}
            placeholder={props.placeHolder}
            isSearchable={true}
            controlShouldRenderValue={false}
            // theme={(theme) => ({
            //   ...theme,
            //   colors: {
            //     ...theme.colors,
            //     primary25: "#ddf0f9",
            //     primary: "#64bfeb",
            //   },
            // })}
            // blurInputOnSelect={true}
            isOptionSelected={(option, selectValue) =>
              selectValue.some((i) => i === option)
            }
          />
        </div>
      )}
    </>
  );
};

export default Checkbox;
