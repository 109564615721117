import React, { useState, useRef, useEffect } from "react";
import Arrow from "./Arrow";
import "./style.css";

function Accordion(props) {
  const [activeState, setActiveState] = useState(
    props.expanded ? "active" : ""
  );
  const [showContent, setShowContent] = useState(
    props.expanded ? props.expanded : false
  );
  const [setRotate, setRotateState] = useState(
    !showContent ? "accordion-icon" : "accordion-icon rotate"
  );

  useEffect(() => {
    if (activeState) {
      props.informOpen && props.informOpen();
    }
  }, [activeState]);

  const toggleAccordion = () => {
    setActiveState(activeState === "" ? "active" : "");
    setRotateState(showContent ? "accordion-icon" : "accordion-icon rotate");
    setShowContent(!showContent);
  };

  return (
    <div className="accordion-section">
      <div className={`accordion ${activeState}`} role="button" aria-expanded={showContent} onClick={toggleAccordion}>
        <div className="flex-container">
          <div className="accordion-summary">
            {props.iconClass && (
              <div className="accordion-title-icon-wrapper">
                <i className={`${props.iconClass} accordion-title-icon`} />
              </div>
            )}
            <div className="accordion-title-wrapper">
              <div className="accordion-title">{props.title}</div>
              {props.description && (
                <span className="accordion-description">
                  {props.description}
                </span>
              )}
            </div>
          </div>
          {props.actions && (
            <div className="accordion-actions">
              <div class="inner">{props.actions.map((action) => action)}</div>
            </div>
          )}
        </div>
        <Arrow className={`${setRotate}`} width={10} fill={"#1e4c88"} />
      </div>
      {showContent && <div className="accordion-content">{props.children}</div>}
    </div>
  );
}

export default Accordion;
