import React, { useState } from "react";
import { authHeader } from "../../helpers/auth-header";
import useFetch from "../../helpers/remote";

import IconLabelButton from "../../components/IconLabelButton";
import Accordion from "../../components/Accordion";
import Snackbar from "../../components/Snackbar";
import CorrectAnswerRecord from "../../components/QuizReport/correctAnswerRecord";

import LoadingGif from "../../assets/images/loading-circle.gif";

import { useTranslation } from "react-i18next";

const SummaryRecord = ({ data }) => {
  const { t } = useTranslation("translation");
  const [submissionDetails, setSumbissionDetails] = useState({});
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [showLoadingSymbol, setShowLoadingSymbol] = useState(false);

  const makeApiCall = useFetch();

  const transformAnswers = (options) => {
    return options.filter((option, key) => option.is_correct === true);
  };

  const transformSubmittedOptions = (
    submittedOptions = [],
    questionOptions
  ) => {
    let transformedOptions = [];

    submittedOptions.forEach((submittedOptionId) => {
      questionOptions.forEach((questionOption) => {
        if (submittedOptionId === questionOption.id) {
          transformedOptions.push(questionOption);
        }
      });
    });

    return transformedOptions;
  };

  const prepareCorrectAnswerList = (element, questionIndex) => {
    let dataToPush = {};

    dataToPush = {
      questionType: element.type,
      question: element.text,
      questionScoredBy: element?.scored_by,
      answerFeedback: submissionDetails?.answers?.[questionIndex]?.feedback,
      answers:
        element.type === "group"
          ? []
          : "ideal_answer_items" in element &&
            (element.type === "table" || element.type === "newbullet")
          ? [{ bullets: element.ideal_answer_items }]
          : "ideal_answer_html" in element &&
            element.type !== "description" &&
            element.type !== "radio" &&
            element.type !== "checkbox"
          ? [{ html: element.ideal_answer_html }]
          : "ideal_answer" in element &&
            element.type !== "radio" &&
            element.type !== "checkbox" &&
            element.type !== "essay"
          ? [{ text: element.ideal_answer }]
          : "matches" in element
          ? [{ matches: element.matches }]
          : element.options
          ? transformAnswers(element.options)
          : "",
      submittedAnswers:
        "text" in submissionDetails.answers[questionIndex]
          ? [{ text: submissionDetails.answers[questionIndex].text }]
          : "matches" in submissionDetails.answers[questionIndex]
          ? [{ matches: submissionDetails.answers[questionIndex].matches }]
          : transformSubmittedOptions(
              submissionDetails.answers[questionIndex].options,
              element.options
            ),
      answerKeySettings:
        "answer_key_settings" in element ? element.answer_key_settings : null,
      questionScore:
        submissionDetails.quiz.score_decimal_number === 0
          ? Math.floor(element.score)
          : element.score.toFixed(
              submissionDetails.quiz.score_decimal_number === null
                ? 2
                : submissionDetails.quiz.score_decimal_number
            ),
      userScore:
        "score" in submissionDetails.answers[questionIndex]
          ? submissionDetails.quiz &&
            submissionDetails.quiz.score_decimal_number === 0
            ? Math.floor(submissionDetails.answers[questionIndex].score)
            : parseFloat(
                submissionDetails.answers[questionIndex].score
              ).toFixed(
                submissionDetails.quiz.score_decimal_number === null
                  ? 2
                  : submissionDetails.quiz.score_decimal_number
              )
          : "-",
        options: element.options ? element.options : []
    };
    return dataToPush;
  };

  const computeQuizResult = () => {
    let correctAnswerList = [];
    if (Object.keys(submissionDetails).length > 0) {
      submissionDetails.quiz.questions.forEach((element, questionIndex) => {
        // if (element.options || "ideal_answer" in element) {
          correctAnswerList.push(prepareCorrectAnswerList(element, questionIndex));
        // }
      });
    }
    return correctAnswerList;
  };

  const loadQuizSubmissionDetails = (submissionId) => {
    setShowLoadingSymbol(true);
    if (submissionId) {
      makeApiCall
        .request(
          window.CHAT_API + `/api/v1/quiz/quiz-submissions/${submissionId}`,
          {
            method: "GET",
            headers: authHeader(),
          },
          true
        )
        .then((json) => {
          if (json.status.code === 200) {
            setSumbissionDetails(json.data.quiz_submission);
            setShowLoadingSymbol(false);
          } else {
            setSnackbar({
              isOpen: true,
              message: t("Error fetching details"),
              type: "error",
            });
          }
        });
    }
  };

  return (
    <div>
      <p className="quiz-name-header">{data.quiz.name}</p>
      {data.result_settings && data.result_settings.show_scores && (
        <p className="quiz-result">
          {t("You scored")}{" "}
          {submissionDetails.quiz &&
          submissionDetails.quiz.score_decimal_number === 0
            ? Math.floor(data.score)
            : parseFloat(data.score).toFixed(
                submissionDetails.quiz &&
                  submissionDetails.quiz.score_decimal_number === null
                  ? 2
                  : submissionDetails.quiz &&
                      submissionDetails.quiz.score_decimal_number
              )}
          /
          {submissionDetails.quiz &&
          submissionDetails.quiz.score_decimal_number === 0
            ? Math.floor(data.quiz.score)
            : parseFloat(data.quiz.score).toFixed(
                submissionDetails.quiz &&
                  submissionDetails.quiz.score_decimal_number === null
                  ? 2
                  : submissionDetails.quiz &&
                      submissionDetails.quiz.score_decimal_number
              )}
        </p>
      )}
      <p className="quiz-result">
        {submissionDetails.result_settings &&
          submissionDetails.result_settings.shows &&
          submissionDetails.result_settings.shows.map((show) => (
            <>
              {show.message && (
                <div className="condition-message">
                  <div className="quarter-clearfix" />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: show.message,
                    }}
                  />
                </div>
              )}
            </>
          ))}
      </p>
      {Object.keys(submissionDetails).length === 0 && showLoadingSymbol && (
        <img
          src={LoadingGif}
          alt={t("Loading...")}
          className="summary-record-loading-gif"
        />
      )}
      {Object.keys(submissionDetails).length === 0 && !showLoadingSymbol && (
        <IconLabelButton
          buttonLabel={t("View answers and feedback")}
          onClick={() => loadQuizSubmissionDetails(data._id)}
        />
      )}
      {submissionDetails.quiz && (
        <div className="correct-answers-container">
          <Accordion
            expanded
            title={t("Check out the correct answers to the quiz questions")}
          >
            {computeQuizResult().map((item) => (
              <CorrectAnswerRecord
                questionType={item.questionType}
                question={item.question}
                answers={item.answers}
                submittedAnswers={item.submittedAnswers}
                questionScore={item.questionScore}
                userScore={item.userScore}
                answerKeySettings={item.answerKeySettings}
                answerFeedback={item?.answerFeedback}
                questionScoredBy={item?.questionScoredBy}
                submissionData={submissionDetails}
                item={item}
              />
            ))}
          </Accordion>
        </div>
      )}
      <hr />
      <Snackbar
        isOpen={snackbar.isOpen}
        primaryMessage={snackbar.message}
        type={snackbar.type}
        onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
      />
    </div>
  );
};

export default SummaryRecord;
