import { useAuth } from "../context/auth";
import { useAuth0 } from "../react-auth0-spa";
// import * as Sentry from "@sentry/react";
import AgentClassService from "./AgentClassService";
const useFetch = () => {
  const { authTokens, setAuthTokens, setShowAccessDenied, selectedClass } =
    useAuth();
  const { getTokenSilently, logout } = useAuth0();
  const isTeam = window.location?.pathname?.startsWith("/team/discussion");
  const customFetch = async (url, options, dontKnow, isToken) => {
    let token, headers;

    if (
      (process.env && process.env.REACT_APP_TYPE === "login_widget") ||
      window.SSO_PROVIDER === "oneauth" ||
      isToken
    ) {
      token = authTokens.access_token;
      headers = {
        ...options.headers,
      };
      if (options.headers && options.headers.Authorization) {
        headers["Authorization"] = `Bearer ${token}`;
      }
      if (Object.keys(options.headers).length === 0) {
        headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        };
      }
    } else if (window.SSO_PROVIDER === "auth0") {
      token = await getTokenSilently();
      headers = {
        ...options.headers,
      };
      if (options.headers && options.headers.Authorization) {
        headers["Authorization"] = `Bearer ${token}`;
      }
      if (Object.keys(options.headers).length === 0) {
        headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        };
      }
    }

    return fetch(url, {
      ...options,
      headers: headers,
    })
      .then((response) => {
        if (response.status === 401 && !isTeam) {
          process.env && process.env.REACT_APP_TYPE === "login_widget"
            ? widgetLogout()
            : window.SSO_PROVIDER === "auth0"
            ? auth0Logout()
            : oneAuthLogout();
        } else if (
          response.status === 200 ||
          response.status === 400 ||
          response.status === 404
        ) {
          return response.json();
          // } else if (response.status === 403) {
        } else if (
          response.status === 403 &&
          AgentClassService.getSelectedAgent()
        ) {
          setShowAccessDenied(true);
        } else {
          return null;
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const auth0Logout = () => {
    localStorage.clear();
AgentClassService.clear();
    logout({
      returnTo: window.location.origin,
    });
  };

  const oneAuthLogout = () => {
    localStorage.clear();
AgentClassService.clear();
    setAuthTokens(false);
    window.location.href =
      window.SSO_DOMAIN + "/logout?redirect_uri=" + window.location.origin;
  };

  const widgetLogout = () => {
    let initialToken = localStorage.getItem("widget_initial_token");
    setAuthTokens(false);
    localStorage.clear();
AgentClassService.clear();
    window.location.href = `/?t=${initialToken}`;
  };

  const request = (url, options, dontKnow, isToken, noAdditionalHeader) => {
    if (!noAdditionalHeader) {
      let agentId = "agent_id=" + AgentClassService.getSelectedAgent();
      let connectionId = "connection_id=" + AgentClassService.getSelectedClass();
      if (AgentClassService.getSelectedAgent() !== null) {
        if (url.includes("?")) {
          url = url + "&" + agentId;
        } else {
          url = url + "?" + agentId;
        }
      }
      if (
        AgentClassService.getSelectedClass() &&
        AgentClassService.getSelectedClass() !== null
      ) {
        if (url.includes("?")) {
          url = url + "&" + connectionId;
        } else {
          url = url + "?" + connectionId;
        }
      }
    }

    return customFetch(url, options, dontKnow, isToken);
  };

  return {
    request,
  };
};
export default useFetch;
