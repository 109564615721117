import React, { useState, useEffect } from "react";
import Moment from "react-moment";

import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";

import IconLabelButton from "../../components/IconLabelButton";
import Snackbar from "../../components/Snackbar";
import Modal from "../../components/Modal";
import NoDataScreen from "../../components/NoDataScreen";
import "./style.css";

import { useTranslation } from "react-i18next";

const NoClassHistoryPanel = (props) => {
  const { t } = useTranslation('translation');
  const [allSessions, setAllSessions] = useState([]);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [openDeleteSessionConfirm, setOpenDeleteSessionConfirm] =
    useState(false);
  const [openDeleteAllSessionsConfirm, setOpenDeleteAllSessionsConfirm] =
    useState(false);
  const [toBeDeleted, setToBeDeleted] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    pageNumbers: [1],
    pageSize: 10,
  });
  const [showNoData, setShowNoData] = useState(false);

  const makeApiCall = useFetch();

  useEffect(() => {
    getSessions();
  }, []);

  const getSessions = (currentPage = 1) => {
    makeApiCall
      .request(
        window.CHAT_API +
          `/api/v1/chatlog/sessions?page=${currentPage}&page_size=${pagination.pageSize}`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data.sessions.length > 0) {
            setAllSessions(json.data.sessions);
            let pageNumbers = [];
            for (let i = 1; i <= json.data.pagination.total_pages; i++) {
              pageNumbers.push(i);
            }
            setPagination({
              currentPage: json.data.pagination.page,
              totalPages: json.data.pagination.total_pages,
              pageNumbers: pageNumbers,
              pageSize: json.data.pagination.page_size,
            });
          } else {
            setShowNoData(true);
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching sessions"),
            type: "error",
          });
        }
      });
  };

  const onPageNumberClick = (number) => {
    setPagination((data) => ({
      ...data,
      currentPage: number,
    }));
    getSessions(number);
  };

  const confirmDeleteSession = (sessionId) => {
    setToBeDeleted(sessionId);
    setOpenDeleteSessionConfirm(true);
  };

  const deleteSession = () => {
    setOpenDeleteSessionConfirm(false);
    makeApiCall
      .request(window.CHAT_API + "/api/v1/chatlog/sessions/" + toBeDeleted, {
        method: "DELETE",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          getSessions();
          setSnackbar({
            isOpen: true,
            message: t("Session is deleted successfully."),
            type: "success",
          });
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });
  };

  const confirmDeleteAllSessions = () => {
    setOpenDeleteAllSessionsConfirm(true);
  };

  // useEffect(() => {
  //   if (openDeleteAllSessionsConfirm === false) {
  //     getSessions();
  //   }
  // }, [openDeleteAllSessionsConfirm]);

  const deleteAllSessions = () => {
    setOpenDeleteAllSessionsConfirm(false);
    allSessions.forEach((session) => {
      makeApiCall
        .request(
          window.CHAT_API + "/api/v1/chatlog/sessions/" + session.session_id,
          {
            method: "DELETE",
            headers: authHeader(),
          }
        )
        .then((json) => {
          if (json.status.code === 200) {
          } else {
            setSnackbar({
              isOpen: true,
              message: t("Oops! Something went wrong."),
              type: "error",
            });
          }
        });
    });
    setSnackbar({
      isOpen: true,
      message: t("Sessions deleted."),
      type: "success",
    });
  };

  return (
    <div className="no-class-history-panel">
      <div className="all-sessions-container">
        <div className="all-sessions-header">
          <div className="left-partition">
            <button className="header-back-btn" onClick={props.onBack}>
              <i className="icon-left-chevron" />
            </button>
            <div className="header-heading">{t("No Class History")}</div>
          </div>
        </div>
        <div className="chat-feature-panel-body">
          {showNoData && (
            <NoDataScreen message={t("You don't have anything here. Select a class from the left menu and start chatting!")} />
          )}
          {allSessions.map((element) => {
            return (
              <div
                key={element.session_id}
                className="session-item"
                onClick={() => {
                  props.onPastSessionClick(
                    element.session_id,
                    element.last_log,
                    element.duration_in_seconds,
                    0
                  );
                }}
              >
                <div className="session-item-flex-wrapper">
                  <div className="left-partition">
                    <i className="icon-bookmark session-item-icon" />
                    <div>
                      <Moment format="DD/MM/YYYY HH:mm" utc local>
                        {element.updated_at}
                      </Moment>
                      <div className="session-item-duration">
                        {t("Duration:")}{" "}
                        {new Date(element.duration_in_seconds * 1000)
                          .toISOString()
                          .substr(11, 8)}
                      </div>
                    </div>
                  </div>
                  <div className="right-partition">
                    {props.enableLearning && (
                      <IconLabelButton
                        buttonLabel={t("Summary")}
                        onClick={(e) => {
                          e.stopPropagation();
                          props.onPastSessionClick(
                            element.session_id,
                            element.last_log,
                            element.duration_in_seconds,
                            1
                          );
                        }}
                      />
                    )}
                    <IconLabelButton
                      isNegative
                      buttonLabel={t("Delete")}
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDeleteSession(element.session_id);
                      }}
                    />
                  </div>
                </div>
                <div className="topic-list-wrapper">
                  {element.topics &&
                    element.topics.map((topic, index) => (
                      <span className="topic-name">
                        {topic.name}
                        {element.topics.length === index + 1 ? "" : ", "}
                      </span>
                    ))}
                </div>
              </div>
            );
          })}
        </div>
        {allSessions.length > pagination.pageSize &&
          pagination.pageNumbers.map((number) => (
            <li
              className={
                number === pagination.currentPage
                  ? "pagination-btn pagination-btn-active"
                  : "pagination-btn"
              }
              key={number}
              id={number}
              onClick={() => onPageNumberClick(number)}
            >
              {number}
            </li>
          ))}

        <Modal
          openModal={openDeleteSessionConfirm}
          onRequestClose={() => setOpenDeleteSessionConfirm(false)}
          buttonAction={deleteSession}
          buttonLabel={t("Delete session")}
        >
          {t("Are you sure you want to delete this session?")}
        </Modal>
        <Modal
          openModal={openDeleteAllSessionsConfirm}
          onRequestClose={() => setOpenDeleteAllSessionsConfirm(false)}
          buttonAction={deleteAllSessions}
          buttonLabel={t("Delete sessions")}
        >
          {t("Are you sure you want to delete all the sessions?")}
        </Modal>
        <Snackbar
          isOpen={snackbar.isOpen}
          primaryMessage={snackbar.message}
          type={snackbar.type}
          onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        />
      </div>
    </div>
  );
};

export default NoClassHistoryPanel;
