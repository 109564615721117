import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import NotificationItem from "../../components/NotificationItem";
import NoDataScreen from "../../components/NoDataScreen";

import { useTranslation } from "react-i18next";

const NotificationPanel = (props) => {
  const { t } = useTranslation('translation');
  let history = useHistory();
 
  const closeNotificationPanel = () => {
    props.setNotificationToggle(false);
    let rightPanel = document.getElementById("notification-panel");
    if (rightPanel) {
      rightPanel.classList.remove("open-panel-wrap");
    }
  };


  return (
    <div id="notification-panel" className="panel-wrap" tabIndex={0}>
      <div className="panel">
       <div className="notification-panel-header">
          <div className="flex-container">
            <div>{t("NOTIFICATIONS")}</div>
            <span className="notification-count">
              {props.notificationCount}
            </span>
          </div>

          <div className="notification-panel-header-actions">
            {/* <button
              className="see-all-button"
              onClick={() => showNotificationPage(0)}
              title="See all notification"
            >
              See all notifications
            </button> */}
            <div className="notification-action-container">
              <div className="right-align">
                <button
                  className="mark-read-button"
                  onClick={props.markAllRead}
                  title={t("Mark all as read")}
                >
                  {t("Mark all as read")}
                </button>
              </div>
              <div className="right-align">
                <button
                  className="mark-read-button"
                  onClick={props.clearAll}
                  title={t("Clear all")}
                >
                  {t("Clear all")}
                </button>
              </div>
            </div>
            <button
              className="close-button"
              onClick={closeNotificationPanel}
              title={t("Close")}
            >
              <i className="icon-close" />
            </button>
          </div>
        </div>

        {props.allNotifications.map((notification, index) => (
          <NotificationItem
            showNotificationButtons
            showPointer={notification.link ? true : false}
            key={notification._id}
            content={notification}
            onClick={(e) => props.onClick(notification.link, notification._id,  e?.target?.tagName?.toLowerCase() === 'a')}
            onDelete={(event) => props.onDelete(event, notification._id)}
            onCheckboxChange={(event) =>
              props.onCheckboxChange(
                event,
                notification._id,
                notification.is_read
              )
            }
            onCustomButtonClick={props.onCustomButtonClick}
            closeNotificationPanel={closeNotificationPanel}
          ></NotificationItem>
        ))}

        {props.allNotifications.length == 0 && (
          <NoDataScreen
            message={t("You don’t have any notifications.")}
          ></NoDataScreen>
        )}
        <button
          className="send-notification-btn"
          onClick={() => history.push("/all-notifications")}
          title={t("Send Notification")}
        >
          {t("See all notifications")}
        </button>
      </div>
    </div>
  );
};

export default NotificationPanel;
