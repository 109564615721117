import React, { useEffect, useState } from "react";
// import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
// import RangeSlider from "react-bootstrap-range-slider";
import useThrottledEffect from "use-throttled-effect";

import TextInput from "../TextInput";
import Button from "../Button";
import "./style.css";

import { useTranslation } from "react-i18next";

const DocumentPreview = (props) => {
  const { t } = useTranslation('translation');
  const [beforeKeywordValue, setBeforeKeywordValue] = useState(15);
  const [afterKeywordValue, setAfterKeywordValue] = useState(15);
  // const [beforeKeywordValue, setBeforeKeywordValue] = useState(props.beforeKeywordValue);
  // const [afterKeywordValue, setAfterKeywordValue] = useState(props.afterKeywordValue);

  // useThrottledEffect(
  //   () => {
  //     props.onSubmit(props.keyword, beforeKeywordValue, afterKeywordValue);
  //   },
  //   2000,
  //   [props.keyword]
  // );

  return (
    <div>
      {/* http://localhost:3000/questions/60c629f727060653f5892637 */}
      <div className="option-question-container">
        {props.onClickBackButton && (
          <div className="back-button-wrapper">
            <button
              className="header-back-btn"
              onClick={props.onClickBackButton}
            >
              <i className="icon-left-chevron" />
            </button>
            <div className="header-heading">{t("Go back to chat")}</div>
          </div>
        )}
        <div className="half-clearfix" />
        <div className="page-subheading">{t("Answer")}</div>
        <div>
          {/* <p>
            {props.answer ? props.answer : "Response will be displayed here"}
          </p> */}
          <TextInput
            required
            inputType="text"
            inputClass="input-nf"
            placeholder={t("Type keyword(s) here")}
            value={props.keyword}
            inputChange={(changeEvent) =>
              props.setKeyword(changeEvent.target.value)
            }
          ></TextInput>
          <div className="half-clearfix" />

          <Button
            buttonClass="primary-button custom-button"
            onClick={() =>
              props.onSubmit(
                props.keyword,
                beforeKeywordValue,
                afterKeywordValue
              )
            }
          >
            {t("Search")}
          </Button>
        </div>

        <div className="half-clearfix" />
        <div className="page-subheading">{t("More Information")}</div>

        <div class="content-preview-wrapper">
          <div class="preview-button-wrapper">
            <Button
              buttonClass="preview-button-up"
              onClick={() => {
                if (props.enableSearch) {
                  props.onSubmit(
                    props.keyword,
                    beforeKeywordValue + 25,
                    afterKeywordValue
                  );
                  setBeforeKeywordValue(beforeKeywordValue + 25);
                }
                else {
                  props.updateTextBlock(props.documentId, props.textBlockIds.previous, null)
                }
              }}
            >
              <svg
                aria-hidden="true"
                viewBox="0 0 16 16"
                version="1.1"
                data-view-component="true"
                height="16"
                width="16"
                class="octicon octicon-fold-up"
              >
                <path d="M7.823 1.677L4.927 4.573A.25.25 0 005.104 5H7.25v3.236a.75.75 0 101.5 0V5h2.146a.25.25 0 00.177-.427L8.177 1.677a.25.25 0 00-.354 0zM13.75 11a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zm-3.75.75a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zM7.75 11a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zM4 11.75a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zM1.75 11a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5z"></path>
              </svg>
            </Button>
          </div>

          <div className="content-preview-file-content">
            <pre
              className="conversation"
              dangerouslySetInnerHTML={{
                __html: props.content
                  ? props.content
                  : t("Sorry! Couldn't find the keyword."),
              }}
            ></pre>
          </div>
          <div class="preview-button-wrapper-down">
            <Button
              buttonClass="preview-button-down"
              onClick={() => {
                if (props.enableSearch) {
                  props.onSubmit(
                    props.keyword,
                    beforeKeywordValue,
                    afterKeywordValue + 25
                  );
                  setAfterKeywordValue(afterKeywordValue + 25);
                }
                else {
                  props.updateTextBlock(props.documentId, null, props.textBlockIds.next)
                }
              }}
            >
              <svg
                aria-hidden="true"
                viewBox="0 0 16 16"
                version="1.1"
                data-view-component="true"
                height="16"
                width="16"
                class="octicon octicon-fold-down"
              >
                <path d="M8.177 14.323l2.896-2.896a.25.25 0 00-.177-.427H8.75V7.764a.75.75 0 10-1.5 0V11H5.104a.25.25 0 00-.177.427l2.896 2.896a.25.25 0 00.354 0zM2.25 5a.75.75 0 000-1.5h-.5a.75.75 0 000 1.5h.5zM6 4.25a.75.75 0 01-.75.75h-.5a.75.75 0 010-1.5h.5a.75.75 0 01.75.75zM8.25 5a.75.75 0 000-1.5h-.5a.75.75 0 000 1.5h.5zM12 4.25a.75.75 0 01-.75.75h-.5a.75.75 0 010-1.5h.5a.75.75 0 01.75.75zm2.25.75a.75.75 0 000-1.5h-.5a.75.75 0 000 1.5h.5z"></path>
              </svg>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentPreview;
