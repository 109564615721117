import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useAuth } from "../../context/auth";
import { useAuth0 } from "../../react-auth0-spa";

import { authHeader } from "../../helpers/auth-header";
import useFetch from "../../helpers/remote";
import useTimer from "./useTimer";
import AccessDenied from "../../components/AccessDenied";
import Modal from "../../components/Modal";
import AgentClassService from "../../helpers/AgentClassService"
import { useTranslation } from "react-i18next";

const DefaultLayout = ({ children, hideLogout, ...rest }) => {
  const { t } = useTranslation('translation');
  let makeApiCall = useFetch();
  // const { logout } = useAuth0();

  let { showAccessDenied, setAuthTokens } = useAuth();
  // const { timer, handleStart, handlePause, handleReset } = useTimer(15);

  // const [openIdleModal, setOpenIdleModal] = useState(false);
  // const [responseTimeLeft, setResponseTimeLeft] = useState(15);

  const idleTimeout = 30; // minutes

  // const handleOnIdle = (event) => {
  //   handleIdleTimerPause();
  //   setOpenIdleModal(true);
  //   handleStart();
  // };

  // useEffect(() => {
  //   if (timer === 0) {
  //     logoutUser();
  //   }
  // }, [timer]);

  // const logoutUser = async () => {
  //   await endSession();

  //   if (process.env && process.env.REACT_APP_TYPE === "login_widget") {
  //     let initialToken = localStorage.getItem("widget_initial_token");
  //     setAuthTokens(false);
  //     localStorage.clear();
  //     AgentClassService.clear();
  //     window.location.href = `/?t=${initialToken}`;
  //   } else if (window.SSO_PROVIDER === "auth0") {
  //     localStorage.clear();
  //     AgentClassService.clear();
  //     logout({
  //       returnTo: window.location.origin,
  //     });             
  //   } else {
  //     localStorage.clear();
  //     AgentClassService.clear();
  //     setAuthTokens(false);
  //     window.location.href =
  //       window.SSO_DOMAIN + "/logout?redirect_uri=" + window.location.origin;
  //   }
  // };

  // const endSession = () => {
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: "PATCH",
  //       headers: authHeader(),
  //     };

  //     makeApiCall
  //       .request(
  //         window.CHAT_API + "/api/v1/chatlog/sessions/current",
  //         requestOptions
  //       )
  //       .then((json) => {
  //         if (json.status.code === 200) {
  //           resolve(json);
  //         } else {
  //           if (process.env && process.env.REACT_APP_TYPE === "login_widget") {
  //             let initialToken = localStorage.getItem("widget_initial_token");
  //             setAuthTokens(false);
  //             localStorage.clear();
  //             AgentClassService.clear();
  //             window.location.href = `/?t=${initialToken}`;
  //           } else if (window.SSO_PROVIDER === "auth0") {
  //             localStorage.clear();
  //             AgentClassService.clear();
  //             logout({
  //               returnTo: window.location.origin,
  //             });
  //           } else {
  //             localStorage.clear();
  //             AgentClassService.clear();
  //             setAuthTokens(false);
  //             window.location.href =
  //               window.SSO_DOMAIN +
  //               "/logout?redirect_uri=" +
  //               window.location.origin;
  //           }
  //         }
  //       });
  //   });
  // };

  // const { pause, reset } = useIdleTimer({
  //   timeout: 1000 * 60 * idleTimeout,
  //   onIdle: handleOnIdle,
  //   debounce: 500,
  // });

  // const handleIdleTimerReset = () => reset();
  // const handleIdleTimerPause = () => pause();

  if (showAccessDenied) {
    return <AccessDenied hideLogout={hideLogout} />;
  }

  return (
    <>
      {children}
      {/* <Modal
        openModal={openIdleModal}
        buttonAction={() => {
          handlePause();
          handleReset();
          setOpenIdleModal(false);
          handleIdleTimerReset();
        }}
        buttonLabel={t("Continue chatting")}
        onRequestClose={logoutUser}
        cancelButtonLabel={t("Logout")}
      >
        {t("Are you still chatting? You will be logged out in") + " " + timer + " " + t("seconds.")}
      </Modal> */}
    </>
  );
};

export default DefaultLayout;
