import React from "react";


import "./style.css";

import { useTranslation } from "react-i18next";
import infoCirclekImg from "../../assets/images/info-circle-red.svg";

const Notice = ({
  avatarIcon,
}) => {
  const { t } = useTranslation("translation");
  const botReplyDivRef = React.useRef(null);
  React.useEffect(() => {
    if (botReplyDivRef && botReplyDivRef.current) {
      // botReplyDivRef.current.scrollIntoView();
    }
  }, []);

  return (
    <div className={"bot-reply-wrapper"} ref={botReplyDivRef}>
      <div className="bot-bubble">
        <div className="bot-chat-avatar-container">
          <img className="bot-avatar-img" src={avatarIcon} />
        </div>
        <div className="bot-reply-container">
          <div className="text-reply-new">
            <div
              className="text-reply w-100 d-flex align-items-center flex-wrap"
            >
              Note: Responses with
              <div className="chatInfo-toltip position-relative">
                <button className="btn px-2">
                  <img src={infoCirclekImg} alt="" />
                </button>
                {/* <div className="toltip-description">
                  This answer is found from outside of the curated knowledge
                  base and system may occasionally generate incorrect or
                  misleading information and produce offensive or biased
                  content.
                </div> */}
              </div>
              uses information outside of the knowledge base.
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bot-reply-time">{replyTime}</div> */}
    </div>
  );
};

export default Notice;
