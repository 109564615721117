import React from "react";
import WindowOpener from "react-window-opener";

import { ResponsivePie } from "@nivo/pie";

const PopupChatOpener = (props) => {
  const agentId =  props.match.params?.agent
  
  const childResponse = (err, res) => {
    if (err) {
      console.log(res, 'err')
    }
    console.log(res, 'res')
  }

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "250px",
        height: "100px",
        transform: "translate(-50%, -50%)",
      }}
    >
      <WindowOpener
        width="600"
        height="600"
        url={`/?agent=${agentId}&through=admin-portal&keepAlive=true`}
        bridge={childResponse}
      >
        {" "}
        <div
          style={{
            backgroundColor: "rgb(9, 35, 230)",
            cursor: "pointer",
            border: "0",
            padding: "14px",
            display: "block",
            boxSizing: "border-box",
            width: "100%",
            overflow: "hidden",
            borderRadius: "5px",
            transition: "0.2s ease-in-out",
            color: "#fff",
            letterSpacing: "1px",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          Continue to chat
        </div>
      </WindowOpener>
    </div>
  );
}
export default PopupChatOpener;
