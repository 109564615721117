import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth0 } from "../../react-auth0-spa";
import { useAuth } from "../../context/auth";
import { v4 as uuidv4 } from "uuid";
import Moment from "react-moment";

import { authHeader } from "../../helpers/auth-header";
import useFetch from "../../helpers/remote";
import AgentClassService from "../../helpers/AgentClassService";
import {
  getNRandomArrayIndices,
  getUrlParameter,
} from "../../helpers/formatFunctions";
import { getLangValue } from "../../components/Internationalisation/i18n";

import throttle from "lodash/throttle";
import ChatInput from "../../components/ChatInput";
import BotChatBubble from "../../components/BotChatBubble";
import UserChatBubble from "../../components/UserChatBubble";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import SuggestionButton from "../../components/SuggestionButton";
import Snackbar from "../../components/Snackbar";
import DocumentPreview from "../../components/DocumentPreview";
import Form from "../../components/Form";
import FormSubmissionMessage from "../../components/FormSubmissionMessage";
import QuizGenerator from "../../components/QuizGenerator";
import QuizReport from "../../components/QuizReport";
import ChatFeaturePanel from "../ChatFeaturePanel";
import IntroPanel from "../../components/IntroPanel";
import ChatHeader from "../../components/ChatHeader";
import NotificationPanel from "./notificationPanel";
import BotReply from "../../components/BotReply";
import PastSessionPanel from "../PastSessionPanel";
import RatingInput from "../../components/RatingInput";
import AgentSelection from "../AgentSelection";
import ResumeSessionModal from "../ResumeSessionModal";
import BotQuizBubble from "../../components/BotQuizBubble";
import BotQuizResultBubble from "../../components/BotQuizResultBubble";
import PopupNotification from "../../components/PopupNotification";
import NoClassHistoryPanel from "../NoClassHistoryPanel";

import { getUserAvatarIcon } from "./data";
import { linkify, prettifyDate } from "../../helpers/formatFunctions";
import { useInterval } from "../../helpers/timer";

import NoodleLogo from "../../assets/images/logo-horizontal.png";
import AvatarIcon from "../../assets/images/walter-head-white.png";
import OpenAIIcon from "../../assets/images/openai-left-btn.svg";

import "./style.css";
import ChatFeedback from "../../components/ChatFeedback";
import AllBookmarks from "../AllBookmarks";
import AllSessions from "../AllSessions";
import UserReport from "../UserReport";
import AllAnswerQuestions from "../AllAnswerQuestions";
import { useTranslation } from "react-i18next";
import Notice from "../../components/BotReply/notice";
import ArrowImg from "../../assets/images/svg/down-long-arrow.svg";
import OrganisationSwitcher from "../OrganisationSwitcher/OrganisationSwitcher";

const Chat = (props) => {
  const defaultDisclaimer =
    "Please note that the information you receive from now on is outside of the set knowledge base and the system may occasionally generate incorrect or misleading information and produce offensive or biased content.";
  const { t } = useTranslation("translation");
  const {
    setAuthTokens,
    showAgentSelection,
    setShowAgentSelection,
    selectedClass,
    setSelectedClass,
    setAgentLanguage,
    agentDisclaimer,
    setAgentDisclaimer,
    showOrgSelection
  } = useAuth();

  const { logout } = useAuth0();
  const [feedbackMap, setFeedbackMap] = useState({});
  const [displayedQuestionsIdConvo, setDisplayedQuestionsIdConvo] = useState(
    []
  );
  const [feedbackComment, setFeedbackComment] = useState("");
  const [sessionFeedbackComment, setSessionFeedbackComment] = useState("");
  const [feedbackId, setFeedbackId] = useState("");
  const lastLog = useRef(null);
  const [newMessage, setNewMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openFeedbackContainer, setOpenFeedbackContainer] = useState(false);
  const [suggestedQuestionsData, setSuggestedQuestionsData] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [form, setForm] = useState({});
  const [showFormSubmissionMessage, setShowFormSubmissionMessage] =
    useState(false);
  const [formSubmissionMessageDetails, setFormSubmissionMessageDetails] =
    useState({
      heading: "",
      message: "",
      buttonLabel: "",
      type: "",
    });
  const [orgDefaultLang, setOrgDefaultLang] = useState("en");

  const [messageCopy, setMessageCopy] = useState("");
  const [showQuiz, setShowQuiz] = useState(false);
  const [quiz, setQuiz] = useState({});
  const [disableQuizButton, setDisableQuizButton] = useState(false);
  const [showQuizReport, setShowQuizReport] = useState({
    open: false,
    type: "success",
    data: {},
  });
  const [title, setTitle] = useState("Chat");
  const [chatContext, setChatContext] = useState("");

  // Settings variables
  const [companyLogo, setCompanyLogo] = useState(NoodleLogo);
  const [sidebarMessage, setSidebarMessage] = useState(
    "<p>Hi there! 👋</p><p>I am Walter, your smart chat buddy.<br></p>"
  );
  const [botAvatar, setBotAvatar] = useState(AvatarIcon);
  const [botName, setBotName] = useState("Walter");
  const [botDelay, setBotDelay] = useState(2000);
  const [customButtonData, setCustomButtonData] = useState([]);

  const [notificationToggle, setNotificationToggle] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [suggestionsExpanded, setSuggestionsExpanded] = useState(false);
  const [showHomePanel, setShowHomePanel] = useState(false);
  const [userAvatarIcon, setUserAvatarIcon] = useState(getUserAvatarIcon());
  const [hideChatInput, setHideChatInput] = useState(false);
  const [buttonReference, setButtonReference] = useState("");
  const [logoutAfterFeedback, setLogoutAfterFeedback] = useState(false);
  const [sessionFeedback, setSessionFeedback] = useState(0);
  const [openSessionEndModal, setOpenSessionEndModal] = useState(false);
  const [pastSessionData, setPastSessionData] = useState([]);
  const [enableLearning, setEnableLearning] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [sessionTime, setSessionTime] = useState(0);
  const [triggerBookmarkLoad, setTriggerBookmarkLoad] = useState(false);
  const [quizDisplayedQuestions, setQuizDisplayedQuestions] = useState([]);
  const [agentsMap, setAgentsMap] = useState({});
  const [selectedAgent, setSelectedAgent] = useState("");
  const [disableInput, setDisableInput] = useState(false);
  const [activeSession, setActiveSession] = useState({});
  const [showDocumentPreview, setShowDocumentPreview] = useState(false);
  const [answerForDocumentPreview, setAnswerForDocumentPreview] = useState("");
  const [replyIdForDocumentPreview, setReplyIdForDocumentPreview] =
    useState("");
  const [documentIdForDocumentPreview, setDocumentIdForDocumentPreview] =
    useState("");
  const [documentPreviewContent, setDocumentPreviewContent] = useState("");
  const [textBlockIds, setTextBlockIds] = useState({
    current: "",
    previous: "",
    next: "",
  });
  const [enableSearch, setEnableSearch] = useState(false);

  const [showResumeSessionModal, setShowResumeSessionModal] = useState(false);
  const [showNewSessionAgentSelection, setShowNewSessionAgentSelection] =
    useState(false);
  const [disableCustomButton, setDisableCustomButton] = useState(false);
  const [isConversationalQuizActive, setIsConversationalQuizActive] =
    useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [pastSessionActiveTab, setPastSessionActiveTab] = useState(false);
  const [popupNotificationData, setPopupNotificationData] = useState({});
  const [openPopupNotification, setOpenPopupNotification] = useState(false);
  const [toggleNoClassHistory, setToggleNoClassHistory] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showSidebarUI, setShowSidebarUI] = useState(false);
  const chatMessagesRef = useRef(null);
  const historyChatRef = useRef(null);
  const currentQuiz = useRef(null);
  const convoQuizAnswers = useRef(null);
  const initialRender = useRef(true);
  const conversationalQuizStrategy = useRef("normal");
  const [selectedSidebarOption, setSelectedSidebarOption] = useState("chat");
  const [sessionDisclaimers, setSessionDisclaimers] = useState([]);
  const [botMode, setBotMode] = useState(false);
  const [showScrollToDownButton, setShowScrollToDownButton] = useState(true);
  const initiallyCalled = useRef(null);

  const makeApiCall = useFetch();
  let history = useHistory();
  useInterval(() => {
    gaSendPing();
  }, 30000);

  useEffect(() => {
    getDefaultAgent("initial");
  }, [window.location.href]);

  useEffect(() => {
    getOrganisationName();
    checkAgentFeatureEnabled(
      "learning",
      () => {
        setEnableLearning(false);
      },
      () => {
        setEnableLearning(true);
      }
    );
    updateLocalSession();
    getNotifications();
    // getAllNotifications();
    if (initiallyCalled) {
      initiallyCalled.current = true;
    }

    if (!showForm) {
      updateScrollbar();
    }

    if (window.innerWidth && window.innerWidth < 769) {
      setIsMobile(true);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (document.getElementById("parent-container-scroll-view")) {
      document
        .getElementById("parent-container-scroll-view")
        .addEventListener("scroll", onScrollChatContainer);
    }
    return () => {
      if (document.getElementById("parent-container-scroll-view")) {
        document
          .getElementById("parent-container-scroll-view")
          .removeEventListener("scroll", onScrollChatContainer);
      }
    };
  }, []);

  const onScrollChatContainer = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
      // console.log("scrolled");
      setShowScrollToDownButton(false);
    } else {
      setShowScrollToDownButton(true);
      // console.log("scrolled top");
    }
  };

  useEffect(() => {
    chatMessagesRef.current = chatData; //allNotifications
    updateScrollbar();
    setTimeout(() => {}, 2000);
  }, [chatData]);

  useEffect(() => {
    if (historyChatRef.current !== null) {
      setChatData(historyChatRef.current);
    }
  }, [historyChatRef]);

  useEffect(() => {
    updateScrollbar();
  }, [showForm === false]);

  useEffect(() => {
    updateScrollbar();
  }, [showQuiz === false]);

  useEffect(() => {
    if (messageCopy !== "" && messageCopy.trim() !== "") {
      if (initiallyCalled) {
        initiallyCalled.current = false;
      }
      sendMessage(messageCopy);
      setSuggestionsExpanded(false);
      setDisableCustomButton(false);
      updateScrollbar();
      setMessageCopy("");
    }
  }, [messageCopy]);

  useEffect(() => {
    if (!initialRender.current) {
      getSuggestedQuestions();
    }
  }, [chatContext]);

  const handleResize = () => {
    if (window.innerWidth && window.innerWidth < 769) {
      setIsMobile(true);
    }
  };

  // start GA functions
  const gaSendPing = () => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });
      window.gtag("event", "active_session", {
        event_category: "session",
        event_label: activeSession._id,
      });
    }
  };

  const gaEndSession = () => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });

      window.gtag("event", "end_session", {
        event_category: "session",
        event_label: activeSession._id,
      });
    }
  };

  const gaClickButton = (button_type, button_label) => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });

      window.gtag("event", "click_button", {
        event_category: "conversation",
        event_label: activeSession._id + "::" + button_type,
        value: button_label,
      });
    }
  };
  // end GA functions

  const updateLocalSession = () => {
    getCurrentSession((ss) => {
      if (ss) {
        setDisclaimers(ss._id);
        setActiveSession(ss);
        gaSendPing();
      }
    });
  };

  const getNotifications = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/summary",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          // setNotificationSummary(json.data.notifications);
          setAllNotifications(json.data.notifications);
          setNotificationCount(json.data.unread_count);
          if (
            json.data.notifications[0] &&
            json.data.notifications[0].is_popup &&
            !json.data.notifications[0].is_read
          ) {
            setPopupNotificationData(json.data.notifications[0]);
            setOpenPopupNotification(true);
            markNotificationAsRead(json.data.notifications[0]._id);
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: "Error fetching notifications",
            type: "error",
          });
        }
      });
  };

  const markNotificationAsRead = (id) => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + id,
        {
          method: "PATCH",
          headers: authHeader(),
          body: JSON.stringify({
            is_read: true,
          }),
        },
        true
      )
      .then((json) => {});
  };

  const getAllNotifications = () => {
    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/notification/notifications?mail=inbox&page_size=1000",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setAllNotifications(json.data.notifications);
        } else {
          setSnackbar({
            isOpen: true,
            message: "Error fetching notifications",
            type: "error",
          });
        }
      });
  };

  const onClickNotification = (link, id) => {
    markNotificationAsRead(id);
    if (link !== "" && link !== null) {
      let strippedDomain = link.replace(/https?:\/\/[^\/]+/i, "");
      var localDomains = [
        "chatbot.staging.noodlefactory.ai",
        "chatbot.noodlefactory.ai",
        "localhost",
      ];
      var isLocalDomain = localDomains.some((element) =>
        link.includes(element)
      );

      if (isLocalDomain === true) {
        history.push(strippedDomain);
      } else {
        window.open(link, "_newtab");
      }
    }
  };

  const onDeleteNotification = (event, key) => {
    event.stopPropagation();
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + key,
        {
          method: "DELETE",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          getNotifications();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Unable to delete notification: " + json.status.message,
            type: "error",
          });
        }
      });
  };

  const onCheckboxChangeNotification = (event, key, isRead) => {
    event.stopPropagation();

    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + key,
        {
          method: "PATCH",
          headers: authHeader(),
          body: JSON.stringify({
            is_read: isRead ? false : true,
          }),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          getNotifications();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Unable to update notification: " + json.status.message,
            type: "error",
          });
        }
      });
  };

  const markAllRead = async () => {
    let notifications = [...allNotifications];

    await notifications.forEach((notification, i) => {
      if (notification.is_read === false) {
        makeApiCall
          .request(
            window.CHAT_API +
              "/api/v1/notification/notifications/" +
              notification._id,
            {
              method: "PATCH",
              headers: authHeader(),
              body: JSON.stringify({
                is_read: notification.isRead ? false : true,
              }),
            },
            true
          )
          .then((json) => {
            if (json.status.code === 200) {
            } else {
              setSnackbar({
                isOpen: true,
                message:
                  "Unable to update notification: " + json.status.message,
                type: "error",
              });
            }
          });
      }
    });

    getNotifications();
  };

  const clearAllNotification = async () => {
    let notifications = [...allNotifications];

    await notifications.forEach((notification, i) => {
      makeApiCall
        .request(
          window.CHAT_API +
            "/api/v1/notification/notifications/" +
            notification._id,
          {
            method: "DELETE",
            headers: authHeader(),
          },
          true
        )
        .then((json) => {
          if (json.status.code === 200) {
          } else {
            setSnackbar({
              isOpen: true,
              message: "Unable to update notification: " + json.status.message,
              type: "error",
            });
          }
        });
    });

    getNotifications();
  };

  const onChatInputFocus = () => {
    setSuggestionsExpanded(true);
  };

  const onChatInputBlur = () => {
    // setSuggestionsExpanded(false);
  };

  const callStartSessionApi = () => {
    let currentAgent = AgentClassService.getSelectedClass();
    applyAgentSetting(agentsMap[currentAgent].settings);
    getGreetings();
  };

  const callResumeSessionApi = () => {
    const requestOptions = {
      method: "PATCH",
      headers: authHeader(),
    };
    if (initiallyCalled) {
      initiallyCalled.current = false;
    }

    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatlog/sessions/last",
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data && json.data.session) {
            setActiveSession(json.data.session);
          }
          setShowResumeSessionModal(false);
          fetchChat(json.data.session._id);
          getLastFeedback();
          initialRender.current = false;
          getSuggestedQuestions();
          updateLocalSession();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Session could not be resumed.",
            type: "error",
          });
        }
      });
  };

  const startFeedbackTimer = (lastFeedbackDate) => {
    let timeDelay = [600000, 900000, 1200000];
    let timeDelay2 = [1000, 5000, 10000];
    let today = new Date();
    let lastFeedback = new Date(lastFeedbackDate);

    today.setHours(0, 0, 0, 0);
    lastFeedback.setHours(0, 0, 0, 0);

    if (today > lastFeedback) {
      setTimeout(() => {
        setOpenFeedbackContainer(true);
      }, timeDelay[Math.floor(Math.random() * timeDelay.length)]);
    }
  };

  const getLastFeedback = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatlog/feedbacks",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        let error = false;
        if (json && json.status.code === 200) {
          if (
            json.data &&
            json.data.session_feedback &&
            json.data.session_feedback[0]
          ) {
            startFeedbackTimer(json.data.session_feedback[0].created_at);
          }
        } else {
          error = true;
        }

        if (error) {
          setSnackbar({
            isOpen: true,
            message: "Error fetching feedback data",
            type: "error",
          });
        }
      });
  };

  const getSessions = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatlog/sessions",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (
            json.data.sessions.length > 0 &&
            json.data.sessions[0].is_active
          ) {
            setActiveSession(json.data.sessions[0]);
            fetchChat(json.data.sessions[0].session_id);
          } else if (
            json.data.sessions.length > 0 &&
            !json.data.sessions[0].is_active
          ) {
            setShowResumeSessionModal(true);
          } else {
            getGreetings();
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: "Error fetching sessions",
            type: "error",
          });
        }
      });
  };

  const getSummary = (sessionId) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/learning/session-summary?session_id=" +
          sessionId,
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          setSummaryData(json.data.session_summary);
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const checkSelectedAgentAndClass = () => {
    if (AgentClassService.getSelectedClass() && selectedClass) {
      callStartSessionApi();
      // setShowNewSessionAgentSelection(false);
      // getSuggestedQuestions();
    } else if (AgentClassService.getSelectedClass()) {
      callStartSessionApi();
    } else {
      if (Object.keys(agentsMap).length === 1) {
        onAgentChange(Object.keys(agentsMap)[0], "new");
      } else {
        setShowAgentSelection(true);
      }
    }
  };

  const getClasses = (receivedClass, type, origin) => {
    let receivedSelectedClass = receivedClass;
    makeApiCall
      .request(
        window.CHAT_API + `/api/v1/course/classes`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (receivedSelectedClass && origin === "params") {
            let temp = json.data.classes.filter(
              (classRecord) => classRecord._id === receivedSelectedClass
            );

            if (temp.length) {
              setSelectedClass(temp[0].connection_id);
              AgentClassService.setSelectedClass(temp[0].connection_id);
              if (type === "initial") {
                getLastFeedback();
                initialRender.current = false;
              }
              getSuggestedQuestions();
            } else {
              receivedSelectedClass = "";
            }
          } else if (receivedSelectedClass) {
            let temp = json.data.classes.filter(
              (classRecord) =>
                classRecord.connection_id === receivedSelectedClass
            );

            if (temp.length) {
              setSelectedClass(receivedSelectedClass);
              AgentClassService.setSelectedClass(receivedSelectedClass);
              if (type === "initial") {
                getLastFeedback();
                initialRender.current = false;
              }
              getSuggestedQuestions();
            } else {
              receivedSelectedClass = "";
            }
          }
          if (!receivedSelectedClass && json.data.classes.length > 0) {
            setSelectedClass(json.data.classes[0].connection_id);
            AgentClassService.setSelectedClass(
              json.data.classes[0].connection_id
            );
          }
          if (!showForm) {
            getSessions();
          }
          setShowSidebarUI(true);
        }
      });
  };

  const selectDefaultClass = () => {
    makeApiCall
      .request(
        window.CHAT_API + `/api/v1/course/classes`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data.classes.length > 0) {
            setSelectedClass(json.data.classes[0].connection_id);
            AgentClassService.setSelectedClass(
              json.data.classes[0].connection_id
            );
          }
        }
      });
  };

  const getDefaultAgent = (type) => {
    setAgentDisclaimer({ defaultDisclaimer: null });
    let allParams = getUrlParameter(window.location.search.split("?")[1]);
    if (
      "class" in allParams &&
      allParams.class &&
      "agent" in allParams &&
      allParams.agent &&
      type === "initial"
    ) {
      let receivedSelectedAgent = allParams.agent;
      let receivedSelectedClass = allParams.class;
      makeApiCall
        .request(window.CHAT_API + "/api/v1/organisation/agents", {
          method: "GET",
          headers: authHeader(),
        }, null, null, true)
        .then((json) => {
          if (json.status.code === 200) {
            if (json.data.agents.length === 0) {
              AgentClassService.setSelectedClass("");
              setSelectedClass("");
              AgentClassService.setSelectedClass("");
              setSelectedAgent("");
              history.push("/no-agent-found");
            } else {
              if (receivedSelectedAgent) {
                let temp = json.data.agents.filter(
                  (agent) => agent._id === receivedSelectedAgent
                );
                if (temp.length) {
                  AgentClassService.setSelectedClass(receivedSelectedAgent);
                  setSelectedAgent(receivedSelectedAgent);
                  setAgentDisclaimer({
                    ...agentDisclaimer,
                    defaultDisclaimer:
                      temp[0]?.settings?.improvising_disclaimer ||
                      defaultDisclaimer,
                  });
                  getClasses(receivedSelectedClass, type, "params");
                  setAgentLanguage(getLangValue(temp[0]?.language_code));
                } else {
                  receivedSelectedAgent = "";
                }
              }

              if (!receivedSelectedAgent && json.data.agents.length > 1) {
                setShowAgentSelection(true);
              } else {
                if (!receivedSelectedAgent) {
                  AgentClassService.setSelectedClass(json.data.agents[0]._id);
                  setSelectedAgent(json.data.agents[0]._id);
                  setAgentDisclaimer({
                    ...agentDisclaimer,
                    defaultDisclaimer:
                      json.data.agents[0]?.settings?.improvising_disclaimer ||
                      defaultDisclaimer,
                  });
                  setAgentLanguage(
                    getLangValue(json?.data?.agents?.[0]?.language_code)
                  );
                  getClasses(receivedSelectedClass, "", "params");
                  if (type === "initial") {
                    getLastFeedback();
                    initialRender.current = false;
                  }
                  getSuggestedQuestions();
                }

                let temp = {};
                json.data.agents.forEach((agent) => {
                  temp[agent._id] = agent;
                });
                setAgentsMap(temp);

                receivedSelectedAgent
                  ? applyAgentSetting(temp[receivedSelectedAgent].settings)
                  : applyAgentSetting(json.data.agents[0].settings);
              }
            }
          }
        });
    } else if (!("code" in allParams)) {
      makeApiCall
        .request(window.CHAT_API + "/api/v1/organisation/states", {
          method: "GET",
          headers: authHeader(),
        })
        .then((json) => {
          if (json.status.code === 200) {
            let receivedSelectedAgent = json.data.states.selected_agent;
            let receivedSelectedClass = json.data.states.selected_class;

            makeApiCall
              .request(window.CHAT_API + "/api/v1/organisation/agents", {
                method: "GET",
                headers: authHeader(),
              }, null, null, true)
              .then((json) => {
                if (json.status.code === 200) {
                  if (json.data.agents.length === 0) {
                    AgentClassService.setSelectedClass("");
                    setSelectedClass("");
                    AgentClassService.setSelectedClass("");
                    setSelectedAgent("");
                    history.push("/no-agent-found");
                  } else {
                    if (receivedSelectedAgent) {
                      let temp = json.data.agents.filter(
                        (agent) => agent._id === receivedSelectedAgent
                      );

                      if (temp.length) {
                        AgentClassService.setSelectedClass(
                          receivedSelectedAgent
                        );
                        setSelectedAgent(receivedSelectedAgent);
                        setAgentDisclaimer({
                          ...agentDisclaimer,
                          defaultDisclaimer:
                            temp[0]?.settings?.improvising_disclaimer ||
                            defaultDisclaimer,
                        });
                        setAgentLanguage(getLangValue(temp[0]?.language_code));
                        getClasses(receivedSelectedClass, type);
                      } else {
                        receivedSelectedAgent = "";
                      }
                    }

                    if (!receivedSelectedAgent && json.data.agents.length > 1) {
                      setShowAgentSelection(true);
                    } else {
                      if (!receivedSelectedAgent) {
                        AgentClassService.setSelectedClass(
                          json.data.agents[0]._id
                        );
                        setSelectedAgent(json.data.agents[0]._id);
                        setAgentDisclaimer({
                          ...agentDisclaimer,
                          defaultDisclaimer:
                            json.data.agents[0]?.settings
                              ?.improvising_disclaimer || defaultDisclaimer,
                        });
                        setAgentLanguage(
                          getLangValue(json?.data?.agents?.[0]?.language_code)
                        );
                        getClasses(receivedSelectedClass);
                        if (type === "initial") {
                          getLastFeedback();
                          initialRender.current = false;
                        }
                        getSuggestedQuestions();
                      }

                      let temp = {};
                      json.data.agents.forEach((agent) => {
                        temp[agent._id] = agent;
                      });
                      setAgentsMap(temp);

                      receivedSelectedAgent
                        ? applyAgentSetting(
                            temp[receivedSelectedAgent].settings
                          )
                        : applyAgentSetting(json.data.agents[0].settings);
                    }
                  }
                }
              });
          }
        });
    }
  };

  const checkAgentFeatureEnabled = (
    feature,
    default_callback,
    enabled_callback = null
  ) => {
    if (!AgentClassService.getSelectedAgent()) {
      return;
    }

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/agent-features", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        let is_feature_enabled = false;
        if (json.status.code === 200 && json.data.features) {
          for (let i = 0; i < json.data.features.length; i++) {
            if (json.data.features[i].name === feature) {
              is_feature_enabled = true;
              break;
            }
          }
        }
        if (is_feature_enabled) {
          if (
            enabled_callback !== null &&
            typeof enabled_callback === "function"
          ) {
            enabled_callback();
          }
        } else if (typeof default_callback === "function") {
          default_callback();
        }
      });
  };

  const applyAgentSetting = (settings) => {
    let avatarIcon = getUserAvatarIcon();
    setUserAvatarIcon(avatarIcon);

    let webchatSettings = settings;

    if (webchatSettings.chat_company_logo) {
      setCompanyLogo(webchatSettings.chat_company_logo);
    }

    if (webchatSettings.chat_short_message) {
      setSidebarMessage(webchatSettings.chat_short_message);
    }

    if (webchatSettings.chat_bot_avatar) {
      setBotAvatar(webchatSettings.chat_bot_avatar);
    }

    if (webchatSettings.chat_bot_name) {
      setBotName(webchatSettings.chat_bot_name);
    } else {
      setBotName("Walter");
    }

    if (webchatSettings.chat_bot_delay) {
      setBotDelay(webchatSettings.chat_bot_delay * 1000);
    } else {
      setBotDelay(2000);
    }

    const root = document.querySelector(":root");
    if (webchatSettings) {
      if (webchatSettings.chat_accent_color) {
        root.style.setProperty(
          "--primary-color",
          `#${webchatSettings.chat_accent_color}`
        );
      } else {
        root.style.setProperty("--primary-color", "#0923E6");
      }
    }

    if (webchatSettings.chat_text_color) {
      root.style.setProperty(
        "--primary-text-color",
        `#${webchatSettings.chat_text_color}`
      );
    }

    if (webchatSettings) {
      if (webchatSettings.chat_user_color) {
        root.style.setProperty(
          "--user-color",
          `#${webchatSettings.chat_user_color}`
        );
      } else {
        root.style.setProperty("--user-color", "#DCE5ED");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_user_text_color) {
        root.style.setProperty(
          "--user-text-color",
          `#${webchatSettings.chat_user_text_color}`
        );
      } else {
        root.style.setProperty("--user-text-color", "#0E0748");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_button_color) {
        root.style.setProperty(
          "--button-color",
          `#${webchatSettings.chat_button_color}`
        );
      } else {
        root.style.setProperty("--button-color", "#DCE5ED");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_button_text_color) {
        root.style.setProperty(
          "--button-text-color",
          `#${webchatSettings.chat_button_text_color}`
        );
      } else {
        root.style.setProperty("--button-text-color", "#0E0748");
      }
    }

    if (webchatSettings.customised_buttons) {
      setDisableCustomButton(false);
      setCustomButtonData(webchatSettings.customised_buttons);
    } else {
      setDisableCustomButton(false);
      setCustomButtonData([]);
    }
  };

  const onStartNewSession = () => {
    // setSelectedAgent("");
    // AgentClassService.setSelectedClass( "");
    // AgentClassService.setSelectedClass( "");

    // makeApiCall
    //   .request(window.CHAT_API + "/api/v1/organisation/states", {
    //     method: "PATCH",
    //     headers: authHeader(),
    //     body: JSON.stringify({
    //       states: {
    //         selected_agent: "",
    //       },
    //     }),
    //   })
    //   .then((json) => {
    //     if (json.status.code === 200) {
    if (initiallyCalled) {
      initiallyCalled.current = true;
    }
    setShowResumeSessionModal(false);
    getLastFeedback();
    initialRender.current = false;
    getSuggestedQuestions();
    // if (Object.keys(agentsMap).length === 1) {
    //   onAgentChange(Object.keys(agentsMap)[0], "new");
    // } else {
    checkSelectedAgentAndClass();
    // }
    // }
    // });
  };

  const displayBotBubbles = (element, callBack) => {
    if (
      element.reply.object_data &&
      Object.keys(element.reply.object_data).length > 0
    ) {
      historyChatRef.current.unshift(
        <BotQuizResultBubble
          avatarIcon={botAvatar}
          submissionData={element.reply.object_data}
          submissionId={element.reply.object_id}
          // displayedQuestionsId={currentQuiz.current.questions}
          onClick={handleQuizReportAction}
          bubbleType="history"
          enableLearning={enableLearning}
        />
      );
    } else if (element.reply.bubbles) {
      element.reply.bubbles.reverse().forEach((bubble, index) => {
        historyChatRef.current.unshift(
          <BotReply
            avatarIcon={botAvatar}
            bubbleIndex={index}
            key={bubble.id}
            documentId={element.document_id}
            replyId={element._id}
            replyType={bubble.template}
            data={bubble}
            replyTime={
              element.reply && element.reply.timestamp ? (
                <Moment unix format="DD/MM/YY hh:mm A">
                  {element.reply.timestamp / 1000}
                </Moment>
              ) : (
                ""
              )
            }
            bookmarkAction={bookmarkAction}
            recordFeedback={recordFeedback}
            showFeedback={true}
            feedback={feedbackMap[element._id] || null}
            setDocumentPreview={setDocumentPreview}
            element={element}
            feedbackType={element.feedback}
            showFallback={false}
            agentDisclaimer={agentDisclaimer}
          />
        );
      });
    } else if (element.reply.text) {
      historyChatRef.current.unshift(
        <BotChatBubble
          key={uuidv4()}
          avatarIcon={botAvatar}
          messageId={element._id}
          message={linkify(element.reply.text)}
          messageTime={
            element.reply && element.reply.timestamp ? (
              <Moment unix format="DD/MM/YY hh:mm A">
                {element.reply.timestamp / 1000}
              </Moment>
            ) : (
              ""
            )
          }
          feedback={feedbackMap[element._id] || null}
          showFeedback={true}
          recordFeedback={recordFeedback}
          bookmarkAction={bookmarkAction}
        />
      );
    }

    callBack();
  };

  const displayUserBubbles = (element) => {
    if (element.question && element.question.question) {
      historyChatRef.current.unshift(
        <UserChatBubble
          key={uuidv4()}
          avatarIcon={userAvatarIcon}
          message={element.question.question}
          messageTime={
            <Moment unix format="DD/MM/YY hh:mm A">
              {element.question.timestamp / 1000}
            </Moment>
          }
          bookmarkAction={bookmarkAction}
          copyAction={copyAction}
        />
      );
    }
  };

  const setDocumentPreview = (answer, replyId) => {
    setShowDocumentPreview(true);
    setAnswerForDocumentPreview(answer);
    setReplyIdForDocumentPreview(replyId);
    generateContent(replyId);
  };

  // console.group('[---------- activeSessoin ----------]')
  // console.log('activeSessoin', activeSession);
  // console.log('sessionDisclaimers', sessionDisclaimers);
  // console.groupEnd()

  const fetchChat = (sessionId) => {
    setChatData([]);
    setDisclaimers(sessionId);

    // if ( disclaimerIndex >= 0 ) {
    //   oldDisclaimers[disclaimerIndex].shownFeedback = shownFeedback
    // } else {
    // }
    chatMessagesRef.current = [];
    historyChatRef.current = [];

    let url;

    // if (lastLog._id) {
    //   url = `/api/v1/chatlog/logs?session_id=${sessionId}&last_log_id=${lastLog._id}`;
    // } else {
    url = `/api/v1/chatlog/logs?session_id=${sessionId}`;
    // }

    setSnackbar({
      isOpen: true,
      message: t("Loading conversations") + "..",
      type: "bubble",
    });

    makeApiCall
      .request(window.CHAT_API + url, {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          let chatLogs = json.data.logs;

          if (chatLogs && chatLogs.length && chatLogs[0].context) {
            if ("is_input_disabled" in chatLogs[0].context) {
              setHideChatInput(chatLogs[0].context.is_input_disabled);
            }
          }

          lastLog.current = json.data.last_log_id;
          chatLogs.forEach((element) => {
            if (element.reply) {
              displayBotBubbles(element, () => {
                displayUserBubbles(element);
              });
            }
          });

          if (chatLogs && chatLogs.length) {
            if (
              chatLogs[0].reply &&
              chatLogs[0].reply.buttons &&
              chatLogs[0].reply.buttons.length > 0
            ) {
              historyChatRef.current.push(
                <UserChatBubble
                  isButtonContainer
                  key={uuidv4()}
                  avatarIcon={AvatarIcon}
                  message={chatLogs[0].reply.buttons.map((button, index) => (
                    <SuggestionButton
                      key={index}
                      buttonLabel={button.label}
                      buttonAction={() => onCustomButtonClick(button)}
                    />
                  ))}
                  showFeedback={false}
                />
              );
            }
          }
          setChatData(historyChatRef.current);
          updateScrollbar();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });

    setChatContext("");
    // getSuggestedQuestions();
  };

  const setDisclaimers = (sessionId) => {
    const oldDisclaimers = [...sessionDisclaimers];
    let disclaimerIndex = oldDisclaimers.findIndex(
      (disc) => disc.sessionId === sessionId
    );
    if (disclaimerIndex < 0) {
      oldDisclaimers.push({
        sessionId,
        shownFeedback: false,
      });
    }
    setSessionDisclaimers(oldDisclaimers);
  };

  const checkIfDisclaimerHasAlreadyBeenShown = () => {
    const oldDisclaimers = [...sessionDisclaimers];
    let disclaimerIndex = oldDisclaimers.findIndex(
      (disc) => disc.sessionId === activeSession._id
    );
    return (
      (disclaimerIndex >= 0 && oldDisclaimers[disclaimerIndex].shownFeedback) ||
      false
    );
  };

  const openPastSessionPanel = (activeTab) => {
    setPastSessionActiveTab(activeTab);
    let rightPanel = document.getElementById("past-session-panel");

    if (rightPanel) {
      rightPanel.classList += " open-panel-wrap";
    }
  };

  const onPastSessionClick = (lastLog, sessionId, time, activeTab) => {
    setSessionTime(Math.floor(time / 60));
    openPastSessionPanel(activeTab);
    getSummary(sessionId);
    let url;

    // if (lastLog._id) {
    //   url = `/api/v1/chatlog/logs?session_id=${sessionId}&last_log_id=${lastLog._id}`;
    // } else {
    url = `/api/v1/chatlog/logs?session_id=${sessionId}`;
    // }

    makeApiCall
      .request(window.CHAT_API + url, {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          setPastSessionData(json.data.logs);
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });

    setChatContext("");
  };

  const onScroll = useMemo(() => {
    const throttled = throttle(
      (e) =>
        e.target.scrollTop > 1 && e.target.scrollTop < 5
          ? fetchChat(false)
          : null,
      300
    );
    return (e) => {
      e.persist();
      return throttled(e);
    };
  }, []);

  const getCurrentSession = (callback) => {
    if (!AgentClassService.getSelectedAgent()) {
      return;
    }

    makeApiCall
      .request(window.CHAT_API + "/api/v1/chatlog/sessions/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        let error = false;
        if (json && (json.status.code === 200 || json.status.code === 404)) {
          if (json.data && json.data.session) {
            callback(json.data.session);
          } else {
            callback(null);
          }
        } else {
          error = true;
        }

        if (error) {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const getOrganisationName = () => {
    let avatarIcon = getUserAvatarIcon();
    setUserAvatarIcon(avatarIcon);

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/organisations/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data.organisation && json.data.organisation.name) {
            setTitle(`${json.data.organisation.name} - Chat`);
          }
          if (json?.data?.organisation?.default_language_code) {
            setOrgDefaultLang(
              getLangValue(json?.data?.organisation?.default_language_code)
            );
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const addBookmark = (text, isAgent) => {
    makeApiCall
      .request(window.CHAT_API + "/api/v1/bookmark/bookmarks", {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          text: text,
          is_agent: isAgent,
        }),
      })
      .then((json) => {
        if (json.status.code === 200) {
          setTriggerBookmarkLoad((data) => !data);
          setSnackbar({
            isOpen: true,
            message: "Bookmark is added successfully.",
            type: "success",
          });
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const bookmarkAction = (referrer, message) => {
    addBookmark(message, referrer === "bot");
  };

  const copyAction = (message) => {
    // put action here
    alert(message);
  };

  const getGreetings = (isClear) => {
    let body = {
      is_greeting: true,
      source: "webchat",
    };

    if (isClear) {
      body = {
        ...body,
        is_refresh: true,
      };
    }

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(body),
    };

    makeApiCall
      .request(window.CHAT_API + "/api/v1/chatbot/questions", requestOptions)
      .then((json) => {
        if (json.status.code === 200) {
          setBotMode(
            json.data &&
              json.data.context &&
              (json.data.context.botmode === "improvising" ||
                json.data.context.botmode === "document_searching")
          );
          if ("is_input_disabled" in json.data.context) {
            if (json.data.context.is_input_disabled === null) {
              setHideChatInput(false);
            } else {
              setHideChatInput(json.data.context.is_input_disabled);
            }
          }
          setChatData([]);
          createBotReply(json.data, chatData, false, false);
          updateLocalSession();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const getSuggestedQuestions = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    let requestUrl = window.CHAT_API + "/api/v1/chatbot/suggested-questions";

    if (chatContext) {
      requestUrl = requestUrl + "?topic_ids=" + chatContext;
    }

    makeApiCall.request(requestUrl, requestOptions).then((json) => {
      if (json.status.code === 200) {
        setSuggestedQuestionsData(json.data.suggested_questions);
      } else {
        setSnackbar({
          isOpen: true,
          message: "Oops! Something went wrong.",
          type: "error",
        });
      }
    });
  };

  const postFeedback = (feedback, key) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ type: feedback }),
    };
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatbot/replies/" + key + "/feedback",
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          // setSnackbar({
          //   isOpen: true,
          //   message: "Thank you for your feedback.",
          //   type: "success",
          // });
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const recordFeedback = (feedback, key) => {
    let feedbacks = { ...feedbackMap };
    feedbacks[key] = feedback;
    setFeedbackId(key);
    setFeedbackMap(feedbacks);
    if (feedback === 0) {
      setOpenModal(true);
    } else {
      postFeedback(feedback, key);
    }
  };

  const beforeLogout = () => {
    setOpenFeedbackModal(true);
    setLogoutAfterFeedback(true);
  };

  const logoutUser = async () => {
    await endSession("logout");

    if (process.env && process.env.REACT_APP_TYPE === "login_widget") {
      let initialToken = localStorage.getItem("widget_initial_token");
      setAuthTokens(false);
      localStorage.clear();
      AgentClassService.clear();
      window.location.href = `/?t=${initialToken}`;
    } else if (window.SSO_PROVIDER === "auth0") {
      localStorage.clear();
      AgentClassService.clear();
      logout({
        returnTo: window.location.origin,
      });
    } else {
      localStorage.clear();
      AgentClassService.clear();
      setAuthTokens(false);
      window.location.href =
        window.SSO_DOMAIN + "/logout?redirect_uri=" + window.location.origin;
    }
  };

  const endSession = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "PATCH",
        headers: authHeader(),
      };
    
      if (!AgentClassService.getSelectedAgent()) {
        return;
      }

      makeApiCall
        .request(
          window.CHAT_API + "/api/v1/chatlog/sessions/current",
          requestOptions
        )
        .then((json) => {
          if (json.status.code === 200) {
            gaEndSession();
            resolve(json);
          } else {
            if (process.env && process.env.REACT_APP_TYPE === "login_widget") {
              let initialToken = localStorage.getItem("widget_initial_token");
              setAuthTokens(false);
              localStorage.clear();
              AgentClassService.clear();
              window.location.href = `/?t=${initialToken}`;
            } else if (window.SSO_PROVIDER === "auth0") {
              localStorage.clear();
              AgentClassService.clear();
              logout({
                returnTo: window.location.origin,
              });
            } else {
              localStorage.clear();
              AgentClassService.clear();
              setAuthTokens(false);
              window.location.href =
                window.SSO_DOMAIN +
                "/logout?redirect_uri=" +
                window.location.origin;
            }
          }
        });
    });
  };

  const sendFeedbackComment = () => {
    // postFeedback(feedbackMap[feedbackId], feedbackId);
    if (feedbackComment) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ type: 0, comment: feedbackComment }),
      };

      makeApiCall
        .request(
          window.CHAT_API +
            "/api/v1/chatbot/replies/" +
            feedbackId +
            "/feedback",
          requestOptions
        )
        .then((json) => {
          if (json.status.code === 200) {
            // setSnackbar({
            //   isOpen: true,
            //   message: "Thank you for your feedback.",
            //   type: "success",
            // });
          } else {
            setSnackbar({
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            });
          }
        });
    }
    setFeedbackComment("");
    setOpenModal(false);
  };

  const sendSessionFeedback = () => {
    if (sessionFeedback) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          rating: sessionFeedback,
          comment: sessionFeedbackComment,
        }),
      };

      makeApiCall
        .request(window.CHAT_API + "/api/v1/chatlog/feedbacks", requestOptions)
        .then((json) => {
          if (json.status.code === 200) {
            setSnackbar({
              isOpen: true,
              message: "Thank you for your feedback.",
              type: "success",
            });
            setOpenFeedbackModal(false);
            setOpenFeedbackContainer(false);
            setSessionFeedbackComment("");
            setSessionFeedback(0);

            if (logoutAfterFeedback === true) {
              setSnackbar({
                isOpen: true,
                message:
                  "Thank you for your feedback! You will be logged out in 3 seconds.",
                type: "success",
              });

              setTimeout(function () {
                logoutUser();
              }, 3000);
            }
          } else {
            setSnackbar({
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            });
          }
        });
    }
    setFeedbackComment("");
    setOpenModal(false);
  };

  const onCustomButtonClick = async (button, origin) => {
    if (button && button.type && button.type === "question") {
      setMessageCopy(button.label);
      return;
    }

    setDisableCustomButton(true);
    let type = "",
      referenceId = "";

    if (button.reference) {
      type = button.reference.split("::")[0];
      referenceId = button.reference.split("::")[1];
    } else if (button.reference_id && button.type) {
      type = button.type;
      referenceId = button.reference_id;
    }

    if (
      button &&
      (button.type === "trigger" ||
        button.type === "return_to_normal" ||
        (!button.reference && !button.reference_id))
    ) {
      const questionJson = {
        question: button.label ? button.label : "Yes",
        source: "webchat",
        button_reference: button.reference ? button.reference : null,
      };

      if (!button.reference) {
        delete questionJson.button_reference;
      }

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(questionJson),
      };
      let json = await makeApiCall.request(
        window.CHAT_API + "/api/v1/chatbot/questions",
        requestOptions
      );
      if (json.status.code === 200) {
        setBotMode(
          json.data &&
            json.data.context &&
            (json.data.context.botmode === "improvising" ||
              json.data.context.botmode === "document_searching")
        );
        if ("is_input_disabled" in json.data.context) {
          if (json.data.context.is_input_disabled === null) {
            setHideChatInput(false);
          } else {
            setHideChatInput(json.data.context.is_input_disabled);
          }
        }

        chatMessagesRef.current.pop();
        setChatData(chatMessagesRef.current);

        let conversation = [...chatData];

        setChatData((data) =>
          data.concat(
            <UserChatBubble
              key={uuidv4()}
              avatarIcon={userAvatarIcon}
              message={questionJson.question}
              messageTime={prettifyDate(new Date())}
              bookmarkAction={bookmarkAction}
              copyAction={copyAction}
            />
          )
        );

        conversation.push(
          <UserChatBubble
            key={uuidv4()}
            avatarIcon={userAvatarIcon}
            message={questionJson.question}
            messageTime={prettifyDate(new Date())}
            bookmarkAction={bookmarkAction}
            copyAction={copyAction}
          />
        );

        setChatData(conversation);

        createBotReply(json.data, chatData, false);
        updateLocalSession();
      } else {
        setSnackbar({
          isOpen: true,
          message: "Oops! Something went wrong.",
          type: "error",
        });
      }
      return;
    } else {
      makeApiCall
        .request(
          window.CHAT_API +
            `/api/v1/chatbot/button-data/${type}::${referenceId}`,
          {
            method: "GET",
            headers: authHeader(),
          },
          true
        )
        .then((json) => {
          if (json.status.code === 200) {
            let temp = {};
            if ("form" in json.data.button_data) {
              temp = {
                form: json.data.button_data.form,
                type: "form",
              };
            } else if ("quiz" in json.data.button_data) {
              temp = {
                quiz: json.data.button_data.quiz,
                type: "quiz",
              };
            } else {
              temp = {
                question: json.data.button_data.question,
                reference: button.reference
                  ? button.reference
                  : button.reference_id,
                type: "question",
              };
            }

            onChatButtonClick(temp, origin);
          }
        });
    }

    updateScrollbar();
  };

  const onChatButtonClick = (button, origin) => {
    setDisableCustomButton(false);
    switch (button.type) {
      case "form":
        if (typeof button.form.layout === "string") {
          const { sections, layout, fields } = button.form;

          const sectionData = {};
          button.form.layout = JSON.parse(layout);

          button.form.layout.formSchema.properties.forEach((_, index) => {
            button.form.layout.formSchema.properties[index].title = "a";
          });
          const { fieldList, formSchema, uiSchema } = button.form.layout;

          if (sections && Array.isArray(sections)) {
            for (const section of sections) {
              const filteredFields = fields.filter(
                (field) => field.section === section
              );
              const filteredProperties = filteredFields.map((t) => t.name);
              const filteredUiSchema = {};
              const filteredFormSchema = {
                dependencies: {},
                properties: {},
              };
              const filteredFieldlist = [];

              // Todo: required

              const schemaKeys = Object.keys(uiSchema);
              for (let i = 0; i < schemaKeys.length; ++i) {
                if (filteredProperties.indexOf(schemaKeys[i]) !== -1) {
                  filteredUiSchema[schemaKeys[i]] = uiSchema[schemaKeys[i]];
                  filteredFieldlist.push(fieldList[i]);

                  filteredFormSchema.properties.push({
                    [filteredProperties[i]]:
                      formSchema.properties[filteredProperties[i]],
                  });
                }
              }

              if (filteredFieldlist.length > 0) {
                sectionData[section] = {
                  fieldList: filteredFieldlist,
                  formSchema: filteredFormSchema,
                  uiSchema: filteredUiSchema,
                };
              }
            }
          }

          sectionData[" "] = {
            fieldList,
            formSchema,
            uiSchema,
          };
          button.form.sectionData = sectionData;
        }
        openFormScreen(button.form);
        break;
      case "quiz":
        setDisableQuizButton(false);
        openQuizGeneratorScreen(button.quiz);
        break;
      case "question":
        if (button.reference) {
          setButtonReference(button.reference);
        }
        if (chatMessagesRef.current.length > 0 && origin !== "custom") {
          if (origin !== "quiz") {
            setChatData((data) =>
              data.concat(
                <BotReply avatarIcon={botAvatar} replyType={"loading"} />
              )
            );
            chatMessagesRef.current.pop();
          }
          setChatData(chatMessagesRef.current);
        }
        setMessageCopy(button.question);
        break;
      case "intent":
        if (button.reference) {
          setButtonReference(button.reference);
        }
        if (chatMessagesRef.current.length > 0 && origin !== "custom") {
          if (origin !== "quiz") {
            setChatData((data) =>
              data.concat(
                <BotReply avatarIcon={botAvatar} replyType={"loading"} />
              )
            );
            chatMessagesRef.current.pop();
          }
          setChatData(chatMessagesRef.current);
        }
        setMessageCopy(button.question);
        break;
      default:
        break;
    }
  };

  const handleQuizReportAction = (
    data,
    origin,
    displayedQuestionsId,
    buttonSubmissionData
  ) => {
    // console.log('data', data)
    // console.log('origin', origin)
    // console.log('displayedQuestionsId', displayedQuestionsId)
    // console.log('buttonSubmissionData', buttonSubmissionData)

    setShowQuizReport({
      open: false,
      type: "success",
      data: {},
    });
    setShowQuiz(false);
    if (origin !== "conversational") {
      // getSessions();
    }

    if (origin === "quiz" && (data.reference || data.reference_id)) {
      if (buttonSubmissionData && displayedQuestionsId) {
        chatMessagesRef.current.push(
          <BotQuizResultBubble
            avatarIcon={botAvatar}
            submissionData={buttonSubmissionData}
            onClick={() => onCustomButtonClick(data, "quiz")}
            enableLearning={enableLearning}
            displayedQuestionsId={displayedQuestionsId}
          />
        );
        setChatData(chatMessagesRef.current);
      }
      onCustomButtonClick(data, origin);
    }

    if (origin === "backToChat") {
      chatMessagesRef.current.push(
        <BotQuizResultBubble
          avatarIcon={botAvatar}
          submissionData={data}
          onClick={handleQuizReportAction}
          enableLearning={enableLearning}
          displayedQuestionsId={displayedQuestionsId}
        />
      );
      setChatData(chatMessagesRef.current);
    }
    setTimeout(() => updateScrollbar());
  };

  const openFormScreen = async (form) => {
    const json = await makeApiCall.request(
      window.CHAT_API + "/api/v1/form/forms/" + form._id + "/submissions/last?state=draft",
      {
        method: "GET",
        headers: authHeader(),
      }
    );

    setForm(form);
    setShowForm(true);
  };

  const activateLoading = () => {
    let details = {
      message: "Hang tight! Your form is being submitted.",
      heading: "Submitting...",
      buttonLabel: "",
      type: "loading",
    };
    setShowFormSubmissionMessage(true);
    setFormSubmissionMessageDetails(details);
  };

  const handleFormSubmit = async (formId, formDetails) => {
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    activateLoading();
    if (formDetails.formData && Object.keys(formDetails.formData).length > 0) {
      let json = await makeApiCall.request(
        window.CHAT_API + "/api/v1/form/forms/" + formId + "/submissions",
        {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({ form_data: formDetails.formData }),
        }
      );
      if (json.status.code === 200) {
        let details = {
          message: json.data.form_reply.text
            ? json.data.form_reply.text
            : json.data.form_reply.error
            ? json.data.form_reply.error
            : "Looks like something is not right here. Please contact your admin.",
          heading: json.data.form_reply.text
            ? ""
            : json.data.form_reply.error
            ? "Oops! Submission was unsucessful."
            : "Oops! Something went wrong.",
          buttonLabel: "GO BACK TO CHAT",
          type: json.data.form_reply.text
            ? "success"
            : json.data.form_reply.error
            ? "error"
            : "error",
        };
        setShowFormSubmissionMessage(true);
        setFormSubmissionMessageDetails(details);
      } else {
        setSnackbar({
          isOpen: true,
          message: "Oops! Something went wrong.",
          type: "error",
        });
      }
    }
  };

  const handleSubmissionMessageAction = () => {
    setShowFormSubmissionMessage(false);
    setShowForm(false);
    getSessions();
  };

  const onAnswerInput = (value, type, questionId) => {
    if (type === "text") {
      let tempAnswers = { ...convoQuizAnswers.current };
      tempAnswers[questionId] = {
        ...convoQuizAnswers.current[questionId],
        question_id: questionId,
        text: value,
      };
      convoQuizAnswers.current = tempAnswers;
    } else if (type === "radio" || type === "checkbox") {
      let tempAnswers = { ...convoQuizAnswers.current };

      tempAnswers[questionId] = {
        ...convoQuizAnswers.current[questionId],
        question_id: questionId,
        options: value,
      };
      convoQuizAnswers.current = tempAnswers;
    } else if (type === "matching") {
      let tempAnswers = { ...convoQuizAnswers.current };
      let temp = [];
      Object.keys(value).forEach((key) => {
        temp.push({
          term: key,
          definition: value[key] ? value[key].id : "",
        });
      });
      tempAnswers[questionId] = {
        ...convoQuizAnswers.current[questionId],
        question_id: questionId,
        matches: temp,
      };
      convoQuizAnswers.current = tempAnswers;
    } else if (type === "group") {
      let tempAnswers = { ...convoQuizAnswers.current };
      Object.entries(value).forEach(
        ([key, value]) => (tempAnswers[key] = value)
      );
      convoQuizAnswers.current = tempAnswers;
    }

    let currentIdIndex = Object.keys(
      currentQuiz.current.transformedQuestions
    ).indexOf(questionId);

    if (
      conversationalQuizStrategy.current === "branching" &&
      currentQuiz.current.transformedQuestions[questionId].type === "radio"
    ) {
      currentQuiz.current.transformedQuestions[questionId].options &&
        currentQuiz.current.transformedQuestions[questionId].options.forEach(
          (option) => {
            if (option.id === convoQuizAnswers.current[questionId].options[0]) {
              if (option.branch && option.branch.action) {
                if (option.branch.action === "end-quiz") {
                  askConversationalQuizQuestion(questionId, true);
                } else if (
                  option.branch.action === "jump-to-question" &&
                  option.branch.reference_id
                ) {
                  askConversationalQuizQuestion(
                    option.branch.reference_id,
                    false
                  );
                }
              } else if (
                currentIdIndex + 1 <=
                  Object.keys(currentQuiz.current.transformedQuestions).length -
                    1 &&
                currentQuiz.current.transformedQuestions
              ) {
                askConversationalQuizQuestion(
                  Object.keys(currentQuiz.current.transformedQuestions)[
                    currentIdIndex + 1
                  ],
                  false
                );
              } else if (
                currentIdIndex + 1 ===
                Object.keys(currentQuiz.current.transformedQuestions).length
              ) {
                askConversationalQuizQuestion(questionId, true);
              }
            }
          }
        );
    } else if (
      currentIdIndex + 1 <=
        Object.keys(currentQuiz.current.transformedQuestions).length - 1 &&
      currentQuiz.current.transformedQuestions
    ) {
      askConversationalQuizQuestion(
        Object.keys(currentQuiz.current.transformedQuestions)[
          currentIdIndex + 1
        ],
        false
      );
    } else if (
      currentIdIndex + 1 ===
      Object.keys(currentQuiz.current.transformedQuestions).length
    ) {
      askConversationalQuizQuestion(questionId, true);
    }
  };

  const onConversationalQuizSubmit = async () => {
    const displayedQuestionsIdConvoFallBack = Object.keys(
      currentQuiz.current.transformedQuestions
    );
    const displayedQuestionsId =
      displayedQuestionsIdConvo.length > 0
        ? displayedQuestionsIdConvo
        : displayedQuestionsIdConvoFallBack;

    let json = await makeApiCall.request(
      window.CHAT_API +
        "/api/v1/quiz/quizzes/" +
        currentQuiz.current._id +
        "/submissions",
      {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          selected_question_ids: displayedQuestionsId,
          answers: Object.values(convoQuizAnswers.current),
          action: "submit",
        }),
      }
    );

    if (json.status.code === 200) {
      setTimeout(() => {
        setChatData((data) => data.slice(0, data.length - 1));
        updateScrollbar();

        setChatData((data) =>
          data.concat(
            <BotQuizResultBubble
              showLoading
              avatarIcon={botAvatar}
              submissionData={json.data.quiz_submission}
              displayedQuestionsId={displayedQuestionsId}
              onClick={handleQuizReportAction}
              updateScrollbar={updateScrollbar}
              enableLearning={enableLearning}
            />
          )
        );
        setHideChatInput(false);
        setIsConversationalQuizActive(false);
      }, 2000);

      updateScrollbar();
    } else if (json.status.code < 500) {
      setSnackbar({
        isOpen: true,
        message: "Unable to submit quiz: " + json.status.message,
        type: "error",
      });
    } else {
      // error
      setSnackbar({
        isOpen: true,
        message: "Oops! Something went wrong.",
        type: "error",
      });
    }
  };

  const askConversationalQuizQuestion = (currentQuestionId, isLastQuestion) => {
    // let temp =
    //   currentQuiz.current.sequence[currentSequenceIndex];
    setChatData((data) =>
      data.concat(
        <BotQuizBubble
          avatarIcon={botAvatar}
          questionData={
            currentQuiz.current.transformedQuestions[currentQuestionId]
          }
          questionId={currentQuestionId}
          isLastQuestion={isLastQuestion}
          onAnswerInput={onAnswerInput}
          onSubmit={onConversationalQuizSubmit}
          updateScrollbar={updateScrollbar}
        />
      )
    );
    updateScrollbar();
  };

  const openQuizGeneratorScreen = (selectedQuiz) => {
    if (selectedQuiz.display_mode === "conversational") {
      // chatMessagesRef.current.pop();
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);

      currentQuiz.current = selectedQuiz;

      setHideChatInput(true);
      setIsConversationalQuizActive(true);
      let temp = {};
      selectedQuiz.questions.forEach((question) => {
        if (
          question.type === "text" ||
          question.type === "bullet" ||
          question.type === "newbullet"
        ) {
          temp[question.id] = {
            text: "",
            question_id: question.id,
          };
        } else if (
          question.type === "table" ||
          question.type === "description"
        ) {
          temp[question.id] = {
            text: "[]",
            question_id: question.id,
          };
        } else if (question.type === "matching") {
          temp[question.id] = {
            matches: [],
            question_id: question.id,
          };
        } else if (question.options) {
          temp[question.id] = {
            options: [],
            question_id: question.id,
          };
        }
      });
      convoQuizAnswers.current = temp;
      transformQuizQuestions();
    } else {
      setQuiz(selectedQuiz);
      setShowQuiz(true);
    }
  };

  const transformQuizQuestions = () => {
    let temp = {};
    currentQuiz.current.questions.forEach((question) => {
      if (question.parent_id && temp[question.parent_id]) {
        temp[question.parent_id] = {
          ...temp[question.parent_id],
          subquestions:
            "subquestions" in temp[question.parent_id]
              ? temp[question.parent_id].subquestions.concat([question])
              : [].concat([question]),
        };
      } else {
        temp[question.id] = question;
      }
    });
    currentQuiz.current.transformedQuestions = temp;
    initialiseConversationalQuiz();
  };

  const initialiseConversationalQuiz = () => {
    let displayCount = currentQuiz.current.display_questions_count
      ? currentQuiz.current.display_questions_count
      : currentQuiz.current.transformedQuestions.length;

    if (
      displayCount &&
      displayCount < currentQuiz.current.transformedQuestions.length
    ) {
      conversationalQuizStrategy.current = "random";
    }

    Object.keys(currentQuiz.current.transformedQuestions).forEach(
      (questionId) => {
        if (currentQuiz.current.transformedQuestions[questionId].options) {
          currentQuiz.current.transformedQuestions[questionId].options.forEach(
            (option) => {
              if (option.branch && option.branch.action) {
                conversationalQuizStrategy.current = "branching";
              }
            }
          );
        }
      }
    );

    if (conversationalQuizStrategy.current === "random") {
      let toDisplay = getNRandomArrayIndices(
        Object.keys(currentQuiz.current.transformedQuestions),
        displayCount
      );

      let result = Object.keys(currentQuiz.current.transformedQuestions);
      let temp = toDisplay.map((item) => result[item]);
      setDisplayedQuestionsIdConvo(temp);
      currentQuiz.current.sequence = toDisplay; // to be removed
      askConversationalQuizQuestion(temp[0], false);
    } else {
      setDisplayedQuestionsIdConvo(
        Object.keys(currentQuiz.current.transformedQuestions)
      );
      //old
      let toDisplay = new Array(displayCount);
      for (let i = 0; i < displayCount; i++) {
        toDisplay[i] = i;
      }
      currentQuiz.current.sequence = toDisplay;
      askConversationalQuizQuestion(
        Object.keys(currentQuiz.current.transformedQuestions)[0],
        false
      );
    }
  };

  const handleQuizQuit = (quizId, data, displayedQuestionsId) => {
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    setQuizDisplayedQuestions(displayedQuestionsId);
    if (data) {
      quizSubmissionPost(quizId, data, "quit", displayedQuestionsId);
    }
  };

  const handleQuizSubmit = (quizId, data, displayedQuestionsId) => {
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    setQuizDisplayedQuestions(displayedQuestionsId);
    setDisableQuizButton(true);
    if (data) {
      quizSubmissionPost(quizId, data, "submit", displayedQuestionsId);
    }
  };

  const quizSubmissionPost = async (
    quizId,
    data,
    actionType,
    displayedQuestionsId
  ) => {
    let json = await makeApiCall.request(
      window.CHAT_API + "/api/v1/quiz/quizzes/" + quizId + "/submissions",
      {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          selected_question_ids: displayedQuestionsId,
          answers: data,
          action: actionType.toLowerCase(),
        }),
      }
    );

    if (json.status.code === 200) {
      // successfull
      if (actionType === "submit") {
        setShowQuizReport({
          open: true,
          type: "success",
          data: json.data.quiz_submission,
        });
        return json.status.quiz_submission;
      } else if (actionType === "quit") {
        setShowQuizReport({
          open: true,
          type: "quit",
          data: json.data.quiz_submission,
        });
        return json.status.quiz_submission;
      }
    } else if (json.status.code < 500) {
      setSnackbar({
        isOpen: true,
        message: "Unable to submit quiz: " + json.status.message,
        type: "error",
      });
    } else {
      // error
      setSnackbar({
        isOpen: true,
        message: "Oops! Something went wrong.",
        type: "error",
      });
    }
  };

  const createBotReply = async (
    newData,
    allData,
    showFeedback,
    showFallback = false
  ) => {
    let documentPreviewSetting;

    if (newData && newData.reply && newData.reply.document_id) {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      let json = await makeApiCall.request(
        window.CHAT_API +
          "/api/v1/convos/documents/" +
          newData.reply.document_id +
          "/settings",
        requestOptions
      );

      if (json.status.code === 200) {
        documentPreviewSetting = json.data.document.settings;
      }
    }

    setDisableInput(true);
    // setChatData((data) =>
    //   data.concat(<BotReply avatarIcon={botAvatar} replyType={"loading"} />)
    // );
    updateScrollbar();
    setTimeout(() => {
      setChatData((data) => data.slice(0, data.length - 1));

      updateScrollbar();

      let replyBubbles = newData.reply.bubbles;

      if (replyBubbles && replyBubbles.length > 1) {
        var j = 0;

        function delayBubble() {
          setTimeout(
            function () {
              if (j !== 0) {
                setChatData((data) => data.slice(0, data.length - 1));
              }

              setChatData((data) =>
                data.concat(
                  <BotReply
                    avatarIcon={botAvatar}
                    bubbleIndex={j}
                    key={replyBubbles[j].id}
                    documentId={newData.reply.document_id}
                    replyId={newData.reply._id}
                    replyType={replyBubbles[j].template}
                    data={replyBubbles[j]}
                    replyTime={prettifyDate(new Date())}
                    bookmarkAction={bookmarkAction}
                    recordFeedback={recordFeedback}
                    showFeedback={showFeedback}
                    feedback={feedbackMap[newData.reply.id] || null}
                    documentPreviewSetting={documentPreviewSetting}
                    setDocumentPreview={setDocumentPreview}
                    element={newData}
                    showFallback={showFallback}
                    agentDisclaimer={agentDisclaimer}
                  />
                )
              );
              updateScrollbar();

              setChatData((data) =>
                data.concat(
                  <BotReply avatarIcon={botAvatar} replyType={"loading"} />
                )
              );

              j++;
              if (j < replyBubbles.length) {
                delayBubble();
                updateScrollbar();
              } else {
                setChatData((data) => data.slice(0, data.length - 1));
                displayButtons();
                setDisableInput(false);

                if (showFallback && !checkIfDisclaimerHasAlreadyBeenShown()) {
                  // Only show in case of improvising mode and fallback
                  setChatData((data) =>
                    data.concat(<Notice avatarIcon={botAvatar} />)
                  );
                  setDisclaimerState();
                }

                if (showFallback) {
                  updateWasThisResponseUsefulWrapper();
                }
              }
            },
            j == 0 ? 0 : replyBubbles[j].delay
          );
        }
        delayBubble();
      } else {
        if (replyBubbles && replyBubbles.length > 0) {
          setChatData((data) =>
            data.concat(
              <BotReply
                bubbleIndex={0}
                avatarIcon={botAvatar}
                key={replyBubbles[0].id}
                replyId={newData.reply._id}
                documentId={newData.reply.document_id}
                data={replyBubbles[0]}
                replyTime={prettifyDate(new Date())}
                bookmarkAction={bookmarkAction}
                recordFeedback={recordFeedback}
                showFeedback={showFeedback}
                feedback={feedbackMap[newData.reply._id] || null}
                documentPreviewSetting={documentPreviewSetting}
                setDocumentPreview={setDocumentPreview}
                element={newData}
                showFallback={showFallback}
                agentDisclaimer={agentDisclaimer}
              />
            )
          );

          if (showFallback) {
            updateWasThisResponseUsefulWrapper();
          }
        }

        displayButtons();
        setDisableInput(false);
        if (showFallback && !checkIfDisclaimerHasAlreadyBeenShown()) {
          // Only show in case of improvising mode and fallback
          setChatData((data) => data.concat(<Notice avatarIcon={botAvatar} />));
          setDisclaimerState();
        }
      }

      updateScrollbar();

      function displayButtons() {
        if (
          newData.reply &&
          newData.reply.buttons &&
          newData.reply.buttons.length
        ) {
          setChatData((data) =>
            data.concat(
              <UserChatBubble
                isButtonContainer
                key={uuidv4()}
                avatarIcon={userAvatarIcon}
                // messageId={newData.reply._id}
                message={newData.reply.buttons.map((button, index) => (
                  <SuggestionButton
                    key={index}
                    buttonLabel={button.label}
                    buttonAction={() => onCustomButtonClick(button)}
                  />
                ))}
                showFeedback={false}
              />
            )
          );
          updateScrollbar();
        }
      }
    }, botDelay);
  };

  const setDisclaimerState = () => {
    const oldDisclaimers = [...sessionDisclaimers];
    let disclaimerIndex = oldDisclaimers.findIndex(
      (disc) => disc.sessionId === activeSession._id
    );
    if (disclaimerIndex >= 0) {
      oldDisclaimers[disclaimerIndex].shownFeedback = true;
    }
    setSessionDisclaimers(oldDisclaimers);
  };

  const onFieldChange = (value, label) => {
    if (label === "feedbackComment") {
      setFeedbackComment(value);
    } else if (label === "newMessage") {
      setNewMessage(value);
    } else if (label === "sessionFeedbackComment") {
      setSessionFeedbackComment(value);
    }
  };

  const sendMessage = async (newMessage) => {
    if (newMessage.trim()) {
      let conversation = [...chatData];

      setChatData((data) =>
        data.concat(
          <UserChatBubble
            key={uuidv4()}
            avatarIcon={userAvatarIcon}
            message={newMessage}
            messageTime={prettifyDate(new Date())}
            bookmarkAction={bookmarkAction}
            copyAction={copyAction}
          />
        )
      );

      conversation.push(
        <UserChatBubble
          key={uuidv4()}
          avatarIcon={userAvatarIcon}
          message={newMessage}
          messageTime={prettifyDate(new Date())}
          bookmarkAction={bookmarkAction}
          copyAction={copyAction}
        />
      );
      let payload = { question: newMessage, source: "webchat" };
      if (buttonReference) {
        payload.button_reference = buttonReference;
      }

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(payload),
      };
      setChatData(conversation);

      setNewMessage("");
      setButtonReference("");
      setChatData((data) =>
        data.concat(<BotReply avatarIcon={botAvatar} replyType={"loading"} />)
      );
      let json = await makeApiCall.request(
        window.CHAT_API + "/api/v1/chatbot/questions",
        requestOptions
      );
      if (json.status.code === 200) {
        setBotMode(
          json.data &&
            json.data.context &&
            (json.data.context.botmode === "improvising" ||
              json.data.context.botmode === "document_searching")
        );
        createBotReply(
          json.data,
          conversation,
          true,
          json.data &&
            json.data.context &&
            json.data.context.botmode === "improvising"
        );
        if (json.data.context && json.data.context.topic_id) {
          setChatContext(json.data.context.topic_id);
        } else {
          setChatContext("");
        }

        if ("is_input_disabled" in json.data.context) {
          if (json.data.context.is_input_disabled === null) {
            setHideChatInput(false);
          } else {
            setHideChatInput(json.data.context.is_input_disabled);
          }
        }

        if (json.data.context && json.data.context.botmode === "improvising") {
          getAgentDisclaimer();
        }
      } else {
        setChatData((data) => data.slice(0, data.length - 1));
        setSnackbar({
          isOpen: true,
          message: "Oops! Something went wrong.",
          type: "error",
        });
      }
    }
  };

  const handleSuggestionSelect = (key) => {
    setSelectedSuggestion(key);
    setMessageCopy(key.split("%%")[1]);
    setSuggestionsExpanded(false);
  };

  const updateScrollbar = (e = null) => {
    let messageElement = document.getElementsByClassName("parent-container");
    if (messageElement[0] && initiallyCalled && initiallyCalled.current) {
      messageElement[0].scrollTop = 0;
    }
    if (
      messageElement[0] &&
      initiallyCalled &&
      initiallyCalled.current === false
    ) {
      messageElement[0].scrollTop = messageElement[0].scrollHeight;
    }
  };

  const updateWasThisResponseUsefulWrapper = (e = null) => {
    let actionWrapers = document.querySelectorAll(".action-wraper");
    let threeDotsFeedbackWrapper = document.querySelectorAll(".text-reply-new");

    if (actionWrapers.length) {
      for (let index = 0; index < actionWrapers.length; index++) {
        if (index < actionWrapers.length - 1) {
          // Do not remove last index
          actionWrapers[index].classList.add("d-none-imp");
          // actionWrapers[index].remove()

          // if ( actionWrapers[index].contains('show-none') ) {
          // }
        }
      }
    }

    // Remove three dots for last division
    if (threeDotsFeedbackWrapper.length) {
      for (let index = 0; index < threeDotsFeedbackWrapper.length; index++) {
        let element = threeDotsFeedbackWrapper[index];
        if (index === threeDotsFeedbackWrapper.length - 1) {
          // Do not remove last index
          element.classList.add("show-none");
        } else {
          if (element.classList.contains("show-none")) {
            element.classList.remove("show-none");
          }
        }
      }
    }
  };

  const handleKeyPress = (event) => {
    if (!isMobile) {
      if (event.key === "Enter" && !event.shiftKey) {
        setMessageCopy(newMessage);
      }
    }
  };

  const openNotificationPanel = () => {
    setNotificationToggle(true);
    let rightPanel = document.getElementById("notification-panel");

    if (rightPanel) {
      rightPanel.classList += " open-panel-wrap";
    }
  };

  const openFeedbackModalFunction = () => {
    setOpenFeedbackModal(true);
  };

  const onConversationClear = () => {
    setOpenSessionEndModal(false);
    // setOpenFeedbackContainer(true);
    setShowHomePanel(false);
    setChatData([]);
    getGreetings(true);
    // getSummary();
  };

  const addBotAvatar = (chatComponent, avatar) => {
    if (chatComponent?.props?.avatarIcon && avatar) {
      return {
        ...chatComponent,
        props: {
          ...chatComponent.props,
          avatarIcon: avatar,
        },
      };
    }

    return chatComponent;
  };

  const onBackToRecentMsg = () => {
    let messageElement = document.getElementsByClassName("parent-container");
    if (messageElement[0]) {
      messageElement[0].scrollTop = messageElement[0].scrollHeight;
    }
  };

  const getChatPanel = (type) => {
    return (
      <>
        {selectedSidebarOption === "chat" && (
          <>
            <div className="chat-messages-outer-container">
              <div
                className="parent-container custom-scrollbar"
                id="parent-container-scroll-view"
              >
                {chatData.map((component) =>
                  addBotAvatar(component, botAvatar)
                )}
              </div>
            </div>
            {showScrollToDownButton && (
              <button
                className="btn btn-back-to-top"
                onClick={onBackToRecentMsg}
              >
                <img src={ArrowImg} alt="" />
              </button>
            )}
            <div className="chat-footer-container">
              {/* {showScrollToDownButton && (
                <button
                  className="btn btn-back-to-top"
                  onClick={onBackToRecentMsg}
                >
                  <img src={ArrowImg} alt="" />
                </button>
              )} */}
              {/* (suggestedQuestionsData.length > 0 && !suggestionsExpanded) &&  */}
              {/* {botMode && (
                <button className="btn btn-opneai" onClick={() => onCustomButtonClick({
                  question: "Return to normal chat",
                  source: "webchat",
                  reference: "trigger::switch_botmode",
                  type: "return_to_normal"
                })}>
                  <img src={OpenAIIcon} alt="" className="mr-2" />
                  {t("Return to knowledge base")}
                </button>
              )} */}

              <div className="chat-footer m-0">
                {/* {suggestedQuestionsData.length === 0 && (
                <div className="suggested-arrow-spaceholder" />
              )} */}
                {suggestedQuestionsData.length > 0 && (
                  <>
                    {!suggestionsExpanded && (
                      <div
                        className="suggested-arrow-container"
                        onClick={() => {
                          setSuggestionsExpanded(true);
                        }}
                      >
                        <i className="icon-up-chevron" />
                      </div>
                    )}
                    <div
                      className={
                        suggestionsExpanded
                          ? "suggested-questions-container"
                          : "suggested-questions-container suggested-questions-collapsed"
                      }
                    >
                      <div className="suggested-questions-header">
                        {suggestionsExpanded ? (
                          <div className="heading">Suggestions</div>
                        ) : (
                          <div className="heading"></div>
                        )}
                        <div
                          className="view-control-btn"
                          onClick={() => {
                            setSuggestionsExpanded(!suggestionsExpanded);
                          }}
                        >
                          {suggestionsExpanded ? (
                            <i className="icon-close" />
                          ) : null}
                        </div>
                      </div>
                      <div className="suggested-questions-body">
                        {suggestedQuestionsData.map((item) => (
                          <button
                            key={`${item._id}%%${item.name}`}
                            disabled={disableInput}
                            className={
                              disableInput
                                ? "topic-button-disabled"
                                : "topic-button"
                            }
                            onClick={() => {
                              handleSuggestionSelect(
                                `${item._id}%%${item.name}`
                              );
                            }}
                          >
                            {item.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </>
                )}
                <div className="chat-input-wrapper">
                  <ChatInput
                    value={newMessage}
                    avatarIcon={userAvatarIcon}
                    onKeyPress={handleKeyPress}
                    onSend={() => setMessageCopy(newMessage)}
                    placeholder={t("Type here to ask something") + "..."}
                    onChange={(value) => onFieldChange(value, "newMessage")}
                    onConversationClear={onConversationClear}
                    onChatInputFocus={onChatInputFocus}
                    onChatInputBlur={onChatInputBlur}
                    isDisabled={hideChatInput || disableInput}
                    disableCustomButton={disableCustomButton || disableInput}
                    isConversationalQuizActive={isConversationalQuizActive}
                    usetextArea
                    max={8}
                    inputType="text"
                  />
                  {/* <TextInput 
                    value={newMessage}
                    onKeyPress={handleKeyPress}
                    placeholder={t("Type here to ask something") + "..."}
                    inputChange={(event, name) =>
                      onFieldChange(event.target.value, name)}
                    onFocus={onChatInputFocus}
                    onBlur={onChatInputBlur}
                    disabled={hideChatInput || disableInput}
                    useTextArea
                    name={"newMessage"}
                  /> */}
                </div>
              </div>
            </div>
            {openFeedbackContainer ? (
              <ChatFeedback
                sessionFeedback={sessionFeedback}
                setSessionFeedback={setSessionFeedback}
                sessionFeedbackComment={sessionFeedbackComment}
                onFieldChange={onFieldChange}
                sendSessionFeedback={sendSessionFeedback}
                closeFeedback={() => setOpenFeedbackContainer(false)}
              />
            ) : null}
          </>
        )}

        {showForm && (
          <div className="chat-overlay-container">
            {showFormSubmissionMessage && (
              <FormSubmissionMessage
                message={formSubmissionMessageDetails.message}
                heading={formSubmissionMessageDetails.heading}
                buttonLabel={formSubmissionMessageDetails.buttonLabel}
                buttonAction={handleSubmissionMessageAction}
                type={formSubmissionMessageDetails.type}
              />
            )}
            {!showFormSubmissionMessage && (
              <Form
                onBack={() => {
                  setShowForm(false);
                  getSessions();
                }}
                onSubmit={handleFormSubmit}
                formSchema={form.layout.formSchema}
                uiSchema={form.layout.uiSchema}
                submitButtonLabel={
                  form.layout.submitLabelText
                    ? form.layout.submitLabelText
                    : "SUBMIT"
                }
                primaryHeaderText={form.layout.headerText}
                formId={form._id}
                formName={form.name}
                sections={form.sections}
              />
            )}
          </div>
        )}
        {showQuiz && (
          <div className="chat-overlay-container">
            {!showQuizReport.open && (
              <QuizGenerator
                quizData={quiz}
                onBack={() => {
                  setShowQuiz(false);
                  getSessions();
                }}
                onQuit={handleQuizQuit}
                onSubmit={handleQuizSubmit}
                disableSubmit={disableQuizButton}
                enableLearning={enableLearning}
              />
            )}
            {showQuizReport.open && (
              <QuizReport
                // hideScores={hideScores}
                submissionData={showQuizReport.data}
                type={showQuizReport.type}
                onClick={handleQuizReportAction}
                displayedQuestionsId={quizDisplayedQuestions}
                enableLearning={enableLearning}
              />
            )}
          </div>
        )}
        {selectedSidebarOption === "bookmarks" && (
          <AllBookmarks triggerBookmarkLoad={triggerBookmarkLoad} />
        )}
        {selectedSidebarOption === "sessions" && (
          <AllSessions
            onPastSessionClick={(sessionId, lastLog, time, activeTab) => {
              onPastSessionClick(lastLog, sessionId, time, activeTab);
            }}
            enableLearning={enableLearning}
          />
        )}
        {selectedSidebarOption === "answerquestions" && <AllAnswerQuestions />}
        {selectedSidebarOption === "reports" && <UserReport />}
      </>
    );
  };

  const generateSearchContent = (keyword, preceed, succeed) => {
    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/convos/replies/" +
          replyIdForDocumentPreview +
          "/content",
        {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            keyword: keyword,
            preceed: parseInt(preceed),
            succeed: parseInt(succeed),
          }),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setDocumentPreviewContent(json.data.content);
          setEnableSearch(true);
        } else {
          if (json.status && json.status.message) {
            setSnackbar({
              isOpen: true,
              message: "Oops! " + json.status.message,
              type: "error",
            });
          }
        }
      });
  };

  const generateContent = (replyId) => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/convos/replies/" + replyId + "/text-block",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setDocumentIdForDocumentPreview(json.data.text_block.document_id);
          setDocumentPreviewContent(json.data.text_block.text);
          setTextBlockIds({
            current: json.data.text_block._id,
            previous: json.data.previous_text_block_id,
            next: json.data.next_text_block_id,
          });
          setEnableSearch(false);
        } else {
          if (json.status && json.status.message) {
            setSnackbar({
              isOpen: true,
              message: "Oops! " + json.status.message,
              type: "error",
            });
          }
        }
      });
  };

  const updateTextBlock = (
    documentId,
    previousTextBlockId,
    nextTextBlockId
  ) => {
    var textBlockId = previousTextBlockId
      ? previousTextBlockId
      : nextTextBlockId
      ? nextTextBlockId
      : null;
    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/convos/documents/" +
          documentId +
          "/text-blocks/" +
          textBlockId,
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setDocumentIdForDocumentPreview(json.data.text_block.document_id);
          setDocumentPreviewContent(json.data.text_block.text);
          setTextBlockIds({
            current: json.data.text_block._id,
            previous: json.data.previous_text_block_id,
            next: json.data.next_text_block_id,
          });
          setEnableSearch(false);
        } else {
          if (json.status && json.status.message) {
            setSnackbar({
              isOpen: true,
              message: "Oops! " + json.status.message,
              type: "error",
            });
          }
        }
      });
  };

  const onAgentChange = async (agentId, type) => {
    setChatData([]);
    setShowDocumentPreview(false);
    setSuggestedQuestionsData([]);
    if (type === "switch") {
      await endSession();
    }
    AgentClassService.setSelectedClass(agentId);
    setSelectedClass("");
    AgentClassService.setSelectedClass("");
    setSelectedAgent(agentId);
    await makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/states", {
        method: "PATCH",
        headers: authHeader(),
        body: JSON.stringify({
          states: {
            selected_agent: agentId.toString(),
          },
        }),
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (type === "switch") {
            setShowAgentSelection(false);
            getDefaultAgent();
          } else {
            callStartSessionApi();
            setShowNewSessionAgentSelection(false);
            getSuggestedQuestions();
            selectDefaultClass();
          }
        }
      });

    checkAgentFeatureEnabled(
      "learning",
      () => {
        setEnableLearning(false);
      },
      () => {
        setEnableLearning(true);
      }
    );
  };

  const getPopups = () => {
    return (
      <React.Fragment>
        <Snackbar
          isOpen={snackbar.isOpen}
          primaryMessage={snackbar.message}
          type={snackbar.type}
          onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        />
        <Modal
          openModal={openModal}
          onRequestClose={() => {
            postFeedback(feedbackMap[feedbackId], feedbackId);
            setFeedbackComment("");
            setFeedbackId("");
            setOpenModal(false);
          }}
          buttonLabel="Send comments"
          cancelButtonLabel="No, that's all"
          buttonAction={sendFeedbackComment}
        >
          <div className="modal-heading">
            {t(
              "I am open to feedback on how to answer better. If you think that the response to your question can be better, please tell me."
            )}
          </div>
          <TextInput
            inputType="text"
            name="feedbackComment"
            placeholder={t("Type your comments here.")}
            value={feedbackComment}
            inputChange={(event, name) =>
              onFieldChange(event.target.value, name)
            }
          />
        </Modal>
        <Modal
          openModal={openFeedbackModal}
          onRequestClose={logoutUser}
          buttonLabel="SEND FEEDBACK"
          cancelButtonLabel="LOGOUT"
          buttonAction={sendSessionFeedback}
        >
          <div className="modal-heading">{t("How was your session?")}</div>
          <RatingInput
            options={{
              enumOptions: [
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
              ],
            }}
            onChange={setSessionFeedback}
          ></RatingInput>
          {sessionFeedback !== 0 && (
            <TextInput
              inputType="text"
              name="sessionFeedbackComment"
              placeholder={
                sessionFeedback > 3
                  ? t("Tell us how your experience was.")
                  : t("Tell us what we could improve.")
              }
              value={sessionFeedbackComment}
              inputChange={(event, name) =>
                onFieldChange(event.target.value, name)
              }
            />
          )}
        </Modal>
        <NotificationPanel
          notificationCount={notificationCount}
          onClick={onClickNotification}
          onDelete={onDeleteNotification}
          onCheckboxChange={onCheckboxChangeNotification}
          allNotifications={allNotifications}
          setNotificationToggle={setNotificationToggle}
          markAllRead={markAllRead}
          clearAll={clearAllNotification}
          onCustomButtonClick={onCustomButtonClick}
        />
        <PastSessionPanel
          pastSessionActiveTab={pastSessionActiveTab}
          sessionData={pastSessionData}
          botAvatar={botAvatar}
          userAvatarIcon={userAvatarIcon}
          summaryData={summaryData}
          sessionTime={sessionTime}
          enableLearning={enableLearning}
        />
        <ResumeSessionModal
          openModal={showResumeSessionModal}
          onStartNewSession={onStartNewSession}
          resumeSession={callResumeSessionApi}
        />
        <PopupNotification
          openNotification={openPopupNotification}
          onRequestClose={() => setOpenPopupNotification(false)}
          notificationData={popupNotificationData}
          onCustomButtonClick={onCustomButtonClick}
        />
      </React.Fragment>
    );
  };

  const getAgentDisclaimer = () => {
    const receivedSelectedAgent = AgentClassService.getSelectedClass();
    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/agents", {
        method: "GET",
        headers: authHeader(),
      }, null, null, true)
      .then((json) => {
        if (json.status.code === 200) {
          if (receivedSelectedAgent) {
            let temp = json.data.agents.filter(
              (agent) => agent._id === receivedSelectedAgent
            );
            if (temp.length) {
              setAgentDisclaimer({
                ...agentDisclaimer,
                defaultDisclaimer:
                  temp[0]?.settings?.improvising_disclaimer ||
                  defaultDisclaimer,
              });
            }
          }
        }
      });
  };

  if (showNewSessionAgentSelection) {
    return (
      <AgentSelection
        onAgentChange={(agentId) => onAgentChange(agentId, "new")}
        hideAgentSelection={() => {
          setShowNewSessionAgentSelection(false);
          updateScrollbar();
        }}
      />
    );
  }

  if (showAgentSelection) {
    return (
      <AgentSelection
        selectedAgent={selectedAgent}
        onAgentChange={(agentId) => onAgentChange(agentId, "switch")}
        hideAgentSelection={() => {
          setShowAgentSelection(false);
          updateScrollbar();
        }}
      />
    );
  }

  if (showOrgSelection) {
    return (
      <OrganisationSwitcher />
    )
  }

  return (
    <div className="chat-page-wrapper">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ChatHeader
        avatar={botAvatar}
        botName={botName}
        openFeedbackModal={openFeedbackModal}
        openNotificationPanel={openNotificationPanel}
        beforeLogout={beforeLogout}
        onBack={() => setShowHomePanel(true)}
        showSwitchAgent={Object.keys(agentsMap).length > 1}
        customButtonData={customButtonData}
        onCustomButtonClick={onCustomButtonClick}
        disableCustomButton={disableCustomButton || disableInput}
        isConversationalQuizActive={isConversationalQuizActive}
        enableLearning={enableLearning}
        showUnread={notificationCount > 0}
        showProfileEdit={true}
        setUserAvatarIcon={(src) => setUserAvatarIcon(src)}
        orgDefaultLang={orgDefaultLang}
        botMode={botMode}
      />
      <main className="chat-page-container">
        {!hideSidebar && (
          <div className="chat-sidebar is-4 p-0 is-hidden-mobile">
            <div className="chat-sidebar-content custom-scrollbar akshay-3">
              {!showDocumentPreview && (
                <>
                  <IntroPanel
                    logoImg={companyLogo}
                    message={sidebarMessage}
                    handleHideSidebar={() => setHideSidebar(true)}
                  />
                  {selectedAgent && showSidebarUI && (
                    <ChatFeaturePanel
                      enableLearning={enableLearning}
                      onPastSessionClick={(
                        sessionId,
                        lastLog,
                        time,
                        activeTab
                      ) => {
                        onPastSessionClick(lastLog, sessionId, time, activeTab);
                      }}
                      triggerBookmarkLoad={triggerBookmarkLoad}
                      getSessions={getSessions}
                      endSession={endSession}
                      setNoClassHistoryPanel={(status) =>
                        setToggleNoClassHistory(status)
                      }
                      showNoClassHistory={toggleNoClassHistory}
                      setSelectedSidebarOption={setSelectedSidebarOption}
                      selectedSidebarOption={selectedSidebarOption}
                      showChatPanel={() => setSelectedSidebarOption("chat")}
                    />
                  )}
                </>
              )}

              {showDocumentPreview && (
                <>
                  <div class="document-preview-wrapper">
                    <DocumentPreview
                      onClickBackButton={() => setShowDocumentPreview(false)}
                      answer={answerForDocumentPreview}
                      keyword={answerForDocumentPreview}
                      onSubmit={generateSearchContent}
                      content={documentPreviewContent}
                      setKeyword={setAnswerForDocumentPreview}
                      enableSearch={enableSearch}
                      textBlockIds={textBlockIds}
                      documentId={documentIdForDocumentPreview}
                      updateTextBlock={updateTextBlock}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {hideSidebar && (
          <div className="chat-sidebar-collapsed is-4 p-0 is-hidden-mobile">
            <button
              className="show-sidebar-btn"
              title="Show sidebar"
              onClick={() => setHideSidebar(false)}
            >
              <i className="icon-view" />
            </button>
          </div>
        )}

        {/* <div
          className={
            hideSidebar
              ? "chat-wrapper-collapsed p-0 is-hidden-mobile akshay-2"
              : "chat-wrapper p-0 is-hidden-mobile akshay-2.1"
          }
        > */}
        {/* && !isMobile  */}
        {/* {!toggleNoClassHistory && getChatPanel("chat")}
          {toggleNoClassHistory && (
            <NoClassHistoryPanel
              heading="No Class History"
              onBack={() => {
                setToggleNoClassHistory(false);
              }}
              enableLearning={enableLearning}
              onPastSessionClick={(sessionId, lastLog, time, activeTab) => {
                onPastSessionClick(lastLog, sessionId, time, activeTab);
              }}
            />
          )}
        </div> */}

        {/* For phones */}
        {showHomePanel && (
          <div className="chat-sidebar is-hidden-tablet akshay-1.1">
            <div className="mb-4">
              <IntroPanel logoImg={companyLogo} message={sidebarMessage} />
            </div>
            <ChatFeaturePanel
              onPastSessionClick={(sessionId, lastLog, time, activeTab) => {
                onPastSessionClick(lastLog, sessionId, time, activeTab);
              }}
              endSession={() => {
                endSession();
                setShowHomePanel(false);
              }}
              getSessions={getSessions}
              showChatPanel={() => setSelectedSidebarOption("chat")}
              selectedSidebarOption={selectedSidebarOption}
              setSelectedSidebarOption={(selector) => {
                setSelectedSidebarOption(selector);
                setShowHomePanel(false);
              }}
            />
            {/* <button
              className="chat-floating-button"
              onClick={() => {
                setShowHomePanel(false);
              }}
            >
              <i className="icon-edit-line" />
            </button> */}
            <div
              className="sidebar-overlay"
              onClick={() => {
                setShowHomePanel(false);
              }}
            ></div>
          </div>
        )}

        {/* && isMobile */}
        {/* {!showHomePanel  && ( */}
        <div className="chat-wrapper akshay-1">
          {!toggleNoClassHistory && getChatPanel("chat")}
          {toggleNoClassHistory && (
            <NoClassHistoryPanel
              heading="No Class History"
              onBack={() => {
                setToggleNoClassHistory(false);
              }}
              enableLearning={enableLearning}
              onPastSessionClick={(sessionId, lastLog, time, activeTab) => {
                onPastSessionClick(lastLog, sessionId, time, activeTab);
              }}
            />
          )}
        </div>
        {/* )} */}

        {getPopups()}
      </main>
    </div>
  );
};

export default Chat;
