class AgentClassService {
  constructor() {
    if (!AgentClassService.instance) {
      this.selectedAgent = null;
      this.selectedClass = null;//connection class id
      this.classUrlId = null;//class id

      AgentClassService.instance = this;
    }
    return AgentClassService.instance;
  }

  setSelectedAgent(selectedAgent) {
    this.selectedAgent = selectedAgent;
  }

  getSelectedAgent() {
    return this.selectedAgent;
  }

  setSelectedClass(selectedClass) {
    this.selectedClass = selectedClass;
  }

  getSelectedClass() {
    return this.selectedClass;
  }

  setClassUrlId(id) {
    this.classUrlId = id;
  }
  getClassUrlId(){
    return this.classUrlId;
  }

  saveToLocalStorage() {
    console.log("local save chat_selected_agent " + this.selectedAgent);
    console.log('current href ' + window.location.href);
    if(window.location.search.includes("agent=") || window.location.search.includes("code=")){
      sessionStorage.setItem("chat_selected_agent", this.selectedAgent);
      sessionStorage.setItem("chat_selected_class", this.classUrlId);
      return
    }
    if (this.selectedAgent) {
      localStorage.setItem("chat_selected_agent", this.selectedAgent);
    }
    if (this.selectedClass) {
      localStorage.setItem("chat_selected_class", this.classUrlId);
    }
  }

  //clear all value
  clear() {
    this.selectedAgent = null;
    this.selectedClass = null;
    this.classUrlId = null;
  }

  loadFromLocalStorage(initAgent, initClass) {
    const savedAgent =
      initAgent ||
      sessionStorage.getItem("chat_selected_agent") ||
      localStorage.getItem("chat_selected_agent");
    const savedClass =
      initClass ||
      sessionStorage.getItem("chat_selected_class") ||
      localStorage.getItem("chat_selected_class");

    if (savedAgent) {
      try {
        this.selectedAgent = JSON.parse(savedAgent);
      } catch (error) {
        this.selectedAgent = savedAgent;
      }
    }
    if (savedClass) {
      try {
        this.classUrlId = JSON.parse(savedClass);
        // this.selectedClass = JSON.parse(savedClass);
      } catch (error) {
        this.classUrlId = savedClass;
        // this.selectedClass = savedClass;
      }
    }
    this.saveToLocalStorage();
  }
}

const service = new AgentClassService();
export default service;
