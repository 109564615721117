import React, { useState } from "react";

import TextInput from "../TextInput";
import IconLabelButton from "../IconLabelButton";
import Checkbox from "../Checkbox";

import { useTranslation } from "react-i18next";

const LinkOption = (props) => {
  const { t } = useTranslation('translation');
  const [linkTitle, setLinkTitle] = useState(
    props.currentState.link && props.currentState.link.title
      ? props.currentState.link.title
      : ""
  );
  const [linkTarget, setLinkTarget] = useState(
    props.currentState.link && props.currentState.link.target
      ? props.currentState.link.target
      : ""
  );
  const [targetOption, setTargetOption] = useState(
    props.currentState.link && props.currentState.link.targetOption
      ? props.currentState.link.targetOption
      : "_blank"
  );
  const [showTargetError, setShowTargetError] = useState(false);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const onFieldChange = (value, key) => {
    if (showTargetError) {
      setShowTargetError(false);
    }
    if (key === "title") {
      setLinkTitle(value);
    } else if (key === "target") {
      setLinkTarget(value);
    }
  };

  const onChange = () => {
    if (!linkTarget) {
      setShowTargetError(true);
    }
    if (linkTitle && linkTarget) {
      props.onChange("link", linkTitle, linkTarget, targetOption);
      setLinkTitle("");
      setLinkTarget("");
    } else if (!linkTitle && linkTarget) {
      props.onChange("link", linkTarget, linkTarget, targetOption);
      setLinkTitle("");
      setLinkTarget("");
    }
  };

  const renderModal = () => {
    return (
      <div onClick={stopPropagation} className="rdw-link-modal">
        <TextInput
          autoFocus
          required
          showError={showTargetError}
          errorMessage={t("This field is required")}
          label={t("Video/image/website link (video and image links will be previewed in the chat)")}
          value={linkTarget}
          inputChange={(e) => onFieldChange(e.target.value, "target")}
        />
        <div className="clearfix" />
        <TextInput
          label={t("Link title (optional, can be used to create hyperlink tags)")}
          value={linkTitle}
          inputChange={(e) => onFieldChange(e.target.value, "title")}
        />
        {/* <div className="clearfix" /> */}
        {/* <Checkbox
          label="Open in new tab"
          checked={targetOption === "_blank"}
          onCheckboxChange={(e) => {
            if (e.target.checked) {
              console.log("hmm");
              setTargetOption("_blank");
            } else {
              setTargetOption("_self");
            }
          }}
        /> */}
        <div className="link-modal-action-bar">
          <IconLabelButton buttonLabel="Add" onClick={onChange} />
          <IconLabelButton buttonLabel="Cancel" onClick={props.doCollapse} />
        </div>
      </div>
    );
  };

  return (
    <div
      aria-haspopup="true"
      aria-expanded={props.expanded}
      aria-label="rdw-link"
      className="rdw-link-wrapper"
    >
      <div
        onClick={props.onExpandEvent}
        className="rdw-option-wrapper markdown-toolbar-option"
      >
        <img src={props.config.link.icon} alt="" />
      </div>
      {props.expanded ? renderModal() : undefined}
    </div>
  );
};

export default LinkOption;
