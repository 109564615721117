import React, { useEffect, useState } from "react";
import IconLabelButton from "../IconLabelButton";

import TextInput from "../TextInput";

import { useTranslation } from "react-i18next";

import "./style.css";

const HeaderDescriptionEditor = ({
  disableHeader,
  onTableChange,
  initialRowCount,
  tableValues,
  spellCheck,
  forInput,
}) => {
  const { t } = useTranslation('translation');
  const [tableMatrix, setTableMatrix] = useState(
    tableValues ? tableValues : [["", ""]]
  );

  useEffect(() => {
    if (initialRowCount && !tableValues) {
      let temp = new Array(initialRowCount).fill(new Array(2).fill(""));
      setTableMatrix(temp);
    }

    // if (tableValues) {
    //   let temp = [];
    //   tableValues.forEach((element) => {
    //     temp.push(["", ""]);
    //   });
    //   setTableMatrix(temp);
    // }
  }, []);

  useEffect(() => {
    onTableChange(tableMatrix);
  }, [tableMatrix]);

  const onRowCellChange = (value, rowIndex, rowCellindex) => {
    let temp = [...tableMatrix];
    temp[rowIndex][rowCellindex] = value;
    setTableMatrix(temp);
  };

  const addNewRow = () => {
    let temp = [...tableMatrix];
    temp.push(new Array(2).fill(""));
    setTableMatrix(temp);
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      return;
    }
  };

  const insertNewRow = (targetRowIndex) => {
    let temp = [...tableMatrix];
    temp.splice(targetRowIndex + 1, 0, new Array(2).fill(""));
    setTableMatrix(temp);
  };

  const removeRow = (targetRowIndex) => {
    let temp = [...tableMatrix];
    temp.splice(targetRowIndex, 1);
    setTableMatrix(temp);
  };

  return (
    <div>
      <div className="flex-container no-justify-flex">
        <div className="description-editor-wrapper">
          <div className="description-editor-body-wrapper">
            {tableMatrix &&
              tableMatrix.map((tableRow, rowIndex) => (
                <div
                  key={rowIndex.toString()}
                  className="description-editor-row"
                >
                  <div className="header-description-pair">
                    {tableRow.map((tableCell, rowCellIndex) => (
                      <div
                        key={`row${rowIndex.toString()}${rowCellIndex.toString()}`}
                        className={
                          rowCellIndex === 0
                            ? "table-header-cell-wrapper"
                            : "table-row-cell-wrapper"
                        }
                      >
                        <TextInput
                          spellCheck={spellCheck}
                          useTextArea
                          id={`row${rowIndex.toString()}${rowCellIndex.toString()}`}
                          value={tableCell}
                          inputChange={(event) =>
                            onRowCellChange(
                              event.target.value,
                              rowIndex,
                              rowCellIndex
                            )
                          }
                          min={rowCellIndex === 0 ? 1 : 2}
                          max={rowCellIndex === 0 ? 1 : 20}
                          placeholder={
                            rowCellIndex === 0
                              ? t("Type header text here")
                              : t("Type text here")
                          }
                          onKeyPress={handleEnter}
                          disabled={
                            rowCellIndex === 0 && disableHeader ? true : false
                          }
                          autoFocus={rowCellIndex === 0}
                        />
                      </div>
                    ))}
                  </div>
                  {!forInput && (
                    <div className="description-editor-row-actions">
                      <button
                        onClick={() => {
                          insertNewRow(rowIndex);
                        }}
                        className="toolbar-btn"
                        title="Insert row below"
                      >
                        <i className="icon-add toolbar-icon" />
                      </button>
                      {tableMatrix.length > 1 && (
                        <button
                          onClick={() => {
                            removeRow(rowIndex);
                          }}
                          className="toolbar-btn"
                          title={t("Remove row")}
                        >
                          <i className="icon-delete toolbar-icon" />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      {!forInput && (
        <React.Fragment>
          <div className="quarter-clearfix" />
          <IconLabelButton
            showIcon
            iconClass="icon-add"
            buttonLabel={t("Add row")}
            onClick={addNewRow}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default HeaderDescriptionEditor;
