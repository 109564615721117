import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import history from "../../history";
import LeftArrow from "../../assets/images/left-arrow-blue.svg";

import { LearnerItem } from "./LearnerItem";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";
import { Post } from "./Post";
import SendBtnImg from "../../assets/images/send-btn.svg"; 
import downarrowblk from "../../assets/images/blue-caret.svg"; 
import ReactSelect from "react-select";
import { Image } from "react-bootstrap";

export const SORTS_BY_OPTIONS = [
  { value: "vote", label: "Most upvotes" },
  { value: "recent", label: "Most recent" },
];

function DiscussionDetail(props) {
  const isChat = props?.isChat || false;
  const makeApiCall = useFetch();
  const { t } = useTranslation("translation");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [awnser, setAnwser] = useState("");
  const [sortBy, setSortBy] = useState(SORTS_BY_OPTIONS[0]);
  let id, question, isDicussion
  if(props?.thread) {
    question = props?.thread;
    isDicussion = question?.thread_type === "discussion";
    id = props?.thread?._id;
  } else {
    question= props?.location?.state?.question;
    isDicussion = props?.location?.state?.isDicussion;
    id = props.match.params.id;
  }

  const fetchPost = async (id) => {
    let filter = "post_type=answer&";
    switch (sortBy.value) {
      case "recent":
        filter = filter + `sort_field=created_at&sort_order=dsc`;
        break;
      case "vote":
        filter = filter + `sort_field=thumbsup_count&sort_order=dsc`;
        break;
      default:
    }
    makeApiCall
      .request(
        window.CHAT_API + `/api/v1/discussion/threads/${id}/posts?${filter}`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true,
        !isChat
      )
      .then((json) => {
        if (json.status.code === 200) {
          setPosts(json.data?.posts);
        }
        setLoading(false);
      });
  };

  const sendPost = async () => {
    const res = await makeApiCall.request(
      window.CHAT_API + `/api/v1/discussion/threads/${id}/posts`,
      {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          text: awnser,
        }),
      },
      true,
      !isChat
    );
    if (res.status.code === 200) {
      setAnwser("");
      fetchPost(id);
    }
  };

  useEffect(() => {
    fetchPost(id);
  }, [id, sortBy]);

  const recomendPost = posts.find((post) => post.answer_status === "approved");
  // const recomendPost = posts[0];

  return (
    <div className="team-discussion">
      <div className="py-3 sticky-top">
        <div
          className="f-16 w-600 c-blue flex my-2 is-clickable"
          onClick={() => {
            isChat ? props?.setThread(null) : history.goBack()
          }}
        >
          <img className="mr-2" src={LeftArrow} alt="" />
          {t("Return to previous page")}
        </div>
        <LearnerItem question={question} isDicussion={isDicussion} isDetails />
        <div className="d-flex is-justify-content-space-between align-items-center">
          {isDicussion ? (
            <b> {t("Responses")}</b>
          ) : (
            <b> {t("Other Answers")}</b>
          )}
          <div className="d-flex align-items-center">
            <span className="px-2"> {t("Sort by: ")}</span>
            <ReactSelect
              className="select-sort select-target select-blue shadow-none radius-8"
              classNamePrefix="select-target-prefix"
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => (
                  <img src={downarrowblk} alt="dropdown arrow" />
                ),
              }}
              options={SORTS_BY_OPTIONS}
              onChange={(e) => {
                setSortBy(e);
              }}
              value={sortBy}
            />
          </div>
        </div>
      </div>
      {recomendPost && (
        <div>
          <div className="mb-3">
            <b>{t("Recommended Answer: ")}</b>
          </div>
          <Post key={recomendPost._id} post={recomendPost} threadId={id} isChat={isChat}/>
        </div>
      )}

        <div className="pt-3 my-3 posts-container">
          {posts.length > 0 &&
            posts.map((post) => (
              <Post key={post._id} post={post} threadId={id} isChat={isChat}/>
            ))}
          { posts.length === 0 && <div className="text-center">{t("No answer yet")}</div>}
        </div>
      {question.answer_status !== "answered" && (
        <div className="d-flex post-input-container">
          <input
            className="flex-1 post-input"
            type="text"
            placeholder={t("Suggest an answer")}
            value={awnser}
            onChange={(e) => setAnwser(e.target.value)}
          />
          <Image
            src={SendBtnImg}
            className="send-button"
            aria-label="send"
            onClick={() => sendPost()}
            type="button"
            disabled={awnser.length === 0}
          />
        </div>
      )}
    </div>
  );
}

export default DiscussionDetail;
