import React from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";

import "./style.css";
import "../../index.css"; // this is for getting icons in the storybook

const TextInput = ({
  label,
  description,
  inputType,
  inputClass,
  value,
  inputChange,
  name,
  placeholder,
  disabled,
  onKeyPress,
  useTextArea,
  id,
  autoFocus,
  ref,
  required,
  showError,
  errorMessage,
  onFocus,
  onBlur,
  min,
  max,
  onPaste,
  tabIndex,
  spellCheck,
  ariaLabelledby,
  onKeyUp
}) => {
  const { t } = useTranslation('translation');
  return (
    <div className="textinput-container">
      {label && (
        <label className="textinput-label">
          {t(label)}
          <div className="textinput-description">{description}</div>
        </label>
      )}
      {!useTextArea && (
        <input
          required={required}
          ref={ref}
          className={`custom-input ${inputClass}`}
          type={inputType}
          value={value || ""}
          placeholder={t(placeholder)}
          onChange={(e) => inputChange(e, name)}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          id={id}
          autoFocus={autoFocus}
          onFocus={onFocus}
          onBlur={onBlur}
          min={min}
          max={max}
          onPaste={onPaste}
          tabIndex={tabIndex}
          spellCheck={spellCheck}
          aria-labelledby={ariaLabelledby}
        />
      )}
      {useTextArea && (
        <TextareaAutosize
          required={required}
          inputRef={ref}
          className={`custom-input ${inputClass}`}
          type={inputType}
          value={value || ""}
          placeholder={t(placeholder)}
          onChange={(e) => inputChange(e, name)}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          minRows={min ? min : 1}
          maxRows={max}
          id={id}
          autoFocus={autoFocus}
          onFocus={onFocus}
          onBlur={onBlur}
          onPaste={onPaste}
          tabIndex={tabIndex}
          spellCheck={spellCheck}
          aria-labelledby={ariaLabelledby}
        />
      )}
      {showError && <div className="textinput-error">{t(errorMessage)}</div>}
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  inputType: PropTypes.string.isRequired,
  inputClass: PropTypes.string,
  value: PropTypes.string,
  inputChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

TextInput.defaultProps = {
  inputClass: "",
  value: "",
};

export default TextInput;
