import React, { useState, useEffect } from "react";

import NoDataScreen from "../../components/NoDataScreen";
import BotChatBubble from "../../components/BotChatBubble";
import BotReply from "../../components/BotReply";
import UserChatBubble from "../../components/UserChatBubble";
import HeaderTabs from "../../components/HeaderTabs";
import SummaryRecord from "./summaryRecord";

import { linkify } from "../../helpers/formatFunctions";
import "./style.css";

import { useTranslation } from "react-i18next";
import Moment from "react-moment";

const PastSessionPanel = (props) => {
  const { t } = useTranslation("translation");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabList = [
    {
      label: t("Chat"),
    },
    {
      label: t("Summary"),
    },
  ];

  const tabListNoSummary = [
    {
      label: t("Chat"),
    },
  ];

  useEffect(() => {
    if (props.pastSessionActiveTab) {
      setSelectedIndex(props.pastSessionActiveTab);
    } else {
      setSelectedIndex(0);
    }
  }, [props.sessionData]);

  const closePastSessionPanel = () => {
    let rightPanel = document.getElementById("past-session-panel");
    if (rightPanel) {
      rightPanel.classList.remove("open-panel-wrap");
    }
  };

  const loadChat = () => {
    let chat = [];
    props.sessionData.forEach((element) => {
      if (element.reply) {
        if (element.reply.bubbles) {
          element.reply.bubbles.reverse().forEach((bubble, index) => {
            if (bubble.botmode !== "buffer") {
              chat = [
                <BotReply
                  avatarIcon={props.botAvatar}
                  bubbleIndex={index}
                  key={bubble.id}
                  replyId={element._id}
                  replyType={bubble.template}
                  data={bubble}
                  replyTime={
                    element.reply && element.reply.timestamp ? (
                      <Moment unix format="DD/MM/YY hh:mm A">
                        {element.reply.timestamp / 1000}
                      </Moment>
                    ) : (
                      ""
                    )
                  }
                  showFeedback={false}
                  element={element}
                />,
              ].concat(chat);
            }
          });
        } else if (element.reply.text) {
          chat = [
            <BotChatBubble
              avatarIcon={props.botAvatar}
              messageId={element._id}
              message={linkify(element.reply.text)}
              replyTime={
                element.reply && element.reply.timestamp ? (
                  <Moment unix format="DD/MM/YY hh:mm A">
                    {element.reply.timestamp / 1000}
                  </Moment>
                ) : (
                  ""
                )
              }
              showFeedback={false}
            />,
          ].concat(chat);
        }
      }

      if (element.question && element.question.question) {
        chat = [
          <UserChatBubble
            avatarIcon={props.userAvatarIcon}
            message={element.question.question}
            messageTime={
              element.reply && element.reply.timestamp ? (
                <Moment unix format="DD/MM/YY hh:mm A">
                  {element.question.timestamp / 1000}
                </Moment>
              ) : (
                ""
              )
            }
          />,
        ].concat(chat);
      }
    });

    return chat;
  };

  return (
    // <div id="past-session-panel" className="panel-wrap">

    <div id="past-session-panel" className="panel-wrap" tabIndex={0}>
      <div className="panel p-0">
        <div className="notification-panel-header flex-column p-0">
          <div className="flex-container py-3 px-4 align-items-center">
            <button
              className="close-button pr-3"
              onClick={closePastSessionPanel}
              title={t("Close")}
            >
              <i className="icon-left-chevron" />
            </button>
            <div className="d-flex align-items-center justify-content-center">
              <HeaderTabs
                selectedIndex={selectedIndex}
                onChange={(index) => setSelectedIndex(index)}
                tabList={props.enableLearning ? tabList : tabListNoSummary}
              />
            </div>
            {/* <div className="session-heading">{t("Session Overview")}</div> */}
          </div>
        </div>

        <div className="notification-lists auto-scroll mt-0">
          {selectedIndex === 1 && (
            <div className="past-session-card mt-0">
              <div className="session-duration-container">
                <i className="icon-alert-circle"></i>
                {t("Duration: ") + props.sessionTime + t(" mins")}
              </div>

              <div className="card-heading left-align">{t("Summary")}</div>
              <p className="summary-topic-header">
                {t("Topics covered/assessed")}
              </p>

              <ol>
                {props.summaryData &&
                  props.summaryData.topics &&
                  props.summaryData.topics.map((topic, index) => (
                    <>
                      <li>{topic.name}</li>
                      <ol type="i">
                        {topic.children.map((subtopic, index) => (
                          <li>{subtopic.name}</li>
                        ))}
                      </ol>
                    </>
                  ))}
              </ol>
              {props.summaryData && props.summaryData.quiz_submissions && (
                <>
                  <div className="half-clearfix" />
                  <div className="card-heading left-align">
                    {t("Quiz feedback")}
                  </div>
                </>
              )}
              {props.summaryData &&
                props.summaryData.quiz_submissions &&
                props.summaryData.quiz_submissions.map(
                  (quizSubmission, index) => (
                    <SummaryRecord
                      key={index.toString()}
                      data={quizSubmission}
                    />
                  )
                )}
            </div>
          )}

          {props.sessionData.length > 0 && selectedIndex === 0 && (
            <div
              className="chat-bubbles-wrapper"
              style={{
                margin: 0,
              }}
            >
              {loadChat().map((component) => component)}
            </div>
          )}

          {props.sessionData.length == 0 && selectedIndex === 0 && (
            <NoDataScreen
              message={t("You don’t have any conversations in this session.")}
            ></NoDataScreen>
          )}
        </div>
      </div>
    </div>

    // </div>
  );
};

export default PastSessionPanel;
