import React, { Children } from "react";
import PropTypes from "prop-types";
import "./style.css";

const Button = ({ children, buttonClass, onClick, isDisabled, type }) => {
  const getButtonClass = () => {
    if (buttonClass && !isDisabled) {
      return buttonClass;
    } else if (buttonClass && isDisabled) {
      return `${buttonClass} disabled-button`;
    } else if (isDisabled && !buttonClass) {
      return "disabled-button custom-button";
    } else {
      return "custom-button";
    }
  };
  return (
    <button
      className={getButtonClass()}
      onClick={onClick}
      disabled={isDisabled}
      type={type}
    >
      {Children.toArray(children)}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.object,
  buttonValue: PropTypes.string,
  onclick: PropTypes.func,
  isDisabled: PropTypes.bool
};

Button.defaultProps = {
  isDisabled: false
};

export default Button;
