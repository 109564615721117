import { fetchEventSource } from "@microsoft/fetch-event-source";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../context/auth";
import { useStateRef } from "../../helpers/useStateRef";
import { useAuth0 } from "../../react-auth0-spa";

import { useLocation } from "react-router-dom";
import { getLangValue } from "../../components/Internationalisation/i18n";
import { authHeader } from "../../helpers/auth-header";
import AgentClassService from "../../helpers/AgentClassService";
import {
  getNRandomArrayIndices,
  getUrlParameter,
} from "../../helpers/formatFunctions";
import useFetch from "../../helpers/remote";

import BotChatBubble from "../../components/BotChatBubble";
import BotQuizBubble from "../../components/BotQuizBubble";
import BotQuizResultBubble from "../../components/BotQuizResultBubble";
import BotReply from "../../components/BotReply";
import ChatHeader from "../../components/ChatHeader";
import ChatInput from "../../components/ChatInput";
import FormSubmissionMessage from "../../components/FormSubmissionMessage";
import IntroPanel from "../../components/IntroPanel";
import Modal from "../../components/Modal";
import PopupNotification from "../../components/PopupNotification";
import QuizGenerator from "../../components/QuizGenerator";
import QuizReport from "../../components/QuizReport";
import RatingInput from "../../components/RatingInput";
import Snackbar from "../../components/Snackbar";
import TextInput from "../../components/TextInput";
import UserChatBubble from "../../components/UserChatBubble";
import { linkify } from "../../helpers/formatFunctions";
import { sleep, useInterval } from "../../helpers/timer";
import AgentSelection from "../AgentSelection";
import ChatFeaturePanel from "../ChatFeaturePanel";
import NoClassHistoryPanel from "../NoClassHistoryPanel";
import PastSessionPanel from "../PastSessionPanel";
import ResumeSessionModal from "../ResumeSessionModal";
import { getUserAvatarIcon } from "./data";
import NotificationPanel from "./notificationPanel";
import QueueService from "../../helpers/QueueService";
import NoodleLogo from "../../assets/images/logo-horizontal.png";
import AvatarIcon from "../../assets/images/walter-head-white.png";
import powerByIcon from "../../assets/images/svg/powerBy.svg";

import { useTranslation } from "react-i18next";
import ArrowImg from "../../assets/images/svg/down-long-arrow.svg";
import Notice from "../../components/BotReply/notice";
import ButtonPaging from "../../components/ButtonPaging";
import ChatFeedback from "../../components/ChatFeedback";
import MultiDocumentPreview from "../../components/MultiDocumentPreview";
import { NEW_HOME } from "../../config/routeMapping";
import AllAnswerQuestions from "../AllAnswerQuestions";
import AllBookmarks from "../AllBookmarks";
import AllSessions from "../AllSessions";
import UserReport from "../UserReport";
import "./style.css";

import Form from "../../components/Form";
import FormBubble from "../../components/FormBubble";
import ChatDiscussion from "../TeamDiscussion/ChatDiscussion";
import { set } from "immutable";

import PromptsIdeaIcon from "../../assets/images/svg/suggested-prompts-bulb-img.svg";
import moment from "moment";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Chat = (props) => {
  const queueRef = useRef(new QueueService());
  const queueService = queueRef.current;
  const defaultDisclaimer =
    "Please note that the information you receive from now on is outside of the set knowledge base and the system may occasionally generate incorrect or misleading information and produce offensive or biased content.";
  const { t } = useTranslation("translation");
  const {
    authTokens,
    setAuthTokens,
    showAgentSelection,
    setShowAgentSelection,
    selectedClass,
    setSelectedClass,
    setAgentLanguage,
    agentDisclaimer,
    setAgentDisclaimer,
  } = useAuth();

  const { logout, getTokenSilently } = useAuth0();
  const [feedbackMap, setFeedbackMap] = useState({});
  const [displayedQuestionsIdConvo, setDisplayedQuestionsIdConvo] = useState(
    []
  );
  const [essayConfig, setEssayConfig] = useState(null);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [sessionFeedbackComment, setSessionFeedbackComment] = useState("");
  const [feedbackId, setFeedbackId] = useState("");
  const lastLog = useRef(null);
  const [newMessage, setNewMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openFeedbackContainer, setOpenFeedbackContainer] = useState(false);
  const [suggestedQuestionsData, setSuggestedQuestionsData] = useState([]);
  const [formButton, setFormButton] = useState(false);
  const [chatData, setChatData, chatDataRef] = useStateRef([]);
  let location = useLocation();

  // const eventQueue = useQueue([]);
  // const { add, remove, clear, first, last, size, queue } = useQueue([]);

  const [isSseClose, setIsSseClose] = useState(true);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [form, setForm] = useState({});
  const [showFormSubmissionMessage, setShowFormSubmissionMessage] =
    useState(false);
  const [formSubmissionMessageDetails, setFormSubmissionMessageDetails] =
    useState({
      heading: "",
      message: "",
      buttonLabel: "",
      type: "",
      displayMode: "",
    });
  const [orgDefaultLang, setOrgDefaultLang] = useState("en");

  const [messageCopy, setMessageCopy] = useState("");
  const [showQuiz, setShowQuiz] = useState(false);
  const [quiz, setQuiz] = useState({});
  const [disableQuizButton, setDisableQuizButton] = useState(false);
  const [showQuizReport, setShowQuizReport] = useState({
    open: false,
    type: "success",
    data: {},
  });
  const [title, setTitle] = useState("Chat");
  const [chatContext, setChatContext] = useState("");

  // Settings variables
  const [companyLogo, setCompanyLogo] = useState(null);
  const [sidebarMessage, setSidebarMessage] = useState(
    "<p>Hi there! 👋</p><p>I am Walter, your smart chat buddy.<br></p>"
  );
  const [botAvatar, setBotAvatar] = useState(AvatarIcon);
  const [brandAvatar, setBrandAvatar] = useState(null);
  const [botName, setBotName] = useState("Walter");
  const [botDelay, setBotDelay] = useState(2000);
  const [customButtonData, setCustomButtonData] = useState([]);

  const [notificationToggle, setNotificationToggle] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [suggestionsExpanded, setSuggestionsExpanded] = useState(false);
  const [showHomePanel, setShowHomePanel] = useState(false);
  const [userAvatarIcon, setUserAvatarIcon] = useState(getUserAvatarIcon());
  const [hideChatInput, setHideChatInput] = useState(false);
  const [buttonReference, setButtonReference] = useState("");
  const [logoutAfterFeedback, setLogoutAfterFeedback] = useState(false);
  const [sessionFeedback, setSessionFeedback] = useState(0);
  const [openSessionEndModal, setOpenSessionEndModal] = useState(false);
  const [pastSessionData, setPastSessionData] = useState([]);
  const [enableLearning, setEnableLearning] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [sessionTime, setSessionTime] = useState(0);
  const [triggerBookmarkLoad, setTriggerBookmarkLoad] = useState(false);
  const [quizDisplayedQuestions, setQuizDisplayedQuestions] = useState([]);
  const [agentsMap, setAgentsMap] = useState({});
  const [selectedAgent, setSelectedAgent] = useState("");
  const [classMap, setClassMap] = useState({})
  const [disableInput, setDisableInput, setDisableInputRef] =
    useStateRef(false);
  const [activeSession, setActiveSession] = useState({});
  const [showDocumentPreview, setShowDocumentPreview] = useState(false);
  const [isShowFb, setIsShowFb] = useState(true);
  const [answerForDocumentPreview, setAnswerForDocumentPreview] = useState("");
  const [replyIdForDocumentPreview, setReplyIdForDocumentPreview] =
    useState("");
  const [isWaitRender, setIsWaitRender] = useState(false);

  const [documentIdForDocumentPreview, setDocumentIdForDocumentPreview] =
    useState("");
  const [docPreviews, setDocPreviews] = useState([]);
  const [documentPreviewContent, setDocumentPreviewContent] = useState("");
  const [textBlockIds, setTextBlockIds] = useState({
    current: "",
    previous: "",
    next: "",
  });
  const [enableSearch, setEnableSearch] = useState(false);

  const [showResumeSessionModal, setShowResumeSessionModal] = useState(false);
  const [showNewSessionAgentSelection, setShowNewSessionAgentSelection] =
    useState(false);
  const [disableCustomButton, setDisableCustomButton] = useState(false);
  const [isConversationalQuizActive, setIsConversationalQuizActive] =
    useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [pastSessionActiveTab, setPastSessionActiveTab] = useState(false);
  const [popupNotificationData, setPopupNotificationData] = useState({});
  const [openPopupNotification, setOpenPopupNotification] = useState(false);
  const [toggleNoClassHistory, setToggleNoClassHistory] = useState(false);
  const [savedProgressDetected, setSavedProgressDetected] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showSidebarUI, setShowSidebarUI] = useState(false);
  const chatMessagesRef = useRef(null);
  const historyChatRef = useRef(null);
  const currentQuiz = useRef(null);
  const convoQuizAnswers = useRef(null);
  const initialRender = useRef(true);
  const conversationalQuizStrategy = useRef("normal");
  const [selectedSidebarOption, setSelectedSidebarOption] = useState("chat");
  const [sessionDisclaimers, setSessionDisclaimers] = useState([]);
  const [botMode, setBotMode, botModeRef] = useStateRef("");
  const [showScrollToDownButton, setShowScrollToDownButton] = useState(true);
  const initiallyCalled = useRef(null);

  const [isBotModeInprovisingEnabled, setIsBotModeInprovisingEnabled] =
    useState(false);
  const [modelBadge, setModelBadge] = useState(null);
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);

  const [showPrompts, setShowPrompts] = useState(false);
  const [copiedSuggestedPrompts, setCopiedSuggestedPrompts] = useState([]);

  const [showTooltip, setShowTooltip] = useState(false);

  const [agentName, setAgentName] = useState(null);
  const [agentSwitched, setAgentSwitched] = useState(false);
  const [promptsMessage, setPromptsMessage] = useState(null);
  const [startQuiz, setStartQuiz] = useState(false);
  const [ exitFromQuiz, setExitFromQuiz] = useState(false);
  const [ openExitQuizConfirm, setOpenExitQuizConfirm] = useState({isExit: false, navigateTo: "", exitData: null});

  const header = authHeader();
  let intervalUpdateBubble = useRef(null);
  let intervalRenderBubble = useRef(null);
  const makeApiCall = useFetch();
  let history = useHistory();

  useInterval(() => {
    gaSendPing();
  }, 30000);

  const initSSEConnection = async () => {
    let token = null;
    if (
      (process.env && process.env.REACT_APP_TYPE === "login_widget") ||
      window.SSO_PROVIDER === "oneauth"
    ) {
      token = authTokens.access_token;
    } else if (window.SSO_PROVIDER === "auth0") {
      token = await getTokenSilently();
    }
    const connectId = selectedClass;
    const agentId = AgentClassService.getSelectedAgent();
    const param = connectId ? `&connection_id=${connectId}` : "";

    return new Promise((resolve) => {
      fetchEventSource(
        window.SSE_API +
          `/chatsse/conversations?agent_id=${agentId}${param}`,
        {
          method: "POST",
          openWhenHidden: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          async onopen(response) {
            // console.log("---OPEN CONNECTION---");
            if (!response.ok) {
              throw response;
            }
            setDisableInput(true);
            resolve(true);
            // setIsSseClose(false);
          },
          async onmessage(ev) {
            setIsWaitRender(true);
            const data = JSON.parse(ev.data);
            ev.data = JSON.parse(ev.data);
  
            switch (ev.event) {
              case "CREATE_REPLIES":
                queueService.pushTask(() =>
                  createReply({
                    context: data.data.context,
                    reply: data.data.replies[0],
                  })
                );
                break;
  
              case "CREATE_BUBBLES":
                queueService.pushTask(() => {
                  if (
                    !chatDataRef.current[
                      chatDataRef.current.length - 1
                    ].key?.includes("typing-bubble")
                  ) {
                    addTypingBubble();
                  }
                  createReply({
                    context: data.data.context,
                    reply: {
                      bubbles: data.data.bubbles,
                    },
                  });
                });
                break;
              case "CREATE_BUTTONS":
                queueService.pushTask(() =>
                  createReply({
                    context: data.data.context,
                    reply: {
                      buttons: data.data.buttons,
                    },
                  })
                );
  
                break;
              case "APPEND_BUBBLE":
                if (data?.data?.bubble) {
                  queueService.pushTask(() =>
                    replaceBubble({
                      ...data.data.bubble,
                      eventType: "APPEND_BUBBLE",
                    })
                  );
                }
  
                break;
              case "UPDATE_BUBBLE":
                if (data?.data?.bubble) {
                  queueService.pushTask(() =>
                    replaceBubble({
                      ...data.data.bubble,
                      eventType: "UPDATE_BUBBLE",
                    })
                  );
                }
                break;
              case "UPDATE_CONTEXT":
                await (data?.data.delay || 0);
                updateContext(data.data);
                break;
              case "ERROR":
                break;
              default:
                break;
            }
          },
          async onclose() {
            // setDisableInput(false);
            queueService.pushTask(() => {
              removeTypingBubble();
              queueService.executeNextTask();
            });
            // console.log("---CLOSE CONNECTION---");
            // await sleep(300)
            // updateScrollbar()
            // await sleep(500)
            setIsSseClose(true);
          },
          onerror(err) {
            if (err) {
              setIsSseClose(false);
              setSnackbar({
                isOpen: true,
                message: "Oops! Something went wrong.",
                type: "error",
              });
              queueService.pushTask(() => {
                removeTypingBubble();
                queueService.executeNextTask();
              });
              throw err;
            }
          },
        }
      );

    })
  }

  useEffect(() => {
    if (!selectedClass) return;

    initSSEConnection();
  }, [selectedClass]);

  const startListenChat = async (requestOptions) => {
    setDisableInput(true);
    addTypingBubble();
    let token = null;
    if (
      (process.env && process.env.REACT_APP_TYPE === "login_widget") ||
      window.SSO_PROVIDER === "oneauth"
    ) {
      token = authTokens.access_token;
    } else if (window.SSO_PROVIDER === "auth0") {
      token = await getTokenSilently();
    }
    const connectId = AgentClassService.getSelectedClass();
    const agentId = AgentClassService.getSelectedAgent();
    const param = connectId ? `&connection_id=${connectId}` : "";
    const payload =
      location.pathname === NEW_HOME
        ? {
            ...requestOptions.body,
            docsearch_message_enabled: true,
            asynchronous_delay: false,
          }
        : {
            ...requestOptions.body,
            asynchronous_delay: false,
          };

    await fetch(
      window.CHAT_API +
        `/api/v1/chatbot/async/questions?agent_id=${agentId}${param}`,
      {
        method: requestOptions.method,
        openWhenHidden: true,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
      }
    );
  };

  useEffect(() => {
    const throughAPI = localStorage.getItem("agentThroughAPI");
    if (throughAPI && throughAPI !== "null") {
      onAgentChange(throughAPI, "switch", true, true);
    }
  }, []);

  useEffect(() => {
    let allParams = getUrlParameter(window.location.search.split("?")[1]);
    const throughAPI = localStorage.getItem("agentThroughAPI");
   
    if (throughAPI && throughAPI !== "null") {
      return;
    }
    if ("agent" in allParams && allParams.agent) {
      handleGotAgentFormUrl(allParams?.agent, allParams?.class);
    } else if( AgentClassService.getSelectedAgent()){
      const initAgent =  AgentClassService.getSelectedAgent();
      const initClass = AgentClassService.getClassUrlId();
      history.push(`?agent=${initAgent}&class=${initClass}`);
      // handleGotAgentFormUrl(initAgent, initClass);
    }else if (
      "through" in allParams &&
      allParams.through === "admin-portal" &&
      !("agent" in allParams) &&
      agentSwitched
    ) {
      setShowAgentSelection(false);
      setAgentSwitched(false);
      getDefaultAgent();
    } else if (!localStorage.getItem("originalPath")?.includes("?agent")) {
      getDefaultAgent("initial");
    }
   
  }, [window.location.href]);

  useEffect(()=> {
    
    if(selectedAgent && selectedClass && classMap?.[selectedClass]){
      const selectedClassId = classMap[selectedClass];
      AgentClassService.setClassUrlId(selectedClassId)
      history.push(`/new-sse/?agent=${selectedAgent}&class=${selectedClassId}`);
    } 
  }, [selectedAgent, selectedClass, classMap?.[selectedClass]])

  // useEffect(() => {
  //   if(selectedClass && classMap?.[selectedClass]){
  //     AgentClassService.setSelectedClass(selectedClass);
  //   }
  // }, [classMap?.[selectedClass]])

  const fixSpace = async () => {
    // console.log('isSseClose=' +isSseClose + ' , isWaitRender= ' + isWaitRender)
    if (isSseClose && !isWaitRender) {
      await sleep(500);
      updateScrollbar();
      await sleep(500);
      setIsShowFb(true);
    }
  };

  useEffect(() => {
    updateScrollbar();
    if (queueService.queue.length === 0) {
      setDisableInput(false);
    } else {
      setDisableInput(true);
    }
  }, [chatDataRef.current, queueService.queue.length]);

  useEffect(() => {
    fixSpace();
  }, [isSseClose, isWaitRender]);

  useEffect(() => {
    if (showQuiz === true) {
      setShowDocumentPreview(false);
    }
  }, [showQuiz]);

  useEffect(() => {
    getOrganisationName();
    getAgentSettings();
    checkAgentFeatureEnabled(
      "learning",
      () => {
        setEnableLearning(false);
      },
      () => {
        setEnableLearning(true);
      }
    );
    updateLocalSession();
    getNotifications();
    // getAllNotifications();
    if (initiallyCalled) {
      initiallyCalled.current = true;
    }
    if (window.innerWidth && window.innerWidth < 769) {
      setIsMobile(true);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (document.getElementById("parent-container-scroll-view")) {
      document
        .getElementById("parent-container-scroll-view")
        .addEventListener("scroll", onScrollChatContainer);
    }
    return () => {
      if (document.getElementById("parent-container-scroll-view")) {
        document
          .getElementById("parent-container-scroll-view")
          .removeEventListener("scroll", onScrollChatContainer);
      }
    };
  }, []);

  const onScrollChatContainer = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    // eslint-disable-next-line no-unused-expressions
    // localStorage.setItem("lastInteractionTime", moment());
    const padding = window
      .getComputedStyle(event.target, null)
      .getPropertyValue("padding-bottom")
      .split("px")[0];
    if (scrollHeight - clientHeight - scrollTop < padding) {
      setShowScrollToDownButton(false);
    } else {
      setShowScrollToDownButton(true);
    }
  };

  useEffect(() => {
    chatMessagesRef.current = chatData; //allNotifications
    // setTimeout(() => {
    //   updateScrollbar()
    // }, 200);
  }, [chatData]);

  useEffect(() => {
    if (historyChatRef.current !== null) {
      setChatData(historyChatRef.current);
    }
  }, [historyChatRef]);

  useEffect(() => {
    if (showForm === false) {
      updateScrollbar();
    }
  }, [showForm]);

  useEffect(() => {
    if (messageCopy !== "" && messageCopy.trim() !== "") {
      if (initiallyCalled) {
        initiallyCalled.current = false;
      }
      sendMessage(messageCopy);
      setSuggestionsExpanded(false);
      setDisableCustomButton(false);
      setMessageCopy("");
      setButtonReference("");
    }
  }, [messageCopy]);

  useEffect(() => {
    if (promptsMessage) {
      setMessageCopy(promptsMessage.text);
    }
  }, [promptsMessage]);

  useEffect(() => {
    if (!initialRender.current) {
      getSuggestedQuestions();
      getSuggestedPrompts();
    }
  }, [chatContext]);

  const onQuizExitHandler = async (e, data = {}) => {
    if(!showQuiz) {
      return;
    }
    if(showQuiz && e) {
      if(!startQuiz) {
        if(e === "selectChannel") {
          await handleClassSelectWhileQuizDisplay(data);
          setShowQuiz(false);
        } else if(e === "newSession") {
          setShowQuiz(false);
          onStartNewSession();
        } else {
          setShowQuiz(false);
          getSessions();
        }
      } else {
        setOpenExitQuizConfirm({isExit: true, navigateTo: e, exitData: data});
      }
    }
  }

  const handleResize = () => {
    if (window.innerWidth && window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // start GA functions
  const gaSendPing = () => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });
      window.gtag("event", "active_session", {
        event_category: "session",
        event_label: activeSession._id,
      });
    }
  };

  const gaEndSession = () => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });

      window.gtag("event", "end_session", {
        event_category: "session",
        event_label: activeSession._id,
      });
    }
  };

  const gaClickButton = (button_type, button_label) => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });

      window.gtag("event", "click_button", {
        event_category: "conversation",
        event_label: activeSession._id + "::" + button_type,
        value: button_label,
      });
    }
  };
  // end GA functions

  const updateLocalSession = () => {
    getCurrentSession((ss) => {
      if (ss) {
        setDisclaimers(ss._id);
        setActiveSession(ss);
        gaSendPing();
      }
    });
  };

  const getAgentSettings = () => {
    if (!AgentClassService.getSelectedAgent()) {
      return;
    }

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/agents/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (json && json.data && json.data.agent) {
            setIsBotModeInprovisingEnabled(
              json.data.agent.is_improvising_botmode_enabled
            );
          }
        }
      });
  };

  const getNotifications = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/summary",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          // setNotificationSummary(json.data.notifications);
          const notificationsData = json.data.notifications.map(
            (notification) => {
              return {
                ...notification,
                content: notification.content, // .replace(/(?:\r\n|\r|\n)/g, '<br>')
                excerpt: notification.excerpt.replace(
                  /(?:\r\n|\r|\n)/g,
                  "<br>"
                ),
              };
            }
          );
          setAllNotifications(notificationsData);
          setNotificationCount(json.data.unread_count);
          if (
            notificationsData[0] &&
            notificationsData[0].is_popup &&
            !notificationsData[0].is_read
          ) {
            setPopupNotificationData(notificationsData[0]);
            setOpenPopupNotification(true);
            markNotificationAsRead(notificationsData[0]._id);
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: "Error fetching notifications",
            type: "error",
          });
        }
      });
  };

  const markNotificationAsRead = (id) => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + id,
        {
          method: "PATCH",
          headers: authHeader(),
          body: JSON.stringify({
            is_read: true,
          }),
        },
        true
      )
      .then((json) => {});
  };

  const getAllNotifications = () => {
    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/notification/notifications?mail=inbox&page_size=1000",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setAllNotifications(json.data.notifications);
        } else {
          setSnackbar({
            isOpen: true,
            message: "Error fetching notifications",
            type: "error",
          });
        }
      });
  };

  const onClickNotification = (link, id, isAnchor = false) => {
    markNotificationAsRead(id);
    if (link !== "" && link !== null && !isAnchor) {
      let strippedDomain = link.replace(/https?:\/\/[^\/]+/i, "");
      var localDomains = [
        "chatbot.staging.noodlefactory.ai",
        "chatbot.noodlefactory.ai",
        "localhost",
      ];
      var isLocalDomain = localDomains.some((element) =>
        link.includes(element)
      );

      if (isLocalDomain === true) {
        history.push(strippedDomain);
      } else {
        window.open(link, "_newtab");
      }
    }
  };

  const onDeleteNotification = (event, key) => {
    event.stopPropagation();
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + key,
        {
          method: "DELETE",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          getNotifications();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Unable to delete notification: " + json.status.message,
            type: "error",
          });
        }
      });
  };

  const onCheckboxChangeNotification = (event, key, isRead) => {
    event.stopPropagation();

    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + key,
        {
          method: "PATCH",
          headers: authHeader(),
          body: JSON.stringify({
            is_read: isRead ? false : true,
          }),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          getNotifications();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Unable to update notification: " + json.status.message,
            type: "error",
          });
        }
      });
  };

  const markAllRead = async () => {
    let notifications = [...allNotifications];

    await notifications.forEach((notification, i) => {
      if (notification.is_read === false) {
        makeApiCall
          .request(
            window.CHAT_API +
              "/api/v1/notification/notifications/" +
              notification._id,
            {
              method: "PATCH",
              headers: authHeader(),
              body: JSON.stringify({
                is_read: notification.isRead ? false : true,
              }),
            },
            true
          )
          .then((json) => {
            if (json.status.code === 200) {
            } else {
              setSnackbar({
                isOpen: true,
                message:
                  "Unable to update notification: " + json.status.message,
                type: "error",
              });
            }
          });
      }
    });

    getNotifications();
  };

  const clearAllNotification = async () => {
    let notifications = [...allNotifications];

    await notifications.forEach((notification, i) => {
      makeApiCall
        .request(
          window.CHAT_API +
            "/api/v1/notification/notifications/" +
            notification._id,
          {
            method: "DELETE",
            headers: authHeader(),
          },
          true
        )
        .then((json) => {
          if (json.status.code === 200) {
          } else {
            setSnackbar({
              isOpen: true,
              message: "Unable to update notification: " + json.status.message,
              type: "error",
            });
          }
        });
    });

    getNotifications();
  };

  const onChatInputFocus = () => {
    // setSuggestionsExpanded(true);
    // setShowPrompts(false);
  };

  const onChatInputBlur = () => {
    // setTimeout(() => setSuggestionsExpanded(false), 100);
  };

  const callStartSessionApi = () => {
    let currentAgent = AgentClassService.getSelectedAgent();
    applyAgentSetting(agentsMap[currentAgent]);
    getGreetings(false, true);
  };

  const callResumeSessionApi = (session_id = "last") => {
    const requestOptions = {
      method: "PATCH",
      headers: authHeader(),
    };
    if (initiallyCalled) {
      initiallyCalled.current = false;
    }

    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatlog/sessions/" + session_id,
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data && json.data.session) {
            setActiveSession(json.data.session);
          }
          setShowResumeSessionModal(false);
          fetchChat(json.data.session._id);
          getLastFeedback();
          initialRender.current = false;
          getSuggestedQuestions();
          updateLocalSession();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Session could not be resumed.",
            type: "error",
          });
        }
      });
  };

  const startFeedbackTimer = (lastFeedbackDate) => {
    let timeDelay = [600000, 900000, 1200000];
    let timeDelay2 = [1000, 5000, 10000];
    let today = new Date();
    let lastFeedback = new Date(lastFeedbackDate);

    today.setHours(0, 0, 0, 0);
    lastFeedback.setHours(0, 0, 0, 0);

    if (today > lastFeedback) {
      setTimeout(() => {
        setOpenFeedbackContainer(true);
      }, timeDelay[Math.floor(Math.random() * timeDelay.length)]);
    }
  };

  const getLastFeedback = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatlog/feedbacks",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        let error = false;
        if (json && json.status.code === 200) {
          if (
            json.data &&
            json.data.session_feedback &&
            json.data.session_feedback[0]
          ) {
            startFeedbackTimer(json.data.session_feedback[0].created_at);
          }
        } else {
          error = true;
        }

        if (error) {
          setSnackbar({
            isOpen: true,
            message: "Error fetching feedback data",
            type: "error",
          });
        }
      });
  };

  const getSessions = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatlog/sessions",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (
            json.data.sessions.length > 0 &&
            json.data.sessions[0].is_active
          ) {
            setActiveSession(json.data.sessions[0]);
            fetchChat(json.data.sessions[0].session_id);
            setSelectedClass(json.data.sessions[0].connection_id);
            AgentClassService.setSelectedClass(
              json.data.sessions[0].connection_id
            );
          } else if (
            json.data.sessions.length > 0 &&
            !json.data.sessions[0].is_active
          ) {
            setShowResumeSessionModal(true);
          } else {
            getGreetings();
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: "Error fetching sessions",
            type: "error",
          });
        }
      });
  };

  const getSummary = (sessionId) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/learning/session-summary?session_id=" +
          sessionId,
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          setSummaryData(json.data.session_summary);
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const checkSelectedAgentAndClass = () => {
    if (AgentClassService.getSelectedAgent() && selectedClass) {
      callStartSessionApi();
      // setShowNewSessionAgentSelection(false);
      // getSuggestedQuestions();
    } else if (AgentClassService.getSelectedAgent()) {
      callStartSessionApi();
    } else {
      if (Object.keys(agentsMap).length === 1) {
        onAgentChange(Object.keys(agentsMap)[0], "new");
      } else {
        setShowAgentSelection(true);
      }
    }
  };

  const getClasses = async (receivedClass, type, origin) => {
    let receivedSelectedClass = receivedClass;
    const json = await makeApiCall.request(
      window.CHAT_API + `/api/v1/course/classes`,
      {
        method: "GET",
        headers: authHeader(),
      },
      true
    );
    if (json.status.code === 200) {
      if (json.data.classes && json.data.classes.length) {
        let tempMap = {};
        json.data.classes.forEach((element) => {
          tempMap[element.connection_id] = element._id;
        });
        setClassMap(tempMap);
      }

      if (receivedSelectedClass && origin === "params") {
        let temp = json.data.classes.filter(
          (classRecord) => classRecord._id === receivedSelectedClass
        );

        if (temp.length) {
          setSelectedClass(temp[0].connection_id);
          AgentClassService.setSelectedClass(temp[0].connection_id);
          if (type === "initial") {
            getLastFeedback();
            initialRender.current = false;
          }
          getSuggestedQuestions();
        } else {
          receivedSelectedClass = "";
        }
      } else if (receivedSelectedClass) {
        let temp = json.data.classes.filter(
          (classRecord) => classRecord.connection_id === receivedSelectedClass
        );

        if (temp.length) {
          setSelectedClass(temp[0]?.connection_id);
          AgentClassService.setSelectedClass(temp[0]?.connection_id);
          if (type === "initial") {
            getLastFeedback();
            initialRender.current = false;
          }
          getSuggestedQuestions();
        } else {
          receivedSelectedClass = "";
        }
      }
      if (!receivedSelectedClass && json.data.classes.length > 0) {
        setSelectedClass(json.data.classes[0].connection_id);
        AgentClassService.setSelectedClass(json.data.classes[0].connection_id);
      }
      if (!showForm) {
        getSessions();
      }
      setShowSidebarUI(true);
    }
  };

  const selectDefaultClass = () => {
    makeApiCall
      .request(
        window.CHAT_API + `/api/v1/course/classes`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data.classes.length > 0) {
            setSelectedClass(json.data.classes[0].connection_id);
            AgentClassService.setSelectedClass(
              json.data.classes[0].connection_id
            );
          }
        }
      });
  };

  const getDefaultAgent = (type) => {
    setAgentDisclaimer({ defaultDisclaimer: null });
    let allParams = getUrlParameter(window.location.search.split("?")[1]);
    if (
      "class" in allParams &&
      allParams.class &&
      "agent" in allParams &&
      allParams.agent &&
      type === "initial"
    ) {
      let receivedSelectedAgent = allParams.agent;
      let receivedSelectedClass = allParams.class;
      makeApiCall
        .request(window.CHAT_API + "/api/v1/organisation/agents", {
          method: "GET",
          headers: authHeader(),
        })
        .then((json) => {
          if (json.status.code === 200) {
            if (json.data.agents.length === 0) {
              AgentClassService.setSelectedAgent("");
              setSelectedClass("");
              AgentClassService.setSelectedClass("");
              setSelectedAgent("");
              history.push("/no-agent-found");
            } else {
              if (receivedSelectedAgent) {
                let temp = json.data.agents.filter(
                  (agent) => agent._id === receivedSelectedAgent
                );
                if (temp.length) {
                  AgentClassService.setSelectedAgent(receivedSelectedAgent);
                  setSelectedAgent(receivedSelectedAgent);
                  setAgentDisclaimer({
                    ...agentDisclaimer,
                    defaultDisclaimer:
                      temp[0]?.settings?.improvising_disclaimer?.length >= 0
                        ? temp[0]?.settings?.improvising_disclaimer
                        : defaultDisclaimer,
                  });
                  getClasses(receivedSelectedClass, type, "params");
                  setAgentLanguage(getLangValue(temp[0]?.language_code));
                } else {
                  receivedSelectedAgent = "";
                }
              }

              if (!receivedSelectedAgent && json.data.agents.length > 1) {
                setShowAgentSelection(true);
              } else {
                if (!receivedSelectedAgent) {
                  AgentClassService.setSelectedAgent(json.data.agents[0]._id);
                  setSelectedAgent(json.data.agents[0]._id);
                  setAgentDisclaimer({
                    ...agentDisclaimer,
                    defaultDisclaimer:
                      json.data.agents[0]?.settings?.improvising_disclaimer
                        ?.length >= 0
                        ? json.data.agents[0]?.settings?.improvising_disclaimer
                        : defaultDisclaimer,
                  });
                  setAgentLanguage(
                    getLangValue(json?.data?.agents?.[0]?.language_code)
                  );
                  getClasses(receivedSelectedClass, "", "params");
                  if (type === "initial") {
                    getLastFeedback();
                    initialRender.current = false;
                  }
                  getSuggestedQuestions();
                }

                let temp = {};
                json.data.agents.forEach((agent) => {
                  temp[agent._id] = agent;
                });
                setAgentsMap(temp);

                receivedSelectedAgent
                  ? applyAgentSetting(temp[receivedSelectedAgent])
                  : applyAgentSetting(json.data.agents[0]);
              }
            }
          }
        });
    } else if (!("code" in allParams)) {
      makeApiCall
        .request(window.CHAT_API + "/api/v1/organisation/states", {
          method: "GET",
          headers: authHeader(),
        })
        .then((json) => {
          if (json.status.code === 200) {
            let receivedSelectedAgent = json.data.states.selected_agent;
            let receivedSelectedClass = json.data.states.selected_class;

            makeApiCall
              .request(window.CHAT_API + "/api/v1/organisation/agents", {
                method: "GET",
                headers: authHeader(),
              })
              .then((json) => {
                if (json.status.code === 200) {
                  if (json.data.agents.length === 0) {
                    AgentClassService.setSelectedAgent("");
                    setSelectedClass("");
                    AgentClassService.setSelectedClass("");
                    setSelectedAgent("");
                    history.push("/no-agent-found");
                  } else {
                    if (receivedSelectedAgent) {
                      let temp = json.data.agents.filter(
                        (agent) => agent._id === receivedSelectedAgent
                      );

                      if (temp.length) {
                        AgentClassService.setSelectedAgent(
                          receivedSelectedAgent
                        );
                        setSelectedAgent(receivedSelectedAgent);
                        setAgentDisclaimer({
                          ...agentDisclaimer,
                          defaultDisclaimer:
                            temp[0]?.settings?.improvising_disclaimer?.length >=
                            0
                              ? temp[0]?.settings?.improvising_disclaimer
                              : defaultDisclaimer,
                        });
                        setAgentLanguage(getLangValue(temp[0]?.language_code));
                        getClasses(receivedSelectedClass, type);
                      } else {
                        receivedSelectedAgent = "";
                      }
                    }

                    if (!receivedSelectedAgent && json.data.agents.length > 1) {
                      setShowAgentSelection(true);
                    } else {
                      if (!receivedSelectedAgent) {
                        AgentClassService.setSelectedAgent(
                          json.data.agents[0]._id
                        );
                        setSelectedAgent(json.data.agents[0]._id);
                        setAgentDisclaimer({
                          ...agentDisclaimer,
                          defaultDisclaimer:
                            json.data.agents[0]?.settings
                              ?.improvising_disclaimer?.length >= 0
                              ? json.data.agents[0]?.settings
                                  ?.improvising_disclaimer
                              : defaultDisclaimer,
                        });
                        setAgentLanguage(
                          getLangValue(json?.data?.agents?.[0]?.language_code)
                        );
                        getClasses(receivedSelectedClass);
                        if (type === "initial") {
                          getLastFeedback();
                          initialRender.current = false;
                        }
                        getSuggestedQuestions();
                        // getSuggestedPrompts();
                      }

                      let temp = {};
                      json.data.agents.forEach((agent) => {
                        temp[agent._id] = agent;
                      });
                      setAgentsMap(temp);

                      receivedSelectedAgent
                        ? applyAgentSetting(temp[receivedSelectedAgent])
                        : applyAgentSetting(json.data.agents[0]);
                    }
                  }
                }
              });
          }
        });
    }
  };

  const handleGotAgentFormUrl = async (receivedSelectedAgent, selectedClass) => {
    setAgentDisclaimer({ defaultDisclaimer: null });
    if (!receivedSelectedAgent) return;
    const json = await makeApiCall.request(
      window.CHAT_API + "/api/v1/organisation/agents",
      {
        method: "GET",
        headers: authHeader(),
      }
    );
    setTimeout(() => {
      localStorage.removeItem("originalPath");
    }, 500);
    if (json.status.code === 200) {
      if (json.data.agents.length === 0) {
        AgentClassService.setSelectedAgent("");
        setSelectedClass("");
        AgentClassService.setSelectedClass("");
        setSelectedAgent("");
        history.push("/no-agent-found");
      } else {
        let temp = json.data.agents.filter(
          (agent) => agent._id === receivedSelectedAgent
        );

        let agentMap = {};
        json.data.agents.forEach((agent) => {
          agentMap[agent._id] = agent;
        });
        setAgentsMap(agentMap);
        
        if (temp.length) {
          AgentClassService.setSelectedAgent(receivedSelectedAgent);
          setSelectedAgent(receivedSelectedAgent);
          setAgentDisclaimer({
            ...agentDisclaimer,
            defaultDisclaimer:
              temp[0]?.settings?.improvising_disclaimer?.length >= 0
                ? temp[0]?.settings?.improvising_disclaimer
                : defaultDisclaimer,
          });
          applyAgentSetting(temp[0])
          AgentClassService.setSelectedClass("");
          // console.log('set selected class null')
          // console.log({agent: AgentClassService.getSelectedAgent()})
          getClasses(selectedClass, "", "params");
          setAgentLanguage(getLangValue(temp[0]?.language_code));
        }
      }
    }
  };


  const checkAgentFeatureEnabled = (
    feature,
    default_callback,
    enabled_callback = null
  ) => {
    if (!AgentClassService.getSelectedAgent()) {
      return;
    }

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/agent-features", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        let is_feature_enabled = false;
        if (json.status.code === 200 && json.data.features) {
          let openAIFeature = json.data.features.find(
            (feature) => feature.name === "openai"
          );
          setModelBadge(
            openAIFeature?.params?.render_model_badge &&
              openAIFeature?.params?.model
              ? openAIFeature?.params?.model.toUpperCase()
              : null
          );
          for (let i = 0; i < json.data.features.length; i++) {
            if (json.data.features[i].name === feature) {
              is_feature_enabled = true;
              break;
            }
          }
          const essayFeatuerConfig = json?.data.features.find(
            (feature) =>
              feature?.name === "essay_grader"
          )
          setEssayConfig(
            essayFeatuerConfig
          );
        }
        if (is_feature_enabled) {
          if (
            enabled_callback !== null &&
            typeof enabled_callback === "function"
          ) {
            enabled_callback();
          }
        } else if (typeof default_callback === "function") {
          default_callback();
        }
      });
  };

  const applyAgentSetting = (agentFeature) => {
    let avatarIcon = getUserAvatarIcon();
    setUserAvatarIcon(avatarIcon);

    let webchatSettings = agentFeature.settings;

    if (agentFeature.name) {
      setAgentName(agentFeature.name);
    }

    if (webchatSettings.chat_company_logo) {
      setCompanyLogo(
        webchatSettings.chat_company_logo
          ? webchatSettings.chat_company_logo
          : NoodleLogo
      );
    } else {
      setCompanyLogo(NoodleLogo);
    }

    if (webchatSettings.chat_short_message) {
      setSidebarMessage(webchatSettings.chat_short_message);
    }

    if (webchatSettings.chat_bot_avatar) {
      setBotAvatar(webchatSettings.chat_bot_avatar);
    }

    if (webchatSettings.chat_bot_brand_avatar) {
      setBrandAvatar(
        webchatSettings.chat_bot_brand_avatar
          ? webchatSettings.chat_bot_brand_avatar
          : NoodleLogo
      );
    } else {
      setBrandAvatar(NoodleLogo);
    }

    if (webchatSettings.chat_bot_name) {
      setBotName(webchatSettings.chat_bot_name);
    } else {
      setBotName("Walter");
    }

    if (webchatSettings.chat_bot_delay) {
      setBotDelay(webchatSettings.chat_bot_delay * 1000);
    } else {
      setBotDelay(2000);
    }

    const root = document.querySelector(":root");
    if (webchatSettings) {
      if (webchatSettings.chat_accent_color) {
        root.style.setProperty(
          "--primary-color",
          `#${webchatSettings.chat_accent_color}`
        );
      } else {
        root.style.setProperty("--primary-color", "#0923E6");
      }
    }

    if (webchatSettings.chat_text_color) {
      root.style.setProperty(
        "--primary-text-color",
        `#${webchatSettings.chat_text_color}`
      );
    }

    if (webchatSettings) {
      if (webchatSettings.chat_user_color) {
        root.style.setProperty(
          "--user-color",
          `#${webchatSettings.chat_user_color}`
        );
      } else {
        root.style.setProperty("--user-color", "#DCE5ED");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_user_text_color) {
        root.style.setProperty(
          "--user-text-color",
          `#${webchatSettings.chat_user_text_color}`
        );
      } else {
        root.style.setProperty("--user-text-color", "#0E0748");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_button_color) {
        root.style.setProperty(
          "--button-color",
          `#${webchatSettings.chat_button_color}`
        );
      } else {
        root.style.setProperty("--button-color", "#DCE5ED");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_button_text_color) {
        root.style.setProperty(
          "--button-text-color",
          `#${webchatSettings.chat_button_text_color}`
        );
      } else {
        root.style.setProperty("--button-text-color", "#0E0748");
      }
    }

    if (webchatSettings.customised_buttons) {
      setDisableCustomButton(false);
      setCustomButtonData(webchatSettings.customised_buttons);
    } else {
      setDisableCustomButton(false);
      setCustomButtonData([]);
    }
  };

  const onStartNewSession = async () => {
    let token = null;
    if (
      (process.env && process.env.REACT_APP_TYPE === "login_widget") ||
      window.SSO_PROVIDER === "oneauth"
    ) {
      token = authTokens.access_token;
    } else if (window.SSO_PROVIDER === "auth0") {
      token = await getTokenSilently();
    }

    const connectId = selectedClass;
    const agentId = AgentClassService.getSelectedAgent();
    const param = connectId ? `&connection_id=${connectId}` : "";

    await fetch(
      window.CHAT_API +
        `/api/v1/chatlog/sessions?agent_id=${agentId}${param}`,
      {
        method: "POST",
        openWhenHidden: true,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      }
    );
    setTimeout(async () => {
      await initSSEConnection();
      getGreetings(true, false);
  
      if (initiallyCalled) {
        initiallyCalled.current = true;
      }
      setShowResumeSessionModal(false);
      getLastFeedback();
      initialRender.current = false;
      getSuggestedQuestions();
      // checkSelectedAgentAndClass();
    }, 500);
  };

  const displayBotBubbles = (element, callBack) => {
    if (
      element.reply.object_data &&
      Object.keys(element.reply.object_data).length > 0
    ) {
      historyChatRef.current.unshift(
        <BotQuizResultBubble
          avatarIcon={botAvatar}
          submissionData={element.reply.object_data}
          submissionId={element.reply.object_id}
          // displayedQuestionsId={currentQuiz.current.questions}
          onClick={handleQuizReportAction}
          bubbleType="history"
          enableLearning={enableLearning}
        />
      );
    } else if (element.reply.bubbles) {
      element.reply.bubbles.reverse().forEach((bubble, index) => {
        if (bubble.botmode !== "buffer") {
          historyChatRef.current.unshift(
            <BotReply
              avatarIcon={botAvatar}
              bubbleIndex={index}
              key={bubble.id}
              documentId={element.document_id}
              replyId={element._id}
              replyType={bubble.template}
              data={bubble}
              replyTime={
                element.reply && element.reply.timestamp ? (
                  <Moment unix format="DD/MM/YY hh:mm A">
                    {element.reply.timestamp / 1000}
                  </Moment>
                ) : (
                  ""
                )
              }
              bookmarkAction={bookmarkAction}
              recordFeedback={recordFeedback}
              showFeedback={true}
              feedback={feedbackMap[element._id] || null}
              setDocumentPreview={setDocumentPreview}
              element={element}
              feedbackType={element.feedback}
              showFallback={false}
              agentDisclaimer={agentDisclaimer}
            />
          );
        }
      });
    } else if (element.reply.text) {
      historyChatRef.current.unshift(
        <BotChatBubble
          key={uuidv4()}
          avatarIcon={botAvatar}
          messageId={element._id}
          message={linkify(element.reply.text)}
          messageTime={
            element.reply && element.reply.timestamp ? (
              <Moment unix format="DD/MM/YY hh:mm A">
                {element.reply.timestamp / 1000}
              </Moment>
            ) : (
              ""
            )
          }
          feedback={feedbackMap[element._id] || null}
          showFeedback={true}
          recordFeedback={recordFeedback}
          bookmarkAction={bookmarkAction}
        />
      );
    }

    callBack();
  };

  const displayUserBubbles = (element) => {
    if (element.question && element.question.question) {
      historyChatRef.current.unshift(
        <UserChatBubble
          key={uuidv4()}
          avatarIcon={userAvatarIcon}
          message={element.question.question}
          messageTime={
            <Moment unix format="DD/MM/YY hh:mm A">
              {element.question.timestamp / 1000}
            </Moment>
          }
          bookmarkAction={bookmarkAction}
          copyAction={copyAction}
        />
      );
    }
  };

  const setDocumentPreview = (answer, replyId) => {
    setShowDocumentPreview(true);
    setAnswerForDocumentPreview(answer);
    setReplyIdForDocumentPreview(replyId);
    generateContent(replyId);
  };

  // console.group('[---------- activeSessoin ----------]')
  // console.log('activeSessoin', activeSession);
  // console.log('sessionDisclaimers', sessionDisclaimers);
  // console.groupEnd()

  const fetchChat = (sessionId) => {
    setChatData([]);
    setDisclaimers(sessionId);

    // if ( disclaimerIndex >= 0 ) {
    //   oldDisclaimers[disclaimerIndex].shownFeedback = shownFeedback
    // } else {
    // }
    chatMessagesRef.current = [];
    historyChatRef.current = [];

    let url;

    // if (lastLog._id) {
    //   url = `/api/v1/chatlog/logs?session_id=${sessionId}&last_log_id=${lastLog._id}`;
    // } else {
    url = `/api/v1/chatlog/logs?session_id=${sessionId}`;
    // }

    setSnackbar({
      isOpen: true,
      message: t("Loading conversations") + "..",
      type: "bubble",
    });

    makeApiCall
      .request(window.CHAT_API + url, {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          let chatLogs = json.data.logs;

          if (chatLogs && chatLogs.length && chatLogs[0].context) {
            if ("is_input_disabled" in chatLogs[0].context) {
              setHideChatInput(chatLogs[0].context.is_input_disabled);
            }
          }

          lastLog.current = json.data.last_log_id;
          chatLogs.forEach((element) => {
            if (element.reply) {
              displayBotBubbles(element, () => {
                displayUserBubbles(element);
              });
            }
          });

          if (chatLogs && chatLogs.length) {
            if (
              chatLogs[0].reply &&
              chatLogs[0].reply.buttons &&
              chatLogs[0].reply.buttons.length > 0
            ) {
              const buttonsId = uuidv4();
              const buttons = chatLogs[0].reply.buttons.filter((b) => {
                return (
                  b.reference ||
                  (b && b.reference && b.reference.trim().length > 0) ||
                  b.reference_id ||
                  (b && b.reference_id && b.reference_id.trim().length > 0)
                );
              });

              if (buttons?.length > 0) {
                historyChatRef.current.push(
                  <UserChatBubble
                    isButtonContainer
                    key={buttonsId}
                    avatarIcon={AvatarIcon}
                    message={
                      <ButtonPaging
                        onCustomButtonClick={onCustomButtonClick}
                        buttons={buttons.map((b) => ({
                          ...b,
                          buttonsId: buttonsId,
                        }))}
                        isMobile={isMobile}
                      />
                    }
                    showFeedback={false}
                  />
                );
              }
            }
          }
          setChatData(historyChatRef.current);
          updateScrollbar();
          getSuggestedPrompts();
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });

    setChatContext("");
    // getSuggestedQuestions();
  };

  const setDisclaimers = (sessionId) => {
    const oldDisclaimers = [...sessionDisclaimers];
    let disclaimerIndex = oldDisclaimers.findIndex(
      (disc) => disc.sessionId === sessionId
    );
    if (disclaimerIndex < 0) {
      oldDisclaimers.push({
        sessionId,
        shownFeedback: false,
      });
    }
    setSessionDisclaimers(oldDisclaimers);
  };

  const checkIfDisclaimerHasAlreadyBeenShown = () => {
    const oldDisclaimers = [...sessionDisclaimers];
    let disclaimerIndex = oldDisclaimers.findIndex(
      (disc) => disc.sessionId === activeSession._id
    );
    return (
      (disclaimerIndex >= 0 && oldDisclaimers[disclaimerIndex].shownFeedback) ||
      false
    );
  };

  const openPastSessionPanel = (activeTab) => {
    setPastSessionActiveTab(activeTab);
    let rightPanel = document.getElementById("past-session-panel");

    if (rightPanel) {
      rightPanel.classList += " open-panel-wrap";
    }
  };

  const onPastSessionClick = (
    lastLog,
    sessionId,
    time,
    activeTab,
    resumePastSessions = false
  ) => {
    if (resumePastSessions) {
      callResumeSessionApi(sessionId);
    } else {
      setSessionTime(Math.floor(time / 60));
      openPastSessionPanel(activeTab);
      getSummary(sessionId);
      let url;

      // if (lastLog._id) {
      //   url = `/api/v1/chatlog/logs?session_id=${sessionId}&last_log_id=${lastLog._id}`;
      // } else {
      url = `/api/v1/chatlog/logs?session_id=${sessionId}`;
      // }

      makeApiCall
        .request(window.CHAT_API + url, {
          method: "GET",
          headers: authHeader(),
        })
        .then((json) => {
          if (json.status.code === 200) {
            setPastSessionData(json.data.logs);
          } else {
            setSnackbar({
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            });
          }
        });

      setChatContext("");
    }
  };

  const getCurrentSession = (callback) => {
    if (!AgentClassService.getSelectedAgent()) {
      return;
    }

    makeApiCall
      .request(window.CHAT_API + "/api/v1/chatlog/sessions/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        let error = false;
        if (json && (json.status.code === 200 || json.status.code === 404)) {
          if (json.data && json.data.session) {
            callback(json.data.session);
          } else {
            callback(null);
          }
        } else {
          error = true;
        }

        if (error) {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const getOrganisationName = () => {
    let avatarIcon = getUserAvatarIcon();
    setUserAvatarIcon(avatarIcon);

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/organisations/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data.organisation && json.data.organisation.name) {
            setTitle(`${json.data.organisation.name} - Chat`);
          }
          if (json?.data?.organisation?.default_language_code) {
            setOrgDefaultLang(
              getLangValue(json?.data?.organisation?.default_language_code)
            );
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const addBookmark = (text, isAgent) => {
    makeApiCall
      .request(window.CHAT_API + "/api/v1/bookmark/bookmarks", {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          text: text,
          is_agent: isAgent,
        }),
      })
      .then((json) => {
        if (json.status.code === 200) {
          setTriggerBookmarkLoad((data) => !data);
          setSnackbar({
            isOpen: true,
            message: "Bookmark is added successfully.",
            type: "success",
          });
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const bookmarkAction = (referrer, message) => {
    addBookmark(message, referrer === "bot");
  };

  const copyAction = (message) => {
    // put action here
    alert(message);
  };

  const getGreetings = (isClear, isNewSession) => {
    let body = {
      is_greeting: true,
      source: "webchat"
    };

    if (isClear) {
      body = {
        ...body,
        is_refresh: true,
      };
    }

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: body,
    };
    setChatData([]);
    startListenChat(requestOptions);
    getSuggestedPrompts();
  };

  const sendFormConversation = (button) => {
    setButtonReference(button?.reference);
    setMessageCopy(button.label);
    chatMessagesRef.current = chatMessagesRef.current.filter(
      (m) => m.key !== button?.buttonsId
    );
    setChatData(chatMessagesRef.current);
    setDisableCustomButton(true);
  };

  const getSuggestedQuestions = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    let requestUrl = window.CHAT_API + "/api/v1/chatbot/suggested-questions";

    if (chatContext) {
      requestUrl = requestUrl + "?topic_ids=" + chatContext;
    }

    makeApiCall.request(requestUrl, requestOptions).then((json) => {
      if (json.status.code === 200) {
        setSuggestedQuestionsData(json.data.suggested_questions);
      } else {
        setSnackbar({
          isOpen: true,
          message: "Oops! Something went wrong.",
          type: "error",
        });
      }
    });
  };

  const postFeedback = (feedback, key) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ type: feedback }),
    };
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatbot/replies/" + key + "/feedback",
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          // setSnackbar({
          //   isOpen: true,
          //   message: "Thank you for your feedback.",
          //   type: "success",
          // });
        } else {
          setSnackbar({
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          });
        }
      });
  };

  const recordFeedback = (feedback, key) => {
    let feedbacks = { ...feedbackMap };
    feedbacks[key] = feedback;
    setFeedbackId(key);
    setFeedbackMap(feedbacks);
    if (feedback === 0) {
      setOpenModal(true);
    } else {
      postFeedback(feedback, key);
    }
  };

  const beforeLogout = () => {
    setOpenFeedbackModal(true);
    setLogoutAfterFeedback(true);
  };

  const logoutUser = async () => {
    let popupParams = "";
    await endSession("logout", true);

    if (process.env && process.env.REACT_APP_TYPE === "login_widget") {
      let initialToken = localStorage.getItem("widget_initial_token");
      setAuthTokens(false);
      localStorage.clear();
AgentClassService.clear();
      window.location.href = `/?t=${initialToken}`;
    } else if (window.SSO_PROVIDER === "auth0") {
      const isThorughPopup = localStorage.getItem("agentThroughAPI");
      popupParams = isThorughPopup
        ? isThorughPopup && isThorughPopup !== "null"
          ? `?login=popup&agent=${isThorughPopup}&through=admin-portal`
          : "?login=popup"
        : "";
      localStorage.clear();
AgentClassService.clear();
      logout({
        returnTo: window.location.origin + popupParams,
      });
    } else {
      const isThorughPopup = localStorage.getItem("agentThroughAPI");
      popupParams = isThorughPopup
        ? isThorughPopup && isThorughPopup !== "null"
          ? `login=popup&agent=${isThorughPopup}&through=admin-portal`
          : "?login=popup"
        : "";
      localStorage.clear();
AgentClassService.clear();
      setAuthTokens(false);
      // If not through external popup
      if (popupParams) {
        let newWindow = window.open(
          window.SSO_DOMAIN +
            "/logout?redirect_uri=" +
            window.location.origin +
            "&" +
            popupParams,
          "logout",
          "width=300,height=300"
        );
        setTimeout(() => {
          newWindow.close();
          window.location.href = window.location.origin + "?" + popupParams;
        }, 1000); //1 sec
      } else {
        window.location.href =
          window.SSO_DOMAIN +
          "/logout?redirect_uri=" +
          window.location.origin +
          popupParams;
      }
    }
  };

  const endSession = async (logout = "", throughLoginPopup = false) => {
    let popupParams = "";
    try {
      if (!AgentClassService.getSelectedAgent()) {
        return;
      }
      
      const requestOptions = {
        method: "PATCH",
        headers: authHeader(),
      };
      const json = await makeApiCall.request(
        window.CHAT_API + "/api/v1/chatlog/sessions/current",
        requestOptions
      );
      if (json.status.code === 200) {
        gaEndSession();
        return json;
      } else {
        console.trace();
        if (process.env && process.env.REACT_APP_TYPE === "login_widget") {
          let initialToken = localStorage.getItem("widget_initial_token");
          setAuthTokens(false);
          localStorage.clear();
          AgentClassService.clear();
          window.location.href = `/?t=${initialToken}`;
        } else if (window.SSO_PROVIDER === "auth0") {
          if (throughLoginPopup) {
            const isThorughPopup = await localStorage.getItem(
              "agentThroughAPI"
            );
            popupParams = isThorughPopup
              ? isThorughPopup && isThorughPopup !== "null"
                ? `?login=popup&agent=${isThorughPopup}&through=admin-portal`
                : "?login=popup"
              : "";
            localStorage.clear();
            AgentClassService.clear();
            if (isThorughPopup) {
              localStorage.setItem("agentThroughAPI", isThorughPopup);
            }
            logout({
              returnTo: window.location.origin + popupParams,
            });
          } else {
            localStorage.clear();
            AgentClassService.clear();
            logout({
              returnTo: window.location.origin,
            });
          }
        } else {
          if (throughLoginPopup) {
            const isThorughPopup = await localStorage.getItem(
              "agentThroughAPI"
            );
            popupParams = isThorughPopup
              ? isThorughPopup && isThorughPopup !== "null"
                ? `&login=popup&agent=${isThorughPopup}&through=admin-portal`
                : "?login=popup"
              : "";
            localStorage.clear();
            AgentClassService.clear();
            if (isThorughPopup) {
              localStorage.setItem("agentThroughAPI", isThorughPopup);
            }
            setAuthTokens(false);
            window.location.href =
              window.SSO_DOMAIN +
              "/logout?redirect_uri=" +
              window.location.origin +
              popupParams;
          } else {
            localStorage.clear();
            AgentClassService.clear();
            setAuthTokens(false);
            window.location.href =
              window.SSO_DOMAIN +
              "/logout?redirect_uri=" +
              window.location.origin;
          }
        }
      }
    } catch (error) {
      // Handle any errors that occur during the session ending process
      console.error(error);
    }
  };

  const sendFeedbackComment = () => {
    // postFeedback(feedbackMap[feedbackId], feedbackId);
    if (feedbackComment) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ type: 0, comment: feedbackComment }),
      };

      makeApiCall
        .request(
          window.CHAT_API +
            "/api/v1/chatbot/replies/" +
            feedbackId +
            "/feedback",
          requestOptions
        )
        .then((json) => {
          if (json.status.code === 200) {
            // setSnackbar({
            //   isOpen: true,
            //   message: "Thank you for your feedback.",
            //   type: "success",
            // });
          } else {
            setSnackbar({
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            });
          }
        });
    }
    setFeedbackComment("");
    setOpenModal(false);
  };

  const sendSessionFeedback = () => {
    if (sessionFeedback) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          rating: sessionFeedback,
          comment: sessionFeedbackComment,
        }),
      };

      makeApiCall
        .request(window.CHAT_API + "/api/v1/chatlog/feedbacks", requestOptions)
        .then((json) => {
          if (json.status.code === 200) {
            setSnackbar({
              isOpen: true,
              message: "Thank you for your feedback.",
              type: "success",
            });
            setOpenFeedbackModal(false);
            setOpenFeedbackContainer(false);
            setSessionFeedbackComment("");
            setSessionFeedback(0);

            if (logoutAfterFeedback === true) {
              setSnackbar({
                isOpen: true,
                message:
                  "Thank you for your feedback! You will be logged out in 3 seconds.",
                type: "success",
              });

              setTimeout(function () {
                logoutUser();
              }, 3000);
            }
          } else {
            setSnackbar({
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            });
          }
        });
    }
    setFeedbackComment("");
    setOpenModal(false);
  };

  const onCustomButtonClick = async (button, origin) => {
    const buttonsId = button?.buttonsId;
    if (button && button.type && button.type === "question") {
      setMessageCopy(button.label);
      return;
    }

    let type = "",
      referenceId = "";

    if (button.reference) {
      type = button.reference.split("::")[0];
      referenceId = button.reference.split("::")[1];
    } else if (button.reference_id && button.type) {
      type = button.type;
      referenceId = button.reference_id;
    }

    if (
      button &&
      button.type &&
      (button.type === "searchoutside" ||
        button.type === "tellmemore" ||
        button.type === "expandonthat")
    ) {
      setButtonReference(button.reference);
      setMessageCopy(button.label);
      chatMessagesRef.current = chatMessagesRef.current.filter(
        (m) => m.key !== buttonsId
      );
      setChatData(chatMessagesRef.current);
      setDisableCustomButton(true);
      return;
    }
    if (
      button &&
      button.type &&
      button.type !== "trigger" &&
      button.type !== "return_to_normal"
    ) {
      makeApiCall
        .request(
          window.CHAT_API +
            `/api/v1/chatbot/button-data/${type}::${referenceId}`,
          {
            method: "GET",
            headers: authHeader(),
          },
          true
        )
        .then((json) => {
          if (json.status.code === 200) {
            let temp = {};
            if (
              json?.data?.button_data?.form?.display_mode ===
                "conversational_v2" ||
              json?.data?.button_type === "cancelform" ||
              json?.data?.button_type === "submitform"
            ) {
              sendFormConversation(button);
              return;
            }

            if (json.data?.button_data && "form" in json.data.button_data) {
              temp = {
                form: json.data.button_data.form,
                type: "form",
              };
            } else if (
              json.data?.button_data &&
              "quiz" in json.data.button_data
            ) {
              temp = {
                quiz: json.data.button_data.quiz,
                type: "quiz",
              };
            } else if (
              json.data?.button_data &&
              json.data.button_type === "intent"
            ) {
              temp = {
                question: json.data.button_data.question,
                reference: button.reference
                  ? button.reference
                  : button.reference_id,
                type: "intent",
              };
            } else {
              temp = {
                question: json.data.button_data.question,
                reference: button.reference
                  ? button.reference
                  : button.reference_id,
                type: "question",
              };
            }
            onChatButtonClick({ ...temp, buttonsId: buttonsId }, origin);
          }
        });
    }
  };

  const onChatButtonClick = async (button, origin) => {
    setDisableCustomButton(false);
    switch (button.type) {
      case "form":
        const { data } = await makeApiCall.request(
          window.CHAT_API + "/api/v1/form/forms/" + button.form._id + "/submissions/last?state=draft",
          {
            method: "GET",
            headers: authHeader(),
          }
        );
        setFormButton(button);
    
        if (data.submission) {
          setSavedProgressDetected(true);

          button.form.fields = data.submission.form_fields;
        } else {
          onResumeForm(false, button);
        }
        break;
      case "quiz":
        setDisableQuizButton(false);
        openQuizGeneratorScreen(button.quiz);
        updateScrollbar();
        break;
      case "question":
        if (button.reference) {
          setButtonReference(button.reference);
        }
        if (chatMessagesRef.current.length > 0 && origin !== "custom") {
          if (origin !== "quiz") {
            setChatData((data) =>
              data.concat(
                <BotReply avatarIcon={botAvatar} replyType={"loading"} />
              )
            );
            chatMessagesRef.current = chatMessagesRef.current.filter(
              (m) => m.key !== button?.buttonsId
            );
          }
          setChatData(chatMessagesRef.current);
        }
        setMessageCopy(button.question);
        break;
      case "intent":
        if (button.reference) {
          let type = button.type,
            referenceId = button.reference;
          if (button.reference && button.reference.includes("::")) {
            type = button.reference.split("::")[0];
            referenceId = button.reference.split("::")[1];
          }
          setButtonReference(`${type}::${referenceId}`);
        }
        if (chatMessagesRef.current.length > 0 && origin !== "custom") {
          if (origin !== "quiz") {
            setChatData((data) =>
              data.concat(
                <BotReply avatarIcon={botAvatar} replyType={"loading"} />
              )
            );
            chatMessagesRef.current = chatMessagesRef.current.filter(
              (m) => m.key !== button?.buttonsId
            );
          }
          setChatData(chatMessagesRef.current);
        }
        setMessageCopy(button.question);
        break;
      default:
        break;
    }
  };

  const handleQuizReportAction = (
    data,
    origin,
    displayedQuestionsId,
    buttonSubmissionData
  ) => {
    // console.group();
    // console.log('data', data)
    // console.log('origin', origin)
    // console.log('displayedQuestionsId', displayedQuestionsId)
    // console.log('buttonSubmissionData', buttonSubmissionData)
    // console.groupEnd();

    setShowQuizReport({
      open: false,
      type: "success",
      data: {},
    });
    setShowQuiz(false);
    setStartQuiz(false);
    if (origin !== "conversational") {
      // getSessions();
    }

    if (origin === "quiz" && (data.reference || data.reference_id)) {
      if (buttonSubmissionData && displayedQuestionsId) {
        chatMessagesRef.current.push(
          <BotQuizResultBubble
            avatarIcon={botAvatar}
            submissionData={buttonSubmissionData}
            onClick={(button,
              origin,
              questionsId,
              submissionData) => onCustomButtonClick(button, origin)}
            enableLearning={enableLearning}
            displayedQuestionsId={displayedQuestionsId}
          />
        );
        setChatData(chatMessagesRef.current);
      }
      onCustomButtonClick(data, origin);
    }

    if (origin === "backToChat") {
      chatMessagesRef.current.push(
        <BotQuizResultBubble
          avatarIcon={botAvatar}
          submissionData={data}
          onClick={handleQuizReportAction}
          enableLearning={enableLearning}
          displayedQuestionsId={displayedQuestionsId}
        />
      );
      setChatData(chatMessagesRef.current);
    }
    setTimeout(() => updateScrollbar());
  };

  const openFormScreen = async (form) => {
    if (form.display_mode === "standard") {
      setForm(form);
      setShowForm(true);
      return;
    }

    // /

    if (form.display_mode === "conversational") {
      chatMessagesRef.current.pop();
      setChatData((data) =>
        data.concat(
          <FormBubble
            avatarIcon={botAvatar}
            onBack={() => {
              setShowForm(false);
              getSessions();
            }}
            onSubmit={handleFormSubmit}
            formLayout={form.layout}
            formSchema={form.layout.formSchema}
            uiSchema={form.layout.uiSchema}
            submitButtonLabel={
              form.layout.submitLabelText
                ? form.layout.submitLabelText
                : "SUBMIT"
            }
            primaryHeaderText={form.layout.headerText}
            formId={form._id}
            displayMode={form.display_mode}
            updateScrollbar={(e) => {
              updateScrollbar(e, true);
            }}
          />
        )
      );
      return;
    }

    // {
    // !showFormSubmissionMessage &&

    // }

    // }
  };

  const activateLoading = (mode) => {
    let details = {
      message: "Hang tight! Your form is being submitted.",
      heading: "Submitting...",
      buttonLabel: "",
      type: "loading",
      displayMode: mode,
    };
    setShowFormSubmissionMessage(true);
    setFormSubmissionMessageDetails(details);
  };

  const handleFormSubmit = async (
    formId,
    formDetails,
    displayMode = "standard"
  ) => {
    // console.log("formDetails", formDetails);
    if (displayMode === "conversational") {
      setShowForm(true);
    }
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    activateLoading(displayMode);
    if (formDetails.formData && Object.keys(formDetails.formData).length > 0) {
      let json = await makeApiCall.request(
        window.CHAT_API + "/api/v1/form/forms/" + formId + "/submissions",
        {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({ form_data: formDetails.formData }),
        }
      );
      if (json.status.code === 200) {
        let details = {
          message:
            json.data.form_reply?.text?.length >= 0
              ? json.data.form_reply.text
              : json.data.form_reply.error
              ? json.data.form_reply.error
              : "Looks like something is not right here. Please contact your admin.",
          heading:
            json.data.form_reply?.text?.length >= 0
              ? ""
              : json.data.form_reply.error
              ? "Oops! Submission was unsucessful."
              : "Oops! Something went wrong.",
          buttonLabel: "GO BACK TO CHAT",
          type:
            json.data.form_reply.text?.length >= 0
              ? "success"
              : json.data.form_reply.error
              ? "error"
              : "error",
          displayMode: displayMode,
        };
        setShowFormSubmissionMessage(true);
        setFormSubmissionMessageDetails(details);
      } else {
        setSnackbar({
          isOpen: true,
          message: "Oops! Something went wrong.",
          type: "error",
        });
      }
    }
  };

  const handleSubmissionMessageAction = () => {
    setShowFormSubmissionMessage(false);
    setShowForm(false);
    getSessions();
  };

  const onAnswerInput = (value, type, questionId) => {
    if (type === "text") {
      let tempAnswers = { ...convoQuizAnswers.current };
      tempAnswers[questionId] = {
        ...convoQuizAnswers.current[questionId],
        question_id: questionId,
        text: value,
      };
      convoQuizAnswers.current = tempAnswers;
    } else if (type === "radio" || type === "checkbox") {
      let tempAnswers = { ...convoQuizAnswers.current };

      tempAnswers[questionId] = {
        ...convoQuizAnswers.current[questionId],
        question_id: questionId,
        options: value,
      };
      convoQuizAnswers.current = tempAnswers;
    } else if (type === "matching") {
      let tempAnswers = { ...convoQuizAnswers.current };
      let temp = [];
      Object.keys(value).forEach((key) => {
        temp.push({
          term: key,
          definition: value[key] ? value[key].id : "",
        });
      });
      tempAnswers[questionId] = {
        ...convoQuizAnswers.current[questionId],
        question_id: questionId,
        matches: temp,
      };
      convoQuizAnswers.current = tempAnswers;
    } else if (type === "group") {
      let tempAnswers = { ...convoQuizAnswers.current };
      Object.entries(value).forEach(
        ([key, value]) => (tempAnswers[key] = value)
      );
      convoQuizAnswers.current = tempAnswers;
    }

    let currentIdIndex = Object.keys(
      currentQuiz.current.transformedQuestions
    ).indexOf(questionId);

    if (
      conversationalQuizStrategy.current === "branching" &&
      currentQuiz.current.transformedQuestions[questionId].type === "radio"
    ) {
      currentQuiz.current.transformedQuestions[questionId].options &&
        currentQuiz.current.transformedQuestions[questionId].options.forEach(
          (option) => {
            if (option.id === convoQuizAnswers.current[questionId].options[0]) {
              if (option.branch && option.branch.action) {
                if (option.branch.action === "end-quiz") {
                  askConversationalQuizQuestion(questionId, true);
                } else if (
                  option.branch.action === "jump-to-question" &&
                  option.branch.reference_id
                ) {
                  askConversationalQuizQuestion(
                    option.branch.reference_id,
                    false
                  );
                }
              } else if (
                currentIdIndex + 1 <=
                  Object.keys(currentQuiz.current.transformedQuestions).length -
                    1 &&
                currentQuiz.current.transformedQuestions
              ) {
                askConversationalQuizQuestion(
                  Object.keys(currentQuiz.current.transformedQuestions)[
                    currentIdIndex + 1
                  ],
                  false
                );
              } else if (
                currentIdIndex + 1 ===
                Object.keys(currentQuiz.current.transformedQuestions).length
              ) {
                askConversationalQuizQuestion(questionId, true);
              }
            }
          }
        );
    } else if (
      currentIdIndex + 1 <=
        Object.keys(currentQuiz.current.transformedQuestions).length - 1 &&
      currentQuiz.current.transformedQuestions
    ) {
      askConversationalQuizQuestion(
        Object.keys(currentQuiz.current.transformedQuestions)[
          currentIdIndex + 1
        ],
        false
      );
    } else if (
      currentIdIndex + 1 ===
      Object.keys(currentQuiz.current.transformedQuestions).length
    ) {
      askConversationalQuizQuestion(questionId, true);
    }
  };

  const onConversationalQuizSubmit = async () => {
    const displayedQuestionsIdConvoFallBack = Object.keys(
      currentQuiz.current.transformedQuestions
    );
    const displayedQuestionsId =
      displayedQuestionsIdConvo.length > 0
        ? displayedQuestionsIdConvo
        : displayedQuestionsIdConvoFallBack;

    let json = await makeApiCall.request(
      window.CHAT_API +
        "/api/v1/quiz/quizzes/" +
        currentQuiz.current._id +
        "/submissions",
      {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          selected_question_ids: displayedQuestionsIdConvoFallBack,
          answers: Object.values(convoQuizAnswers.current),
          action: "submit",
        }),
      }
    );

    if (json.status.code === 200) {
      setTimeout(() => {
        updateScrollbar();

        setChatData((data) => {
          data.pop();
          return data.concat(
            <BotQuizResultBubble
              showLoading
              avatarIcon={botAvatar}
              submissionData={json.data.quiz_submission}
              displayedQuestionsId={displayedQuestionsIdConvoFallBack}
              onClick={handleQuizReportAction}
              updateScrollbar={updateScrollbar}
              enableLearning={enableLearning}
            />
          );
        });
        setHideChatInput(false);
        setIsConversationalQuizActive(false);
      }, 2000);
    } else if (json.status.code < 500) {
      setSnackbar({
        isOpen: true,
        message: "Unable to submit quiz: " + json.status.message,
        type: "error",
      });
    } else {
      // error
      setSnackbar({
        isOpen: true,
        message: "Oops! Something went wrong.",
        type: "error",
      });
    }
  };

  const askConversationalQuizQuestion = (currentQuestionId, isLastQuestion) => {
    // let temp =
    //   currentQuiz.current.sequence[currentSequenceIndex];
    setChatData((data) =>
      data.concat(
        <BotQuizBubble
          avatarIcon={botAvatar}
          questionData={
            currentQuiz.current.transformedQuestions[currentQuestionId]
          }
          questionId={currentQuestionId}
          isLastQuestion={isLastQuestion}
          onAnswerInput={onAnswerInput}
          onSubmit={onConversationalQuizSubmit}
          updateScrollbar={updateScrollbar}
        />
      )
    );
    updateScrollbar();
  };

  const openQuizGeneratorScreen = (selectedQuiz) => {
    if (selectedQuiz.display_mode === "conversational") {
      // chatMessagesRef.current.pop();
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);

      currentQuiz.current = selectedQuiz;

      setHideChatInput(true);
      setIsConversationalQuizActive(true);
      let temp = {};
      selectedQuiz.questions.forEach((question) => {
        if (
          question.type === "text" ||
          question.type === "essay" ||
          question.type === "bullet" ||
          question.type === "newbullet"
        ) {
          temp[question.id] = {
            text: "",
            question_id: question.id,
          };
        } else if (
          question.type === "table" ||
          question.type === "description"
        ) {
          temp[question.id] = {
            text: "[]",
            question_id: question.id,
          };
        } else if (question.type === "matching") {
          temp[question.id] = {
            matches: [],
            question_id: question.id,
          };
        } else if (question.options) {
          temp[question.id] = {
            options: [],
            question_id: question.id,
          };
        }
      });
      convoQuizAnswers.current = temp;
      transformQuizQuestions();
    } else {
      setQuiz(selectedQuiz);
      setShowQuiz(true);
    }
  };

  const transformQuizQuestions = () => {
    let temp = {};
    currentQuiz.current.questions.forEach((question) => {
      if (question.parent_id && temp[question.parent_id]) {
        temp[question.parent_id] = {
          ...temp[question.parent_id],
          subquestions:
            "subquestions" in temp[question.parent_id]
              ? temp[question.parent_id].subquestions.concat([question])
              : [].concat([question]),
        };
      } else {
        temp[question.id] = question;
      }
    });
    currentQuiz.current.transformedQuestions = temp;
    initialiseConversationalQuiz();
  };

  const initialiseConversationalQuiz = () => {
    let displayCount = currentQuiz.current.display_questions_count
      ? currentQuiz.current.display_questions_count
      : Object.keys(currentQuiz.current.transformedQuestions).length;

    if (
      displayCount &&
      displayCount <
        Object.keys(currentQuiz.current.transformedQuestions).length
    ) {
      conversationalQuizStrategy.current = "random";
    }

    Object.keys(currentQuiz.current.transformedQuestions).forEach(
      (questionId) => {
        if (currentQuiz.current.transformedQuestions[questionId].options) {
          currentQuiz.current.transformedQuestions[questionId].options.forEach(
            (option) => {
              if (option.branch && option.branch.action) {
                conversationalQuizStrategy.current = "branching";
              }
            }
          );
        }
      }
    );

    if (conversationalQuizStrategy.current === "random") {
      let toDisplay = getNRandomArrayIndices(
        Object.keys(currentQuiz.current.transformedQuestions),
        displayCount
      );

      let result = Object.keys(currentQuiz.current.transformedQuestions);
      let temp = toDisplay.sort((a, b) => a - b).map((item) => result[item]);

      let allQues = { ...currentQuiz.current.transformedQuestions };
      let tempQue = {};
      for (let key in allQues) {
        let q = temp.find((e) => e === key);
        if (q) {
          tempQue = {
            ...tempQue,
            [q]: allQues[q],
          };
        }
      }
      if (tempQue) {
        currentQuiz.current.transformedQuestions = tempQue;
      }
      setDisplayedQuestionsIdConvo([...temp]);
      currentQuiz.current.sequence = toDisplay; // to be removed
      askConversationalQuizQuestion(temp[0], false);
    } else {
      setDisplayedQuestionsIdConvo([
        ...Object.keys(currentQuiz.current.transformedQuestions),
      ]);
      //old
      let toDisplay = new Array(displayCount);
      for (let i = 0; i < displayCount; i++) {
        toDisplay[i] = i;
      }
      currentQuiz.current.sequence = toDisplay;
      askConversationalQuizQuestion(
        Object.keys(currentQuiz.current.transformedQuestions)[0],
        false
      );
    }
  };

  const handleQuizQuit = (quizId, data, displayedQuestionsId) => {
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    setQuizDisplayedQuestions(displayedQuestionsId);
    if (data) {
      quizSubmissionPost(quizId, data, "quit", displayedQuestionsId);
    }
  };

  const handleQuizSubmit = (quizId, data, displayedQuestionsId) => {
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    setQuizDisplayedQuestions(displayedQuestionsId);
    setDisableQuizButton(true);
    if (data) {
      quizSubmissionPost(quizId, data, "submit", displayedQuestionsId);
    }
  };

  const quizSubmissionPost = async (
    quizId,
    data,
    actionType,
    displayedQuestionsId
  ) => {
    let json = await makeApiCall.request(
       window.CHAT_API + "/api/v1/quiz/quizzes/" + quizId + "/submissions",
       {
         method: "POST",
         headers: authHeader(),
         body: JSON.stringify({
           selected_question_ids: displayedQuestionsId,
           answers: data,
           action: actionType.toLowerCase(),
         }),
       }
     );

    if (json.status.code === 200) {
      // successfull
      if (actionType === "submit") {
        setShowQuizReport({
          open: true,
          type: "success",
          data: json.data.quiz_submission,
        });
        return json.status.quiz_submission;
      } else if (actionType === "quit") {
        if (exitFromQuiz) {
          chatMessagesRef.current.push(
            <BotQuizResultBubble
              avatarIcon={botAvatar}
              submissionData={json.data.quiz_submission}
              onClick={handleQuizReportAction}
              enableLearning={enableLearning}
              displayedQuestionsId={displayedQuestionsId}
            />
          );
          setChatData(chatMessagesRef.current);
          updateScrollbar();
          if(openExitQuizConfirm.navigateTo === "selectChannel") {
            await handleClassSelectWhileQuizDisplay(openExitQuizConfirm.exitData)
          }
          if(openExitQuizConfirm.navigateTo === "newSession") {
            await onStartNewSession();
          }
          setShowQuiz(false);
          setExitFromQuiz(false);
          setStartQuiz(false);
          setOpenExitQuizConfirm({ isExit: false, navigateTo: "", exitData: null});
        } else {
          setShowQuizReport({
            open: true,
            type: "quit",
            data: json.data.quiz_submission,
          });
        }
        return json.status.quiz_submission;
      }
    } else if (json.status.code < 500) {
      setSnackbar({
        isOpen: true,
        message: "Unable to submit quiz: " + json.status.message,
        type: "error",
      });
    } else {
      // error
      setSnackbar({
        isOpen: true,
        message: "Oops! Something went wrong.",
        type: "error",
      });
    }
  };

  const createBotReply = async (
    newData,
    allData,
    showFeedback,
    showFallback = false
  ) => {
    let documentPreviewSetting;
    // console.log({ newData });
    if (newData && newData.reply && newData.reply.document_id) {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      let json = await makeApiCall.request(
        window.CHAT_API +
          "/api/v1/convos/documents/" +
          newData.reply.document_id +
          "/settings",
        requestOptions
      );

      if (json.status.code === 200) {
        documentPreviewSetting = json.data.document.settings;
      }
    }

    const replyBubbles = newData.reply.bubbles;
    if (replyBubbles && replyBubbles.length > 0) {
      for (let j = 0; j < replyBubbles.length; j++) {
        await sleep(replyBubbles[j].delay || 0);
        // console.log({botMode})
        // console.log("queue create bubble: ", replyBubbles[j].id);
        let element = newData;
        if (!newData.context) {
          element.context = { botMode: botModeRef.current };
        }
        const botReply = (
          <BotReply
            avatarIcon={botAvatar}
            bubbleIndex={j}
            key={replyBubbles[j].id}
            documentId={newData.reply.document_id}
            replyId={replyBubbles[j].reply_id}
            replyType={replyBubbles[j].template}
            data={replyBubbles[j]}
            replyTime={<Moment format="DD/MM/YY hh:mm A">{new Date()}</Moment>}
            bookmarkAction={bookmarkAction}
            recordFeedback={recordFeedback}
            showFeedback={true && isShowFb}
            feedback={feedbackMap[newData.reply.id] || null}
            documentPreviewSetting={documentPreviewSetting}
            setDocumentPreview={setDocumentPreview}
            element={{
              ...newData,
              context: { botmode: replyBubbles[j]?.botmode },
            }}
            showFallback={replyBubbles[j]?.botmode === "improvising"}
            agentDisclaimer={agentDisclaimer}
          />
        );

        const newChat = chatDataRef.current;
        //find first typing bubble and replace it with bot reply

        for (let i = 0; i < newChat.length; i++) {
          let reply = newChat[i];
          if (reply?.key?.includes("typing-bubble")) {
            newChat[i] = { ...botReply };
            break;
            // return {
            //   ...reply,
            //   props: {
            //     ...botReply.props,
            //   },
            // };
          }
        }
        // const newChat = chatDataRef.current.map((reply, index) => {
        //   if(reply.key.includes('typing-bubble') && index === chatDataRef.current.length - 1 ){
        //     return {
        //       ...reply,
        //       props: {
        //         ...botReply.props,
        //       }
        //     }
        //   }
        //   return reply
        // })

        setChatData(newChat);
      }
    }

    if (
      newData.reply &&
      newData.reply.buttons &&
      newData.reply.buttons.length
    ) {
      await sleep(newData.reply.buttons[0].delay || 0);
      const buttonsId = uuidv4();
      const buttons = newData.reply.buttons.filter((b) => {
        return (
          b.reference ||
          (b && b.reference && b.reference.trim().length > 0) ||
          b.reference_id ||
          (b && b.reference_id && b.reference_id.trim().length > 0)
        );
      });
      if (buttons?.length > 0) {
        setChatData((data) =>
          data.concat(
            <UserChatBubble
              isButtonContainer
              key={buttonsId}
              avatarIcon={userAvatarIcon}
              isPagingBtn={newData.reply.buttons.length > 4}
              message={
                <ButtonPaging
                  onCustomButtonClick={onCustomButtonClick}
                  buttons={buttons.map((b) => ({
                    ...b,
                    buttonsId: buttonsId,
                  }))}
                  isMobile={isMobile}
                />
              }
              showFeedback={false}
            />
          )
        );
      }
    }

    if (
      replyBubbles?.[0]?.botmode === "improvising" &&
      !checkIfDisclaimerHasAlreadyBeenShown() &&
      agentDisclaimer?.length >= 0
    ) {
      setChatData((data) => data.concat(<Notice avatarIcon={botAvatar} />));
      setDisclaimerState();
    }

    if (replyBubbles?.[0]?.botmode === "improvising") {
      updateWasThisResponseUsefulWrapper();
    }
    setIsWaitRender(false);
    queueService.executeNextTask();
  };

  const onResumeForm = (resume, button) => {
    setSavedProgressDetected(false);
    let currentFormButton;

    if (button) currentFormButton = button;
    else currentFormButton = formButton;

    if (typeof currentFormButton === "object") {
      let formLayout;
      if (typeof currentFormButton.form.layout === "string")
        formLayout = JSON.parse(currentFormButton.form.layout);
      else
        formLayout = currentFormButton.form.layout;
      const uiSchemaLayout = formLayout.uiSchema
        ? { ...formLayout.uiSchema }
        : {};
      
      if (resume) {
        for (let i = 0; i < currentFormButton.form.fields.length; ++ i){
          formLayout.fieldList[i].default = currentFormButton.form.fields[i].value;
        }
      }

      let updatedFieldList = [...formLayout.fieldList];
      if (currentFormButton.form && currentFormButton.form.display_mode === "standard") {
        updatedFieldList = updatedFieldList.map((field, index) => {
          if (field.is_searchable && field.fieldType === "checkboxes") {
            uiSchemaLayout[`property${index}`] = {
              "ui:widget": "SelectInputWidget",
            };
            return {
              ...field,
              fieldType: "SelectInputWidget",
            };
          }
          return {
            ...field,
          };
        });
      }

      formLayout.fieldList = [...updatedFieldList];
      formLayout.uiSchema = { ...uiSchemaLayout };
      currentFormButton.form = {
        ...currentFormButton.form,
        layout: { ...formLayout },
      };
      
      const { sections, fields } = currentFormButton.form;
      const sectionData = {};
      const { fieldList, formSchema, uiSchema } = formLayout;

      if (resume) {
        for (let i = 0; i < currentFormButton.form.fields.length; ++ i) {
          if (currentFormButton.form.fields[i].value)
            formSchema.properties[currentFormButton.form.fields[i].name].default = currentFormButton.form.fields[i].value;
        }
      }

      if (sections && Array.isArray(sections)) {
        for (const section of sections) {
          const filteredFields = fields.filter(field => field.section === section);
          const filteredProperties = filteredFields.map(t => t.name);
          const filteredUiSchema = {};
          const filteredFormSchema = {
            dependencies: {},
            properties: {},
          };
          const filteredFieldlist = [];
          let index = 1;

          // Todo: required

          for (const property of filteredProperties) {
            const propertyIndex = parseInt(property.slice(8));
            filteredFieldlist.push(fieldList[propertyIndex]);
            filteredFormSchema.properties[property] = {
              ...formSchema.properties[property],
              title: `${index ++}. ${formSchema.properties[property].title}`
            };
            filteredUiSchema[property] = uiSchema[property];
          }

          if (filteredFieldlist.length > 0) {
            sectionData[section] = {
              fieldList: filteredFieldlist,
              formSchema: filteredFormSchema,
              uiSchema: filteredUiSchema,
            };
          }
        }
        currentFormButton.form.sectionData = sectionData;
      } else {
        let index = 1;
        for (let property in formSchema.properties) {
          formSchema.properties[property].title = `${index ++}. ${formSchema.properties[property].title}`;
          // formSchema.properties[property].default = "a";
        }
        sectionData[" "] = {
          fieldList: updatedFieldList,
          formSchema,
          uiSchema: uiSchemaLayout,
        }
        currentFormButton.form.sectionData = sectionData;
      }
    }
    openFormScreen(currentFormButton.form);
  }

  const setDisclaimerState = () => {
    const oldDisclaimers = [...sessionDisclaimers];
    let disclaimerIndex = oldDisclaimers.findIndex(
      (disc) => disc.sessionId === activeSession._id
    );
    if (disclaimerIndex >= 0) {
      oldDisclaimers[disclaimerIndex].shownFeedback = true;
    }
    setSessionDisclaimers(oldDisclaimers);
  };

  const onFieldChange = (value, label) => {
    if (label === "feedbackComment") {
      setFeedbackComment(value);
    } else if (label === "newMessage") {
      if (suggestionsExpanded) {
        let prompts = [];
        let copiedPrompts = [...copiedSuggestedPrompts];
        if (copiedPrompts && copiedPrompts.length > 0) {
          if (value && value.trim().length > 0) {
            prompts = copiedPrompts.filter((e) => e.text.includes(value));
            setSuggestedPrompts([...prompts]);
          } else {
            setSuggestedPrompts([...copiedPrompts]);
          }
        } else {
          setSuggestedPrompts([]);
        }
      }
      setNewMessage(value);
    } else if (label === "sessionFeedbackComment") {
      setSessionFeedbackComment(value);
    }
  };

  const sendMessage = async (newMessage) => {
    if (newMessage.trim()) {
      setChatData((data) =>
        data.concat(
          <UserChatBubble
            key={uuidv4()}
            avatarIcon={userAvatarIcon}
            message={newMessage}
            messageTime={
              <Moment format="DD/MM/YY hh:mm A">{new Date()}</Moment>
            }
            bookmarkAction={bookmarkAction}
            copyAction={copyAction}
          />
        )
      );
      let payload = { question: newMessage, source: "webchat" };
      if (promptsMessage && "_id" in promptsMessage) {
        const temp = {
          ...payload,
          suggested_prompt_id: promptsMessage._id,
        };
        payload = temp;
        setPromptsMessage(null);
      }
      if (buttonReference) {
        payload.button_reference = buttonReference;
      }

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: payload,
      };

      setNewMessage("");
      setButtonReference("");
      setIsSseClose(false);
      setIsShowFb(false);
      await sleep(300);
      scrollUpToLastMsg();
      startListenChat(requestOptions);
      // setTimeout(() => {
      //   scrollUpToLastMsg()
      // }, 100)
    }
  };

  const updateContext = (data) => {
    if (data.context && data?.context.topic_id) {
      setChatContext(data.context.topic_id);
    } else {
      setChatContext("");
    }

    if (data?.context) {
      setHideChatInput(!!data.context.is_input_disabled);
    }

    if (data.context && data.context.botmode === "improvising") {
      getAgentDisclaimer();
    }
    if (data?.context?.botmode) {
      setBotMode(data?.context?.botmode);
    }
  };

  const removeTypingBubble = () => {
    const newChatData = chatDataRef.current.filter((c) => !c.props?.isTyping);
    setChatData(newChatData);
  };

  const addTypingBubble = () => {
    const typingData = {
      html: "",
      template: "text",
    };
    const element = {
      context: "",
    };
    setChatData((data) =>
      data.concat(
        <BotReply
          data={typingData}
          key={uuidv4() + " typing-bubble"}
          avatarIcon={botAvatar}
          showFallback={true}
          replyTime={<Moment format="DD/MM/YY hh:mm A">{new Date()}</Moment>}
          isTyping={true}
          element={element}
        />
      )
    );
  };

  const replaceBubble = async (b) => {
    await sleep(b.delay | 0);
    const newChats = [...chatDataRef.current];
    const index = newChats.findIndex((c) => c.props?.data?.id === b?.id);
    if (index >= 0) {
      let replaceBubble;
      // console.log(
      //   "queue update/replace bubble: ",
      //   newChats[index].props?.data?.id
      // );
      if (b.eventType === "APPEND_BUBBLE") {
        replaceBubble = { ...newChats[index] };
        replaceBubble.props.data.html = `<span>${replaceBubble.props.data.html}${b.html}</span>`;
        replaceBubble.props.data.text = replaceBubble.props.data.text + b.text;
        replaceBubble.props.data.document_preview = b.document_preview;
      } else if (b.eventType === "UPDATE_BUBBLE") {
        replaceBubble = { ...newChats[index] };
        replaceBubble.props.data.html = b.html;
        replaceBubble.props.data.text = b.text;
        replaceBubble.props.data.document_preview = b.document_preview;
      }
      const newChatData = newChats.map((c, i) =>
        i === index ? replaceBubble : c
      );
      setChatData(newChatData);
      queueService.executeNextTask();
    } else {
      console.log(
        "%c error edit bubble " + b.id + ", not found in rendered bubble",
        "background: white; color: #ad3da3"
      );
      console.log("rendered bubble: ");
      console.log(chatDataRef.current);
      queueService.executeNextTask();
      const newChatsUpdated = newChats.map((c, i) => {return {...c, props: { ...c.props,isLoadingTyping: false }}});
      setChatData(newChatsUpdated);
    }
    // queueService.executeNextTask();
    setIsWaitRender(false);
  };

  const createReply = async (data) => {
    let conversation = [...chatData];
    // if()
    if (data?.context?.botmode) {
      setBotMode(data?.context?.botmode);
    }
    await createBotReply(
      data,
      conversation,
      false,
      data && data.context && data.context.botmode === "improvising"
    );

    updateContext(data);
  };

  const handleSuggestionSelect = (key) => {
    setSelectedSuggestion(key);
    setMessageCopy(key.split("%%")[1]);
    setSuggestionsExpanded(false);
  };

  const updateScrollbar = (e = null, isTop = false) => {
    setTimeout(() => {
      scrollUpToLastMsg();
    }, 50);
  };

  const scrollUpToLastMsg = (isTop = true) => {
    // setTimeout(() => {
    let messageElement = document.querySelector(
      ".parent-container.custom-scrollbar"
    );
    if (messageElement) {
      if (isTop) {
        messageElement.scrollTop = messageElement.scrollHeight;
      } else if (messageElement && messageElement.lastChild) {
        messageElement.lastChild.scrollIntoView(isTop, { behavior: "smooth" });
        // console.log("scrool top", messageElement.lastChild);
      }
      // }, 20)
    }
  };

  const updateWasThisResponseUsefulWrapper = (e = null) => {
    let actionWrapers = document.querySelectorAll(".action-wraper");
    let threeDotsFeedbackWrapper = document.querySelectorAll(".text-reply-new");

    if (actionWrapers.length) {
      for (let index = 0; index < actionWrapers.length; index++) {
        if (index < actionWrapers.length - 1) {
          // Do not remove last index
          actionWrapers[index].classList.add("d-none-imp");
          // actionWrapers[index].remove()

          // if ( actionWrapers[index].contains('show-none') ) {
          // }
        }
      }
    }

    // Remove three dots for last division
    if (threeDotsFeedbackWrapper.length) {
      for (let index = 0; index < threeDotsFeedbackWrapper.length; index++) {
        let element = threeDotsFeedbackWrapper[index];
        if (index === threeDotsFeedbackWrapper.length - 1) {
          // Do not remove last index
          element.classList.add("show-none");
        } else {
          if (element.classList.contains("show-none")) {
            element.classList.remove("show-none");
          }
        }
      }
    }
  };

  const handleKeyPress = (event) => {
    if (!isMobile) {
      if (
        !setDisableInputRef.current &&
        event.key === "Enter" &&
        !event.shiftKey
      ) {
        setMessageCopy(newMessage);
        setDisableInput(true);
      }
    }
  };

  const openNotificationPanel = () => {
    setNotificationToggle(true);
    let rightPanel = document.getElementById("notification-panel");

    if (rightPanel) {
      rightPanel.classList += " open-panel-wrap";
    }
  };

  const openFeedbackModalFunction = () => {
    setOpenFeedbackModal(true);
  };

  const onConversationClear = () => {
    setOpenSessionEndModal(false);
    // setOpenFeedbackContainer(true);
    setShowHomePanel(false);
    setChatData([]);
    getGreetings(true);
    // getSummary();
  };

  const addBotAvatar = (chatComponent, avatar) => {
    if (
      !chatComponent?.props?.replyId &&
      typeof chatComponent.props?.message !== "string"
    ) {
      return {
        ...chatComponent,
        props: {
          ...chatComponent.props,
          message: {
            ...chatComponent.props.message,
            props: {
              ...chatComponent.props?.message?.props,
              disabled: hideChatInput || disableInput,
            },
          },
        },
      };
    }

    if (chatComponent?.props?.avatarIcon && avatar) {
      return {
        ...chatComponent,
        props: {
          ...chatComponent.props,
          avatarIcon: avatar,
          isShowFb: isShowFb,
        },
      };
    }

    return chatComponent;
  };

  const onBackToRecentMsg = () => {
    updateScrollbar();
    // let messageElement = document.getElementsByClassName("parent-container");
    // if (messageElement[0]) {
    //   messageElement[0].scrollTop = messageElement[0].scrollHeight;
    // }
  };

  const conversationalForm = () => {};

  // useEffect(() => {
  //   if(!isSseClose) {
  //     setTimeout(() => {
  //       scrollUpToLastMsg()
  //     }, 1000);
  //   }
  // }, [isSseClose])

  // Show Prompts API
  const getSuggestedPrompts = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    let requestUrl = window.CHAT_API + "/api/v1/chatbot/suggested-prompts";

    if (chatContext) {
      requestUrl = requestUrl + "?topic_ids=" + chatContext;
    }

    setTimeout(() => {
      makeApiCall.request(requestUrl, requestOptions).then((json) => {
        if (json.status.code === 200) {
          setSuggestedPrompts(json.data.suggested_prompts);
          setCopiedSuggestedPrompts(json.data.suggested_prompts);
          let isCookieSet = getCookie("view-suggestions");
          setShowTooltip(
            isCookieSet === null ? true : isCookieSet === "shown" ? false : true
          );
        } else {
          // setSnackbar({
          //   isOpen: true,
          //   message: "Oops! Something went wrong.",
          //   type: "error",
          // });
          setSuggestedPrompts([]);
        }
      });
    }, 400);
  };

  const handleSuggestedPromptSelect = (messageObj) => {
    setPromptsMessage(messageObj);
    // setMessageCopy(message);
    // setNewMessage(message);
    // setShowPrompts(false);
    setSuggestionsExpanded(false);
  };

  const getChatPanel = (type) => {
    return (
      <>
        {selectedSidebarOption === "chat" && (
          <>
            {/* <div className="chat-messages-outer-container"> */}
            <div
              className={`parent-container custom-scrollbar`}
              id="parent-container-scroll-view"
            >
              {chatDataRef.current.map((component) =>
                addBotAvatar(component, botAvatar)
              )}
              {/* {isLoading && (
                <BotReply avatarIcon={botAvatar} replyType={"loading"} />
              )} */}
            </div>
            {/* </div> */}
            {showScrollToDownButton && isSseClose && (
              <button
                className="btn btn-back-to-top"
                onClick={onBackToRecentMsg}
              >
                <img src={ArrowImg} alt="" />
              </button>
            )}
            {/* <div className="chat-footer-container">
              <div className="chat-footer m-0">
                {suggestedQuestionsData.length > 0 && (
                  <>
                    {!suggestionsExpanded && (
                      <div
                        className="suggested-arrow-container"
                        onClick={() => {
                          setSuggestionsExpanded(true);
                        }}
                      >
                        <i className="icon-up-chevron" />
                      </div>
                    )}
                    <div
                      className={
                        suggestionsExpanded
                          ? "suggested-questions-container"
                          : "suggested-questions-container suggested-questions-collapsed"
                      }
                    >
                      <div className="suggested-questions-header">
                        {suggestionsExpanded ? (
                          <div className="heading">Suggestions</div>
                        ) : (
                          <div className="heading"></div>
                        )}
                        <div
                          className="view-control-btn"
                          onClick={() => {
                            setSuggestionsExpanded(!suggestionsExpanded);
                          }}
                        >
                          {suggestionsExpanded ? (
                            <i className="icon-close" />
                          ) : null}
                        </div>
                      </div>
                      <div className="suggested-questions-body">
                        {suggestedQuestionsData.map((item) => (
                          <button
                            key={`${item._id}%%${item.name}`}
                            disabled={disableInput}
                            className={
                              disableInput
                                ? "topic-button-disabled"
                                : "topic-button"
                            }
                            onClick={() => {
                              handleSuggestionSelect(
                                `${item._id}%%${item.name}`
                              );
                            }}
                          >
                            {item.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </>
                )}
                <div className="chat-input-wrapper">
                  <ChatInput
                    value={newMessage}
                    avatarIcon={userAvatarIcon}
                    onKeyPress={handleKeyPress}
                    onSend={() => {
                      setMessageCopy(newMessage);
                      setDisableInput(true);
                    }}
                    placeholder={t("Type here to ask something") + "..."}
                    onChange={(value) => onFieldChange(value, "newMessage")}
                    onConversationClear={onConversationClear}
                    onChatInputFocus={onChatInputFocus}
                    onChatInputBlur={onChatInputBlur}
                    isDisabled={hideChatInput || disableInput}
                    disableCustomButton={disableCustomButton || disableInput}
                    isConversationalQuizActive={isConversationalQuizActive}
                    usetextArea
                    max={8}
                    inputType="text"
                  />
                </div>
              </div>
            </div> */}
            <div className="chat-footer-container">
              <div className="chat-footer m-0">
                {suggestedPrompts && suggestedPrompts.length > 0 && (
                  <>
                    {!suggestionsExpanded && !showResumeSessionModal && (
                      <OverlayTrigger
                        placement="top"
                        show={showTooltip}
                        overlay={
                          <Tooltip className="suggested-prompts-tooltip">
                            <div>
                              <span>
                                {t("Not sure where to start?")}
                                <br />
                                {t("Click here to view suggestions")}
                              </span>
                              <button
                                className="btn btn-primary right-align"
                                onClick={() => {
                                  setCookie("view-suggestions", "shown", 7);
                                  setShowTooltip(!showTooltip);
                                }}
                              >
                                {t("Got it")}
                              </button>
                            </div>
                          </Tooltip>
                        }
                      >
                        <div
                          className="suggested-arrow-container"
                          onClick={() => {
                            setSuggestionsExpanded(true);
                            setShowTooltip(false);
                          }}
                        >
                          <i className="icon-up-chevron" />
                        </div>
                      </OverlayTrigger>
                    )}
                    {suggestionsExpanded && (
                      <div
                        className="suggested-arrow-container"
                        onClick={() => {
                          setSuggestionsExpanded(false);
                        }}
                      >
                        <i className="icon-down-chevron" />
                      </div>
                    )}
                    {suggestionsExpanded && (
                      <div
                        className={
                          suggestionsExpanded
                            ? "suggested-questions-container"
                            : "suggested-questions-container suggested-questions-collapsed"
                        }
                      >
                        <div className="suggested-questions-header">
                          {suggestionsExpanded ? (
                            <>
                              <div className="prompts-icon">
                                <img src={PromptsIdeaIcon} alt="prompts" />
                              </div>
                              <div className="heading">
                                {suggestionsExpanded &&
                                newMessage &&
                                newMessage.trim().length > 0
                                  ? "Search Suggestions"
                                  : "Suggestions"}
                              </div>
                            </>
                          ) : (
                            <div className="heading"></div>
                          )}
                        </div>
                        <div className="suggested-questions-body custom-scrollbar">
                          {suggestedPrompts.map((item) => (
                            <div
                              className="prompts"
                              key={`${item._id}`}
                              onClick={() => {
                                handleSuggestedPromptSelect(item);
                              }}
                            >
                              {item.text}
                            </div>
                          ))}
                          {!suggestedPrompts &&
                            Array.apply(null, Array(5)).map((item) => (
                              <div className="prompts">
                                <div className="empty-prompt"></div>
                              </div>
                            ))}
                          {suggestedPrompts &&
                            suggestedPrompts.length === 0 && (
                              <div>Could not find suggestions.</div>
                            )}
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="chat-input-wrapper">
                  <ChatInput
                    value={newMessage}
                    avatarIcon={userAvatarIcon}
                    onKeyPress={handleKeyPress}
                    onSend={() => {
                      setMessageCopy(newMessage);
                    }}
                    placeholder={t("Type here to ask something") + "..."}
                    onChange={(value) => onFieldChange(value, "newMessage")}
                    onConversationClear={onConversationClear}
                    onChatInputFocus={onChatInputFocus}
                    onChatInputBlur={onChatInputBlur}
                    isDisabled={hideChatInput || disableInput}
                    disableCustomButton={disableCustomButton || disableInput}
                    isConversationalQuizActive={isConversationalQuizActive}
                    usetextArea
                    max={8}
                    inputType="text"
                  />
                </div>
              </div>
            </div>
            {openFeedbackContainer ? (
              <ChatFeedback
                sessionFeedback={sessionFeedback}
                setSessionFeedback={setSessionFeedback}
                sessionFeedbackComment={sessionFeedbackComment}
                onFieldChange={onFieldChange}
                sendSessionFeedback={sendSessionFeedback}
                closeFeedback={() => setOpenFeedbackContainer(false)}
              />
            ) : null}

            <div className="powerby-nf">
              <img src={powerByIcon} alt="" />
              <span>by</span>
              <span
                className="powerby-nf-click"
                onClick={() =>
                  window.open("https://www.noodlefactory.ai/", "_blank")
                }
              >
                Noodle Factory
              </span>
            </div>
          </>
        )}

        {showForm && (
          <div className="chat-overlay-container">
            {showFormSubmissionMessage && (
              <FormSubmissionMessage
                message={formSubmissionMessageDetails.message}
                heading={formSubmissionMessageDetails.heading}
                buttonLabel={formSubmissionMessageDetails.buttonLabel}
                buttonAction={handleSubmissionMessageAction}
                type={formSubmissionMessageDetails.type}
                mode={formSubmissionMessageDetails.displayMode}
              />
            )}
            {!showFormSubmissionMessage && (
              <Form
                onBack={() => {
                  setShowForm(false);
                  getSessions();
                }}
                onSubmit={handleFormSubmit}
                formSchema={form.layout.formSchema}
                uiSchema={form.layout.uiSchema}
                submitButtonLabel={
                  form.layout.submitLabelText
                    ? form.layout.submitLabelText
                    : "SUBMIT"
                }
                primaryHeaderText={form.layout.headerText}
                formId={form._id}
                formName={form.name}
                sectionData={form.sectionData}
              />
              // conversationalForm
              // setChatData((data) =>
              //   data.concat(
              // )
              //   )
            )}
          </div>
        )}
        {showQuiz && (
          <div className="chat-overlay-container">
            {!showQuizReport.open && (
              <QuizGenerator
                essayConfig={essayConfig}
                quizData={quiz}
                onBack={() => {
                  setShowQuiz(false);
                  getSessions();
                }}
                onQuit={handleQuizQuit}
                onSubmit={handleQuizSubmit}
                disableSubmit={disableQuizButton}
                enableLearning={enableLearning}
                setStartQuiz={setStartQuiz}
                exitFromQuiz={exitFromQuiz}
              />
            )}
            {showQuizReport.open && (
              <QuizReport
                // hideScores={hideScores}
                submissionData={showQuizReport.data}
                type={showQuizReport.type}
                onClick={handleQuizReportAction}
                displayedQuestionsId={quizDisplayedQuestions}
                enableLearning={enableLearning}
              />
            )}
          </div>
        )}
        {selectedSidebarOption === "bookmarks" && (
          <AllBookmarks triggerBookmarkLoad={triggerBookmarkLoad} />
        )}
        {selectedSidebarOption === "sessions" && (
          <AllSessions
            onPastSessionClick={(
              sessionId,
              lastLog,
              time,
              activeTab,
              resumePastSessions
            ) => {
              onPastSessionClick(
                lastLog,
                sessionId,
                time,
                activeTab,
                resumePastSessions
              );
            }}
            enableLearning={enableLearning}
            showChatPanel={() => {
              setSelectedSidebarOption("chat");
              setShowHomePanel(false);
            }}
            removeSession={true}
          />
        )}
        {selectedSidebarOption === "answerquestions" && <AllAnswerQuestions />}
        {selectedSidebarOption === "reports" && <UserReport />}
        {selectedSidebarOption === "discussions" && <ChatDiscussion />}
      </>
    );
  };

  const generateSearchContent = (keyword, preceed, succeed) => {
    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/convos/replies/" +
          replyIdForDocumentPreview +
          "/content",
        {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            keyword: keyword,
            preceed: parseInt(preceed),
            succeed: parseInt(succeed),
          }),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setDocumentPreviewContent(json.data.content);
          setEnableSearch(true);
        } else {
          if (json.status && json.status.message) {
            setSnackbar({
              isOpen: true,
              message: "Oops! " + json.status.message,
              type: "error",
            });
          }
        }
      });
  };

  const getTextBlock = async (replyId, excerpt_id) => {
    const params = excerpt_id ? `?excerpt_id=${excerpt_id}` : "";
    const json = await makeApiCall.request(
      window.CHAT_API +
        "/api/v1/convos/replies/" +
        replyId +
        "/text-block" +
        params,
      {
        method: "GET",
        headers: authHeader(),
      },
      true
    );
    if (json.status.code === 200) {
      return json.data;
    } else {
      if (json.status && json.status.message) {
        setSnackbar({
          isOpen: true,
          message: "Oops! " + json.status.message,
          type: "error",
        });
      }
    }
  };

  const generateContent = (replyId) => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/convos/replies/" + replyId + "/excerpts",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setDocPreviews(json.data.excerpts);
          setEnableSearch(false);
        } else {
          if (json.status && json.status.message) {
            setSnackbar({
              isOpen: true,
              message: "Oops! " + json.status.message,
              type: "error",
            });
          }
        }
      });
  };

  const updateTextBlock = async (documentId, textBlockId) => {
    const json = await makeApiCall.request(
      window.CHAT_API +
        "/api/v1/convos/documents/" +
        documentId +
        "/text-blocks/" +
        textBlockId,
      {
        method: "GET",
        headers: authHeader(),
      },
      true
    );
    if (json.status.code === 200) {
      setEnableSearch(false);
      return json.data;
    } else {
      if (json.status && json.status.message) {
        setSnackbar({
          isOpen: true,
          message: "Oops! " + json.status.message,
          type: "error",
        });
      }
    }
  };

  const onAgentChange = async (
    agentId,
    type,
    switchBtnClick = false,
    forceLoginPopup = false
  ) => {
    setCompanyLogo(null);
    setBrandAvatar(null);
    setChatData([]);
    setShowDocumentPreview(false);
    setSuggestedQuestionsData([]);
    if (type === "switch") {
      await endSession("", forceLoginPopup);
    }
    AgentClassService.setSelectedAgent(agentId);
    setSelectedClass("");
    AgentClassService.setSelectedClass("");
    setSelectedAgent(agentId);
    await makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/states", {
        method: "PATCH",
        headers: authHeader(),
        body: JSON.stringify({
          states: {
            selected_agent: agentId.toString(),
          },
        }),
      })
      .then(async (json) => {
        if (json.status.code === 200) {
          if (type === "switch") {
            setAgentSwitched(true);
            let allParams = getUrlParameter(
              window.location.search.split("?")[1]
            );

            if (
              "through" in allParams &&
              allParams.through === "admin-portal" &&
              "agent" in allParams &&
              allParams.agent &&
              switchBtnClick
            ) {
              // console.log("redirect to "  + window.location.origin + "?through=admin-portal", )
              const url = window.location.origin + "?through=admin-portal";
              window.history.pushState({}, "", url);
              await getAndSetAgents(agentId);
              // history.push({
              //   pathname: window.location.pathname,
              //   search: "?through=admin-portal",
              // });
            } else if (
              (AgentClassService.getSelectedAgent() || agentId) &&
              !switchBtnClick
            ) {
              await getAndSetAgents(agentId);
            } else if(switchBtnClick && agentId){
              setShowAgentSelection(false);
              history.push(`/?agent=${agentId}`);
            }else{
              setShowAgentSelection(false);
              getDefaultAgent();
            }
          } else {
            callStartSessionApi();
            setShowNewSessionAgentSelection(false);
            getSuggestedQuestions();
            selectDefaultClass();
          }
        }
      });

    checkAgentFeatureEnabled(
      "learning",
      () => {
        setEnableLearning(false);
      },
      () => {
        setEnableLearning(true);
      }
    );
  };

  const getPopups = () => {
    return (
      <React.Fragment>
        <Snackbar
          isOpen={snackbar.isOpen}
          primaryMessage={snackbar.message}
          type={snackbar.type}
          onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        />
        <Modal
          openModal={openModal}
          onRequestClose={() => {
            postFeedback(feedbackMap[feedbackId], feedbackId);
            setFeedbackComment("");
            setFeedbackId("");
            setOpenModal(false);
          }}
          buttonLabel="Send comments"
          cancelButtonLabel="No, that's all"
          buttonAction={sendFeedbackComment}
        >
          <div className="modal-heading">
            {t(
              "I am open to feedback on how to answer better. If you think that the response to your question can be better, please tell me."
            )}
          </div>
          <TextInput
            inputType="text"
            name="feedbackComment"
            placeholder={t("Type your comments here.")}
            value={feedbackComment}
            inputChange={(event, name) =>
              onFieldChange(event.target.value, name)
            }
          />
        </Modal>

        <Modal
          openModal={savedProgressDetected}
          onRequestClose={() => {
            onResumeForm(false);
          }}
          buttonAction={() => onResumeForm(true)}
          buttonLabel={t("Resume Saved Progress")}
          cancelButtonLabel={"Start New Form"}
          title={t("Saved Progress Detected")}
        >
          <div>
            We've found previous progress. Would you like to resume where you left off or start a new form?
          </div>
        </Modal>

        <Modal
          openModal={openFeedbackModal}
          onRequestClose={logoutUser}
          buttonLabel="SEND FEEDBACK"
          cancelButtonLabel="LOGOUT"
          buttonAction={sendSessionFeedback}
        >
          <div className="modal-heading">{t("How was your session?")}</div>
          <RatingInput
            options={{
              enumOptions: [
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
              ],
            }}
            onChange={setSessionFeedback}
          ></RatingInput>
          {sessionFeedback !== 0 && (
            <TextInput
              inputType="text"
              name="sessionFeedbackComment"
              placeholder={
                sessionFeedback > 3
                  ? t("Tell us how your experience was.")
                  : t("Tell us what we could improve.")
              }
              value={sessionFeedbackComment}
              inputChange={(event, name) =>
                onFieldChange(event.target.value, name)
              }
            />
          )}
        </Modal>
        <NotificationPanel
          notificationCount={notificationCount}
          onClick={onClickNotification}
          onDelete={onDeleteNotification}
          onCheckboxChange={onCheckboxChangeNotification}
          allNotifications={allNotifications}
          setNotificationToggle={setNotificationToggle}
          markAllRead={markAllRead}
          clearAll={clearAllNotification}
          onCustomButtonClick={onCustomButtonClick}
        />
        <PastSessionPanel
          pastSessionActiveTab={pastSessionActiveTab}
          sessionData={pastSessionData}
          botAvatar={botAvatar}
          userAvatarIcon={userAvatarIcon}
          summaryData={summaryData}
          sessionTime={sessionTime}
          enableLearning={enableLearning}
        />
        <ResumeSessionModal
          openModal={showResumeSessionModal}
          onStartNewSession={onStartNewSession}
          resumeSession={callResumeSessionApi}
        />
        <PopupNotification
          openNotification={openPopupNotification}
          onRequestClose={() => setOpenPopupNotification(false)}
          notificationData={popupNotificationData}
          onCustomButtonClick={onCustomButtonClick}
        />
        <Modal
          openModal={openExitQuizConfirm.isExit}
          onRequestClose={() => setOpenExitQuizConfirm({ ...openExitQuizConfirm, isExit: false})}
          buttonAction={() => onExitQuiz()}
          buttonLabel={t("I want to exit")}
        >
          {t("Are you sure you want to exit?")}
        </Modal>
      </React.Fragment>
    );
  };

  const getAgentDisclaimer = () => {
    const receivedSelectedAgent = AgentClassService.getSelectedAgent();
    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/agents", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (receivedSelectedAgent) {
            let temp = json.data.agents.filter(
              (agent) => agent._id === receivedSelectedAgent
            );
            if (temp.length) {
              setAgentDisclaimer({
                ...agentDisclaimer,
                defaultDisclaimer:
                  temp[0]?.settings?.improvising_disclaimer?.length >= 0
                    ? temp[0]?.settings?.improvising_disclaimer
                    : defaultDisclaimer,
              });
            }
          }
        }
      });
  };


  const getAndSetAgents = async (id) => {
    const json = await makeApiCall.request(
      window.CHAT_API + "/api/v1/organisation/agents",
      {
        method: "GET",
        headers: authHeader(),
      }
    );
    await getClasses('', '');
    if (json.status.code === 200) {
      let temp = {};
      json.data.agents.forEach((agent) => {
        temp[agent._id] = agent;
      });
      setAgentsMap(temp);
      applyAgentSetting(temp[id]);
    }
  };

  function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  // let timer = undefined;
  // const events = ["click", "scroll", "load", "keydown"];
  // const eventHandler = (eventType) => {
  //   console.log(eventType);
  //   localStorage.setItem("lastInteractionTime", moment());
  //   if (timer) {
  //     // props.onActive();
  //     startTimer();
  //   }
  // };

  // useEffect(() => {
  //   addEvents();

  //   return () => {
  //     removeEvents();
  //     clearTimeout(timer);
  //     localStorage.removeItem("lastInteractionTime");
  //   };
  // }, []);

  // const startTimer = () => {
  //   if (timer) {
  //     clearTimeout(timer);
  //   }
  //   timer = setTimeout(() => {
  //     let lastInteractionTime = localStorage.getItem("lastInteractionTime");
  //     const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
  //     let timeOutInterval = props.timeOutInterval
  //       ? props.timeOutInterval
  //       : 6000;

  //     if (diff._milliseconds < timeOutInterval) {
  //       startTimer();
  //     } else {
  //       setShowPrompts(true);
  //     }
  //   }, 60 * 1000);
  // };
  // const addEvents = () => {
  //   events.forEach((eventName) => {
  //     window.addEventListener(eventName, eventHandler);
  //   });

  //   startTimer();
  // };

  // const removeEvents = () => {
  //   events.forEach((eventName) => {
  //     window.removeEventListener(eventName, eventHandler);
  //   });
  // };

  const onExitQuiz = async () => {
    if(showQuizReport.open) {
      chatMessagesRef.current.push(
        <BotQuizResultBubble
          avatarIcon={botAvatar}
          submissionData={showQuizReport.data}
          onClick={handleQuizReportAction}
          enableLearning={enableLearning}
          displayedQuestionsId={quizDisplayedQuestions}
        />
      );
      setChatData(chatMessagesRef.current);
      updateScrollbar();
      if(openExitQuizConfirm.navigateTo === "selectChannel") {
        await handleClassSelectWhileQuizDisplay(openExitQuizConfirm.exitData)
      }
      if(openExitQuizConfirm.navigateTo === "newSession") {
        await onStartNewSession();
      }
      setShowQuiz(false);
      setExitFromQuiz(false);
      setStartQuiz(false);
      setOpenExitQuizConfirm({ isExit: false, navigateTo: "", exitData: null});
      setShowQuizReport({
        open: false,
        type: "success",
        data: {},
      });
    } else {
      setExitFromQuiz(true);
    }
  }

  const handleClassSelectWhileQuizDisplay = async (data) => {
    await endSession();
    AgentClassService.setSelectedClass(data.value);
    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/states", {
        method: "PATCH",
        headers: authHeader(),
        body: JSON.stringify({
          states: {
            selected_class: data.value.toString(),
          },
        }),
      })
      .then(async (json) => {
        if (json.status.code === 200) {
          await getSessions();
        }
      });
      setSelectedSidebarOption("chat");
  };

  if (showNewSessionAgentSelection) {
    return (
      <AgentSelection
        onAgentChange={(agentId) => onAgentChange(agentId, "new")}
        hideAgentSelection={() => {
          setShowNewSessionAgentSelection(false);
          updateScrollbar();
        }}
      />
    );
  }

  if (showAgentSelection) {
    return (
      <AgentSelection
        selectedAgent={selectedAgent}
        onAgentChange={(agentId) => onAgentChange(agentId, "switch", true)}
        hideAgentSelection={() => {
          setShowAgentSelection(false);
          updateScrollbar();
        }}
      />
    );
  }

  return (
    <div className="chat-page-wrapper">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ChatHeader
        avatar={botAvatar}
        brandAvatar={brandAvatar}
        botName={botName}
        openFeedbackModal={openFeedbackModal}
        openNotificationPanel={openNotificationPanel}
        beforeLogout={beforeLogout}
        onBack={() => setShowHomePanel(true)}
        showSwitchAgent={Object.keys(agentsMap).length > 1}
        customButtonData={customButtonData}
        onCustomButtonClick={onCustomButtonClick}
        disableCustomButton={disableCustomButton || disableInput}
        isConversationalQuizActive={isConversationalQuizActive}
        enableLearning={enableLearning}
        showUnread={notificationCount > 0}
        showProfileEdit={true}
        setUserAvatarIcon={(src) => setUserAvatarIcon(src)}
        orgDefaultLang={orgDefaultLang}
        botMode={botMode}
        agentName={agentName}
        showQuiz={showQuiz}
        onStartNewSession={() => onStartNewSession()}
        onQuizExitHandler={(e) => onQuizExitHandler(e)}
      />
      <div className="chat-page-container">
        {!hideSidebar && (
          <div className="chat-sidebar is-4 p-0 is-hidden-mobile">
            <div className="chat-sidebar-content auto-scroll akshay-3">
              {!showDocumentPreview && (
                <>
                  <IntroPanel
                    logoImg={companyLogo}
                    message={sidebarMessage}
                    handleHideSidebar={() => setHideSidebar(true)}
                  />
                  {selectedAgent && showSidebarUI && (
                    <ChatFeaturePanel
                      enableLearning={enableLearning}
                      onPastSessionClick={(
                        sessionId,
                        lastLog,
                        time,
                        activeTab
                      ) => {
                        onPastSessionClick(lastLog, sessionId, time, activeTab);
                      }}
                      triggerBookmarkLoad={triggerBookmarkLoad}
                      getSessions={getSessions}
                      endSession={endSession}
                      setNoClassHistoryPanel={(status) =>
                        setToggleNoClassHistory(status)
                      }
                      showNoClassHistory={toggleNoClassHistory}
                      setSelectedSidebarOption={(e) => {
                        if(e) {
                          onQuizExitHandler(e);
                        }
                        setSelectedSidebarOption(e)}}
                      selectedSidebarOption={selectedSidebarOption}
                      showChatPanel={() => setSelectedSidebarOption("chat")}
                      showQuiz={showQuiz}
                      onQuizExitHandler={(e, data) => onQuizExitHandler(e, data)}
                    />
                  )}
                </>
              )}

              {showDocumentPreview && (
                <div className="document-preview-wrapper">
                  <MultiDocumentPreview
                    onClickBackButton={() => setShowDocumentPreview(false)}
                    answer={answerForDocumentPreview}
                    keyword={answerForDocumentPreview}
                    onSubmit={generateSearchContent}
                    content={documentPreviewContent}
                    setKeyword={setAnswerForDocumentPreview}
                    enableSearch={enableSearch}
                    textBlockIds={textBlockIds}
                    documentId={documentIdForDocumentPreview}
                    updateTextBlock={updateTextBlock}
                    docPreviews={docPreviews || []}
                    getTextBlock={getTextBlock}
                    replyId={replyIdForDocumentPreview}
                  />
                </div>
              )}
            </div>
            {/* <div className="powered-by">
              Powered by{" "}
              <strong
                onClick={() =>
                  window.open("https://www.noodlefactory.ai/", "_blank")
                }
              >
                Noodle Factory
              </strong>
            </div> */}
          </div>
        )}
        {hideSidebar && (
          <div className="chat-sidebar-collapsed is-4 p-0 is-hidden-mobile">
            <button
              className="show-sidebar-btn"
              title="Show sidebar"
              onClick={() => setHideSidebar(false)}
            >
              <i className="icon-view" />
            </button>
          </div>
        )}

        {/* <div
          className={
            hideSidebar
              ? "chat-wrapper-collapsed p-0 is-hidden-mobile akshay-2"
              : "chat-wrapper p-0 is-hidden-mobile akshay-2.1"
          }
        > */}
        {/* && !isMobile  */}
        {/* {!toggleNoClassHistory && getChatPanel("chat")}
          {toggleNoClassHistory && (
            <NoClassHistoryPanel
              heading="No Class History"
              onBack={() => {
                setToggleNoClassHistory(false);
              }}
              enableLearning={enableLearning}
              onPastSessionClick={(sessionId, lastLog, time, activeTab) => {
                onPastSessionClick(lastLog, sessionId, time, activeTab);
              }}
            />
          )}
        </div> */}

        {/* For phones */}
        {showHomePanel && (
          <div className="chat-sidebar is-hidden-tablet 1.1">
            <div className="mb-auto  auto-scroll custom-scrollbar">
              <div className="mb-4">
                <IntroPanel logoImg={companyLogo} message={sidebarMessage} />
              </div>
              <ChatFeaturePanel
                onPastSessionClick={(sessionId, lastLog, time, activeTab) => {
                  onPastSessionClick(lastLog, sessionId, time, activeTab);
                }}
                endSession={() => {
                  endSession();
                  setShowHomePanel(false);
                }}
                getSessions={getSessions}
                showChatPanel={() => setSelectedSidebarOption("chat")}
                selectedSidebarOption={selectedSidebarOption}
                setSelectedSidebarOption={(selector) => {
                  setSelectedSidebarOption(selector);
                  setShowHomePanel(false);
                }}
              />

              <div
                className="sidebar-overlay"
                onClick={() => {
                  setShowHomePanel(false);
                }}
              ></div>
            </div>
            {/* <div className="powered-by">
              Powered by{" "}
              <strong
                onClick={() =>
                  window.open("https://www.noodlefactory.ai/", "_blank")
                }
              >
                Noodle Factory
              </strong>
            </div> */}
          </div>
        )}

        {showDocumentPreview && (
          <div className="is-hidden-tablet">
            <div className="document-preview-wrapper">
              <MultiDocumentPreview
                onClickBackButton={() => setShowDocumentPreview(false)}
                answer={answerForDocumentPreview}
                keyword={answerForDocumentPreview}
                onSubmit={generateSearchContent}
                content={documentPreviewContent}
                setKeyword={setAnswerForDocumentPreview}
                enableSearch={enableSearch}
                textBlockIds={textBlockIds}
                documentId={documentIdForDocumentPreview}
                updateTextBlock={updateTextBlock}
                docPreviews={docPreviews || []}
                getTextBlock={getTextBlock}
                replyId={replyIdForDocumentPreview}
                isMobile={true}
              />
            </div>
          </div>
        )}
        {/* && isMobile */}
        {/* {!showHomePanel  && ( */}
        <div className="chat-wrapper akshay-1">
          {modelBadge && (
            <div className="model-badge">{modelBadge + " " + t("Enabled")}</div>
          )}
          {!toggleNoClassHistory && getChatPanel("chat")}
          {toggleNoClassHistory && (
            <NoClassHistoryPanel
              heading="No Class History"
              onBack={() => {
                setToggleNoClassHistory(false);
              }}
              enableLearning={enableLearning}
              onPastSessionClick={(sessionId, lastLog, time, activeTab) => {
                onPastSessionClick(lastLog, sessionId, time, activeTab);
              }}
            />
          )}
        </div>
        {/* )} */}

        {getPopups()}
      </div>
    </div>
  );
};

export default Chat;
