import React, { useState } from "react";

import { Emoji } from "emoji-mart";

import EmojiPicker from "../EmojiPicker";

const EmojiOption = (props) => {
  const [expanded, setExpanded] = useState(false);
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const onChange = (data) => {
    props.onChange(data.native);
    props.doCollapse();
  };

  const renderModal = () => {
    return (
      <div onClick={stopPropagation} className="rdw-emoji-modal">
        <EmojiPicker onSelect={onChange} useButton={false} />
      </div>
    );
  };

  const expandCollapse = () => {
    if (!expanded) {
      props.modalHandler.closeAllModals();
    }
    setExpanded((expanded) => !expanded);
  };

  const doCollapse = () => {
    setExpanded(false);
  };

  return (
    <div
      aria-haspopup="true"
      aria-expanded={props.expanded}
      aria-label="rdw-link"
      className="rdw-link-wrapper"
    >
      <div
        onClick={props.onExpandEvent}
        className="rdw-option-wrapper markdown-toolbar-option"
      >
        <img src={props.config.icon} alt="" />
      </div>
      {props.expanded ? renderModal() : undefined}
    </div>
  );
};

export default EmojiOption;
