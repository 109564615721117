import React from "react";

import "./style.css";

const PopupNotification = ({
  openNotification,
  onRequestClose,
  notificationData,
  onCustomButtonClick,
}) => {
  if (!openNotification || !notificationData.is_popup) {
    return null;
  }

  return (
    <div className="popup-notification-modal">
      <div className="popup-notification-modal-box">
        <div className="popup-notification-modal-content">
          <button
            className="popup-notification-modal-close-btn"
            onClick={onRequestClose}
          >
            <i className="icon-close" />
          </button>
          {notificationData.image && (
            <img
              src={notificationData.image}
              className="popup-notification-modal-img"
              alt="popup-notification-modal image"
            />
          )}
          <h3 className="popup-notification-modal-title">
            {notificationData.title}
          </h3>
          {
            notificationData.content_html && (

              <div className="popup-notification-modal-description" style={{
                'max-height': '300px',
                'overflow-y': 'auto'
              }}
              dangerouslySetInnerHTML={{
                __html: notificationData.content_html
              }} 
              ></div>
            )
          }
          {
            !notificationData.content_html && (
              <div className="popup-notification-modal-description" style={{
                'max-height': '300px',
                'overflow-y': 'auto'
              }}
              >
                {notificationData.content}
              </div>
            )
          }
          <div className="popup-notification-btn-wrapper">
            {notificationData?.buttons?.map((button, index) => (
              <button
                key={index}
                className="notification-btn"
                onClick={() => {
                  onRequestClose();
                  onCustomButtonClick(button, "custom");
                }}
              >
                {button.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupNotification;
