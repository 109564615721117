import React, { useState, useEffect } from "react";
import Edit from "../../assets/images/edit.svg";
import { useTranslation } from "react-i18next";
import ThumpUp from "../../assets/images/thump-up.svg";
import ThumpUpBlue from "../../assets/images/thum-up-blue.svg";
import ThumpDown from "../../assets/images/thump-down.svg";
import ThumpDownRed from "../../assets/images/thump-down-red.svg";
import Flag from "../../assets/images/flag.svg";
import FlagRed from "../../assets/images/red-flag.svg";
import CheckGreen from "../../assets/images/green-check.svg";
import CrossRed from "../../assets/images/red-cross.svg";

import { useAuth } from "../../context/auth";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";

const defautlAvatar =
  "https://d17x1gpob0fzk.cloudfront.net/img/walter-head-white.png";
export const Post = ({ post, threadId , isChat = false}) => {
  // const navigate = useNavigate();
  const { authTokens } = useAuth();
  const { sub } = authTokens;
  const { t } = useTranslation("translation");
  const {
    text,
    author_first_name,
    author_last_name,
    author_profile_image,
    answer_status,
    author_nickname,
    _id,
    feedbacks,
    author_id,
    created_at
  } = post;
  const makeApiCall = useFetch();
  const [feedback, setFeedback] = useState("");
  const [isThumpUp, setIsThumpUp] = useState(false);
  const [isThumpDown, setIsThumpDown] = useState(false);
  const [isFlag, setIsFlag] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState(post?.text);
  const [postText, setPostText] = useState(post?.text);
  const [likeCount, setLikeCount] = useState(
    feedbacks.filter((f) => f.feedback_type === 1).length
  );
  const isEditable = author_id === authTokens?.sub;

  const resetAllFeedbacks = () => {
    setIsThumpDown(false);
    setIsFlag(false);
    setIsThumpUp(false);
    deleteFeedback();
  };

  const sendFeedback = async (feedback_type) => {
    makeApiCall.request(
      window.CHAT_API +
        `/api/v1/discussion/threads/${threadId}/posts/${_id}/feedbacks`,
      {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          feedback_type,
        }),
      },
      true,
      !isChat
    );
  };

  const confirmEdit = async () => {
    const res = await updatePost();
    setPostText(res.data?.post?.text);
    setIsEdit(false);
  };
  const discardEdit = () => {
    setEditValue(postText);
    setIsEdit(false);
  };

  const deleteFeedback = async () => {
    makeApiCall.request(
      window.CHAT_API +
        `/api/v1/discussion/threads/${threadId}/posts/${_id}/feedbacks`,
      {
        method: "DELETE",
        headers: authHeader(),
      },
      true,
      !isChat
    );
  };

  const updatePost = async () => {
    const res = await makeApiCall.request(
      window.CHAT_API + `/api/v1/discussion/threads/${threadId}/posts/${_id}`,
      {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify({
          text: editValue,
          // comment: '',
          // author_id: authTokens?.sub
        }),
      },
      true,
      !isChat
    );
    return res;
  };

  const thumupClick = () => {
    if (isThumpUp) {
      resetAllFeedbacks();
      setLikeCount(likeCount - 1);
    } else {
      setIsThumpUp(true);
      setIsThumpDown(false);
      setIsFlag(false);
      sendFeedback(1);
      setLikeCount(likeCount + 1);
    }
  };
  const thumpDownClick = () => {
    if (isThumpDown) {
      resetAllFeedbacks();
    } else {
      setIsThumpUp(false);
      setIsThumpDown(true);
      setIsFlag(false);
      sendFeedback(0);
    }
    if (isThumpUp) {
      setLikeCount(likeCount - 1);
    }
  };

  const flagClick = () => {
    if (isFlag) {
      resetAllFeedbacks();
    } else {
      setIsThumpUp(false);
      setIsThumpDown(false);
      setIsFlag(true);
      sendFeedback(3);
    }

    if (isThumpUp) {
      setLikeCount(likeCount - 1);
    }
  };
//   FEEDBACK_THUMBSDOWN = 0
// FEEDBACK_THUMBSUP = 1
// FEEDBACK_SUGGESTION = 2
// FEEDBACK_FLAG = 3

  useEffect(() => {
    const userFeedback = feedbacks.find((f) => sub.includes( f.author_id));
    switch (userFeedback?.feedback_type) {
      case 0:
        setIsThumpDown(true);
        break;
      case 1:
        setIsThumpUp(true);
        break;
      case 3:
        setIsFlag(true);
        break;
      default:
    }
  }, [feedbacks]);

  const renderFlag = () => {
    return (
      <>
        {isFlag ? (
          <img
            className="icon-button"
            src={FlagRed}
            alt=""
            onClick={() => flagClick()}
          />
        ) : (
          <img
            className="icon-button"
            src={Flag}
            alt=""
            onClick={() => {
              flagClick();
            }}
          />
        )}
      </>
    );
  };

  const renderThumps = () => {
    return (
      <>
        {isThumpUp ? (
          <img
            className="icon-button"
            src={ThumpUpBlue}
            alt=""
            onClick={() => {
              thumupClick();
            }}
          />
        ) : (
          <img
            className="icon-button"
            src={ThumpUp}
            alt=""
            onClick={() => {
              thumupClick();
            }}
          />
        )}
        <span className="c-gray">{likeCount}</span>
        {isThumpDown ? (
          <img
            className="icon-button"
            src={ThumpDownRed}
            alt=""
            onClick={() => {
              thumpDownClick();
            }}
          />
        ) : (
          <img
            className="icon-button"
            src={ThumpDown}
            alt=""
            onClick={() => {
              thumpDownClick();
            }}
          />
        )}
      </>
    );
  };


  return (
    <div className="ques-item-container mb-4">
      <div className="d-flex ques-item-container py-1 align-items-center">
        <div className="d-flex align-items-center is-flex-direction-column is-justify-content-space-around px-2 is-hidden-mobile">
          {renderThumps()}
          
        </div>
        <div className="d-flex align-items-center">
          <img
            className="avar"
            src={author_profile_image || defautlAvatar}
            alt=""
          />
        </div>
        <div className="py-1 px-2  flex-1 ">
          <div className="f-16 pb-1">
            <b>{author_nickname || `${author_first_name} ${author_last_name}`}</b>
          </div>
          {isEdit ? (
            <textarea
              type="text"
              className="edit-input w-100"
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
            />
          ) : (
            <div className="pb-1 c-black">{postText}</div>
          )}
        </div>
        {isEditable && !isEdit && (
          <div className="px-3 is-hidden-mobile">
            <img
              className="icon-button"
              src={Edit}
              alt=""
              onClick={() => {
                setIsEdit(!isEdit);
              }}
            />
          </div>
        )}
        {isEditable && isEdit && (
          <div className="px-3 d-flex is-hidden-mobile">
            <img
              className="icon-button mr-4"
              src={CheckGreen}
              alt=""
              onClick={() => {
                confirmEdit();
              }}
            />
            <img
              className="icon-button"
              src={CrossRed}
              alt=""
              onClick={() => {
                discardEdit();
              }}
            />
          </div>
        )}
        <div className="icon-comment d-flex d-flex align-items-center ml-2 is-hidden-mobile">
          {renderFlag()}
        
        </div>
      </div>
      <div className="is-hidden-tablet-only is-hidden-desktop">
        <div className="d-flex justify-content-center mb-3">
          {isEditable && !isEdit && (
            <div
              className="px-2 is-clickable"
              onClick={() => {
                setIsEdit(!isEdit);
              }}
            >
              <img className="icon-button" src={Edit} alt="" />
              <span className="pl-2 c-blue">{t("Edit answer")}</span>
            </div>
          )}
          {isEditable && isEdit && (
            <div className="px-3 d-flex">
              <img
                className="icon-button mr-4"
                src={CheckGreen}
                alt=""
                onClick={() => {
                  confirmEdit();
                }}
              />
              <img
                className="icon-button"
                src={CrossRed}
                alt=""
                onClick={() => {
                  discardEdit();
                }}
              />
            </div>
          )}
        </div>
        <div className="feeback-mobile d-flex justify-content-around align-items-center">
          {renderThumps()}
          {renderFlag()}
        </div>
      </div>
    </div>
  );
};
