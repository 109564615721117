import React, { useState, useEffect, useMemo, useRef } from "react";
import FormBuilder from "react-jsonschema-form";

import Button from "../Button";
import FileUpload from "../FileUpload";
import DatePicker from "../DatePicker";
import FormDropdown from "../FormDropdown";
import RatingInput from "../RatingInput";
import Radio from "../Radio";
import AgentClassService from "../../helpers/AgentClassService";
import Checkbox from "../Checkbox";
import Modal from "../../components/Modal";

import "./style.css";

import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import SearchableCheckbox from "../SearchableCheckbox/SearchableCheckbox";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";


const TabWidget = (props) => {
  return (
    <input
      type="text"
      id={props.id}
      className="form-control"
      value={props.value}
      required={props.required}
      onChange={(event) => props.onChange(event.target.value)}
      aria-label={props.schema.title}
      role="alert"
    />
  );
}

const Form = ({
  onBack,
  primaryHeaderText,
  formSchema,
  uiSchema,
  onSubmit,
  submitButtonLabel,
  formId,
  formName,
  disableButton,
  sections,
  sectionData,
  ...rest
}) => {
  const { t } = useTranslation("translation");
  const [formData, setFormData] = useState({});
  const [disableFormButton, setDisableFormButton] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const makeApiCall = useFetch();
  const agentId = AgentClassService.getSelectedAgent();
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const operation = useRef("");

  const [sectionIndex, setSectionIndex] = useState(0);
  const sectionCount = useMemo(
    () => (sectionData ? Object.values(sectionData).length : 0),
    [sectionData]
  );

  useEffect(() => {
    if (formSchema && formSchema.required && formSchema.required.length === 0) {
      setDisableFormButton(false);
    }
  }, [formSchema]);

  useEffect(() => {
    document.querySelector('.form-container').scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [sectionIndex]);

  const CustomDescriptionField = ({ id, description }) => {
    return (
      <div
        className="form-field-description"
        id={id}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    );
  };

  const CustomTitleField = ({ title, required }) => {
    const legend = required
      ? title + <span className="mandatory-star">*</span>
      : title;
    return <div id="custom">{legend}</div>;
  };

  let widgets = {
    TextWidget: TabWidget,
    FileWidget: FileUpload,
    DateWidget: DatePicker,
    SelectWidget: FormDropdown,
    // CheckboxesWidget: Checkbox,
    RadioWidget: Radio,
    RatingWidget: RatingInput,
    SelectInputWidget: SearchableCheckbox,
  };

  let fields = {
    DescriptionField: CustomDescriptionField,
    TitleField: CustomTitleField,
  };

  const checkDropdownData = (item) => {
    if (item instanceof Object && item.value && item.label) {
      return item.value;
    } else if (item instanceof Date) {
      let month = item.getUTCMonth() + 1; //months from 1-12
      let day = item.getUTCDate();
      let year = item.getUTCFullYear();
      return day + "/" + month + "/" + year;
    } else {
      return item;
    }
  };

  const onSaveFormProgress = async () => {
    const json = await makeApiCall.request(
      window.CHAT_API + "/api/v1/form/forms/" + formId + "/submission-drafts",
      {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          form_data: formData,
        }),
      }
    );

    setShowCancelConfirmModal(false);
    onBack();
  };

  const handleOnSubmit = () => {
    let currentData = {};
    let details = {};
    for (let k in formData) {
      if (formData[k] instanceof Array) {
        let v = [];
        formData[k].forEach((item) => v.push(checkDropdownData(item)));
        currentData[k] = v;
      } else {
        currentData[k] = checkDropdownData(formData[k]);
      }
    }
    if (Object.keys(currentData).length > 0) {
      details.formData = currentData;
      onSubmit(formId, details);
      setIsSubmitting(true);
      // setDisableFormButton(true);
    }
  };

  const getFormValue = (value) => {
    if (value instanceof String) {
      return value;
    } else if (value instanceof Object) {
      for (let k in value) {
        value[k] = value[k].toString();
      }
      return value;
    } else if (value instanceof Date) {
      return value.toISOString();
    }

    return value ? value.toString() : value;
  };

  const handleFormChange = (data) => {
    let currentData = {};
    for (let k in data.formData) {
      if (data.formData[k] instanceof Array) {
        let v = [];
        data.formData[k].forEach((item) => v.push(getFormValue(item)));
        currentData[k] = v;
      } else {
        currentData[k] = getFormValue(data.formData[k]);
      }
    }
    setFormData(currentData);

    if (formSchema && formSchema.required.length > 0 && data.formData) {
      let shouldEnable = formSchema.required.every(function (val) {
        return Object.keys(data.formData).indexOf(val) >= 0;
      });
      setDisableFormButton(!shouldEnable);
    }
  };

  return (
    <div className="form-container full-chat">
      <button
        className="go-back-button"
        onClick={() => setShowCancelConfirmModal(true)}
      >
        <i className="icon-left-chevron back-icon" />
        {formName}
      </button>
      <div className="form-primary-header">{primaryHeaderText}</div>
      {sectionData &&
        Object.values(sectionData).map((value, index) => (
          <div className={sectionIndex === index ? "" : "hidden"}>
            <h3 className="font-bold">{Object.keys(sectionData)[index]}</h3>
            <FormBuilder
              {...rest}
              noValidate
              schema={value.formSchema}
              onSubmit={(formId, formDetails) => {
                if (operation.current === "Next") {
                  setSectionIndex((prev) => prev + 1);
                } else if (operation.current === "Submit") {
                  handleOnSubmit(formId, formDetails, formData);
                }
              }}
              uiSchema={value.uiSchema}
              widgets={widgets}
              fields={fields}
              onChange={handleFormChange}
              formData={formData}
              children={true}
            >
              <div className="form-button-container">
                {sectionIndex !== 0 && (
                  <Button
                    buttonClass="primary-button custom-button"
                    onClick={(e) => {
                      operation.current = "Prev";
                      setSectionIndex((prev) => prev - 1);
                      e.preventDefault();
                    }}
                    type="submit"
                  >
                    Previous Section
                  </Button>
                )}
                {sectionIndex !== sectionCount - 1 && (
                  <Button
                    buttonClass="custom-button primary-button"
                    onClick={() => {
                      operation.current = "Next";
                    }}
                    disabled={sectionIndex === sectionCount - 1}
                    type="submit"
                  >
                    Next Section
                  </Button>
                )}
                {sectionIndex === sectionCount - 1 && (
                  <Button
                    buttonClass="custom-button primary-button"
                    onClick={() => { operation.current = "Submit"; }}
                  >
                    {isSubmitting ? t("Submitting...") : submitButtonLabel}
                  </Button>
                )}
              </div>
            </FormBuilder>
          </div>
        ))}

      <Modal
        openModal={showCancelConfirmModal}
        onRequestClose={() => {
          setShowCancelConfirmModal(false);
          onBack();
        }}
        buttonAction={() => onSaveFormProgress()}
        buttonLabel={t("Save and Exit")}
        cancelButtonLabel={"Exit without Saving"}
        title={t("Cancel Form Input")}
      >
        <div>Would you like to save your progress before exiting?</div>
      </Modal>
    </div>
  );
};

export default Form;
