import React, { useState, useEffect } from "react";
import CommentsImg from "../../assets/images/comments.svg";
import { useTranslation } from "react-i18next";
import VerifiedQuesImg from "../../assets/images/verified-ques.svg";
import UnanwserQuesImg from "../../assets/images/question.svg";
import history from "../../history";
import cx from "classnames";

const defautlAvatar =
  "https://d17x1gpob0fzk.cloudfront.net/img/walter-head-white.png";

export const LearnerItem = ({
  question,
  isDicussion,
  isDetails,
  onClick = null,
}) => {
  const [showingDetails, setShowingDetails] = useState(false);
  // const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const {
    text,
    author_first_name,
    author_last_name,
    author_nickname,
    author_profile_image,
    answer_status,
    post_count,
    created_at,
    _id,
  } = question;

  return (
    <div
      className={cx("d-flex mb-4 ques-item-container py-1", {
        "is-clickable": !isDetails,
      })}
      role="button"
      onClick={() => {
        if (!isDetails) {
          onClick
            ? onClick(question)
            : history.push(`/team/discussion/details/${_id}`, {
                question,
                isDicussion,
              });
        }
      }}
    >
      <div className="d-flex align-items-center">
        <img
          className="avar"
          src={author_profile_image || defautlAvatar}
          alt=""
        />
      </div>
      <div className="py-1 px-2  flex-1 ">
        {isDicussion && (
          <div className="d-flex pb-1">
            <img src={CommentsImg} alt="" />
            <span className="f-w-600 f-12 px-2">{t("Dicussion")}</span>
          </div>
        )}
        {!isDicussion && (
          <div className="d-flex pb-1">
            {answer_status === "answered" ? (
              <>
                <img src={VerifiedQuesImg} alt="" />
                <span className="f-w-600 c-green f-12 px-2">
                  {t("Question with Acknowledged Answer")}
                </span>
              </>
            ) : (
              <>
                <img src={UnanwserQuesImg} alt="" />
                <span className="c-gray f-w-600 f-12 px-2">
                  {t("Question")}
                </span>
              </>
            )}
          </div>
        )}

        <div className="ques-title pb-1">{text}</div>
        <div className="ques-first-name pb-1 f-14">
          {author_nickname || `${author_first_name} ${author_last_name}`}
        </div>
        {/* {isDicussion && (
          <div className="d-flex pb-1">
            {answer_status === "answered" ? (
              <>
                <img src={ChecImg} alt="" />
                <span className="f-w-600 c-green f-12 px-2">
                  {t("Answer submitted")}
                </span>
              </>
            ) : (
              <>
                <img src={WarningImg} alt="" />
                <span className="f-w-600 c-pink f-12 px-2">
                  {t("Yet to attempt")}
                </span>
              </>
            )}
          </div>
        )} */}
        <div className="icon-comment d-flex d-flex align-items-center ml-2 is-hidden-tablet-only is-hidden-desktop">
          <img src={CommentsImg} alt="" />
          <span className="px-2 c-blue f-16 f-w-700">
            <b>{post_count}</b>
          </span>
        </div>
      </div>
      {!isDetails && (
        <div className="icon-comment d-flex d-flex align-items-center ml-2 is-hidden-mobile">
          <img src={CommentsImg} alt="" />
          <span className="px-2 c-blue f-16 f-w-700">
            <b>{post_count}</b>
          </span>
        </div>
      )}
    </div>
  );
};
