import React, { useEffect, useState } from "react";
import Moment from "react-moment";

import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";

import "./style.css";
import IconLabelButton from "../../components/IconLabelButton";
import Snackbar from "../../components/Snackbar";
import Modal from "../../components/Modal";
import sessionIcon from "../../assets/images/svg/session-icon-bold.svg";
import sessionActiveIcon from "../../assets/images/svg/session-active-bold.svg";
import removeIcon from "../../assets/images/svg/delete-red.svg";
import summaryIcon from "../../assets/images/summary-bold.png";
import sessionOverview from "../../assets/images/svg/session-overview.svg";

import { useTranslation } from "react-i18next";

const AllSessions = (props) => {
  const { t } = useTranslation("translation");
  const [allSessions, setAllSessions] = useState([]);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [openDeleteSessionConfirm, setOpenDeleteSessionConfirm] =
    useState(false);
  const [openDeleteAllSessionsConfirm, setOpenDeleteAllSessionsConfirm] =
    useState(false);
  const [toBeDeleted, setToBeDeleted] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    pageNumbers: [1],
    pageSize: 10,
  });

  const makeApiCall = useFetch();

  useEffect(() => {
    getSessions();
  }, []);

  const getSessions = (currentPage = 1) => {
    makeApiCall
      .request(
        window.CHAT_API +
          `/api/v1/chatlog/sessions?page=${currentPage}&page_size=${pagination.pageSize}`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setAllSessions(json.data.sessions);
          let pageNumbers = [];
          for (let i = 1; i <= json.data.pagination.total_pages; i++) {
            pageNumbers.push(i);
          }
          setPagination({
            currentPage: json.data.pagination.page,
            totalPages: json.data.pagination.total_pages,
            pageNumbers: pageNumbers,
            pageSize: json.data.pagination.page_size,
          });
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching sessions"),
            type: "error",
          });
        }
      });
  };

  const onPageNumberClick = (number) => {
    setPagination((data) => ({
      ...data,
      currentPage: number,
    }));
    getSessions(number);
  };

  const confirmDeleteSession = (sessionId) => {
    setToBeDeleted(sessionId);
    setOpenDeleteSessionConfirm(true);
  };

  const deleteSession = () => {
    setOpenDeleteSessionConfirm(false);
    makeApiCall
      .request(window.CHAT_API + "/api/v1/chatlog/sessions/" + toBeDeleted, {
        method: "DELETE",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          getSessions();
          setSnackbar({
            isOpen: true,
            message: t("Session is deleted successfully."),
            type: "success",
          });
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });
  };

  const confirmDeleteAllSessions = () => {
    setOpenDeleteAllSessionsConfirm(true);
  };

  // useEffect(() => {
  //   if (openDeleteAllSessionsConfirm === false) {
  //     getSessions();
  //   }
  // }, [openDeleteAllSessionsConfirm]);

  const deleteAllSessions = () => {
    setOpenDeleteAllSessionsConfirm(false);
    allSessions.forEach((session) => {
      makeApiCall
        .request(
          window.CHAT_API + "/api/v1/chatlog/sessions/" + session.session_id,
          {
            method: "DELETE",
            headers: authHeader(),
          }
        )
        .then((json) => {
          if (json.status.code === 200) {
          } else {
            setSnackbar({
              isOpen: true,
              message: t("Oops! Something went wrong."),
              type: "error",
            });
          }
        });
    });
    setSnackbar({
      isOpen: true,
      message: t("Sessions deleted."),
      type: "success",
    });
  };

  return (
    <div className="all-sessions-container">
      <div className="all-sessions-header flex-wrap">
        <div className="left-partition">
          {/* <button className="header-back-btn" onClick={props.onBack}>
            <i className="icon-left-chevron" />
          </button> */}
          <div className="header-heading">{t("Sessions")}</div>
        </div>
        {/* <div className="right-partition">
          <IconLabelButton
            buttonLabel="Delete all"
            onClick={confirmDeleteAllSessions}
          />
        </div> */}
        <div className="w-100">
          {t(
            "Pick up where you left off. Rediscover and resume past chats effortlessly."
          )}
        </div>
      </div>
      <div className="chat-feature-panel-body px-0">
        {allSessions.map((element) => {
          // if (!element.is_active) {
          return (
            <div
              key={element.session_id}
              className="session-item"
              // onClick={() => {
              //   props.onPastSessionClick(
              //     element.session_id,
              //     element.last_log,
              //     element.duration_in_seconds,
              //     0
              //   );
              // }}
            >
              <div className="session-item-flex-wrapper">
                <div className="left-partition flex-wrap">
                  {element.is_active ? (
                    <div className="active-session-label w-100">
                      {t("Active Session")}
                    </div>
                  ) : (
                    ""
                  )}
                  {element.is_active ? (
                    <img
                      className="session-item-icon"
                      src={sessionActiveIcon}
                    />
                  ) : (
                    <img className="session-item-icon" src={sessionIcon} />
                  )}

                  <div>
                    <Moment format="DD/MM/YYYY HH:mm" utc local>
                      {element.updated_at}
                    </Moment>
                    <div className="session-item-duration">
                      {t("Duration:")}{" "}
                      {new Date(element.duration_in_seconds * 1000)
                        .toISOString()
                        .substr(11, 8)}
                      {/* {Math.floor(element.duration_in_seconds / 60)} mins */}
                    </div>
                  </div>
                </div>
                <div className="right-partition">
                  {!element.is_active && (
                    <button
                      //buttonLabel="Summary"
                      className="btn p-0"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.showChatPanel && props.showChatPanel();

                        props.onPastSessionClick(
                          element.is_active ? "last" : element.session_id,
                          element.last_log,
                          element.duration_in_seconds,
                          1,
                          true
                        );
                      }}
                    >
                      <img src={summaryIcon} alt="" />
                    </button>
                  )}
                  {!element.is_active && (
                    <button
                      className="btn p-0 ml-5"
                      aria-label={t("View summary")}
                      title={t("View summary")}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onPastSessionClick(
                          element.session_id,
                          element.last_log,
                          element.duration_in_seconds,
                          1
                        );
                        document.getElementById("past-session-panel").focus();
                      }}
                    >
                      <img src={sessionOverview} alt="" />
                    </button>
                  )}
                  {!element.is_active && (
                    // <IconLabelButton
                    //   isNegative
                    //   buttonLabel={t("Delete")}
                    //   onClick={(e) => {
                    //     e.stopPropagation();
                    //     confirmDeleteSession(element.session_id);
                    //   }}
                    // />
                    <button
                      className="btn p-0 ml-5"
                      isNegative
                      title={t("Delete")}
                      aria-label={t("Delete")}
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDeleteSession(element.session_id);
                      }}
                    >
                      <img src={removeIcon} alt="" />
                    </button>
                  )}
                </div>
              </div>
              <div className="topic-list-wrapper">
                {element.topics &&
                  element.topics.map((topic, index) => (
                    <span className="topic-name">
                      {topic.name}
                      {element.topics.length === index + 1 ? "" : ", "}
                    </span>
                  ))}
              </div>
              {/* {element.topics.length > 0 && (
                <div className="session-tooltip">
                  {element.topics &&
                    element.topics.map((topic, index) => (
                      <>
                        <li>{topic.name}</li>
                        <ol type="i">
                          {topic.subtopics.map((subtopic, index) => (
                            <li>{subtopic.name}</li>
                          ))}
                        </ol>
                      </>
                  ))}
                </div>
              )} */}
            </div>
          );
          // }
        })}
      </div>
      {allSessions.length > pagination.pageSize &&
        pagination.pageNumbers.map((number) => (
          <li
            className={
              number === pagination.currentPage
                ? "pagination-btn pagination-btn-active"
                : "pagination-btn"
            }
            key={number}
            id={number}
            onClick={() => onPageNumberClick(number)}
          >
            {number}
          </li>
        ))}

      <Modal
        openModal={openDeleteSessionConfirm}
        onRequestClose={() => setOpenDeleteSessionConfirm(false)}
        buttonAction={deleteSession}
        buttonLabel={t("Yes, Delete")}
        removeSession={props.removeSession}
      >
        <div className="confirm-msg">{t("Are you sure you want to delete this session?")}</div>
      </Modal>
      <Modal
        openModal={openDeleteAllSessionsConfirm}
        onRequestClose={() => setOpenDeleteAllSessionsConfirm(false)}
        buttonAction={deleteAllSessions}
        buttonLabel={t("Yes, Delete")}
      >
        <div className="confirm-msg">{t("Are you sure you want to delete all the sessions?")}</div>
      </Modal>
      <Snackbar
        isOpen={snackbar.isOpen}
        primaryMessage={snackbar.message}
        type={snackbar.type}
        onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
      />
    </div>
  );
};

export default AllSessions;
