import React from "react";
import useFetch from "../../helpers/remote";

import Modal from "../../components/Modal";

import { useTranslation } from "react-i18next";

const ResumeSessionModal = (props) => {
  const { t } = useTranslation('translation');
  return (
    <Modal
      openModal={props.openModal}
      buttonLabel={t("Start new session")}
      buttonAction={props.onStartNewSession}
      cancelButtonLabel={t("Resume last session")}
      onRequestClose={props.resumeSession}
    >
      {t('Your last session was ended. Do you want to resume the last session or start a new one?')}
    </Modal>
  );
};

export default ResumeSessionModal;
