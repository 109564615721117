import React, { useState, useEffect, useRef } from "react";

import Button from "../Button";
import Radio from "../Radio";
import Checkbox from "../Checkbox";
import InfoBlock from "../InfoBlock";
import Modal from "../Modal";

import {
  getNRandomElements,
  getNRandomArrayIndices,
} from "../../helpers/formatFunctions";

import "./style.css";
import LoadingGif from "../../assets/images/loading-circle.gif";
import QuitQuizIcon from "../../assets/images/svg/exit.svg";
import PreviousIcon from "../../assets/images/svg/arrow-left.svg";
import NextIcon from "../../assets/images/svg/arrow-right.svg";
import MarkdownEditor from "../MarkdownEditor";
import { mdToDraftjs, draftjsToMd } from "draftjs-md-converter";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import clearFormatting from "draft-js-clear-formatting";
import BulletEditor from "../BulletEditor";
import QuizTableEditor from "../QuizTableEditor";
import HeaderDescriptionEditor from "../HeaderDescriptionEditor";
import DragMatch from "../DragMatch";

import { useTranslation } from "react-i18next";
import cx from "classnames";

const QuizGenerator = ({
  onBack,
  onQuit,
  quizData,
  onSubmit,
  disableSubmit,
  enableLearning,
  setStartQuiz,
  exitFromQuiz,
  essayConfig,
}) => {
  const { t } = useTranslation("translation");
  const [showSummary, setShowSummary] = useState(true);
  const [currentQuestionId, setCurrentQuestionId] = useState(
    quizData.questions[0] ? quizData.questions[0].id : ""
  );
  const [answers, setAnswers] = useState({});
  // const answersBackup = useRef(null);
  const [openQuitConfirm, setOpenQuitConfirm] = useState(false);
  const [openSubmitConfirm, setOpenSubmitConfirm] = useState(false);
  const [quizHistory, setQuizHistory] = useState(
    quizData.questions[0] ? [quizData.questions[0].id] : [""]
  );
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [displayedQuestionsId, setDisplayedQuestionsId] = useState([]);
  const [strategy, setStrategy] = useState("normal");
  const [displayCount] = useState(
    quizData.display_questions_count
      ? quizData.display_questions_count
      : quizData.questions.length
  );
  const [enterComplete, setEnterComplete] = useState({
    status: false,
    identifier: "",
    index: "",
  });
  const [showLoadingScreen, setShowLaodingScreen] = useState(false);
  const [transformedQuestions, setTransformedQuestions] = useState([]);
  useEffect(() => {
    let temp = {};
    quizData.questions.forEach((question) => {
      if (question.type === "text" || question.type === "essay") {
        temp[question.id] = {
          text: EditorState.createEmpty(),
          isAnswered: false,
          type: question.type,
          question_id: question.id,
        };
      } else if (question.type === "bullet" || question.type === "newbullet") {
        temp[question.id] = {
          text: "",
          bulletArray: [""],
          isAnswered: false,
          type: question.type,
          question_id: question.id,
        };
      } else if (question.type === "table") {
        temp[question.id] = {
          text: [["", ""]],
          isAnswered: false,
          type: question.type,
          hasColumnHeaders: false,
          question_id: question.id,
        };
      } else if (question.type === "description") {
        temp[question.id] = {
          text: [["", ""]],
          isAnswered: false,
          type: question.type,
          question_id: question.id,
        };
      } else if (question.type === "matching") {
        temp[question.id] = {
          matches: [],
          isAnswered: false,
          type: question.type,
          question_id: question.id,
        };
      } else if (question.options) {
        temp[question.id] = {
          options: [],
          isAnswered: false,
          type: question.type,
          question_id: question.id,
        };
      }
    });
    setAnswers(temp);
    transformAllQuestions();
    setShowSummary(quizData.is_welcome_enabled);
  }, []);

  useEffect(() => {
    if (exitFromQuiz === true) {
      handleQuit();
    }
  }, [exitFromQuiz]);

  function transformAllQuestions() {
    let temp = {};
    quizData.questions.forEach((question) => {
      if (question.parent_id && temp[question.parent_id]) {
        temp[question.parent_id] = {
          ...temp[question.parent_id],
          subquestions:
            "subquestions" in temp[question.parent_id]
              ? temp[question.parent_id].subquestions.concat([question])
              : [].concat([question]),
        };
      } else {
        temp[question.id] = question;
      }
    });
    setTransformedQuestions(temp);
    initialiseDisplayedQuestions(temp);
  }

  const convertToMarkdown = (data) => {
    const content = data.getCurrentContent();
    let markup = draftjsToMd(convertToRaw(content));
    let splitMarkup = markup.replace("&nbsp;", " ");

    let transformedMarkup = splitMarkup.trim();
    return transformedMarkup;
  };

  const initialiseDisplayedQuestions = (questionData) => {
    // for all in one page
    if (quizData.display_mode === "continuous_scroll") {
      // if the display count is more than 0 and less than the total number of questions,
      // then randomly select display count questions
      if (displayCount && displayCount < Object.keys(questionData).length) {
        let toDisplay = getNRandomArrayIndices(
          Object.keys(questionData),
          displayCount
        );

        let result = Object.keys(questionData);
        let temp = toDisplay.map((item) => result[item]);
        setDisplayedQuestionsId(temp);
        setStrategy("random");
      } else {
        setStrategy("normal");
        setDisplayedQuestionsId(Object.keys(questionData));
      }
    } else if (quizData.display_mode === "single_question") {
      let tempStrategy = "normal";

      if (displayCount > 0 && displayCount < Object.keys(questionData).length) {
        setStrategy("random");
        tempStrategy = "random";
      }

      Object.keys(questionData).forEach((questionId) => {
        if (questionData[questionId].options) {
          questionData[questionId].options.forEach((option) => {
            if (option.branch && option.branch.action) {
              setStrategy("branching");
              tempStrategy = "branching";
            }
          });
        }
      });

      if (tempStrategy === "random") {
        let toDisplay = getNRandomArrayIndices(
          Object.keys(questionData),
          displayCount
        );

        let result = Object.keys(questionData);
        let temp = toDisplay.map((item) => result[item]);
        setDisplayedQuestionsId(temp);
        setCurrentQuestionId(temp[0]);
        setQuizHistory([temp[0]]);
        if (temp.length === 1) {
          setShowSubmitButton(true);
        }
      } else if (tempStrategy === "normal") {
        setStrategy("normal");
        setDisplayedQuestionsId(Object.keys(questionData));
        if (Object.keys(questionData).length === 1) {
          setShowSubmitButton(true);
        }
      } else if (tempStrategy === "branching") {
        setQuizHistory([Object.keys(questionData)[0]]);
        if (Object.keys(questionData).length === 1) {
          setShowSubmitButton(true);
        }
      }
    }
  };

  const startQuiz = () => {
    setShowSummary(false);
    setStartQuiz(true);
  };

  const removeValue = (list, value) => {
    let newList = [...list];
    for (var i = 0; i < newList.length; i++) {
      if (newList[i] === value) {
        newList.splice(i, 1);
        i--;
      }
    }
    return newList;
  };

  const onRadioChange = (questionId, value, currentQuestion) => {
    if (currentQuestion && currentQuestion.options) {
      currentQuestion.options.forEach((option) => {
        if (option.id === value) {
          if (option.branch && option.branch.action) {
            if (option.branch.action === "end-quiz") {
              setShowSubmitButton(true);
            }
          }
        }
      });
    }

    let temp = { ...answers };
    temp[questionId] = {
      ...temp[questionId],
      options: [value],
      isAnswered: true,
    };
    setAnswers(temp);
  };

  const onCheckboxChange = (questionId, checked, optionId) => {
    let temp = { ...answers };
    let tempOptions = temp[questionId].options;
    if (checked) {
      tempOptions.push(optionId);
    } else if (!checked) {
      tempOptions = removeValue(tempOptions, optionId);
    }

    if (tempOptions.length === 0) {
      temp[questionId].isAnswered = false;
    } else {
      temp[questionId].isAnswered = true;
    }

    temp[questionId].options = tempOptions;
    setAnswers(temp);
  };

  const onTextChange = (questionId, value, maxEssayChars = null) => {
    // const test = convertToMarkdown(value)
    // console.log('onTextChange')

    // console.log({test})
    if (
      maxEssayChars &&
      value?.getCurrentContent()?.getPlainText("").length > maxEssayChars
    ) {
      return;
    }

    const newValue = clearFormatting(value, {
      inline: true,
      entities: true,
      lists: true,
    });
    let temp = { ...answers };
    temp[questionId] = {
      ...temp[questionId],
      text: newValue,
    };

    if (
      newValue.getCurrentContent() &&
      newValue.getCurrentContent().hasText()
    ) {
      temp[questionId].isAnswered = true;
    } else {
      temp[questionId].isAnswered = false;
    }

    setAnswers(temp);
  };

  const onTableChange = (questionId, value, enableColumnHeader) => {
    let temp = { ...answers };

    temp[questionId] = {
      ...temp[questionId],
      text: value,
      hasColumnHeaders: enableColumnHeader,
    };
    temp[questionId].isAnswered = false;

    value.forEach((row) => {
      if (row.filter((cell) => cell !== "").length > 0) {
        temp[questionId].isAnswered = true;
      }
    });
    temp[questionId].type = "table";
    setAnswers(temp);
  };

  const onMatchAnswerChange = (questionId, data) => {
    let temp = { ...answers };

    temp[questionId] = {
      ...temp[questionId],
      matches: data,
    };
    temp[questionId].isAnswered = false;

    Object.keys(data).forEach((termId) => {
      if (data[termId]) {
        temp[questionId].isAnswered = true;
      }
    });
    temp[questionId].type = "matching";
    setAnswers(temp);
  };

  const onDescriptionEditorChange = (questionId, value) => {
    let temp = { ...answers };
    temp[questionId] = {
      ...temp[questionId],
      text: value,
    };
    temp[questionId].isAnswered = false;

    value.forEach((row) => {
      if (row.filter((cell) => cell !== "").length > 0) {
        temp[questionId].isAnswered = true;
      }
    });
    temp[questionId].type = "description";
    setAnswers(temp);
  };

  const findAndSetNextBranchedQuestion = (questionId) => {
    setCurrentQuestionId(questionId);
    let history = [...quizHistory];
    history.push(questionId);
    setQuizHistory(history);
  };

  const animateNextScreen = () => {
    let questionContainer =
      document.getElementsByClassName("question-container");
    if (questionContainer[0]) {
      questionContainer[0].classList.add("next-animation");
      setTimeout(() => {
        questionContainer[0].classList.remove("next-animation");
      }, 400);
    }
  };

  const handleNext = (currentQuestion) => {
    if (
      currentQuestion.type === "radio" &&
      answers[currentQuestionId].options.length > 0 &&
      strategy === "branching"
    ) {
      currentQuestion.options.forEach((option) => {
        if (option.id === answers[currentQuestionId].options[0]) {
          if (option.branch && option.branch.action) {
            if (option.branch.action === "end-quiz") {
              handleSubmit();
            } else if (
              option.branch.action === "jump-to-question" &&
              option.branch.reference_id
            ) {
              findAndSetNextBranchedQuestion(option.branch.reference_id);
              animateNextScreen();
            }
          } else {
            let history = [...quizHistory];
            let currentIdIndex =
              Object.keys(transformedQuestions).indexOf(currentQuestionId);
            history.push(Object.keys(transformedQuestions)[currentIdIndex + 1]);
            setQuizHistory(history);

            if (
              currentIdIndex + 1 ===
              Object.keys(transformedQuestions).length - 1
            ) {
              setShowSubmitButton(true);
            }

            setCurrentQuestionId(
              Object.keys(transformedQuestions)[currentIdIndex + 1]
            );

            animateNextScreen();
          }
        }
      });
    } else if (strategy === "random") {
      let history = [...quizHistory];
      history.push(
        displayedQuestionsId[
          displayedQuestionsId.indexOf(currentQuestionId) + 1
        ]
      );
      setQuizHistory(history);
      setCurrentQuestionId(
        displayedQuestionsId[
          displayedQuestionsId.indexOf(currentQuestionId) + 1
        ]
      );
      animateNextScreen();
    } else {
      let history = [...quizHistory];
      let currentIdIndex =
        Object.keys(transformedQuestions).indexOf(currentQuestionId);
      history.push(Object.keys(transformedQuestions)[currentIdIndex + 1]);
      setQuizHistory(history);

      if (currentIdIndex + 1 === Object.keys(transformedQuestions).length - 1) {
        setShowSubmitButton(true);
      }

      setCurrentQuestionId(
        Object.keys(transformedQuestions)[currentIdIndex + 1]
      );

      animateNextScreen();
    }

    // else if (strategy === "random") {
    //   let nextIndex = Math.floor(Math.random() * quizData.questions.length);

    //   while (quizHistory.indexOf(nextIndex) !== -1) {
    //     nextIndex = Math.floor(Math.random() * quizData.questions.length);
    //   }

    //   let history = [...quizHistory];
    //   history.push(nextIndex);
    //   setQuizHistory(history);
    //   setCurrentQuestionIndex(nextIndex);

    //   animateNextScreen();
    // }
  };

  const handlePrevious = () => {
    setShowSubmitButton(false);
    let history = [...quizHistory];
    history.pop();
    setCurrentQuestionId(history[history.length - 1]);
    setQuizHistory(history);
    let questionContainer =
      document.getElementsByClassName("question-container");
    if (questionContainer[0]) {
      questionContainer[0].classList.add("previous-animation");
      setTimeout(() => {
        questionContainer[0].classList.remove("previous-animation");
      }, 400);
    }
  };

  const handleSubmit = () => {
    setShowLaodingScreen(true);
    setOpenSubmitConfirm(false);
    let transformedAnswer = [];

    Object.keys(answers).forEach((questionId, index) => {
      let type = answers[questionId].type;
      delete answers[questionId].isAnswered;
      delete answers[questionId].type;

      let enableColumnHeader = answers[questionId].hasColumnHeaders;

      if ("hasColumnHeaders" in answers[questionId]) {
        delete answers[questionId].hasColumnHeaders;
      }

      if (answers[questionId].text) {
        if (enableColumnHeader && type === "table") {
          answers[questionId].text.map((row) => row.shift());
        }

        transformedAnswer[index] = {
          question_id: questionId,
          text:
            type === "table" || type === "description"
              ? JSON.stringify(answers[questionId].text)
              : convertToMarkdown(answers[questionId].text),
        };
      } else if (answers[questionId].bulletArray) {
        if (
          answers[questionId].bulletArray.length === 1 &&
          answers[questionId].bulletArray[0] === ""
        ) {
          transformedAnswer[index] = {
            question_id: questionId,
            text: "",
          };
        } else {
          let filtered = answers[questionId].bulletArray.filter(Boolean);
          transformedAnswer[index] = {
            question_id: questionId,
            text: "- " + filtered.join("\n- "),
            // html: (type === "bullet")
            //   ? "<ul>\n<li>" + filtered.join("</li>\n<li>") + "</li>\n</ul>"
            //   : "<ul><li>" + filtered.join("</li><li>") + "</li></ul>",
          };
        }
      } else if (answers[questionId].matches) {
        let temp = [];
        Object.keys(answers[questionId].matches).forEach((key) => {
          temp.push({
            term: key,
            definition: answers[questionId].matches[key]
              ? answers[questionId].matches[key].id
              : "",
          });
        });
        transformedAnswer[index] = { question_id: questionId, matches: temp };
      } else {
        transformedAnswer[index] = { ...answers[questionId] };
      }
    });

    if (strategy === "branching") {
      onSubmit(quizData._id, transformedAnswer, quizHistory);
    } else {
      onSubmit(quizData._id, transformedAnswer, displayedQuestionsId);
    }
  };

  const handleQuit = () => {
    setShowLaodingScreen(true);
    setOpenQuitConfirm(false);
    let transformedAnswer = [];

    Object.keys(answers).forEach((questionId, index) => {
      let type = answers[questionId].type;
      delete answers[questionId].isAnswered;
      delete answers[questionId].type;
      let enableColumnHeader = answers[questionId].hasColumnHeaders;

      if ("hasColumnHeaders" in answers[questionId]) {
        delete answers[questionId].hasColumnHeaders;
      }
      if (answers[questionId].text) {
        if (enableColumnHeader && type === "table") {
          answers[questionId].text.map((row) => row.shift());
        }
        transformedAnswer[index] = {
          question_id: questionId,
          text:
            type === "table" || type === "description"
              ? JSON.stringify(answers[questionId].text)
              : convertToMarkdown(answers[questionId].text),
        };
      } else if (answers[questionId].bulletArray) {
        let filtered = answers[questionId].bulletArray.filter(Boolean);
        transformedAnswer[index] = {
          question_id: questionId,
          text: "- " + filtered.join("\n- "),
          // html: (type === "bullet")
          //   ? "<ul>\n<li>" + filtered.join("</li>\n<li>") + "</li>\n</ul>"
          //   : "<ul><li>" + filtered.join("</li><li>") + "</li></ul>",
        };
      } else if (answers[questionId].matches) {
        let temp = [];
        Object.keys(answers[questionId].matches).forEach((key) => {
          temp.push({
            term: key,
            definition: answers[questionId].matches[key]
              ? answers[questionId].matches[key]
              : "",
          });
        });
        transformedAnswer[index] = { question_id: questionId, matches: temp };
      } else {
        transformedAnswer[index] = { ...answers[questionId] };
      }
    });

    if (strategy === "branching") {
      onQuit(quizData._id, transformedAnswer, quizHistory);
    } else {
      onQuit(quizData._id, transformedAnswer, displayedQuestionsId);
    }
  };

  const confirmSubmit = () => {
    setOpenSubmitConfirm(true);
  };

  const confirmQuit = () => {
    setOpenQuitConfirm(true);
  };

  const handleQuestionCardDelete = (id, questionId) => {
    let temp = { ...answers };

    temp[questionId]["bulletArray"].splice(id, 1);
    setAnswers(temp);
  };

  const onQuestionEdit = (event, id, questionId) => {
    let sentences = event.target.value.split("\n");

    let temp = { ...answers };

    if (sentences.length <= 1) {
      temp[questionId]["bulletArray"][id] = event.target.value;

      temp[questionId].isAnswered = false;

      temp[questionId]["bulletArray"].forEach((point) => {
        if (point.trim() !== "") {
          temp[questionId].isAnswered = true;
        }
      });
      setAnswers(temp);
    }
  };

  const handlePaste = (event, id, questionId) => {
    let temp = event.clipboardData
      ? event.clipboardData.getData("text")
      : event.target.value;
    let sentences = temp.split("\n");

    let tempAnswers = { ...answers };

    if (sentences.length > 1) {
      let count = 0;
      sentences.forEach((sentence) => {
        if (sentence.trim()) {
          tempAnswers[questionId]["bulletArray"].splice(
            id + count,
            0,
            sentence.trim()
          );
          count += 1;
        }
      });
    }

    tempAnswers[questionId]["bulletArray"].forEach((point) => {
      if (point.trim() !== "") {
        tempAnswers[questionId].isAnswered = true;
      }
    });
    setAnswers(tempAnswers);
  };

  useEffect(() => {
    if (enterComplete.status) {
      let element = document.getElementById(
        `input${enterComplete.identifier.toString()}${enterComplete.index.toString()}`
      );
      if (element) {
        element.focus();
      }
    }
  }, [enterComplete]);

  const handleBulletInputEnter = (event, id, questionId, type) => {
    if (event.key === "Enter" || event.keyCode === 13 || event.which === 13) {
      let tempAnswers = { ...answers };

      tempAnswers[questionId]["bulletArray"].splice(id + 1, 0, "");
      setAnswers(tempAnswers);
      setEnterComplete({
        status: true,
        identifier: questionId,
        index: id + 1,
      });
    }
  };

  const getSingleModeParentQueHTML = (question, type, questionNumber) => {
    let computedQuestionId = type === "sub" ? question.id : currentQuestionId;
    let queForDisplay = question
      ? question
      : transformedQuestions[computedQuestionId]
      ? transformedQuestions[computedQuestionId]
      : {};
    return (
      <React.Fragment>
        <p
          className={cx({
            "quiz-question-text": true,
            isStrong: queForDisplay.text.includes("<strong>"),
          })}
          dangerouslySetInnerHTML={{
            __html: queForDisplay.text,
          }}
        />
        {quizData.score > 0 && queForDisplay.score && (
          <div className="question-score-container">
            {queForDisplay.score} {queForDisplay.score > 1 ? "marks" : "mark"}
          </div>
        )}
        {queForDisplay.type !== "group" && (
          <div>
            {queForDisplay.options && (
              <>
                {queForDisplay.type === "radio" && (
                  <Radio
                    isQuiz
                    options={{
                      enumOptions: queForDisplay.options.map((item) => {
                        return { value: item.id, label: item.text };
                      }),
                    }}
                    id={queForDisplay.text}
                    onChange={(value) =>
                      onRadioChange(computedQuestionId, value, queForDisplay)
                    }
                    selectedValues={answers[computedQuestionId].options}
                  />
                )}
                {queForDisplay.type === "checkbox" && (
                  <Checkbox
                    isQuiz
                    options={{
                      enumOptions: queForDisplay.options.map((item) => {
                        return { value: item.id, label: item.text };
                      }),
                    }}
                    onChange={(checked, optionId) =>
                      onCheckboxChange(computedQuestionId, checked, optionId)
                    }
                    selectedValues={answers[computedQuestionId].options}
                  />
                )}
              </>
            )}
            {queForDisplay.type === "text" && (
              <>
                <MarkdownEditor
                  key={computedQuestionId}
                  id={computedQuestionId}
                  value={answers[computedQuestionId].text}
                  onChange={(value) => onTextChange(computedQuestionId, value)}
                  placeholder={t("Type your answer here")}
                  isQuizAnswer
                />
              </>
            )}
            {queForDisplay.type === "essay" && (
              <>
                <MarkdownEditor
                  key={computedQuestionId}
                  id={computedQuestionId}
                  value={answers[computedQuestionId].text}
                  onChange={(value) =>
                    onTextChange(
                      computedQuestionId,
                      value,
                      essayConfig?.params?.max_essay_characters || 8000
                    )
                  }
                  placeholder={t("Type your answer here")}
                  maxEssayChars={
                    essayConfig?.params?.max_essay_characters || 8000
                  }
                  isQuizAnswer
                />
              </>
            )}
            {(queForDisplay.type === "bullet" ||
              queForDisplay.type === "newbullet") && (
              <BulletEditor
                spellCheck={false}
                identifier={computedQuestionId}
                data={answers[computedQuestionId].bulletArray}
                handleCardDelete={(index) =>
                  handleQuestionCardDelete(index, computedQuestionId)
                }
                onInputEdit={(event, index) =>
                  onQuestionEdit(event, index, computedQuestionId)
                }
                handlePaste={(event, index) =>
                  handlePaste(event, index, computedQuestionId)
                }
                handleKeyPress={(event, index) =>
                  handleBulletInputEnter(event, index, computedQuestionId)
                }
                shouldAutoFocus={false}
                handleKeyUp={(event, index) =>
                  handleBulletInputEnter(event, index, computedQuestionId, "up")
                }
              />
            )}
            {queForDisplay.type === "table" && (
              <QuizTableEditor
                spellCheck={false}
                key={computedQuestionId}
                headerValues={
                  queForDisplay.answer_key_settings
                    ? queForDisplay.answer_key_settings.headers
                    : ["", ""]
                }
                columnHeaderValues={
                  queForDisplay.answer_key_settings &&
                  "column_headers" in queForDisplay.answer_key_settings
                    ? queForDisplay.answer_key_settings.column_headers
                    : []
                }
                disableColumnAdd
                onTableChange={(value, enableColumnHeader) =>
                  onTableChange(computedQuestionId, value, enableColumnHeader)
                }
                rowCount={
                  queForDisplay.answer_key_settings &&
                  "row_count" in queForDisplay.answer_key_settings
                    ? queForDisplay.answer_key_settings.row_count
                    : 1
                }
                columnCount={
                  queForDisplay.answer_key_settings &&
                  "column_count" in queForDisplay.answer_key_settings
                    ? queForDisplay.answer_key_settings.column_count
                    : 2
                }
                enableColumnHeader={
                  queForDisplay.answer_key_settings
                    ? queForDisplay.answer_key_settings.enable_column_header
                    : false
                }
                tableValues={answers[computedQuestionId]}
              />
            )}
            {queForDisplay.type === "description" && (
              <HeaderDescriptionEditor
                spellCheck={false}
                key={computedQuestionId}
                // disableHeader
                onTableChange={(value) =>
                  onDescriptionEditorChange(computedQuestionId, value)
                }
                initialRowCount={1}
                tableValues={answers[computedQuestionId].text}
              />
            )}
            {queForDisplay.type === "matching" && (
              <DragMatch
                headerValues={
                  queForDisplay.answer_key_settings
                    ? queForDisplay.answer_key_settings.headers
                    : ["", ""]
                }
                terms={queForDisplay.terms ? queForDisplay.terms : []}
                definitions={
                  queForDisplay.definitions ? queForDisplay.definitions : []
                }
                onChange={(data) =>
                  onMatchAnswerChange(computedQuestionId, data)
                }
              />
            )}
          </div>
        )}
        <div className="clearfix" />
      </React.Fragment>
    );
  };

  const getSingleModeHTML = () => {
    let mainQuestionNumber = displayedQuestionsId.length
      ? displayedQuestionsId.indexOf(currentQuestionId) + 1
      : quizHistory.length
      ? quizHistory.indexOf(currentQuestionId) + 1
      : "";
    return (
      <div className="single-question-manager">
        <div className="quit-btn-container">
          <button
            className="quit-btn"
            onClick={confirmQuit}
            title={t("Quit Quiz")}
          >
            <img
              className="quit-quiz-icon"
              src={QuitQuizIcon}
              alt={t("Quit Quiz")}
            />
          </button>
        </div>
        <h1 className="inside-quiz-heading">{quizData.name}</h1>
        <div className="question-container single-question-container">
          <div className="flex-container">
            <div className="question-number-container">
              {mainQuestionNumber}.
            </div>
            <div className="flex-one">
              {getSingleModeParentQueHTML("", "", mainQuestionNumber)}
              {transformedQuestions[currentQuestionId].type === "group" &&
                "subquestions" in transformedQuestions[currentQuestionId] && (
                  <div>
                    {transformedQuestions[currentQuestionId].subquestions.map(
                      (question, index) =>
                        getSingleModeParentQueHTML(
                          question,
                          "sub",
                          `${mainQuestionNumber}.${index + 1}`
                        )
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="quiz-navigation-bar">
          {quizHistory.length > 1 ? (
            <button className="quiz-navigation-button" onClick={handlePrevious}>
              <img
                className="quiz-navigation-button-icon"
                src={PreviousIcon}
                alt={t("Previous")}
              />
            </button>
          ) : (
            <div />
          )}
          {quizHistory.length < displayCount && !showSubmitButton && (
            <button
              className="quiz-navigation-button"
              onClick={() =>
                handleNext(transformedQuestions[currentQuestionId])
              }
              disabled={
                transformedQuestions[currentQuestionId].type !== "group"
                  ? quizData.is_required &&
                    answers[currentQuestionId] &&
                    !answers[currentQuestionId].isAnswered
                  : quizData.is_required &&
                    !checkAllSubQuesAnswered(
                      transformedQuestions[currentQuestionId].subquestions
                    )
              }
            >
              <img
                className="quiz-navigation-button-icon"
                src={NextIcon}
                alt={t("Next")}
              />
            </button>
          )}
          {(quizHistory.length === displayCount || showSubmitButton) && (
            <Button
              isDisabled={
                disableSubmit ||
                (transformedQuestions[currentQuestionId].type !== "group"
                  ? quizData.is_required &&
                    answers[currentQuestionId] &&
                    !answers[currentQuestionId].isAnswered
                  : quizData.is_required &&
                    !checkAllSubQuesAnswered(
                      transformedQuestions[currentQuestionId].subquestions
                    ))
              }
              buttonClass="custom-button primary-button quiz-submit-btn"
              onClick={handleSubmit}
            >
              {t("Submit Quiz")}
            </Button>
          )}
        </div>
      </div>
    );
  };

  const checker = (arr) => arr.every((v) => v === true);

  const checkAllAnswered = () => {
    let allAnswered = true;
    let subQuestionsAnswered = [];

    displayedQuestionsId.forEach((questionId) => {
      if (
        transformedQuestions[questionId].type !== "group" &&
        !answers[questionId].isAnswered
      ) {
        allAnswered = false;
      } else if (transformedQuestions[questionId].type === "group") {
        subQuestionsAnswered.push(
          checkAllSubQuesAnswered(transformedQuestions[questionId].subquestions)
        );
      }
    });
    let isValid = allAnswered && checker(subQuestionsAnswered);
    return isValid;
  };

  const checkAllSubQuesAnswered = (data) => {
    let allAnswered = true;

    data.forEach((subque) => {
      if (!answers[subque.id].isAnswered) {
        allAnswered = false;
      }
    });

    return allAnswered;
  };

  const getAllModeQueHTML = (question, questionId, questionNumber) => {
    return (
      <div key={question.text} className="all-in-one-question-container">
        {/* <p>
          <b>
            <u>Question {questionNumber}</u>
          </b>
        </p> */}
        <p
          className={cx({
            "quiz-question-text": true,
            isStrong: question.text.includes("<strong>"),
          })}
          dangerouslySetInnerHTML={{
            __html: question.text,
          }}
        />
        {quizData.score > 0 && question.score && (
          <div className="question-score-container">
            {question.score} {question.score > 1 ? t("marks") : t("mark")}
          </div>
        )}
        <div>
          {question.options && (
            <>
              {question.type === "radio" && (
                <Radio
                  isQuiz
                  id={question.text}
                  options={{
                    enumOptions: question.options.map((item) => {
                      return { value: item.id, label: item.text };
                    }),
                  }}
                  onChange={(value) =>
                    onRadioChange(questionId, value, question)
                  }
                  selectedValues={answers[questionId].options}
                />
              )}

              {question.type === "checkbox" && (
                <Checkbox
                  isQuiz
                  options={{
                    enumOptions: question.options.map((item) => {
                      return { value: item.id, label: item.text };
                    }),
                  }}
                  onChange={(checked, optionId) =>
                    onCheckboxChange(questionId, checked, optionId)
                  }
                  selectedValues={answers[questionId].options}
                />
              )}
            </>
          )}
          {question.type === "text" && (
            <>
              <MarkdownEditor
                key={questionId}
                id={questionId}
                value={answers[questionId].text}
                onChange={(value) => onTextChange(questionId, value)}
                placeholder={t("Type your answer here")}
                isQuizAnswer
              />
            </>
          )}
          {question.type === "essay" && (
            <>
              <MarkdownEditor
                maxEssayChars={
                  essayConfig?.params?.max_essay_characters || 8000
                }
                key={questionId}
                id={questionId}
                value={answers[questionId].text}
                onChange={(value) =>
                  onTextChange(
                    questionId,
                    value,
                    essayConfig?.params?.max_essay_characters || 8000
                  )
                }
                placeholder={t("Type your answer here")}
                isQuizAnswer
              />
            </>
          )}
          {(question.type === "bullet" || question.type === "newbullet") && (
            <BulletEditor
              spellCheck={false}
              identifier={questionId}
              data={answers[questionId].bulletArray}
              handleCardDelete={(index) =>
                handleQuestionCardDelete(index, questionId)
              }
              onInputEdit={(event, index) =>
                onQuestionEdit(event, index, questionId)
              }
              handlePaste={(event, index) =>
                handlePaste(event, index, questionId)
              }
              handleKeyPress={(event, index) =>
                handleBulletInputEnter(event, index, questionId)
              }
              shouldAutoFocus={false}
              handleKeyUp={(event, index) =>
                handleBulletInputEnter(event, index, questionId, "up")
              }
            />
          )}
          {question.type === "table" && (
            <QuizTableEditor
              spellCheck={false}
              key={questionId}
              headerValues={
                question.answer_key_settings
                  ? question.answer_key_settings.headers
                  : ["", ""]
              }
              columnHeaderValues={
                question.answer_key_settings &&
                "column_headers" in question.answer_key_settings
                  ? question.answer_key_settings.column_headers
                  : []
              }
              disableHeader
              disableColumnAdd
              onTableChange={(value, enableColumnHeader) =>
                onTableChange(questionId, value, enableColumnHeader)
              }
              rowCount={
                question.answer_key_settings &&
                "row_count" in question.answer_key_settings
                  ? question.answer_key_settings.row_count
                  : 1
              }
              columnCount={
                question.answer_key_settings &&
                "column_count" in question.answer_key_settings
                  ? question.answer_key_settings.column_count
                  : 2
              }
              enableColumnHeader={
                question.answer_key_settings
                  ? question.answer_key_settings.enable_column_header
                  : false
              }
            />
          )}
          {question.type === "description" && (
            <HeaderDescriptionEditor
              spellCheck={false}
              key={questionId}
              // disableHeader
              onTableChange={(value) =>
                onDescriptionEditorChange(questionId, value)
              }
              // tableValues={JSON.parse(
              //   question.ideal_answer
              // )}
            />
          )}
          {question.type === "matching" && (
            <DragMatch
              headerValues={
                question.answer_key_settings
                  ? question.answer_key_settings.headers
                  : ["", ""]
              }
              terms={question.terms ? question.terms : []}
              definitions={question.definitions ? question.definitions : []}
              onChange={(data) => onMatchAnswerChange(questionId, data)}
            />
          )}
          {question.type === "group" && "subquestions" in question && (
            <div>
              {question.subquestions.map((question, index) =>
                getAllModeQueHTML(
                  question,
                  question.id,
                  `${questionNumber}.${(index + 1).toString()}`
                )
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const getAllModeHTML = () => {
    return (
      <div className="all-questions-manager">
        <div className="quit-btn-container">
          <button
            className="quit-btn"
            onClick={confirmQuit}
            title={t("Quit Quiz")}
          >
            <img
              className="quit-quiz-icon"
              src={QuitQuizIcon}
              alt={t("Quit Quiz")}
            />
          </button>
        </div>

        <h1 className="inside-quiz-heading">{quizData.name}</h1>
        {displayedQuestionsId.map((questionId, index) => {
          return (
            <div className="flex-container question-container">
              <div className="question-number-container">
                {(index + 1).toString()}.
              </div>
              <div className="flex-one">
                {getAllModeQueHTML(
                  transformedQuestions[questionId],
                  questionId,
                  (index + 1).toString()
                )}
              </div>
            </div>
          );
        })}
        <div className="quiz-submit-bar">
          <Button
            isDisabled={
              disableSubmit || (quizData.is_required && !checkAllAnswered())
            }
            buttonClass="custom-button primary-button"
            onClick={confirmSubmit}
          >
            Submit Quiz
          </Button>
          <div className="half-clearfix" />
          {quizData.display_mode === "continuous_scroll" &&
            quizData.is_required && (
              <p className="quiz-description-important">
                {t("All questions in this quiz are mandatory.")}
              </p>
            )}
        </div>
      </div>
    );
  };

  return (
    <div className="quiz-generator-container">
      {showLoadingScreen && (
        <div className="quiz-loading-gif-container">
          <img
            className="quiz-loading-gif"
            src={LoadingGif}
            alt={t("Submitting...")}
          />
          <div className="half-clearfix" />
          <p>{t("Hang on! This might take a short while.")}</p>
        </div>
      )}
      {showSummary && !showLoadingScreen && (
        <div className="quiz-summary" tabIndex={0}>
          <button className="go-back-button" onClick={onBack}>
            <i className="icon-back-arrow back-icon" />
            {t("GO BACK")}
          </button>
          <h1 className="quiz-title">{quizData.name}</h1>
          <p className="quiz-description">{quizData.description}</p>
          <p className="quiz-description">
            {t("Click on the button below to start the quiz")}
          </p>
          <div className="quiz-navigation-bar">
            <Button
              buttonClass="custom-button primary-button"
              onClick={startQuiz}
            >
              {t("Start Quiz")}
            </Button>
          </div>
          {quizData.is_required && (
            <p className="quiz-description-important">
              {t("All questions in this quiz are mandatory.")}
            </p>
          )}
        </div>
      )}
      {!showSummary && !showLoadingScreen && (
        <div className="quiz-questions">
          {quizData.display_mode === "continuous_scroll" && getAllModeHTML()}
          {quizData.display_mode === "single_question" && getSingleModeHTML()}
        </div>
      )}
      <Modal
        openModal={openQuitConfirm}
        onRequestClose={() => setOpenQuitConfirm(false)}
        buttonAction={handleQuit}
        buttonLabel={t("I want to quit")}
      >
        {t(
          "Are you sure you want to quit? Your score will be computed according to your current progress."
        )}
      </Modal>
      <Modal
        openModal={openSubmitConfirm}
        onRequestClose={() => setOpenSubmitConfirm(false)}
        buttonAction={handleSubmit}
        buttonLabel={t("I want to submit")}
      >
        {t(
          "Are you sure you want to submit? Your report will be shown after the submission."
        )}
      </Modal>
    </div>
  );
};

export default QuizGenerator;
