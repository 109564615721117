import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { authHeader } from "../../helpers/auth-header";
import useFetch from "../../helpers/remote";
import { v4 as uuidv4 } from "uuid";
import Modal from "../Modal";
import { useAuth } from "../../context/auth";
import AgentClassService from "./../../helpers/AgentClassService";

import "./style.css";
import noodleAva1 from "../../assets/images/profiles/noodleAva1.png";
import noodleAva2 from "../../assets/images/profiles/noodleAva2.png";
import noodleAva3 from "../../assets/images/profiles/noodleAva3.png";
import noodleAva4 from "../../assets/images/profiles/noodleAva4.png";
import noodleAva5 from "../../assets/images/profiles/noodleAva5.png";
import noodleAva6 from "../../assets/images/profiles/noodleAva6.png";
import noodleAva7 from "../../assets/images/profiles/noodleAva7.png";
import noodleAva8 from "../../assets/images/profiles/noodleAva8.png";
import noodleAva9 from "../../assets/images/profiles/noodleAva9.png";
import noodleAva10 from "../../assets/images/profiles/noodleAva10.png";
import noodleAva11 from "../../assets/images/profiles/noodleAva11.png";
import plus from "../../assets/images/profiles/plus.svg";

import i18n from "../Internationalisation/i18n";
const { t } = i18n;

const profileEditViews = {
  VIEW: "VIEW",
  EDIT_NAME: "EDIT_NAME",
  EDIT_AVA: "EDIT_AVA",
};
const avatarSamples = [
  {
    id: "noodleAva1",
    src: noodleAva1,
  },
  {
    id: "noodleAva2",
    src: noodleAva2,
  },
  {
    id: "noodleAva3",
    src: noodleAva3,
  },
  {
    id: "noodleAva4",
    src: noodleAva4,
  },
  {
    id: "noodleAva5",
    src: noodleAva5,
  },
  {
    id: "noodleAva6",
    src: noodleAva6,
  },
  {
    id: "noodleAva7",
    src: noodleAva7,
  },
  {
    id: "noodleAva8",
    src: noodleAva8,
  },
  {
    id: "noodleAva9",
    src: noodleAva9,
  },
  {
    id: "noodleAva10",
    src: noodleAva10,
  },
  {
    id: "noodleAva11",
    src: noodleAva11,
  },
];

const getTile = (view) => {
  switch (view) {
    case profileEditViews.VIEW:
      return t("Manage Profile");
    case profileEditViews.EDIT_NAME:
      return t("Edit your Profile");
    case profileEditViews.EDIT_AVA:
      return t("Profile Picture");
    default:
      return t("Manage Profile");
  }
};

function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

function base64ToBlob(base64, mime) {
  mime = mime || "";
  var sliceSize = 1024;
  var byteChars = window.atob(base64);
  var byteArrays = [];

  for (
    var offset = 0, len = byteChars.length;
    offset < len;
    offset += sliceSize
  ) {
    var slice = byteChars.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mime });
}

const EditProfileModal = ({
  isOpenProfileEdit,
  setIsOpenProfileEdit,
  isWidget,
}) => {
  const { t } = useTranslation("translation");
  const [profileView, setProfileView] = useState(profileEditViews.VIEW);
  const imageInputRef = useRef();
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [uploadImgs, setUploadImgs] = useState([]);
  const makeApiCall = useFetch();
  const {
    setBubbleIcon,
    access_token,
    setAgentProfileImage,
    setUserFullName,
    setUserRole,
  } = useAuth();
  const updateImage = (e) => {
    const file = e?.target?.files[0];
    if (file.type.includes("image") && file?.size < 2 * 1024 * 1024) {
      const objectURL = URL.createObjectURL(file);
      const selectedAvaId = "file_" + file?.name;
      setUploadImgs([
        ...uploadImgs,
        {
          id: selectedAvaId,
          src: objectURL,
        },
      ]);
      setSelectedAvatar(selectedAvaId);
    } else {
      alert(t("Profile avatar should is a image type and has size < 2 MB"));
    }
  };

  const saveNickNameAndImg = async () => {
    const { nickname } = userInfo;
    if (nickname && profileView === profileEditViews.EDIT_NAME) {
      try {
        setLoading(true);
        await makeApiCall.request(
          window.CHAT_API + "/api/v1/organisation/users/current",
          {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify({ nickname: nickname }),
          }
        );
        setLoading(false);
        setProfileView(profileEditViews.VIEW);
        setIsOpenProfileEdit(false);
      } catch (e) {
        setLoading(false);
        alert(t("Error when save nick name"));
      }
    }
    if (profileView === profileEditViews.EDIT_AVA && selectedAvatar) {
      const img = [...uploadImgs, ...avatarSamples].find(
        (img) => img.id === selectedAvatar
      );
      toDataURL(img.src, async (result) => {
        const jpegFile64 = result.replace(
          /^data:image\/(png|jpeg);base64,/,
          ""
        );
        const jpegBlob = base64ToBlob(jpegFile64, "image/png");

        try {
          const formData = new FormData();
          formData.append(
            "file",
            new File([jpegBlob], uuidv4() + ".png", {
              type: "image/png",
              lastModified: Date.now(),
            })
          );

          setLoading(true);
          const res = await makeApiCall.request(
            window.CHAT_API +
              "/api/v1/organisation/users/current/profile-images",
            {
              method: "POST",
              headers: {
                Authorization: "Bearer " + access_token?.access_token,
              },
              body: formData,
            }
          );
          setLoading(false);
          if (res?.data?.user?.profile_image) {
            setUserInfo({
              ...userInfo,
              profile_image: res.data.user.profile_image,
            });
            setAgentProfileImage(res.data.user.profile_image);
            setBubbleIcon(res.data.user.profile_image);
          }
          setProfileView(profileEditViews.VIEW);
          setIsOpenProfileEdit(false);
        } catch (e) {
          setLoading(false);
          alert(t("Error when save profile image"));
        }
      });
    }
  };

  useEffect(() => {
    const getUserInfo = async () => {
      if (!AgentClassService.getSelectedAgent()) {
        return;
      }

      setLoading(true);
      await makeApiCall
        .request(window.CHAT_API + "/api/v1/learning/users/current", {
          method: "GET",
          headers: authHeader(),
        })
        .then((json) => {
          setLoading(false);
          if (json?.data && json?.data?.user) {
            setUserInfo(json.data.user);
            setAgentProfileImage(json.data.user?.profile_image);
            setBubbleIcon(json.data.user?.profile_image);
            setUserFullName(json.data.user?.full_name);
            setUserRole(json.data.user?.role);
          }
        })
        .catch((e) => setLoading(false));
    };
    getUserInfo();
  }, []);

  const { nickname, email, profile_image, classes, knowledge_groups } =
    userInfo;

  if (
    isWidget ||
    (process.env && process.env.REACT_APP_TYPE === "login_widget")
  ) {
    return <></>;
  }

  return (
    <div className="profile-modal">
      <Modal
        openModal={isOpenProfileEdit}
        title={t(getTile(profileView))}
        onRequestClose={() => {
          setProfileView(profileEditViews.VIEW);
          setIsOpenProfileEdit(false);
        }}
        showCloseIcon={true}
      >
        {loading && (
          <div className="block-ui">
            <div className="spinner-border"></div>
          </div>
        )}
        <div className="mb-12 content-edit">
          <div className="is-flex is-flex-direction-column is-align-items-center">
            {profileView !== profileEditViews.EDIT_AVA && (
              <div
                className="profile-ava"
                onClick={() => setProfileView(profileEditViews.EDIT_AVA)}
              >
                <div className="image is-128x128 ">
                  <img
                    className="is-rounded"
                    src={profile_image || noodleAva1}
                    alt="avatar"
                  />
                </div>
                <div className="camera-icon"></div>
              </div>
            )}

            {profileView === profileEditViews.VIEW && (
              <>
                <div className="name">{nickname}</div>
                <div className="email"> {email}</div>
              </>
            )}
          </div>
          {profileView === profileEditViews.VIEW && (
            <div className="tag-list">
              {classes && (
                <>
                  <div className="tag-title">{t("Classes")}</div>
                  <div>
                    {classes.map((c) => (
                      <span className="tag">{c.name}</span>
                    ))}
                  </div>
                </>
              )}
              {knowledge_groups && (
                <>
                  <div className="tag-title">{t("Modules")}</div>
                  <div>
                    {knowledge_groups.map((m) => (
                      <span className="tag">{m.name}</span>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
          {profileView === profileEditViews.EDIT_NAME && (
            <div className="edit-name">
              <div className="edit-name-title">{t("Nickname")}</div>
              <input
                type="text"
                placeholder={t("Input nickname...")}
                value={nickname}
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    nickname: e.target.value,
                  });
                }}
              />
            </div>
          )}
          {profileView === profileEditViews.EDIT_AVA && (
            <div className="edit-ava">
              <div
                className="image plus is-80x80"
                onClick={() => imageInputRef.current.click()}
              >
                <img className="plus-icon" src={plus} alt="" />
                <input
                  type="file"
                  name="myImage"
                  onChange={(event) => {
                    updateImage(event);
                  }}
                  ref={imageInputRef}
                  className="is-hidden"
                  accept="image/*"
                />
              </div>
              {[...uploadImgs, ...avatarSamples].map((v) => (
                <div
                  key={v?.id}
                  className={`image is-80x80 ${
                    v?.id === selectedAvatar && "selected"
                  }`}
                  onClick={() => setSelectedAvatar(v?.id)}
                >
                  <img src={v.src} alt="avatar" />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="is-flex is-justify-content-flex-end footer">
          <button
            className="secondary"
            onClick={() => {
              setProfileView(profileEditViews.VIEW);
              setIsOpenProfileEdit(false);
            }}
          >
            {t("Cancel")}
          </button>
          {profileView !== profileEditViews.VIEW ? (
            <button className="main" onClick={() => saveNickNameAndImg()}>
              {t("Save")}
            </button>
          ) : (
            <button
              className="main"
              onClick={() => setProfileView(profileEditViews.EDIT_NAME)}
            >
              {t("Edit your Profile")}
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default EditProfileModal;
