import React from "react";
import { useTranslation } from "react-i18next";
import Ins1 from "../../assets/images/ins-1.png";
import Ins2 from "../../assets/images/ins-2.png";
import Ins3 from "../../assets/images/ins-3.png";
import Ins4 from "../../assets/images/ins-4.png";
import NoodleLaptop from "../../assets/images/noodle-laptop.png";

import { Image } from "react-bootstrap";

export const Instruction = () => {
  const { t } = useTranslation("translation");
  return (
    <div className="bg-white flex flex-col px-4">
      <div className="md:mx-auto md:max-w-[1200px]">
        <h2 className="text-[#0923E6] text-center text-2xl font-bold leading-8 mt-6">
          {t("Oops! Looks like your question board has not been setup yet")}
        </h2>
        <p className="text-black text-center text-lg leading-6 mt-2">
          {t("Follow these simple steps to configure question board on your teams channel")}
        </p>
      </div>
      <div className="md:mx-auto md:max-w-[1200px] mt-6 md:flex">
        <div className="hidden md:flex md:w-2/6 ">
         <Image className="w-full object-contain" src={NoodleLaptop} />

        </div>
        <div className="w-full md:grid md:grid-cols-2 gap-4">
          <div className="w-full bg-[#E6EDFF] flex flex-col mb-4 p-4 pb-7 px-4 rounded-2xl text-sm md:mb-0">
            <div className="text-black text-xs font-semibold leading-4">
              <span className="font-bold text-pink-600 text-lg">
                {t("Step 1: Get teams channel link")}
              </span>
              <ul className="list-disc">
                <li className="ml-5 text-base">
                  <span className="text-black">
                    {t("Click on the ellipsis icon (...) next to your preferred channel.")}
                  </span>
                </li>
                <li className="ml-5 text-base">
                  {t("Select 'Get link to team' and copy the link provided for the channel")}
                </li>
              </ul>
            </div>
            <div className="flex items-stretch justify-between gap-0 mt-8 pr-6 max-md:pr-5">
              <Image className="w-full" src={Ins1} />
            </div>
          </div>
          <div className="w-full bg-[#E6EDFF] flex flex-col mb-4 p-4 pb-7 px-4 rounded-2xl text-sm md:mb-0">
            <div className="text-black text-xs font-semibold leading-4">
              <span className="font-bold text-pink-600 text-lg">
                {t("Step 2: Login to the Web Portal")}
              </span>
              <ul className="list-disc">
                <li className="ml-5 text-base">
                  <span className="text-black">
                    {t("Login to ")}
                    <a href="https://portal.noodlefactory.ai" target="blank" className="text-[#0923E6]">
                      https://portal.noodlefactory.ai 
                    </a>
                  </span>
                </li>
              </ul>
            </div>
            <div className="flex items-stretch justify-between gap-0 mt-8 pr-6 max-md:pr-5">
              <Image className="w-full" src={Ins2} />
            </div>
          </div>
          <div className="w-full bg-[#E6EDFF] flex flex-col mb-4 p-4 pb-7 px-4 rounded-2xl text-sm md:mb-0">
            <div className="text-black text-xs font-semibold leading-4">
              <span className="font-bold text-pink-600 text-lg">
                {t("Step 3: Navigate to Class Settings")}
              </span>
              <ul className="list-disc">
                <li className="ml-5 text-base">
                  <span className="text-black">
                    {t("Head to the “Users” tab")}
                  </span>
                </li>
                <li className="ml-5 text-base">
                  {t("Select “Classes”")}
                </li>
                <li className="ml-5 text-base">
                  {t("Find the class to be linked to teams channel, or create a new one if it’s not available yet")}
                </li>
              </ul>
            </div>
            <div className="flex items-stretch justify-between gap-0 mt-8 pr-6 max-md:pr-5">
              <Image className="w-full" src={Ins3} />
            </div>
          </div>
          <div className="w-full bg-[#E6EDFF] flex flex-col mb-4 p-4 pb-7 px-4 rounded-2xl text-sm md:mb-0">
            <div className="text-black text-xs font-semibold leading-4">
              <span className="font-bold text-pink-600 text-lg">
                {t("Step 4: Add Channel Link")}
              </span>
              <ul className="list-disc">
                <li className="ml-5 text-base">
                  <span className="text-black">
                    {t("Click on “Edit Class for your preferred class")}
                  </span>
                </li>
                <li className="ml-5 text-base">
                  {t("Under the “Manage Class” section, click on “Add”")}
                </li>
                <li className="ml-5 text-base">
                  <span className="text-black">
                    {t("Select “Microsoft 365” for LMS and paste the copied link into the “Course ID” section")}
                  </span>
                </li>
                <li className="ml-5 text-base">
                  {t("Click on “Link Course” to complete the setup!")}
                </li>
              </ul>
            </div>
            <div className="flex items-stretch justify-between gap-0 mt-8 pr-6 max-md:pr-5">
              <Image className="w-full" src={Ins4} />
            </div>
          </div>
        </div>
      </div>
    
     
    </div>
  );
};
