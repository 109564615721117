import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";

import Snackbar from "../../components/Snackbar";
import EmptyBadge from "../../assets/images/empty-badge.png";

import "./style.css";

import { useTranslation } from "react-i18next";

const TrophyRoom = () => {
  const { t } = useTranslation('translation');
  const makeApiCall = useFetch();
  let history = useHistory();

  const [lockedBadges, setLockedBadges] = useState([]);
  const [allBadgesLoadPending, setAllBadgesLoadPending] = useState(false);
  const [earnedBadges, setEarnedBadges] = useState([]);
  const [earnedBadgesLoadPending, setEarnedBadgesLoadPending] = useState(false);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    getEarnedBadges();
  }, []);

  const getAllBadges = (earnedBadges) => {
    setAllBadgesLoadPending(true);
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/badge/badge-types",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          let temp = [];
          json.data.badge_types.forEach((badge) => {
            if (!isInEarnedBadges(badge._id, earnedBadges)) {
              temp.push(badge);
            }
          });
          setLockedBadges(temp);
          setAllBadgesLoadPending(false);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching badges"),
            type: "error",
          });
        }
      });
  };

  const isInEarnedBadges = (badgeId, earnedBadges) => {
    let check = false;
    earnedBadges.forEach((badge) => {
      if (badge.badge_type_id === badgeId) {
        check = true;
        return;
      }
    });
    return check;
  };

  const getEarnedBadges = () => {
    setEarnedBadgesLoadPending(true);
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/badge/badges?page=1&page_size=100",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setEarnedBadges(json.data.badges);
          setEarnedBadgesLoadPending(false);
          getAllBadges(json.data.badges);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching badges"),
            type: "error",
          });
        }
      });
  };

  return (
    <div className="trophy-room-page-container">
      <div className="page-header">
        <button
          className="header-btn flex-one pl-0"
          onClick={() => {
            history.push("/");
          }}
          role="link"
        >
          <i className="icon-left-chevron" />
          {t("Back to chat")}
        </button>
        <div className="header-heading flex-one">
          <h3 className="page-heading mb-0">{t("Trophy Room")}</h3>
        </div>
        <div className="flex-one"></div>
      </div>
      <div className="page-content">
        <h4 className="page-subheading">{t("Earned Badges")}</h4>
        {earnedBadgesLoadPending && (
          <Skeleton className="data-table-skeleton" count={4} height={100} />
        )}
        {!earnedBadgesLoadPending && earnedBadges.length === 0 && (
          <div className="badge-card">
            <div className="badge-img-wrapper">
              <img className="badge-img" src={EmptyBadge} alt={t("no badge")} />
            </div>
            <h5 className="badge-name">{t("No Badge Yet")}</h5>
            <p className="badge-description">
              {t("You haven't earned any badge yet. Fret not! Check out locked badges and find out how you can earn them.")}
            </p>
          </div>
        )}
        {!earnedBadgesLoadPending &&
          earnedBadges.length > 0 &&
          earnedBadges.map((badge) => (
            <div className="badge-card" key={badge._id}>
              <div className="badge-img-wrapper">
                <img
                  className="badge-img"
                  src={badge.badge_type.image}
                  alt={badge.badge_type.name}
                />
              </div>
              <h5 className="badge-name">
                {badge.badge_type.name.split("_").join(" ")}
              </h5>
              <p className="badge-description">
                {badge.badge_type.description}
              </p>
              <p className="badge-date">
                <Moment format="DD/MM/YYYY HH:mm" utc local>
                  {badge.created_at}
                </Moment>
              </p>
            </div>
          ))}
        <div className="clearfix" />
        <h4 className="page-subheading">{t("Locked Badges")}</h4>
        {allBadgesLoadPending && (
          <Skeleton className="data-table-skeleton" count={4} width={100} />
        )}
        {!allBadgesLoadPending && lockedBadges.length === 0 && (
          <div>{t("No badge")}</div>
        )}
        {!allBadgesLoadPending &&
          lockedBadges.length > 0 &&
          lockedBadges.map((badge) => (
            <div className="badge-card" key={badge._id}>
              <div className="badge-img-wrapper">
                <img
                  className="badge-img locked-badge-img"
                  src={badge.image}
                  alt={badge.name}
                />
              </div>
              <h5 className="badge-name">{badge.name.split("_").join(" ")}</h5>
              <p className="badge-description">{badge.description}</p>
            </div>
          ))}
      </div>
      <Snackbar
        isOpen={snackbar.isOpen}
        primaryMessage={snackbar.message}
        type={snackbar.type}
        onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
      />
    </div>
  );
};

export default TrophyRoom;
