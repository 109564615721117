import React, { forwardRef, useState } from "react";

import "./style.css";
import ConversationClearModal from "../SessionEndModal";
import Button from "../../components/Button";
import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";

const ChatInput = forwardRef(
  (
    {
      placeholder,
      isDisabled,
      onKeyPress,
      onSend,
      value,
      onChange,
      avatarIcon,
      onCustomButtonClick,
      onChatInputFocus,
      onChatInputBlur,
      customButtonData,
      disableCustomButton,
      isConversationalQuizActive,
      max,
      name,
      usetextArea,
      min,
      inputType,
    },
    ref
  ) => {
    const { t } = useTranslation("translation");
    return (
      <div className="chat-input-container">
        {/* <div className="avatar-container">
        <img src={avatarIcon} alt="User" />
      </div> */}
      <div className="input-container">
        {!usetextArea && (
          <input
            // id="chat-input"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="input"
            placeholder={placeholder}
            disabled={isDisabled}
            type="text"
            onKeyPress={onKeyPress}
            onFocus={onChatInputFocus}
            onBlur={onChatInputBlur}
            ref={ref}
            // rows="1"
          />
        )}
        {usetextArea && (
          <TextareaAutosize
            // required={required}
            inputRef={ref}
            className="input"
            type={inputType}
            value={value}
            placeholder={t(placeholder)}
            onChange={(e) => onChange(e.target.value, name)}
            disabled={isDisabled}
            onKeyPress={onKeyPress}
            // onKeyUp={onKeyUp}
            minRows={min ? min : 1}
            maxRows={max}
            // id={id}
            // autoFocus={autoFocus}
            onFocus={onChatInputFocus}
            onBlur={onChatInputBlur}
            // onPaste={onPaste}
            // tabIndex={tabIndex}
            // spellCheck={spellCheck}
          />
        )}
      </div>
      <div className="action-container">
        <button
          className="send-button"
          onClick={onSend}
          type="button"
          aria-label={t("send")}
          disabled={value.length === 0 || isDisabled}
        >
          <i className="icon-send" />
        </button>
        {customButtonData &&
          customButtonData.length > 0 &&
          customButtonData.map((button) => (
            <button
              className="admin-custom-btn"
              title={button.label}
              onClick={() => onCustomButtonClick(button, "custom")}
              type="button"
              disabled={disableCustomButton || isConversationalQuizActive}
            >
              {button.label}
            </button>
          ))}
      </div>
    </div>
  );
});

export default ChatInput;
