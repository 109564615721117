import React from "react";

import "./style.css";

const HeaderTabs = ({ tabList, onChange, selectedIndex }) => {
  return (
    <div className="header-tabs-wrapper">
      {tabList.map((tab, index) => (
        <button
          key={tab.label}
          className={
            selectedIndex === index
              ? "header-tab-active header-tab"
              : "header-tab"
          }
          onClick={() => onChange(index)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

HeaderTabs.defaultProps = {
  tabList: [],
};

export default HeaderTabs;
