import React, { useEffect, useState } from "react";
import IconLabelButton from "../IconLabelButton";

import TextInput from "../TextInput";

import "./style.css";

import { useTranslation } from "react-i18next";

const QuizTableEditor = ({
  disableHeader,
  headerValues,
  onHeaderChange,
  onTableChange,
  rowCount,
  columnCount,
  enableColumnHeader,
  updateEnableColumnHeader,
  columnHeaderValues,
  spellCheck,
  tableValues
}) => {
  const { t } = useTranslation('translation');
  const [tableMatrix, setTableMatrix] = useState([["", ""]]);
  const [tableHeader, setTableHeader] = useState(
    headerValues ? headerValues : ["", ""]
  );
  const [isColumnHeader, setIsColumnHeader] = useState(enableColumnHeader);
    

  useEffect(() => {
    if (rowCount && columnCount) {
      let temp = new Array(rowCount).fill(new Array(columnCount).fill(""));
      setTableMatrix(temp);
      if (typeof headerValues !== 'undefined' && headerValues.length === columnCount) {
        let temp = [...headerValues];
        temp.unshift("");
        setTableHeader(temp);
      }
    }
  }, []);

  useEffect(() => {
    onTableChange(tableMatrix);
  }, [tableMatrix]);

  useEffect(() => {
    if (tableValues && tableValues.isAnswered && tableValues.text && tableValues.text.length ) {
      setTableMatrix(tableValues.text)
      onTableChange(tableValues.text);
    }
  }, []);

  const onHeaderCellChange = (value, headerIndex) => {
    let temp = [...tableHeader];
    temp[headerIndex] = value;
    setTableHeader(temp);
  };

  const onRowCellChange = (value, rowIndex, rowCellIndex) => {
    let temp = [...tableMatrix];
    let temp1 = [...temp[rowIndex]];
    temp1[rowCellIndex] = value;
    temp[rowIndex] = temp1;
    setTableMatrix(temp);
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      return;
    }
  };

  return (
    <div>
      <div className="flex-container no-justify-flex">
        <div className="quiz-table-editor-wrapper">
          <table className="quiz-table">
            <thead className="quiz-table-editor-header-row">
              <tr>
                {tableHeader &&
                  tableHeader.map((headerName, index) => (
                    <th
                      key={index.toString()}
                      className="quiz-table-header-disabled-cell"
                      scope="col"
                      id={`col-header-${index}`}
                    >
                      {headerName}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="quiz-table-editor-body-wrapper">
              {tableMatrix &&
                tableMatrix.map((tableRow, rowIndex) => (
                  <tr key={rowIndex} className="quiz-table-editor-row">
                    <th className="column-header-cell-wrapper" scope="row" id={`row-header-${rowIndex}`}>
                      {columnHeaderValues[rowIndex]
                        ? columnHeaderValues[rowIndex]
                        : ""}
                    </th>
                    {tableRow.map((tableCell, rowCellIndex) => (
                      <td className={"quiz-table-row-cell-wrapper"}>
                        <TextInput
                          spellCheck={spellCheck}
                          useTextArea
                          id={`row${rowIndex.toString()}${rowCellIndex.toString()}`}
                          key={`row${rowIndex.toString()}${rowCellIndex.toString()}`}
                          value={tableCell}
                          inputChange={(event) =>
                            onRowCellChange(
                              event.target.value,
                              rowIndex,
                              rowCellIndex
                            )
                          }
                          min={2}
                          placeholder={t("Type text here")}
                          onKeyPress={handleEnter}
                          ariaLabelledby={`row-header-${rowIndex} col-header-${rowCellIndex + 1}`}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default QuizTableEditor;
