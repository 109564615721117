import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/auth";

import { authHeader } from "../../helpers/auth-header";
import useFetch from "../../helpers/remote";

import "./style.css";

import { useTranslation } from "react-i18next";
import { Card, Image } from "react-bootstrap";

import backimg from "../../assets/images/new-images/chevron-left-blk.svg";
import chevblue from "../../assets/images/new-images/chevron-right-blue.svg";

const OrganisationSwitcher = (props) => {
  const { t } = useTranslation("translation");
  const { allOrganisations, setShowOrgSelection } = useAuth();

  const makeApiCall = useFetch();

  const [currentOrganisation, setCurrentOrganisation] = useState(null);

  useEffect(() => {
    getCurrentOrg();
  }, []);

  const getCurrentOrg = () => {
    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/organisations/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          setCurrentOrganisation(json.data.organisation._id || null);
        }
      });
  };

  return (
    <section className="mt-5 choose-organisation">
      <div className="container-md">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex align-items-center mb-4">
              <button
                className="btn p-0 mr-3"
                onClick={() => {
                  setShowOrgSelection(false);
                }}
              >
                <Image src={backimg} />
              </button>

              <h1 className="font-weight-bold mb-0">
                {t("Change Organisation")}
              </h1>
            </div>
            <div className="font-18-px font-600 mb-5">
              {t("Choose the organisation you wish to switch to.")}
            </div>

            <div className="accordion-viewby scroll-wrap accordion-org">
              {(allOrganisations || []).map((item, index) => {
                return (
                  <Card className="mb-3">
                    <Card.Header className="d-flex px-4 py-2 justify-content-between align-items-center">
                      <span
                        className={`d-flex font-weight-bold align-items-center`}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            backgroundColor: "#CDD5EF",
                            textAlign: "center",
                            lineHeight: "40px",
                            overflow: "hidden",
                          }}
                          className="d-flex align-items-center"
                        >
                          <Image
                            src={item?.settings?.webchat?.company_logo}
                            alt="Org"
                          />
                        </div>
                        <div className="pl-3">
                            <p className="clr-pink font-weight-bold w-100 m-0">
                            { currentOrganisation === item?._id ? t("Current Selected"): ""}
                            </p>
                          {item.name}
                        </div>
                      </span>
                      {currentOrganisation !== item?._id && window.APP_BASE_DOMAIN && (
                        <button
                          className="d-flex btn pr-4 align-items-center "
                          onClick={() => {
                            window.location.href = `https://${item.subdomain}.${window.APP_BASE_DOMAIN}`;
                          }}
                        >
                          {t("Switch Organisation")}
                          <img
                            className="pl-3"
                            src={chevblue}
                            alt="cheveron-blue"
                          />
                        </button>
                      )}
                    </Card.Header>
                  </Card>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrganisationSwitcher;
