import React, { useEffect, useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

import "./style.css";

import { useTranslation } from "react-i18next";

const DatePicker = (props) => {
  const { t } = useTranslation("translation");
  const [selectedDays, setSelectedDays] = useState([]);
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    if (Array.isArray(props.value)) {
      setSelectedDays(props.value.map(dateString => new Date(dateString)));

      props.onChange([...selectedDays]);
    }
  }, []);

  const handleDayClick = (day, { selected }) => {
    const allDays = [...selectedDays];
    if (selected) {
      const selectedIndex = allDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      allDays.splice(selectedIndex, 1);
    } else {
      allDays.push(day);
    }
    setSelectedDays(allDays);
    props.onChange(allDays);
  };

  const resetDatePicker = () => {
    setSelectedDays([]);
  };

  const onDateDummyInputChange = (event) => {
    event.preventDefault();
  };

  const removeDate = (index) => {
    const filtered = selectedDays.filter((_, _index) => index !== _index);
    setSelectedDays(filtered);
    props.onRemoveDate && props.onRemoveDate(filtered);
  };

  const prettifyDate = (date) => {
    var month = date.getUTCMonth() + 1; //months from 1-12
    var day = date.getUTCDate();
    var year = date.getUTCFullYear();

    return day + "/" + month + "/" + year;
  };

  return (
    <div className="date-picker-container">
      <div
        className="date-picker-input-wrapper"
        onClick={() => setShowPicker(true)}
      >
        <input
          type="text"
          className="date-picker-button"
          placeholder={t("Select date(s)")}
          required={props.required ? props.required : false}
          value={selectedDays.length === 0 ? "" : "Select date(s)"}
          onChange={onDateDummyInputChange}
        />
        <span className="date-picker-input-helptext">
          {t("Select date(s)")}
        </span>
        <span className="date-picker-icon-wrapper" aria-label={t("Select date(s)")}>
          <i className="icon-calendar" />
        </span>
      </div>
      <div className="selected-dates-preview">
        {selectedDays &&
          selectedDays.map((day, index) => (
            <span className="selected-date-preview-item">
              {prettifyDate(day)}{" "}
              <button
                type="button"
                title="Remove"
                onClick={() => removeDate(index)}
                className="selected-date-preview-item-delete"
              >
                <i className="icon-close" />
              </button>
            </span>
          ))}
      </div>
      {showPicker && (
        <div className="date-picker-popover">
          <div className="display-cell">
            <DayPicker
              selectedDays={selectedDays}
              onDayClick={handleDayClick}
            />
            <div className="date-picker-popover-actions">
              <button
                type="button"
                className="close-date-picker-button"
                onClick={() => setShowPicker(false)}
              >
                {t("DONE")}
              </button>
              <button
                type="button"
                className="reset-date-picker-button"
                onClick={resetDatePicker}
              >
                {t("RESET")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
