import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { draftjsToMd } from "draftjs-md-converter";
import { EditorState, convertToRaw } from "draft-js";

import QuizTableEditor from "../QuizTableEditor";
import MarkdownEditor from "../MarkdownEditor";
import Radio from "../Radio";
import Checkbox from "../Checkbox";
import Button from "../Button";
import UserChatBubble from "../UserChatBubble";
import BulletEditor from "../BulletEditor";
import UserAvatarIcon from "../../assets/images/user-av.png";
import DragMatch from "../DragMatch";
import FileUpload from "../FileUpload";

import "./style.css";
import LoadingAnimation from "../LoadingAnimation";
import HeaderDescriptionEditor from "../HeaderDescriptionEditor";
import clearFormatting from "draft-js-clear-formatting";
// import SubQuestion from "./subQuestion";
import TextInput from "../../components/TextInput";
import FormDropdown from "../../components/FormDropdown";
import Dropdown from "../../components/Dropdown";

import { useTranslation } from "react-i18next";
import DatePicker from "../DatePicker";
import RatingInput from "../../components/RatingInput";
import { string } from "prop-types";
import * as _ from "lodash";

const FormBubble = ({
  // isLoading,
  formLayout,
  avatarIcon,
  updateScrollbar,
  submitButtonLabel,
  onSubmit,
  formId,
  displayMode,
}) => {
  const { t } = useTranslation("translation");
  const [textInputValue, setTextInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isAnswered, setIsAnswered] = useState(false);
  const optionsMap = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tableInputValue, setTableInputValue] = useState([["", ""]]);
  const [bulletArray, setBulletArray] = useState([""]);
  const [enterComplete, setEnterComplete] = useState({
    status: false,
    identifier: "",
    index: "",
  });
  const [matchAnswer, setMatchAnswer] = useState([]);
  const [groupAnswersArray, setGroupAnswersArray] = useState([]);
  const [field, setField] = useState(null);
  const [properties, setProperties] = useState([]);
  const [formData, setFormData] = useState({});
  const [allFields, setAllFields] = useState([]);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(null);
  const [values, setValues] = useState([]);
  const [fieldsData, setFieldsData] = useState({});
  const [isBtnDisable, setIsBtnDisable] = useState(true);

  const [checkboxOptions, setCheckboxOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (formLayout && "formSchema" in formLayout) {
      setProperties(formLayout.formSchema.properties);
    }
    if (
      formLayout &&
      "fieldList" in formLayout &&
      formLayout.fieldList.length > 0
    ) {
      setAllFields(formLayout.fieldList);
      onFieldChange(formLayout.fieldList, 0);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  const handleKeyPress = (e) => {
    const btn = document.getElementById("enter-send-button");
    if (btn && (e.key === "Enter" || e.keyCode === 13) && !e.shiftKey) {
      btn.click();
    }
  };

  const onFieldChange = (fieldList, index) => {
    setField(fieldList[index]);
    setSelectedFieldIndex(index);
    setIsBtnDisable(true);
    setSelectedOptions([]);
    // setCheckboxOptions({});
    if (fieldList && fieldList[index]) {
      createFields(
        fieldList,
        index,
        Object.keys(formLayout.formSchema.properties)[index]
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      updateScrollbar();
    }, 2000);
  }, []);

  useEffect(() => {
    if (fieldsData && field) {
      let temp = { ...fieldsData };
      let currentField = temp[field.id];
      let val = "values" in currentField ? currentField.values : null;
      if (Array.isArray(val)) {
        validatingInputs(val, currentField);
        return;
      } else if (typeof val === "object" && "values" in currentField) {
        validatingInputs(
          { value: currentField.values, label: currentField.label },
          currentField
        );
        return;
      } else {
        validatingInputs(val, currentField);
        return;
      }
    }
  }, [fieldsData]);

  const createFields = (fieldList, index, property) => {
    let data = Object.keys(fieldsData);
    const isPresent = data.indexOf(fieldList[index].id);

    if (isPresent < 0) {
      const temp = {
        [fieldList[index].id]: {
          ...fieldList[index],
          property,
          inputType: fieldList[index]?.inputType?.value
            ? fieldList[index]?.inputType?.value
            : fieldList[index].fieldType,
        },
      };
      if (fieldList[index].fieldType === "checkboxes") {
        setCheckboxOptions(
          fieldList[index]?.items?.enum
            ? fieldList[index]?.items?.enum?.map((item, index) => {
                return { value: item, label: item, index: index };
              })
            : []
        );
      }
      setFieldsData({ ...fieldsData, ...temp });
    }

    updateScrollbar();
  };

  const onQuizDone = () => {
    setIsLoading(true);
    onSubmit(formId, { formData: formData }, displayMode);
  };

  const getBotFormLabelHtml = (field, index) => {
    let temp = { ...fieldsData };
    let currentField = temp[field.id];
    return (
      <div className="quiz-question-frame">
        <label>
          <div>{currentField.label}</div>
          <p>{currentField.description}</p>
        </label>
      </div>
    );
  };

  const getBotFormInputHtml = (field, index) => {
    // If function receives parameters, use the params. Else, use questionData variable.
    let temp = { ...fieldsData };
    let currentField = temp[field.id];
    let countryOptions = [];

    if (currentField.fieldType === "select") {
      if (
        currentField.hasOwnProperty("isCountry") &&
        currentField?.isCountry === true
      ) {
        for (const key in properties) {
          if (key === currentField?.property) {
            countryOptions = properties[key]?.enum?.map((e) => {
              return {
                label: e,
                value: e,
              };
            });
          }
        }
      }
    }

    if (currentField.fieldType === "text") {
      return (
        <div className="quiz-text-input-frame">
          <TextInput
            inputType={
              currentField?.inputType?.value === "email"
                ? "email"
                : currentField.fieldType
            }
            placeholder={currentField.placeholder}
            required={currentField.required}
            inputChange={(e) =>
              onSetFormValues(e.target.value, currentField.id)
            }
            value={currentField?.values}
            autoFocus
          />
        </div>
      );
    } else if (currentField.fieldType === "textarea") {
      return (
        <div className="quiz-text-input-frame">
          <TextInput
            inputType="text"
            id="text-area-input-field"
            placeholder={currentField.placeholder}
            required={currentField.required}
            useTextArea={true}
            inputChange={(e) => {
              onSetFormValues(e.target.value, currentField.id);
            }}
            value={currentField?.values}
            autoFocus
          />
        </div>
      );
    } else if (currentField.fieldType === "select") {
      return (
        <div className="quiz-text-input-frame">
          <FormDropdown
            // onChange={}
            options={
              currentField?.isCountry
                ? countryOptions
                : currentField?.items.enum.map((e) => {
                    return {
                      label: e,
                      value: e,
                    };
                  })
            }
            isMandatory={currentField.required}
            onChange={(value) => onSetFormValues(value, currentField.id)}
            value={currentField.values}
            dropdownContainerClassName="form-bubble-zindex"
          />
        </div>
      );
    } else if (currentField.fieldType === "radio") {
      return (
        <div className="quiz-text-input-frame">
          <Radio
            isQuiz
            options={{
              enumOptions: currentField?.items?.enum?.map((item, index) => {
                return { value: item, label: item };
              }),
            }}
            id={currentField.id}
            onChange={(value) => onSetFormValues(value, currentField.id)}
            selectedValues={currentField?.values}
          />
        </div>
      );
    } else if (currentField.fieldType === "checkboxes") {
      return (
        <>
          <div className="quiz-text-input-frame">
            <Checkbox
              isQuiz
              options={{ enumOptions: checkboxOptions }}
              id={currentField.id}
              onChange={(checked, value, index) =>
                onCheckboxChange(
                  checked,
                  value,
                  index,
                  currentField && currentField.is_searchable ? true : false
                )
              }
              selectedValues={currentField?.values}
              isSearchable={currentField?.is_searchable}
              placeHolder="Start typing to see matching results in the dropdown."
            />
          </div>
          {currentField?.is_searchable && (
            <div className="checkbox-options-container">
              <div className="selected-option-label">
                {t("Selected Options")}
              </div>

              {selectedOptions &&
                selectedOptions.length > 0 &&
                // selectedOptions.value &&
                selectedOptions.map((item) => {
                  return (
                    <div className="checkbox-options">
                      <p className="option-label">{item.value}</p>
                      <div
                        className="close-btn"
                        onClick={() =>
                          onCheckboxChange(false, item.value, item.index, true)
                        }
                      >
                        <i className="icon-close" />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </>
      );
    } else if (currentField.fieldType === "date") {
      return (
        <div className="quiz-text-input-frame">
          <DatePicker
            onChange={(value) => {
              onSetFormValues(value, currentField.id);
            }}
            onRemoveDate={(value) => {
              onSetFormValues(value, currentField.id);
            }}
          />
        </div>
      );
    } else if (currentField.fieldType === "RatingWidget") {
      return (
        <div className="quiz-text-input-frame">
          <RatingInput
            options={{
              enumOptions: currentField?.items.enum.map((item) => {
                return { value: item, label: item };
              }),
            }}
            onChange={(value) => {
              onSetFormValues(value, currentField.id);
            }}
          ></RatingInput>
        </div>
      );
    } else if (currentField.fieldType === "data-url") {
      return (
        <div className="quiz-text-input-frame">
          <FileUpload
            id="file-input-field"
            onChange={(value) => {
              onSetFormValues(
                {
                  value: { data: value.data, file_name: value.file_name },
                  label: currentField.label,
                },
                currentField.id,
                "file-input-field"
              );
            }}
          />
        </div>
      );
    }
  };

  const onCheckboxChange = (
    checked,
    value,
    index = 0,
    isSearchable = false
  ) => {
    let temp = { ...fieldsData };
    let currentField = temp[field.id];
    let tempOptions = "values" in currentField ? [...currentField.values] : [];
    if (checked) {
      if (isSearchable) {
        tempOptions = handleSelectedValues(value, index);
      } else {
        tempOptions.push(value);
      }
    } else if (!checked) {
      if (isSearchable) {
        tempOptions = handleRemoveSelectedValues(currentField, value, index);
      } else {
        tempOptions = removeValue(tempOptions, value);
      }
    }
    onSetFormValues(tempOptions, currentField.id);
  };

  const handleSelectedValues = (value, index) => {
    const selectOpt = [...selectedOptions];
    selectOpt.push({ value: value, index: index });
    setSelectedOptions(selectOpt);
    const options = [...checkboxOptions];
    setCheckboxOptions(options.filter((i) => i.index !== index));
    const list = _.orderBy(selectOpt, "index", "asc");
    return list;
  };

  const handleRemoveSelectedValues = (currentField, value, index) => {
    const selectOpt = [...selectedOptions];
    setSelectedOptions(selectOpt.filter((i) => i.index !== index));
    const optionsList = [...checkboxOptions];
    optionsList.push({
      value: value,
      label: currentField?.items?.enum[index],
      index: index,
    });
    setCheckboxOptions(_.orderBy(optionsList, "index", "asc"));
    return selectOpt;
  };

  const removeValue = (list, value) => {
    let newList = [...list];
    for (let i = 0; i < newList.length; i++) {
      if (newList[i] === value) {
        newList.splice(i, 1);
        i--;
      }
    }
    return newList;
  };

  const checkDropdownData = (item) => {
    if (item instanceof Object && item.value && item.label) {
      return item.value;
    } else if (item instanceof Object && item.value && item.index) {
      return item;
    } else if (item instanceof Date) {
      let month = item.getUTCMonth() + 1; //months from 1-12
      let day = item.getUTCDate();
      let year = item.getUTCFullYear();
      return day + "/" + month + "/" + year;
    } else {
      return item;
    }
  };

  function clearFileInput(id) {
    let oldInput = document.getElementById(id);
    if (oldInput?.type === "file") {
      oldInput.blur();
    }
    return;
  }

  const onSetFormValues = (value, name, id = "") => {
    let temp = { ...fieldsData };
    let val = value;
    let currentField = Object.keys(temp).find((e) => e === name);
    if (typeof val === "number") {
      val = val.toString();
    }
    // validatingInputs(val, temp[currentField]);
    if (val instanceof Array) {
      let v = [];
      if (val && val.length > 0) {
        val.forEach((item) => {
          v.push(checkDropdownData(item));
        });
      }
      temp[currentField].values = v;
    } else {
      temp[currentField].values = checkDropdownData(val);
    }

    setFieldsData(temp);
    clearFileInput(id);
  };

  const validatingInputs = (item, field) => {
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    if (item instanceof Array) {
      if (item && item.length > 0 && field?.required === true) {
        setIsBtnDisable(false);
      } else if (
        item &&
        item.length > 0 &&
        (!field.hasOwnProperty("required") ||
          (field.hasOwnProperty("required") && field?.required === false))
      ) {
        setIsBtnDisable(false);
      } else if (
        !field.hasOwnProperty("required") ||
        (field.hasOwnProperty("required") && field?.required === false)
      ) {
        setIsBtnDisable(false);
      } else {
        setIsBtnDisable(true);
      }
    } else if (item instanceof Object && item.value && item.label) {
      if (
        item.value &&
        item.value?.data &&
        item.value?.file_name &&
        field?.required === true
      ) {
        setIsBtnDisable(false);
      } else if (
        item.value &&
        item.value?.data &&
        item.value?.file_name &&
        (!field.hasOwnProperty("required") ||
          (field.hasOwnProperty("required") && field?.required === false))
      ) {
        setIsBtnDisable(false);
      } else if (
        !field.hasOwnProperty("required") ||
        (field.hasOwnProperty("required") && field?.required === false)
      ) {
        setIsBtnDisable(false);
      } else {
        setIsBtnDisable(true);
      }
    } else {
      if (
        item &&
        item.trim().length !== 0 &&
        field.inputType !== "email" &&
        field?.required === true
      ) {
        setIsBtnDisable(false);
      } else if (
        item &&
        item.trim().length > 0 &&
        field.inputType === "email"
      ) {
        const valid = emailRegex.test(item);
        if (field?.required === true && valid === true) {
          setIsBtnDisable(false);
        } else if (field?.required === false && valid === true) {
          setIsBtnDisable(false);
        } else {
          setIsBtnDisable(true);
        }
      } else if (
        item &&
        item.length !== 0 &&
        field.inputType === "select" &&
        field.required === true
      ) {
        setIsBtnDisable(false);
      } else if (
        item &&
        item.trim().length !== 0 &&
        !field.hasOwnProperty("required")
      ) {
        setIsBtnDisable(false);
      } else if (
        (field.hasOwnProperty("required") && field?.required === false) ||
        !field.hasOwnProperty("required")
      ) {
        setIsBtnDisable(false);
      } else {
        setIsBtnDisable(true);
      }
    }
  };

  const getFormSubmitHtml = (field, index) => {
    let temp = { ...fieldsData };
    let currentField = temp[field.id];
    return (
      <div className="quiz-text-input-action">
        <button
          id="enter-send-button"
          disabled={isBtnDisable}
          className="send-button"
          aria-label="send"
          onClick={() => onAnswerSubmit(field, index)}
          type="button"
        >
          <i className="icon-send" />
        </button>
      </div>
    );
  };

  const onAnswerSubmit = (field, index) => {
    let data = { ...formData };
    let temp = { ...fieldsData };
    let currentField = temp[field.id];
    let values = currentField.values;
    if (typeof values === "string") {
      values = values.trim();
    }
    if (
      currentField &&
      currentField.fieldType === "checkboxes" &&
      currentField.is_searchable &&
      currentField.is_searchable === true
    ) {
      values = currentField.values.map((e) => e.value);
    }
    if (currentField.property in data) {
      data[currentField.property] = values;
    } else {
      data = {
        [currentField.property]: values,
      };
    }
    setFormData({ ...formData, ...data });
    onFieldChange(allFields, selectedFieldIndex + 1);
    setIsAnswered(true);
  };

  return (
    <>
      <div className={"bot-quiz-bubble-wrapper"}>
        {isLoading && (
          <div className="bot-bubble">
            <div className="bot-chat-avatar-container">
              <img className="bot-avatar-img" src={avatarIcon} />
            </div>
            <div className="loading-bubble">
              <LoadingAnimation />
            </div>
          </div>
        )}

        {!isLoading && (
          <div>
            <div className="bot-bubble">
              <div className="bot-chat-avatar-container">
                <img className="bot-avatar-img" src={avatarIcon} />
              </div>
              {allFields[selectedFieldIndex] && (
                <div className="bot-quiz-bubble-container">
                  {getBotFormLabelHtml(field, selectedFieldIndex)}
                </div>
              )}
              {!allFields[selectedFieldIndex] && (
                <div className="bot-quiz-bubble-container">
                  <div className="quiz-question-frame">
                    {t("You are all done. Click Done to submit your answers.")}
                  </div>
                </div>
              )}
            </div>
            {allFields[selectedFieldIndex] && (
              <>
                <div className="bot-quiz-input-container">
                  {getBotFormInputHtml(field, selectedFieldIndex)}
                  {getFormSubmitHtml(field, selectedFieldIndex)}
                </div>
              </>
            )}
            {!allFields[selectedFieldIndex] && (
              <div className="bot-quiz-input-container">
                <div className="quiz-text-input-frame">
                  <Button
                    buttonClass="custom-button primary-button"
                    onClick={onQuizDone}
                  >
                    {submitButtonLabel}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div></div>
      <div></div>
    </>
  );
};

export default FormBubble;
