import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";
import Skeleton from "react-loading-skeleton";

import NotificationItem from "../../components/NotificationItem";
import IconLabelButton from "../../components/IconLabelButton";

import Snackbar from "../../components/Snackbar";

import "./style.css";

import { useTranslation } from "react-i18next";

const AllNotifications = () => {
  const { t } = useTranslation('translation');
  const makeApiCall = useFetch();
  let history = useHistory();

  const [allNotifications, setAllNotifications] = useState([]);
  const [loadPending, setLoadPending] = useState(false);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    getAllNotifications();
  }, []);

  const getAllNotifications = () => {
    setLoadPending(true);
    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/notification/notifications?mail=inbox&page_size=1000",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setAllNotifications(json.data.notifications);
          setLoadPending(false);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching notifications"),
            type: "error",
          });
        }
      });
  };

  const onClick = (link, id, isAnchor=false) => {
    markAsRead(id);
    if (link !== "" && link !== null && !isAnchor) {
      window.open(link, "_newtab");
    }
  };

  const onDeleteNotification = (event, key) => {
    event.stopPropagation();
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + key,
        {
          method: "DELETE",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          getAllNotifications();
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Unable to delete notification: ") + json.status.message,
            type: "error",
          });
        }
      });
  };

  const onCheckboxChange = (event, key, isRead) => {
    event.stopPropagation();

    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + key,
        {
          method: "PATCH",
          headers: authHeader(),
          body: JSON.stringify({
            is_read: isRead ? false : true,
          }),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          getAllNotifications();
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Unable to update notification: ") + json.status.message,
            type: "error",
          });
        }
      });
  };

  const markAllRead = () => {
    let notifications = [...allNotifications];

    let allRequests = [];

    notifications.forEach((notification, i) => {
      if (notification.is_read === false) {
        allRequests.push(markAsRead(notification));
      }
    });

    Promise.all(allRequests).then(() => {
      getAllNotifications();
    });
  };

  const markAsRead = (notification) => {
    return new Promise((resolve, reject) => {
      makeApiCall
        .request(
          window.CHAT_API +
            "/api/v1/notification/notifications/" +
            notification._id,
          {
            method: "PATCH",
            headers: authHeader(),
            body: JSON.stringify({
              is_read: notification.isRead ? false : true,
            }),
          },
          true
        )
        .then((json) => {
          if (json.status.code === 200) {
            resolve();
          } else {
            setSnackbar({
              isOpen: true,
              message: t("Unable to update notification: ") + json.status.message,
              type: "error",
            });
          }
        });
    });
  };

  return (
    <div className="all-notifications-page-container">
      <div className="page-header">
        <button
          className="header-btn flex-one"
          onClick={() => {
            history.push("/");
          }}
        >
          <i className="icon-left-chevron" />
          {t("Back to chat")}
        </button>
        <div className="header-heading flex-one">
          <h3 className="page-heading">{t("All Notifications")}</h3>
        </div>
        <div className="flex-one"></div>
      </div>
      <div className="page-content">
        {loadPending && (
          <Skeleton className="data-table-skeleton" count={4} height={100} />
        )}
        {!loadPending && (
          <div className="bulk-action-container">
            <IconLabelButton
              onClick={markAllRead}
              buttonLabel={t("Mark all as read")}
            />
          </div>
        )}
        {!loadPending &&
          allNotifications.map((notification, index) => (
            <NotificationItem
              // showFullDescription
              index={index}
              key={notification._id}
              content={notification}
              onClick={(e) => {
                onClick(notification.link, notification, e?.target?.tagName?.toLowerCase() === 'a')
              }}
              onDelete={(event) =>
                onDeleteNotification(event, notification._id)
              }
              onCheckboxChange={(event) =>
                onCheckboxChange(event, notification._id, notification.is_read)
              }
              showPointer={notification.link ? true : false}
            ></NotificationItem>
          ))}
      </div>
      <Snackbar
        isOpen={snackbar.isOpen}
        primaryMessage={snackbar.message}
        type={snackbar.type}
        onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
      />
    </div>
  );
};

export default AllNotifications;
