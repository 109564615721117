import React from "react";

import "./style.css";

const BotChatBubble = ({
  avatarIcon,
  messageId,
  message,
  messageTime,
  feedback,
  showFeedback,
  recordFeedback,
  bookmarkAction,
  copyAction,
}) => {
  const bubbleRender = (element) => {
    return (
      <div className="bot-chat-bubble-container">
        <div className="bot-chat-bubble">
          <div className="bot-chat-avatar-container">
            <img className="bot-avatar-img" src={avatarIcon} />
          </div>
          <div className="bot-chat-message-container">
            {typeof element !== "object" ? (
              <div
                id={messageId}
                className="bot-chat-message"
                dangerouslySetInnerHTML={{
                  __html: element.replace(/\^/gim, "<br>"),
                }}
              ></div>
            ) : (
              <div id={messageId} className="bot-chat-message">
                {element}
              </div>
            )}
            <div className="more-menu-wrapper">
              <button
                className="more-menu-button"
                onClick={() => bookmarkAction("bot", element)}
                title="Menu"
              >
                <i className="icon-star-outline"></i>
              </button>
            </div>
          </div>
          {showFeedback && (
            <div className="bot-reply-feedback-container">
              <i
                className={`icon-thumbs-up bot-reply-feedback-icon${
                  feedback === 1 ? " bot-reply-feedback-icon-selected" : ""
                }`}
                onClick={() => recordFeedback(1, messageId)}
              />
              <i
                className={`icon-thumbs-down bot-reply-feedback-icon${
                  feedback === 0 ? " bot-reply-feedback-icon-selected" : ""
                }`}
                onClick={() => recordFeedback(0, messageId)}
              />
            </div>
          )}
        </div>
        <div className="bot-chat-time">{messageTime}</div>
      </div>
    );
  };

  if (
    message &&
    message.toString() &&
    ((message.toString().match(/<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/) &&
      message.toString().match(/<img.*?src="(.*?)"[^>]+>/)) ||
      message.toString().match(/<iframe.*>.*<\/iframe>/))
  ) {
    let matchedString = message
      .toString()
      .split(/(<a.*>.*<\/a>)|(<iframe.*>.*<\/iframe>)/g);
    return matchedString.map((element) => {
      if (element && element.trim()) {
        if (
          element.match(/<img.*?src="(.*?)"[^>]+>/) ||
          element.match(/<iframe.*>.*<\/iframe>/)
        ) {
          return (
            <div className="bot-chat-bubble-container">
              <div className="bot-chat-message-container">
                {typeof element !== "object" ? (
                  <div
                    id={messageId}
                    className="bot-chat-media-message"
                    dangerouslySetInnerHTML={{
                      __html: element.replace(/\^/gim, "<br>"),
                    }}
                  ></div>
                ) : (
                  <div id={messageId} className="bot-chat-media-message">
                    {element}
                  </div>
                )}
                <div className="bot-chat-time">{messageTime}</div>
              </div>
              {showFeedback && (
                <div className="bot-chat-feedback-container">
                  <i
                    className={`icon-thumbs-up bot-chat-feedback-icon${
                      feedback === 1 ? " bot-chat-feedback-icon-selected" : ""
                    }`}
                    onClick={() => recordFeedback(1, messageId)}
                  />
                  <i
                    className={`icon-thumbs-down bot-chat-feedback-icon${
                      feedback === 0 ? " bot-chat-feedback-icon-selected" : ""
                    }`}
                    onClick={() => recordFeedback(0, messageId)}
                  />
                </div>
              )}
            </div>
          );
        } else {
          return bubbleRender(element);
        }
      }
    });
  } else {
    return bubbleRender(message);
  }
};

export default BotChatBubble;
