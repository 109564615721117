
 class QueueService {
  constructor() {
    this.queue = [];
    this.isQueueRunning = false;
  }

  startQueue() {
    if (!this.isQueueRunning) {
      this.isQueueRunning = true;
      this.executeNextTask();
    }
  }

  pushTask(task) {
    this.queue.push(task);
    this.counter = this.counter + 1;
    if (!this.isQueueRunning) {
      this.startQueue();
    }
  }

  executeNextTask() {
    if (this.queue.length > 0) {
      const task = this.queue.shift();
      task();
      this.exeTimes = this.exeTimes + 1;
    } else {
      this.isQueueRunning = false;
    }
  }
}
export default QueueService;
