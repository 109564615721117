import React from "react";

import "./style.css";

const NotificationCheckbox = ({
  checked,
  onCheckboxChange,
  label,
  defaultChecked,
  defaultValue,
  isDisabled,
}) => {
  return (
    <div className="notification-checkbox-container">
      <input
        defaultChecked={defaultChecked}
        defaultValue={defaultValue}
        disabled={isDisabled}
        type="checkbox"
        className="custom-checkbox"
        onClick={onCheckboxChange}
        checked={checked}
      />
      <span className="checkbox-label">{label}</span>
    </div>
  );
};

export default NotificationCheckbox;
