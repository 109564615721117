import React, { useEffect, useState } from "react";
import Moment from "react-moment";

import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";

import "./style.css";
import Snackbar from "../../components/Snackbar";
import IconLabelButton from "../../components/IconLabelButton";
import Modal from "../../components/Modal";
import bookmark from "../../assets/images/svg/bookmark-new.svg";
import removeIcon from "../../assets/images/svg/trash-icon.svg";

import { useTranslation } from "react-i18next";

const Allbookmarks = (props) => {
  const { t } = useTranslation('translation');
  const [allBookmarks, setAllBookmarks] = useState([]);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [openRemoveAllBookmarksConfirm, setOpenRemoveAllBookmarksConfirm] =
    useState(false);
  const makeApiCall = useFetch();

  useEffect(() => {
    getBookmarks();
  }, [props.triggerBookmarkLoad]);

  const getBookmarks = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/bookmark/bookmarks",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setAllBookmarks(json.data.bookmarks);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching bookmarks"),
            type: "error",
          });
        }
      });
  };

  const removeBookmark = (bookmarkId) => {
    makeApiCall
      .request(window.CHAT_API + "/api/v1/bookmark/bookmarks/" + bookmarkId, {
        method: "DELETE",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          getBookmarks();
          setSnackbar({
            isOpen: true,
            message: t("Bookmark is deleted successfully."),
            type: "success",
          });
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });
  };

  const confirmRemoveAllBookmarks = () => {
    setOpenRemoveAllBookmarksConfirm(true);
  };

  const removeAllBookmarks = () => {
    setOpenRemoveAllBookmarksConfirm(false);

    allBookmarks.forEach((bookmark, index) => {
      makeApiCall
        .request(
          window.CHAT_API + "/api/v1/bookmark/bookmarks/" + bookmark._id,
          {
            method: "DELETE",
            headers: authHeader(),
          }
        )
        .then((json) => {
          if (json.status.code === 200) {
            if (index === allBookmarks.length - 1) {
              getBookmarks();
              setSnackbar({
                isOpen: true,
                message: t("Bookmarks deleted."),
                type: "success",
              });
            }
          } else {
            setSnackbar({
              isOpen: true,
              message: t("Oops! Something went wrong."),
              type: "error",
            });
          }
        });
    });
  };

  return (
    <div className="all-bookmarks-container">
      <div className="all-bookmarks-header">
        <div className="left-partition">
          {/* <button className="header-back-btn" onClick={props.onBack}>
            <i className="icon-left-chevron" />
          </button> */}
          <div className="header-heading">{t("Bookmarks")}</div>
        </div>
        <div className="right-partition">
          <IconLabelButton
            buttonLabel={t("Remove All")}
            onClick={confirmRemoveAllBookmarks}
          />
        </div>
      </div>
      <div className="chat-feature-panel-body px-0">
        {allBookmarks.map((element) => {
          return (
            <div className="bookmark-item">
              <div className="left-partition">
                <img className="bookmark-item-icon" src={bookmark} />
                <div>
                  <div>{element.text}</div>
                  <div className="bookmark-item-duration">
                    <Moment format="DD/MM/YYYY HH:mm" utc local>
                      {element.updated_at}
                    </Moment>
                  </div>
                </div>
              </div>
              <div className="right-partition">
                <button
                  className="btn p-0"
                  aria-label={t("Remove")}
                  title={t("Remove")}
                  //buttonLabel={t("Remove")}
                  onClick={() => {
                    removeBookmark(element._id);
                  }}
                >
                  <img src={removeIcon} alt=""/>
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        openModal={openRemoveAllBookmarksConfirm}
        onRequestClose={() => setOpenRemoveAllBookmarksConfirm(false)}
        buttonAction={removeAllBookmarks}
        buttonLabel={t("Remove bookmarks")}
      >
        {t("Are you sure you want to remove all the bookmarks?")}
      </Modal>
      <Snackbar
        isOpen={snackbar.isOpen}
        primaryMessage={snackbar.message}
        type={snackbar.type}
        onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
      />
    </div>
  );
};

export default Allbookmarks;
