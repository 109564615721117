import React, { useState, useMemo } from "react";
import Table from "react-data-table-component";
import Skeleton from "react-loading-skeleton";

import TextInput from "../TextInput";
import "./style.css";

import { useTranslation } from "react-i18next";

const customStyles = {
  table: {
    // style: {
    //   backgroundColor: "#000",
    // },
  },
  tableWrapper: {
    style: {
      // boxShadow: "0 0 12px rgba(237,237,232,.8)",
    },
  },
  contextMenu: {
    style: {
      backgroundColor: "#f9fafd",
      fontSize: "14px",
      height: 40,
      minHeight: 40,
    },
  },
  headRow: {
    style: {
      backgroundColor: "#0923E6",
      borderBottom: "none",
      borderRadius: "8px 8px 0 0",
      minHeight: 48,
      color: "#fff",
    },
  },
  headCells: {
    style: {
      fontWeight: 600,
      letterSpacing: "0.02rem",
      fontSize: 13,
      color: "#fff",
    },
  },
  subHeader: {
    style: {
      padding: "10px 0",
    },
  },
  header: {
    style: {
      minHeight: 40,
    },
  },
  rows: {
    style: {
      padding: "16px 0",
      borderBottomColor: "#eceff7 !important",
      // margin: "16px 0 0",
      border: "1px solid #eceff7",
      borderTop: "none",
      // borderRadius: 5,
    },
  },
  expanderRow: {
    style: {
      padding: "16px 0",
      borderBottomColor: "#eceff7 !important",
      // margin: "16px 0 0",
      border: "1px solid #eceff7",
      borderTop: "none",
      // borderRadius: 5,
    },
  },
  pagination: {
    style: {
      border: "none",
      padding: "24px 8px",
    },
  },
};

const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  searchPlaceholder,
}) => {
  const { t } = useTranslation('translation');

  return (<div className="searchbar-container">
    <TextInput
      inputClass={filterText ? "highlight-search-input" : ""}
      id="search"
      inputType="text"
      placeholder={searchPlaceholder}
      value={filterText}
      inputChange={onFilter}
    />
    <button
      className="search-clear-button"
      onClick={onClear}
      title={t("Clear search")}
    >
      <i className="icon-close" />
    </button>
  </div>)
};

const DataTable = ({
  defaultSortField,
  columns,
  data,
  selectableRows,
  contextActions,
  onSelectedRowsChange,
  clearSelectedRows,
  hideFilter,
  headerActions,
  selectableRowSelected,
  showPagination,
  progressPending,
  onSearch,
  searchPlaceholder,
  sortFunction,
  expandableRows,
  expandOnRowClicked,
  expandableRowDisabled,
  expandableRowsComponent,
  paginationServer,
  paginationTotalRows,
  onChangePage,
  onChangeRowsPerPage,
  sortServer,
  onSort,
  conditionalRowStyles,
  paginationPerPage,
  fixedHeader,
  subHeader,
  subHeaderComponent,
}) => {
  const [filterText, setFilterText] = useState("");
  const [showContextMenu, setShowContextMenu] = useState(false);

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
      onSearch(data, "");
    }
  };

  const handleRowSelection = (data) => {
    if (data.selectedRows.length === 0) {
      setShowContextMenu(false);
    } else if (data.selectedRows.length > 0) {
      setShowContextMenu(true);
    }
    onSelectedRowsChange(data);
  };

  return (
    <div className="custom-data-table">
      {(!hideFilter || showContextMenu || headerActions) && (
        <div className="context-menu">
          <div className="context-menu-actions">
            {showContextMenu && contextActions}
          </div>
          <div className="flex-container">
            {!hideFilter && (
              <FilterComponent
                onFilter={(e) => {
                  setFilterText(e.target.value);
                  onSearch(data, e.target.value);
                }}
                onClear={handleClear}
                filterText={filterText}
                searchPlaceholder={searchPlaceholder}
              />
            )}
            {headerActions && (
              <div className="header-actions">{headerActions}</div>
            )}
          </div>
        </div>
      )}

      <Table
        expandableRows={expandableRows}
        expandOnRowClicked={expandOnRowClicked}
        expandableRowDisabled={expandableRowDisabled}
        highlightOnHover={expandOnRowClicked}
        // expandableRowsHideExpander={true}
        expandableIcon={{
          collapsed: <i className="icon-plus-circle" />,
          expanded: <i className="icon-minus-circle" />,
        }}
        expandableRowsComponent={expandableRowsComponent}
        progressPending={progressPending}
        progressComponent={
          <Skeleton className="data-table-skeleton" count={4} height={100} />
        }
        noHeader
        pagination={showPagination}
        paginationPerPage={paginationPerPage ? paginationPerPage : 30}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
        defaultSortField={defaultSortField}
        columns={columns}
        data={data}
        selectableRows={selectableRows}
        onSelectedRowsChange={handleRowSelection}
        clearSelectedRows={clearSelectedRows}
        customStyles={customStyles}
        style={{
          boxSizing: "border-box",
        }}
        selectableRowSelected={selectableRowSelected}
        sortFunction={sortFunction ? sortFunction : null}
        paginationServer={paginationServer}
        // paginationServerOptions={{
        //   persistSelectedOnPageChange: true,
        //   persistSelectedOnSort: true,
        // }}
        paginationTotalRows={paginationTotalRows}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        sortServer={sortServer}
        onSort={onSort}
        conditionalRowStyles={conditionalRowStyles}
        persistTableHead={true}
        fixedHeader={fixedHeader}
        fixedHeaderScrollHeight="400px"
        subHeader={subHeader}
        subHeaderComponent={subHeaderComponent}
      />
    </div>
  );
};

export default DataTable;
