import React, { useState } from "react";

import "./style.css";

const SuggestionButton = ({ buttonLabel, buttonAction, disabled }) => {
  return (
    <button
      type="button"
      className="suggestion-button"
      onClick={() => {
        buttonAction()
      }}
      disabled={disabled}
    >
      {buttonLabel}
    </button>
  );
};

export default SuggestionButton;
