import React from "react";

import "./style.css";

import { useTranslation } from "react-i18next";

const VideoPreview = (props) => {
  // if (props.src) {
  //   if (props.provider === "youtube") {
  //     return (
  //       <div className="video-preview-wrapper">
  //         <iframe src={props.src} allowfullscreen></iframe>
  //       </div>
  //     );
  //   } else if (props.provider === "video") {
  //     return (
  //       <div className="video-preview-wrapper">
  //         <iframe
  //           title="vimeo-player"
  //           src={props.src}
  //           frameborder="0"
  //           allowfullscreen
  //         ></iframe>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className="video-preview-wrapper">
  //         <video controls>
  //           <source src={props.src} type="video/mp4" />
  //         </video>
  //       </div>
  //     );
  //   }
  // } else {
  //   return <div>No video preview available</div>;
  // }
  const { t } = useTranslation('translation');
  return (
    <div className="video-preview-wrapper">
      <div
        className="video-preview"
        dangerouslySetInnerHTML={{ __html: props.html }}
      />
      <div className="video-expanded-wrapper">
        <a className="new-tab-btn" href={props.link} target="_blank">
          {t("Open in new tab")}
        </a>
      </div>
    </div>
  );
};

export default VideoPreview;
