import React, { Fragment, useState } from "react";
import { elements } from "./data";
import Element from "./element";
import "./style.css";

const PeriodicTablePicker = (props) => {
  const [showInfo, setShowInfo] = useState(false);
  const [element, setElement] = useState({});

  const insertElement = (num) => {
    setShowInfo(true);
    setElement(elements[num]);
    props.onElementSelect(elements[num].symbol);
  };

  const closeInfo = () => {
    setShowInfo(false);
  };

  /**
   * Helper method that will generate a number of elements
   * from start index to end index to avoid copy pasting
   * all the hundred elements
   * @param {number} start
   * @param {number} end
   * @return {Array<Object>}
   */
  const populateElements = (start, end) => {
    let items = [];
    for (let i = start; i <= end; i++) {
      items.push(<Element insertElement={insertElement} num={i} />);
    }
    return items;
  };

  return (
    <div className="periodic-table-wrapper">
      <div id="table">
        {/* Elements 1-4 */}
        {populateElements(1, 4)}
        {/* Information Table */}
        {showInfo && (
          <Fragment>
            <div id="element-box" className={`${element.category}`}>
              <div className="number">{element.number}</div>
              <div className="symbol">{element.symbol}</div>
              <div className="element-name">{element.name}</div>
            </div>
            <div id="information">
              <div
                onClick={closeInfo}
                className="close-button"
                title="Close Info"
              >
                Close [&times;]
              </div>
              <div>
                <h1 className="big_title">{element.name}</h1>
                <span className={`cat_name ${element.category}`}>
                  {element.category}
                </span>
                {element.appearance && (
                  <div className="appearance">
                    <strong>Appearance:</strong> {element.appearance}
                  </div>
                )}
                <div className="atom_info">
                  <span>Atomic Mass: {element.atomic_mass} | </span>
                  <span>Density: {element.density}</span>
                  {element.molar_heat && (
                    <span> | Molar Heat: {element.molar_heat}</span>
                  )}
                  {element.melt && <span> | Melt: {element.melt}K</span>}
                  {element.boil && <span> | Boil: {element.boil}K</span>}
                </div>
                <div>
                  {element.summary} ...{" "}
                  <a target="_blank" href={element.source}>
                    Source
                  </a>
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {/* Populating elements from 5-57 */}
        {populateElements(5, 57)}
        {/* Lanthanoids split 72-89 */}
        {populateElements(72, 89)}
        {/* Actinoids split 104-119*/}
        {populateElements(104, 119)}
        {/* Lanthenoids 58-71*/}
        {populateElements(58, 71)}
        {/* Actionoids 90-103 */}
        {populateElements(90, 103)}
      </div>
    </div>
  );
};

export default PeriodicTablePicker;
