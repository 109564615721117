import React from "react";

import "./style.css";

const Radio = (props) => {
  return (
    <>
      {props.options &&
        props.options.enumOptions &&
        props.options.enumOptions.map((option, index) => (
          <div className="radio-wrapper">
            <input
              type="radio"
              value={option.value}
              name={props.label}
              id={`${props.id}${index}`}
              checked={
                (props.isQuiz
                  ? props.selectedValues?.includes(option.value)
                  : props.checked) || props.value === option.value
              }
              onChange={() => props.onChange(option.value)}
              className={`position-type-radio ${
                props.isQuiz ? "absolute-quiz-radio" : ""
              }`}
              required={props.required}
            />
            {!props.isQuiz && (
              <label className="radio-label">{option.label}</label>
            )}
            {props.isQuiz && (
              <label
                className={`quiz-radio-wrapper ${
                  props.selected === option.value ? "active" : ""
                }`}
                for={`${props.id}${index}`}
              >
                <div
                  className="quiz-radio"
                  dangerouslySetInnerHTML={{ __html: option.label }}
                />
              </label>
            )}
          </div>
        ))}
    </>
  );
};

export default Radio;
