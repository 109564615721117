import React, { useState, useEffect } from "react";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";

import Button from "../Button";
import Accordion from "../Accordion";
import Snackbar from "../../components/Snackbar";

import "./style.css";
import SuggestionButton from "../SuggestionButton";
import CorrectAnswerRecord from "./correctAnswerRecord";

const QuizReport = ({
  submissionData,
  hideScores,
  displayedQuestionsId,
  enableLearning,
}) => {
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [displayScores, setDisplayScores] = useState(!hideScores);
  const [totalScore, setTotalScore] = useState(0);
  const [questionsToReview, setQuestionsToReview] = useState([]);
  const [resetCheckboxes, setResetCheckboxes] = useState(false);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    transformAllQuestions();
  }, []);

  const transformAnswers = (options) => {
    return options.filter((option, key) => option.is_correct === true);
  };

  const transformAllQuestions = () => {
    let temp = {};
    submissionData.quiz &&
      submissionData.quiz.questions.forEach((question) => {
        if (question.parent_id && temp[question.parent_id]) {
          temp[question.parent_id] = {
            ...temp[question.parent_id],
            subquestions:
              "subquestions" in temp[question.parent_id]
                ? temp[question.parent_id].subquestions.concat([question])
                : [].concat([question]),
          };
        } else {
          temp[question.id] = question;
        }
      });

    let temp1 = {};
    submissionData.answers &&
      submissionData.answers.forEach((answer) => {
        temp1[answer.question_id] = answer;
      });
    computeReport(temp, temp1);
  };

  const transformSubmittedOptions = (submittedOptions, questionOptions) => {
    let transformedOptions = [];

    submittedOptions &&
      submittedOptions.forEach((submittedOptionId) => {
        questionOptions.forEach((questionOption) => {
          if (submittedOptionId === questionOption.id) {
            transformedOptions.push(questionOption);
          }
        });
      });

    return transformedOptions;
  };

  const prepareCorrectAnswerList = (element, userAnswers) => {
    let dataToPush = {};

    dataToPush = {
      questionId: element.id,
      questionType: element.type,
      question: element.text,
      answers:
        element.type === "group"
          ? []
          : "ideal_answer" in element
          ? [{ text: element.ideal_answer }]
          : "matches" in element
          ? [{ matches: element.matches }]
          : transformAnswers(element.options),
      submittedAnswers: !userAnswers[element.id]
        ? []
        : "text" in userAnswers[element.id]
        ? [{ text: userAnswers[element.id].text }]
        : "matches" in element
        ? [{ matches: userAnswers[element.id].matches }]
        : element.type !== "group"
        ? transformSubmittedOptions(
            userAnswers[element.id].options,
            element.options
          )
        : "",
      questionScore:
        submissionData.quiz.score_decimal_number === 0
          ? Math.floor(element.score)
          : element.score.toFixed(
              submissionData.quiz.score_decimal_number === null
                ? 2
                : submissionData.quiz.score_decimal_number
            ),
      userScore: !userAnswers[element.id]
        ? ""
        : "score" in userAnswers[element.id]
        ? submissionData.quiz && submissionData.quiz.score_decimal_number === 0
          ? Math.floor(userAnswers[element.id].score)
          : parseFloat(userAnswers[element.id].score).toFixed(
              submissionData.quiz.score_decimal_number === null
                ? 2
                : submissionData.quiz.score_decimal_number
            )
        : "-",
      reviewerComment:
        element.type === "group"
          ? ""
          : userAnswers[element.id].reviewer_comment,
      correctedScore:
        element.type === "group" ? "" : userAnswers[element.id].corrected_score,
      answerKeySettings:
        "answer_key_settings" in element ? element.answer_key_settings : null,
    };

    if (element.subquestions) {
      let temp = [];
      element.subquestions.forEach((subque) => {
        temp.push(prepareCorrectAnswerList(subque, userAnswers));
      });
      dataToPush.subquestionAnswers = temp;
    }

    return dataToPush;
  };

  const computeReport = (quizQuestions, userAnswers) => {
    let totalScore = 0;
    let correctAnswerList = [];
    Object.keys(quizQuestions).forEach((questionId) => {
      if (
        quizQuestions[questionId].options ||
        "ideal_answer" in quizQuestions[questionId] ||
        "matches" in quizQuestions[questionId] ||
        quizQuestions[questionId].type === "group"
      ) {
        totalScore += quizQuestions[questionId].score;
        correctAnswerList.push(
          prepareCorrectAnswerList(quizQuestions[questionId], userAnswers)
        );
      }
    });
    setCorrectAnswers(correctAnswerList);
    if (submissionData.quiz_score) {
      setTotalScore(submissionData.quiz_score);
    } else {
      setTotalScore(totalScore);
    }
  };

  const onReviewRequest = (questionId, action, reviewComment) => {
    let temp = [...questionsToReview];

    if (action === "add") {
      temp.push({ question_id: questionId, comment: reviewComment });
    } else if (action === "remove") {
      temp.forEach((item, index) => {
        if (item.question_id === questionId) {
          temp.splice(index, 1);
        }
      });
    } else if (action === "change") {
      temp.forEach((item, index) => {
        if (item.question_id === questionId) {
          item.comment = reviewComment;
        }
      });
    }
    setQuestionsToReview(temp);
  };

  return (
    <div className="quiz-submission-page">
      {correctAnswers.length > 0 && (
        <div className="correct-answers-container">
          {correctAnswers.map((item, index) => (
            <CorrectAnswerRecord
              questionNumber={index + 1}
              questionId={item.questionId}
              questionType={item.questionType}
              question={item.question}
              answers={item.answers}
              submittedAnswers={item.submittedAnswers}
              questionScore={item.questionScore}
              userScore={item.userScore}
              answerKeySettings={item.answerKeySettings}
              subquestionsData={item.subquestionAnswers}
              onReviewRequest={onReviewRequest}
              resetCheckboxes={resetCheckboxes}
              enableLearning={enableLearning}
              showAllScores={submissionData.quiz.score !== 0 && displayScores}
              correctedScore={item.correctedScore}
              reviewerComment={item.reviewerComment}
            />
          ))}
        </div>
      )}

      <Snackbar
        isOpen={snackbar.isOpen}
        primaryMessage={snackbar.message}
        type={snackbar.type}
        onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
      />
    </div>
  );
};

export default QuizReport;
