import React, { useEffect, useState } from "react";

import IconLabelButton from "../IconLabelButton";
import Checkbox from "../Checkbox";
import TextInput from "../TextInput";

import { useTranslation } from "react-i18next";

const CorrectAnswerRecord = ({
  questionId,
  questionType,
  question,
  answers,
  submittedAnswers,
  questionScore,
  userScore,
  answerKeySettings,
  onReviewRequest,
  resetCheckboxes,
  enableLearning,
  reviewerComment,
  correctedScore,
  showAllScores,
  subquestionsData,
  questionNumber,
}) => {
  const { t } = useTranslation("translation");
  const [toBeReviewed, setToBeReviewed] = useState(false);
  const [reviewComment, setReviewComment] = useState("");

  useEffect(() => {
    setToBeReviewed(false);
  }, [resetCheckboxes]);

  const handleReviewRequestToggle = (checked) => {
    setToBeReviewed(checked);
    if (checked) {
      onReviewRequest(questionId, "add", reviewComment);
    } else {
      onReviewRequest(questionId, "remove", reviewComment);
    }
  };

  const onFieldChange = (value, label) => {
    if (label === "reviewComment") {
      setReviewComment(value);
      onReviewRequest(questionId, "change", value);
    }
  };

  const getTableDisplay = (data, noHeader) => {
    let temp = typeof data === "string" ? JSON.parse(data) : data;

    if (answerKeySettings && answerKeySettings.enable_column_header) {
      temp.map((row, index) =>
        row.unshift(answerKeySettings.column_headers[index])
      );
    }

    return (
      <table>
        {!noHeader && (
          <thead>
            <tr>
              {answerKeySettings &&
                answerKeySettings.headers &&
                answerKeySettings.headers.map((column) => <th>{column}</th>)}
            </tr>
          </thead>
        )}
        <tbody>
          {temp.map((row) => (
            <tr>
              {row.map((column) => (
                <td>{column}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const getMatchDisplay = (data, referenceData) => {
    return (
      <table>
        <thead>
          <tr>
            {answerKeySettings &&
              answerKeySettings.headers &&
              answerKeySettings.headers.map((column) => <th>{column}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr>
              <td>{row.term}</td>
              <td>{row.definition}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const getSubAnswerDisplay = (data, subQuestionNumber) => {
    return (
      <React.Fragment>
        <div className="question-row side-padding">
          <h3 className="page-subheading">
            {t("Question")} {questionNumber}.{subQuestionNumber}
          </h3>
          <div
            className="answer-table-question answer-table-subquestion"
            dangerouslySetInnerHTML={{ __html: data.question }}
          />
        </div>
        <div className="half-clearfix" />
        {data.questionType !== "group" && (
          <div className="answer-row flex-container">
            <div className="flex-one side-padding">
              <h3 className="page-subheading">{t("Recommended Answer")}</h3>
              {(data.questionType === "table" ||
                data.questionType === "description") &&
                data.answers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getTableDisplay(
                      "text" in answer ? answer.text : "",
                      data.questionType === "description"
                    )}
                  </div>
                ))}
              {data.questionType === "matching" &&
                data.answers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getMatchDisplay("matches" in answer ? answer.matches : [])}
                  </div>
                ))}
              {data.questionType !== "table" &&
                data.questionType !== "description" &&
                data.questionType !== "matching" &&
                data.answers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {"text" in answer ? answer.text : ""}
                  </div>
                ))}
            </div>
            <div className="flex-one side-padding">
              <h3 className="page-subheading">{t("Your Answer")}</h3>
              {(data.questionType === "table" ||
                data.questionType === "description") &&
                data.submittedAnswers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getTableDisplay(
                      "text" in answer ? answer.text : "",
                      data.questionType === "description"
                    )}
                  </div>
                ))}
              {data.questionType === "matching" &&
                data.submittedAnswers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getMatchDisplay(
                      "matches" in answer ? answer.matches : [],
                      data.answers[localIndex]
                    )}
                  </div>
                ))}
              {data.questionType !== "table" &&
                data.questionType !== "description" &&
                data.questionType !== "matching" &&
                data.submittedAnswers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {"text" in answer ? answer.text : ""}
                  </div>
                ))}
            </div>
          </div>
        )}
        {(data.questionScore !== undefined || data.userScore !== undefined) &&
          (data.questionScore !== 0 ||
            data.questionScore !== "0" ||
            data.questionScore !== "0.00") &&
          showAllScores !== false &&
          data.questionType !== "group" && (
            <>
              <div className="clearfix" />
              <div className="flex-container">
                <div className="flex-one side-padding">
                  <h3 className="page-subheading">{t("Question Score")}</h3>
                  <div className="left-align">{data.questionScore}</div>
                </div>
                <div className="flex-one side-padding">
                  <h3 className="page-subheading no-margin">
                    {t("Your Final Score")}
                  </h3>
                  <p className="left-align review-footnotes">
                    {t(
                      "Note that this is your final score and will be reflected in all your reports."
                    )}
                  </p>
                  <div className="left-align">
                    {data.userScore === "-"
                      ? t("Not available")
                      : data.userScore}
                  </div>
                </div>
              </div>
            </>
          )}
        <div className="half-clearfix" />
        {(data.reviewerComment !== undefined ||
          data.correctedScore !== undefined) && (
          <>
            <div className="flex-container updated-score-wrapper">
              <div className="flex-one side-padding">
                <h3 className="page-subheading">{t("Updated Score")}</h3>
                <p className="left-align">{data.correctedScore}</p>
                <div className="half-clearfix" />
                <h3 className="page-subheading">{t("Lecturer Comments")}</h3>
                <p className="left-align">{data.reviewerComment}</p>
              </div>
            </div>
            <div className="clearfix" />
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="correct-answer-record-container">
      <div className="question-row side-padding">
        <h3 className="page-subheading">
          {t("Question")} {questionNumber}
        </h3>
        <div
          className="answer-table-question question"
          dangerouslySetInnerHTML={{ __html: question }}
        />
      </div>
      <div className="half-clearfix" />
      {questionType !== "group" && (
        <div className="answer-row flex-container">
          <div className="flex-one side-padding">
            <h3 className="page-subheading">{t("Recommended Answer")}</h3>
            {(questionType === "table" || questionType === "description") &&
              answers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getTableDisplay(
                    "text" in answer ? answer.text : "",
                    questionType === "description"
                  )}
                </div>
              ))}
            {questionType === "matching" &&
              answers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getMatchDisplay("matches" in answer ? answer.matches : [])}
                </div>
              ))}
            {questionType !== "table" &&
              questionType !== "description" &&
              questionType !== "matching" &&
              answers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {"text" in answer ? answer.text : ""}
                </div>
              ))}
          </div>
          <div className="flex-one side-padding">
            <h3 className="page-subheading">{t("Your Answer")}</h3>
            {(questionType === "table" || questionType === "description") &&
              submittedAnswers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getTableDisplay(
                    "text" in answer ? answer.text : "",
                    questionType === "description"
                  )}
                </div>
              ))}
            {questionType === "matching" &&
              submittedAnswers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getMatchDisplay(
                    "matches" in answer ? answer.matches : [],
                    answers[localIndex]
                  )}
                </div>
              ))}
            {/* {(questionType === "bullet" || questionType === "newbullet") &&
              submittedAnswers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  <div dangerouslySetInnerHTML={{ __html: answer.text }}/>
                </div>
              ))} */}
            {questionType !== "table" &&
              questionType !== "description" &&
              questionType !== "matching" &&
              // questionType !== "bullet" && questionType !== "newbullet" &&
              submittedAnswers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {"text" in answer ? answer.text : ""}
                </div>
              ))}
          </div>
        </div>
      )}
      {(questionScore !== undefined || userScore !== undefined) &&
        (questionScore !== 0 ||
          questionScore !== "0" ||
          questionScore !== "0.00") &&
        showAllScores !== false &&
        questionType !== "group" && (
          <>
            <div className="clearfix" />
            <div className="flex-container">
              <div className="flex-one side-padding">
                <h3 className="page-subheading">{t("Question Score")}</h3>
                <div className="left-align">{questionScore}</div>
              </div>
              <div className="flex-one side-padding">
                <h3 className="page-subheading no-margin">
                  {t("Your Final Score")}
                </h3>
                <p className="left-align review-footnotes">
                  {t(
                    "Note that this is your final score and will be reflected in all your reports."
                  )}
                </p>
                <div className="left-align">
                  {userScore === "-" ? t("Not available") : userScore}
                </div>
              </div>
            </div>
          </>
        )}
      {questionType === "group" &&
        subquestionsData &&
        subquestionsData.map((subque, index) =>
          getSubAnswerDisplay(subque, index + 1)
        )}

      {questionType !== "group" &&
        (reviewerComment !== undefined || correctedScore !== undefined) && (
          <>
            <div className="clearfix" />
            <div className="flex-container updated-score-wrapper">
              <div className="flex-one side-padding">
                <h3 className="page-subheading">{t("Updated Score")}</h3>
                <p className="left-align">{correctedScore}</p>
                <div className="half-clearfix" />
                <h3 className="page-subheading">{t("Lecturer Comments")}</h3>
                <p className="left-align">{reviewerComment}</p>
              </div>
            </div>
          </>
        )}
      <div className="half-clearfix" />
      <hr />
      <div className="half-clearfix" />
    </div>
  );
};

export default CorrectAnswerRecord;
