import React from "react";

import NoData from "../../assets/images/empty-data.png";
import "./style.css";

import { useTranslation } from "react-i18next";

const NoDataScreen = ({ message }) => {
  const { t } = useTranslation('translation');
  return (
    <div className="no-data-screen-container">
      <div className="no-data-screen-img-wrapper">
        <img src={NoData} alt={t("No Data")} className="no-data-screen-img" />
      </div>
      <div className="no-data-screen-text">{message}</div>
    </div>
  );
};

export default NoDataScreen;
