import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { authHeader } from "../../helpers/auth-header";
import useFetch from "../../helpers/remote";

import AllAnswerQuestions from "../AllAnswerQuestions";
import AllBookmarks from "../AllBookmarks";
import AllSessions from "../AllSessions";
import "./style.css";
import AgentClassService from "../../helpers/AgentClassService";
import DiscussionIcon from "../../assets/images/discussion.svg";
import DiscussionNormalIcon from "../../assets/images/discussion-normal.png";
import { useTranslation } from "react-i18next";
import bookmarkwhite from "../../assets/images/bookmark_white.svg";
import chatwhite from "../../assets/images/chat-white.svg";
import reportwhite from "../../assets/images/report_white.svg";
import sessionwhite from "../../assets/images/session_white.svg";
import Bookmark from "../../assets/images/svg/bookmark.svg";
import Chat from "../../assets/images/svg/chat.svg";
import Report from "../../assets/images/svg/report.svg";
import Session from "../../assets/images/svg/session.svg";
import i18n from "../../components/Internationalisation/i18n";

import botBookmark from "../../assets/images/svg/bookmark.svg";
import botSession from "../../assets/images/svg/session.svg";
import botAnswerQuestions from "../../assets/images/svg/answerquestions.svg";
import botReport from "../../assets/images/svg/report.svg";
import botChat from "../../assets/images/svg/chat.svg";
import botBookmarkActive from "../../assets/images/svg/bookmark-active.svg";
import botSessionActive from "../../assets/images/svg/session-active.svg";
import botAnswerQuestionsActive from "../../assets/images/svg/answerquestions-active.svg";
import botReportActive from "../../assets/images/svg/report-active.svg";
import botChatActive from "../../assets/images/svg/chat-active.svg";

import { getUrlParameter } from "../../helpers/formatFunctions";
import Dropdown from "../../components/NewDropdown";

const { t } = i18n;

const features = [
  {
    title: t("Chat"),
    titleDesc: t("Return to your conversations"),
    selector: "chat",
    icon: Chat,
    iconActive: chatwhite,
  },
  {
    title: t("Bookmarks"),
    titleDesc: t("View saved responses from your chats"),
    selector: "bookmarks",
    icon: Bookmark,
    iconActive: bookmarkwhite,
  },
  {
    title: t("Sessions"),
    titleDesc: t("View summary of pass sessions with chatbot"),
    selector: "sessions",
    icon: Session,
    iconActive: sessionwhite,
  },
  {
    title: t("Reports"),
    titleDesc: t("View Quiz Reports"),
    selector: "reports",
    icon: Report,
    iconActive: reportwhite,
  },
];

const chatBotFeatures = [
  {
    title: t("Chat"),
    titleDesc: t("Return to your conversations"),
    selector: "chat",
    icon: botChat,
    iconActive: botChatActive,
  },
  {
    title: t("Bookmarks"),
    titleDesc: t("View saved responses from your chats"),
    selector: "bookmarks",
    icon: botBookmark,
    iconActive: botBookmarkActive,
  },
  // {
  //   title: t("Answer questions"),
  //   selector: "answerquestions",
  //   icon: botAnswerQuestions,
  //   iconActive: botAnswerQuestionsActive,
  // },
  {
    title: t("Sessions"),
    titleDesc: t("View summary of pass sessions with chatbot"),
    selector: "sessions",
    icon: botSession,
    iconActive: botSessionActive,
  },
  {
    title: t("Question Board"),
    titleDesc: t("View question Board"),
    selector: "discussions",
    icon: DiscussionNormalIcon,
    iconActive: DiscussionIcon,
  },
  {
    title: t("Reports"),
    titleDesc: t("View Quiz Reports"),
    selector: "reports",
    icon: botReport,
    iconActive: botReportActive,
  },
];

const ChatFeaturePanel = (props) => {
  const { t } = useTranslation("translation");
  let history = useHistory();
  const { selectedClass, setSelectedClass } = useAuth();
  const [showSessionsScreen, setShowSessionsScreen] = useState(false);
  const [showAnswerQuestionsScreen, setShowAnswerQuestionsScreen] =
    useState(false);
  const [showBookmarksScreen, setShowBookmarksScreen] = useState(false);
  const [allClasses, setAllClasses] = useState([]);
  const [allClassesDropdownData, setAllClassesDropdownData] = useState([]);
  const [classMap, setClassMap] = useState({});
  const [disableReports, setDisableReports] = useState(false);
  const [disableAnswerQuestions, setDisableAnswerQuestions] = useState(false);
  const [botFeatures, setBotFeatures] = useState([]);
  const [isWidgetSso, setIsWidgetSso] = useState(false);
  const location = useLocation();

  const makeApiCall = useFetch();

  const isTestBot = props.hideChatbotWidget
    ? true
    : isWidgetSso ||
      (process.env && process.env.REACT_APP_TYPE === "login_widget");

  useEffect(() => {
    getClasses();
    let allParams = getUrlParameter(location.search.split("?")[1]);
    if (allParams.widgettype && allParams.widgettype === "SSO") {
      setIsWidgetSso(true);
    }
    getFeatures(
      (allParams.widgettype && allParams.widgettype === "SSO") ||
        props.hideChatbotWidget
    );
  }, []);

  const getClasses = () => {
    makeApiCall
      .request(
        window.CHAT_API + `/api/v1/course/classes`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          let temp = [],
            tempMap = {};
          json.data.classes.forEach((element) => {
            temp.push({
              value: element.connection_id,
              label: element.name,
            });
            tempMap[element.connection_id] = element.name;
          });
          setClassMap(tempMap);
          setAllClassesDropdownData(temp);
          setAllClasses(json.data.classes);
        }
      });
  };

  const onFeatureClick = (type) => {
    if (type === "Bookmarks") {
      setShowSessionsScreen(false);
      setShowBookmarksScreen(true);
      setShowAnswerQuestionsScreen(false);
    } else if (type === "Sessions") {
      setShowBookmarksScreen(false);
      setShowSessionsScreen(true);
      setShowAnswerQuestionsScreen(false);
    } else if (type === "Allanswerquestions") {
      setShowBookmarksScreen(false);
      setShowSessionsScreen(false);
      setShowAnswerQuestionsScreen(true);
    } else if (type === "Reports") {
      history.push("/reports");
    }
  };

  const handleClassSelect = async (data) => {
    if(props.showQuiz) {
      await props.onQuizExitHandler("selectChannel", data)
    } else {
      await props.endSession();
      AgentClassService.setSelectedClass(data.value);
      makeApiCall
        .request(window.CHAT_API + "/api/v1/organisation/states", {
          method: "PATCH",
          headers: authHeader(),
          body: JSON.stringify({
            states: {
              selected_class: data.value.toString(),
            },
          }),
        })
        .then((json) => {
          if (json.status.code === 200) {
            // props.getSessions();
          }
        });
      props.showChatPanel && props.showChatPanel();
    }
    setSelectedClass(data.value);
  };

  const handleNoClassSelect = () => {
    props.setNoClassHistoryPanel(true);
  };

  const closeNoClassPanel = () => {
    props.setNoClassHistoryPanel(false);
  };

  const getFeatures = async (isWidget = false) => {
    let temp = isWidget ? [...features] : [...chatBotFeatures];

    if (!AgentClassService.getSelectedAgent()) {
      return;
    }

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/agents/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then(async (json) => {
        if (json.status.code === 200) {
          if (
            json &&
            json.data &&
            json.data.agent &&
            json.data.agent.settings
          ) {
            if (json.data.agent.settings.chat_is_reports_disabled) {
              temp = temp.filter((e) => e.selector !== "reports");
            }
            if (
              json.data.agent.settings.chat_is_answer_questions_disabled ||
              isWidgetSso
            ) {
              temp = temp.filter((e) => e.selector !== "discussions");
            }
            setBotFeatures([...temp]);
          }
        } else {
          if (isWidgetSso) {
            temp = temp.filter((e) => e.selector !== "answerquestions");
          }
          setBotFeatures([...temp]);
        }
      });
  };

  return (
    <div className={"chat-feature-panel-wrapper auto-scroll"}>
      <div className="chat-feature-panel">
        {allClassesDropdownData.length > 0 && !isTestBot && (
          <Dropdown
            value={{
              value: selectedClass,
              label: classMap[selectedClass] ? classMap[selectedClass] : "",
            }}
            isSearchable
            onChange={handleClassSelect}
            data={allClassesDropdownData}
          />
        )}
        <div className="sidebar-menu" id="main-content">
          {botFeatures.map((option) => (
            <button
              key={option.title}
              className={
                props.selectedSidebarOption === option.selector
                  ? "sidebar-menu-btn sidebar-menu-btn-active"
                  : "sidebar-menu-btn"
              }
              onClick={() => {
                props.setSelectedSidebarOption(option.selector);
              }}
            >
              <span className="sidebar-icon-wrapper">
                <img
                  className="sidebar-icon"
                  alt={t(option.title)}
                  src={
                    props.selectedSidebarOption === option.selector
                      ? option.iconActive
                      : option.icon
                  }
                />
              </span>
              <span className="sidebar-title">
                <div>{t(option.title)}</div>
                {isTestBot && (
                  <div className="title-desc">{t(option.titleDesc)}</div>
                )}
              </span>
            </button>
          ))}

          {/* <button
            key={t("Minimize Widget")}
            className={"sidebar-menu-btn"}
            onClick={() => {}}
          >
            <span className="sidebar-icon-wrapper">
              <img
                className="sidebar-icon"
                alt={t("Minimize Widget")}
                src={minimise_widget}
              />
            </span>
            <span className="sidebar-title">
              <div>{t("Minimize Widget")}</div>
            </span>
          </button> */}
        </div>
        {/* {!showSessionsScreen && !showBookmarksScreen && (
          <>
            {features.map((feature) => (
              <div className="feature-card-wrapper">
                <FeatureCard
                  featureImg={feature.img}
                  featureTitle={feature.title}
                  bgColor={feature.bgColor}
                  accentColor={feature.accentColor}
                  onClick={() => {
                    onFeatureClick(feature.title);
                  }}
                />
              </div>
            ))}
          </>
        )} */}
        {showSessionsScreen && (
          <>
            <AllSessions
              onBack={() => {
                setShowSessionsScreen(false);
              }}
              onPastSessionClick={props.onPastSessionClick}
              enableLearning={props.enableLearning}
            />
            {/* <div className="decor-img">
            <img src={SessionsImg} alt="" />
          </div> */}
          </>
        )}
        {showAnswerQuestionsScreen && !isWidgetSso && (
          <AllAnswerQuestions
            onBack={() => {
              setShowAnswerQuestionsScreen(false);
            }}
          />
        )}
        {showBookmarksScreen && (
          <>
            <AllBookmarks
              onBack={() => {
                setShowBookmarksScreen(false);
              }}
              triggerBookmarkLoad={props.triggerBookmarkLoad}
            />
            {/* <div className="decor-img">
            <img src={BookmarksImg} alt="" />
          </div> */}
          </>
        )}
      </div>
      {/* {props.showNoClassHistory && (
        <div className="class-list">
          You are viewing No Class history.
          <div className="half-clearfix" />
          <IconLabelButton
            buttonLabel="Close and go back"
            onClick={closeNoClassPanel}
          />
        </div>
      )} */}
      {/* {!showSessionsScreen &&
        !showBookmarksScreen &&
        !props.showNoClassHistory &&
        allClasses.length > 0 && (
          <div className="class-list">
            {!props.isWidget && (
              <IconLabelButton
                onClick={handleNoClassSelect}
                buttonLabel="View No Class History"
                buttonClass="no-class-btn"
              />
            )}
          </div>
        )} */}
    </div>
  );
};

export default ChatFeaturePanel;
