import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";
import Moment from "react-moment";
import AgentClassService from "../../helpers/AgentClassService";

import SummaryCard from "./summaryCard";
import DataTable from "../../components/DataTable";
import Snackbar from "../../components/Snackbar";
import "./style.css";

import { useTranslation } from "react-i18next";

const UserReport = () => {
  const { t } = useTranslation("translation");
  const makeApiCall = useFetch();
  let history = useHistory();

  const [quizAttemptedCount, setQuizAttemptedCount] = useState(0);
  const [quizSubmissionCount, setQuizSubmissionCount] = useState(0);
  const [quizList, setQuizList] = useState([]);
  const [disableQuizReport, setDisableQuizReport] = useState(false);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  let columns = [
    {
      name: t("Quiz"),
      selector: "quiz",
      wrap: true,
    },
    {
      name: t("No of Submissions"),
      selector: "submissionCount",
    },
    {
      name: t("Last Submitted"),
      selector: "lastSubmitted",
    },
    {
      name: t("Latest Score"),
      selector: "latestScore",
    },
    {
      name: t("Highest Score"),
      selector: "highestScore",
    },
    {
      name: t("Average Score"),
      selector: "avgScore",
    },
    {
      name: t("Lowest Score"),
      selector: "lowestScore",
    },
  ];

  useEffect(() => {
    getAgentSettings();
  }, []);

  const getAgentSettings = () => {
    if (!AgentClassService.getSelectedAgent()) {
      return;
    }

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/agents/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (
            json &&
            json.data &&
            json.data.agent &&
            json.data.agent.settings
          ) {
            setDisableQuizReport(
              json.data.agent.settings.chat_is_quiz_report_disabled
            );
          }
          getSummary();
          getQuizData();
        }
      });
  };

  const getSummary = () => {
    makeApiCall
      .request(
        window.DATA_API + "/report/report-data/personal-quiz-summary",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          if (
            json.data &&
            json.data.report_data &&
            json.data.report_data.rows &&
            json.data.report_data.rows.length
          ) {
            setQuizAttemptedCount(json.data.report_data.rows[0].total_quiz);
            setQuizSubmissionCount(
              json.data.report_data.rows[0].total_quiz_submission
            );
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching report summary"),
            type: "error",
          });
        }
      });
  };

  const getQuizData = () => {
    makeApiCall
      .request(
        window.DATA_API + "/report/report-data/personal-quiz-list?sort=last_submitted::dsc",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setQuizList(json.data.report_data.rows);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching quiz details"),
            type: "error",
          });
        }
      });
  };

  const showScore = (row, type) => {
    if (
      row &&
      row.result_settings &&
      (row.result_settings.show_scores
        //  ||
        // row.result_settings.show_scores === null
        )
    ) {
      if (type === "latestScore") {
        return `${row.last_score}/${row.quiz_score}`;
      }
      if (type === "highestScore") {
        return `${row.highest_score}/${row.quiz_score}`;
      }
      if (type === "avgScore") {
        return `${row.average_score.toFixed(2)}/${row.quiz_score}`;
      }
      if (type === "lowestScore") {
        return `${row.lowest_score}/${row.quiz_score}`;
      }
    }
    return `-`;
  };

  const transformTableData = (data) => {
    return quizList.map((row) => ({
      id: row.quiz_id,
      quiz: row.quiz_name,
      submissionCount: row.submission_count,
      lastSubmitted: (
        <Moment format="DD/MM/YYYY HH:mm" utc>
          {row.last_submitted}
        </Moment>
      ),
      latestScore: showScore(row, "latestScore"),
      highestScore: showScore(row, "highestScore"),
      avgScore: showScore(row, "avgScore"),
      lowestScore: showScore(row, "lowestScore"),
    }));
  };

  return (
    <div className="user-report-page-container">
      <div className="user-report-header">
        <div className="left-partition">
          {/* <button className="header-back-btn" onClick={props.onBack}>
            <i className="icon-left-chevron" />
          </button> */}
          <div className="header-heading">{t("Reports")}</div>
        </div>
        <div className="right-partition"></div>
      </div>
      <div className="page-content">
        <div className="flex-container">
          <SummaryCard
            title={t("No of quizzes attempted")}
            value={quizAttemptedCount}
          />
          <SummaryCard
            title={t("Total quiz submissions")}
            value={quizSubmissionCount}
          />
        </div>
        {!disableQuizReport && (
          <div>
            <div className="clearfix" />
            <h5 className="container-card-heading">{t("Quiz Submissions")}</h5>
            <DataTable
              showPagination
              paginationPerPage="10"
              noHeader="true"
              data={transformTableData(quizList)}
              columns={columns}
              hideFilter
            />
          </div>
        )}
      </div>
      <Snackbar
        isOpen={snackbar.isOpen}
        primaryMessage={snackbar.message}
        type={snackbar.type}
        onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
      />
    </div>
  );
};

export default UserReport;
