import React, { useRef } from "react";

import InputCard from "../InputCard";

import "./style.css";

import { useTranslation } from "react-i18next";

const BulletEditor = ({
  data,
  handleCardDelete,
  onInputEdit,
  handlePaste,
  handleKeyPress,
  shouldAutoFocus,
  identifier,
  spellCheck,
  handleKeyUp,
}) => {
  const { t } = useTranslation('translation');
  const showInstruction = useRef(null);
  return (
    <div className="bullet-editor">
      {data &&
        data.map((content, index) => {
          if (content) {
            showInstruction.current = true;
          } else {
            showInstruction.current = false;
          }
          return (
            <InputCard
              autoFocus={shouldAutoFocus}
              key={index.toString()}
              id={`input${identifier.toString()}${index.toString()}`}
              numberLabel="&#8226;"
              hideClose={index === 0 ? true : false}
              placeholder={
                index === 0 ? t("Start typing here") : t("Type next point here")
              }
              type="edit"
              value={content}
              onClose={() => handleCardDelete(index)}
              inputChange={(event) => onInputEdit(event, index)}
              onPrimaryPaste={(event) => handlePaste(event, index)}
              tabIndex={2 * index + 1}
              useTextArea
              // handleKeyPress={(event) => handleKeyPress(event, index)}
              spellCheck={spellCheck}
              handleKeyUp={(event) => handleKeyUp(event, index)}
            />
          );
        })}
      {showInstruction.current && (
        <div className="bullet-editor-instruction">
          {t("Press ENTER to type next point")}
        </div>
      )}
    </div>
  );
};

export default BulletEditor;
