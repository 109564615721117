import React from "react";

import { useAuth0 } from "../../react-auth0-spa";
import { useAuth } from "../../context/auth";
import { useHistory } from "react-router-dom";
import AgentClassService from "../../helpers/AgentClassService"
import Button from "../Button";
import HeroImage from "../../assets/images/access-denied.png";
import "./style.css";

import { useTranslation } from "react-i18next";

const AccessDenied = ({ hideLogout, location }) => {
  const { t } = useTranslation('translation');
  let history = useHistory();
  const { logout, isAuthenticated } = useAuth0();
  const { setAuthTokens } = useAuth();

  return (
    <div className="access-denied-page">
      <div className="sub-heading">{t('We are sorry :(')}</div>
      <div className="page-description">
        {t('You do not have permission to view this page.')}
      </div>
      <div className="page-description">
        {t('Please contact the administrator to know more.')}
      </div>
      {!hideLogout && (
        <div className="logout-btn-container">
          <Button
            buttonClass="custom-button primary-button"
            onClick={
              process.env && process.env.REACT_APP_TYPE === "login_widget"
                ? () => {
                    let initialToken = localStorage.getItem(
                      "widget_initial_token"
                    );
                    setAuthTokens(false);
                    localStorage.clear();
                    AgentClassService.clear();
                    window.location.href = `/?t=${initialToken}`;
                  }
                : window.SSO_PROVIDER === "auth0"
                ? () => {
                    localStorage.clear();
                    AgentClassService.clear();
                    logout({
                      returnTo: window.location.origin,
                    });
                  }
                : () => {
                    localStorage.clear();
                    AgentClassService.clear();
                    setAuthTokens(false);
                    window.location.href =
                      window.SSO_DOMAIN +
                      "/logout?redirect_uri=" +
                      window.location.origin;
                  }
            }
          >
            {t('LOGOUT')}
          </Button>
        </div>
      )}
      <div className="hero-image-container">
        <img className="hero-image" src={HeroImage} alt={t("Access Denied")} />
      </div>
    </div>
  );
};

export default AccessDenied;
