import React from "react";
import "emoji-mart/css/emoji-mart.css";

import { Picker } from "emoji-mart";
import "./style.css";

let excludedEmojis = [
  "partying_face",
  "bone",
  "beverage_box",
  "smiling_face_with_3_hearts",
  "hot_face",
  "cold_face",
  "woozy_face",
  "pleading_face",
  "yawning_face",
  "pinching_hand",
  "mechanical_arm",
  "mechanical_leg",
  "leg",
  "foot",
  "ear_with_hearing_aid",
  "tooth",
  "haired_man",
  "curly_haired_man",
  "white_haired_man",
  "red_haired_man",
  "bald_man",
  "bald_woman",
  "red_haired_woman",
  "red_haired_person",
  "curly_haired_woman",
  "white_haired_woman",
  "bald_person",
  "curly_haired_person",
  "white_haired_person",
  "deaf_person",
  "shrug",
  "deaf_woman",
  "deaf_man",
  "superhero",
  "male_superhero",
  "female_superhero",
  "supervillain",
  "male_supervillain",
  "female_supervillain",
  "standing_person",
  "man_standing",
  "woman_standing",
  "kneeling_person",
  "man_kneeling",
  "woman_kneeling",
  "person_with_probing_cane",
  "woman_with_probing_cane",
  "man_with_probing_cane",
  "person_in_motorized_wheelchair",
  "man_in_motorized_wheelchair",
  "woman_in_motorized_wheelchair",
  "person_in_manual_wheelchair",
  "man_in_manual_wheelchair",
  "woman_in_manual_wheelchair",
  "brown_heart",
  "white_heart",
  "orangutan",
  "guide_dog",
  "service_dog",
  "raccoon",
  "hippopotamus",
  "llama",
  "sloth",
  "otter",
  "skunk",
  "kangaroo",
  "badger",
  "swan",
  "flamingo",
  "peacock",
  "parrot",
  "mosquito",
  "microbe",
  "mango",
  "leafy_green",
  "garlic",
  "onion",
  "bagel",
  "waffle",
  "falafel",
  "butter",
  "salt",
  "moon_cake",
  "lobster",
  "oyster",
  "cupcake",
  "mate_drink",
  "ice_cube",
  "firecracker",
  "red_envelope",
  "softball",
  "flying_disc",
  "lacrose",
  "diving_mask",
  "yo-yo",
  "nazar_amulet",
  "kite",
  "jigsaw",
  "teddy_bear",
  "thread",
  "yarn",
  "compass",
  "bricks",
  "hindu_temple",
  "manual_wheelchair",
  "motorized_wheelchair",
  "auto_rickshaw",
  "skateboard",
  "parachute",
  "luggage",
  "lacrose",
  "ringed_planet",
  "goggles",
  "lab_coat",
  "safety_vest",
  "sari",
  "one_piece_swimsuit",
  "briefs",
  "shorts",
  "hiking_boot",
  "womans_flat_shoes",
  "ballet_shoes",
  "banjo",
  "abacus",
  "diya_lamp",
  "receipt",
  "axe",
  "probing_cane",
  "toolbox",
  "magnet",
  "test_tube",
  "petri_dish",
  "dna",
  "drop_of_blood",
  "adhesive_bandage",
  "stethoscope",
  "chair",
  "razor",
  "lotion_bottle",
  "safety_pin",
  "broom",
  "basket",
  "roll_of_paper",
  "sponge",
  "fire_extinguisher",
  "large_orange_circle",
  "large_yellow_circle",
  "large_green_circle",
  "large_purple_circle",
  "large_brown_circle",
  "large_red_square",
  "large_yellow_square",
  "large_green_square",
  "large_purple_square",
  "large_brown_square",
  "large_red_square",
  "large_blue_square",
];

const EmojiPickerCompo = (props) => {
  return (
    <Picker
      set={props.set}
      enableFrequentEmojiSort={true}
      onSelect={props.onSelect}
      native={props.native}
      emojisToShowFilter={(emoji) =>
        excludedEmojis.indexOf(emoji.short_names[0]) === -1
      }
      recent={[""]}
    />
  );
};

export default EmojiPickerCompo;
