import React, { useEffect, useState } from "react";
import ChatHeader from "../../components/ChatHeader";
import Select from "react-select";
import "./style.css";
import { useTranslation } from "react-i18next";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";
import Snackbar from "../../components/Snackbar";
import { useAuth } from "../../context/auth";
import { useAuth0 } from "../../react-auth0-spa";
import AgentClassService from  "../../helpers/AgentClassService"
const cachedClasses = {};

const SLSConfirm = () => {
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useAuth0();

  const {
    setAuthTokens,
  } = useAuth();
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);

  const { t } = useTranslation("translation");
  const makeApiCall = useFetch();
  const getSchools = async () => {
    try {
      const res = await makeApiCall.request(
        window.CHAT_API + "/api/v1/moe/organisations",
        {
          method: "GET",
          headers: authHeader(),
        }
      );
      if (res?.data?.organisations) {
        setSchools(
          res?.data?.organisations.map((o) => ({
            value: o._id,
            label: o?.name,
          }))
        );
      }
    } catch (error) {
      setSnackbar({
        isOpen: true,
        message: "Oops! Something went wrong.",
        type: "error",
      });
    }
  };

  const getClasses = async (id) => {
    try {
      if (cachedClasses[id]) {
        setClasses(cachedClasses[id]);
        return;
      }

      const res = await makeApiCall.request(
        window.CHAT_API + `/api/v1/moe/organisations/${id}/classes`,
        {
          method: "GET",
          headers: authHeader(),
        }
      );
      if (res?.data?.classes) {
        const resClasses = res?.data?.classes.map((o) => ({
          value: o._id,
          label: o?.name,
        }));
        setClasses(resClasses);
        cachedClasses[id] = resClasses;
      }
    } catch (error) {
      setSnackbar({
        isOpen: true,
        message: "Oops! Something went wrong.",
        type: "error",
      });
    }
  };

  const getMoeInfo = async () => {
    setIsLoading(true);
    try {
      const res = await makeApiCall.request(
        window.CHAT_API + "/api/v1/moe/redirect-url",
        {
          method: "GET",
          headers: authHeader(),
        }
      );
      if (res?.data?.redirect_url?.length) {
        window.location.href = res?.data?.redirect_url;
        return;
      }
      await getSchools();
    } catch (error) {
      setSnackbar({
        isOpen: true,
        message: "Oops! Something went wrong.",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMoeInfo();
  }, []);

  useEffect(() => {
    if (selectedSchool?.value) {
      getClasses(selectedSchool.value);
    }
  }, [selectedSchool]);

  const save = async () => {
    try {
      const res = await makeApiCall.request(
        window.CHAT_API + "/api/v1/moe/registrations",
        {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            class_id: selectedClass?.value,
          }),
        }
      );
      if (res?.data?.redirect_url) {
        window.location.href = res?.data?.redirect_url;
      } else {
        setSnackbar({
          isOpen: true,
          message: "Oops! Something went wrong.",
          type: "error",
        });
      }
    } catch (error) {
      setSnackbar({
        isOpen: true,
        message: "Oops! Something went wrong.",
        type: "error",
      });
    }
  };

  const beforeLogout = async() => {
    if (process.env && process.env.REACT_APP_TYPE === "login_widget") {
      let initialToken = localStorage.getItem("widget_initial_token");
      setAuthTokens(false);
      localStorage.clear();
      AgentClassService.clear();
      window.location.href = `/?t=${initialToken}`;
    } else if (window.SSO_PROVIDER === "auth0") {
      localStorage.clear();
      AgentClassService.clear();
      logout({
        returnTo: window.location.origin,
      });
    } else {
      localStorage.clear();
      AgentClassService.clear();
      setAuthTokens(false);
      window.location.href =
        window.SSO_DOMAIN + "/logout?redirect_uri=" + window.location.origin;
    }
  }

  if (isLoading) {
    return (
      <div className="d-flex flex-column chat-page-wrapper sls">
        <Snackbar
          isOpen={snackbar.isOpen}
          primaryMessage={snackbar.message}
          type={snackbar.type}
          onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        />
        <ChatHeader
          orgDefaultLang="en"
          showProfileEdit={false}
          beforeLogout={beforeLogout}
        />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column chat-page-wrapper sls">
      <Snackbar
        isOpen={snackbar.isOpen}
        primaryMessage={snackbar.message}
        type={snackbar.type}
        onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
      />
      <ChatHeader
        orgDefaultLang="en"
        showProfileEdit={false}
        beforeLogout={beforeLogout}
      />
      <div className="flex-1 d-flex flex-column align-items-center is-justify-content-flex-end pb-3">
        <div className="col-sm-12 col-md-4">
          <div className="p-2 title">
            <b>{t("Before we begin,")}</b>
          </div>
          <div className="p-2">
            {t("Select the school and class you belong to")}
          </div>
          <div className="col-12 p-2">
            <div className="sls-select-label pb-1">{t("School")}</div>
            <Select
              isSearchable={false}
              classNamePrefix="react-select-sls"
              components={{
                IndicatorSeparator: () => null,
              }}
              placeholder="Select School"
              options={schools}
              onChange={(e) => {
                setSelectedSchool(e);
              }}
              value={selectedSchool}
            />
          </div>
          <div className="col-12 p-2">
            <div className="sls-select-label pb-1">{t("Class")}</div>
            <Select
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
              placeholder="Change Class"
              options={classes}
              onChange={(e) => {
                setSelectedClass(e);
              }}
              value={selectedClass}
            />
          </div>
          <div className="text-center mt-3">
            <button
              className="blue-button"
              disabled={!selectedClass?.value}
              onClick={() => save()}
            >
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
      <div className="flex-1 bg"></div>
    </div>
  );
};
export default SLSConfirm;
