import React from "react";
import SuggestionButton from "../../components/SuggestionButton";

import UserAvatar from "../../assets/images/user-av.png";

export const message = "Hi there, I am Walter!";
export const messageTime = "5:30 PM, Today";
export const feedback = 1;

export const userMessage = (
  <SuggestionButton
    buttonLabel="Hi Walter! What can you do for me?"
    buttonAction={null}
  />
);
export const userMessageTime = "5:30 PM, Today";

export const faqData = [];

export const heading = "TOPIC SUGGESTIONS";

export const dummyFormSchema = {
  type: "object",
  properties: {
    firstName: {
      type: "string",
      title: "First name",
    },
    lastName: {
      type: "string",
      title: "Last name",
    },
    age: {
      type: "integer",
      title: "Age",
    },
    bio: {
      type: "string",
      title: "Bio",
    },
    password: {
      type: "string",
      title: "Password",
      minLength: 3,
    },
    date: {
      type: "string",
      title: "Date",
    },
    screenshot: {
      type: "string",
      title: "Screenshot",
    },
  },
};

export const uiSchema = {
  firstName: {
    "ui:emptyValue": "",
  },
  age: {
    "ui:widget": "updown",
    "ui:description": "(earthian year)",
  },
  bio: {
    "ui:widget": "textarea",
  },
  password: {
    "ui:widget": "password",
    "ui:help": "Hint: Make it strong!",
  },
  date: {
    "ui:widget": "date",
  },
  screenshot: {
    "ui:options": {
      inputType: "tel",
    },
    "ui:widget": "data-url",
  },
};

export const bookmarkDataExample = {
  data: {
    bookmark: [
      {
        _id: "12345",
        author_email: "string",
        content: "Sample bookmark.",
        created_at: "2020-04-16T21:14:42.290Z",
      },
      {
        _id: "123",
        author_email: "string",
        content: "Sample bookmark.",
        created_at: "2020-04-16T21:14:42.290Z",
      },
    ],
  },
};

export const dummyReplyData = {
  text:
    "this is a mixed content with[vimeo video](https://vimeo.com/435759073) and[image](https://www.noodlefactory.ai/home/wp-content/uploads/2020/05/rule-three.png) and || a new buuble, finnally an[youtube video](https://www.youtube.com/watch?v=yZJECIq5-Ac)  Good luck\n",
  bubbles: [
    {
      id: "d448add0b8375fa7aafa5798a6f1e2ae",
      template: "text",
      text: "this is a mixed content with",
      delay: 2000,
      html: "<p>this is a mixed content with</p>",
    },
    {
      id: "26b8f0732cfc75355dfbfc86e0edb2f6",
      template: "video",
      text: "[vimeo video](https://vimeo.com/435759073)",
      delay: 2000,
      html:
        '<iframe title="vimeo-player" src="https://player.vimeo.com/video/435759073" frameborder="0" allowfullscreen></iframe>',
      video: { url: "https://vimeo.com/435759073", provider: "vimeo" },
    },
    {
      id: "ca418e07fc6ad3bcc3ea16158ecea3f6",
      template: "text",
      text: "and",
      delay: 2000,
      html: "<p>and</p>",
    },
    {
      id: "7755826411dd4898fec0e68119d99115",
      template: "image",
      text:
        "[image](https://www.noodlefactory.ai/home/wp-content/uploads/2020/05/rule-three.png)",
      delay: 2000,
      html:
        '<p><img alt="image" src="https://www.noodlefactory.ai/home/wp-content/uploads/2020/05/rule-three.png" /></p>',
      image: {
        url:
          "https://www.noodlefactory.ai/home/wp-content/uploads/2020/05/rule-three.png",
      },
    },
    {
      id: "f537758eb27ad563b3d6d8b431abcfd9",
      template: "text",
      text: "and  a new buuble, finnally an",
      delay: 2000,
      html: "<p>and  a new buuble, finnally an</p>",
    },
    {
      id: "cae3fb5f9efc62442c35a20638d8c9f2",
      template: "video",
      text: "[youtube video](https://www.youtube.com/watch?v=yZJECIq5-Ac)",
      delay: 2000,
      html:
        '<iframe src="https://www.youtube.com/embed/yZJECIq5-Ac" frameborder="0" allow="encrypted-media" allowfullscreen></iframe>',
      video: {
        url: "https://www.youtube.com/watch?v=yZJECIq5-Ac",
        provider: "youtube",
      },
    },
    {
      id: "7c2f357c8a0140c3e3b51f2b93240321",
      template: "text",
      text: "Good luck",
      delay: 2000,
      html: "<p>Good luck</p>",
    },
  ],
  buttons: [],
  _id: "5f223af5a0b765187b112007",
};

export const getUserAvatarIcon = () => {
  // let imgAr = [Pattern1, Pattern2, Pattern3, Pattern4, Pattern5, Pattern6];
  // let num = Math.floor(Math.random() * imgAr.length);
  // return imgAr[num];
  return UserAvatar;
};
