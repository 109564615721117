import React, { Children, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import FocusTrap from 'focus-trap-react';

import Button from "../Button";
import "./style.css";

import { useTranslation } from "react-i18next";

const Modal = ({
  children,
  title,
  subTitle,
  buttonLabel,
  buttonAction,
  openModal,
  onRequestClose,
  bannerBgColor,
  bannerImg,
  cancelButtonLabel,
  showCloseIcon = false,
}) => {
  const { t } = useTranslation("translation");
  const modalRef = useRef(null);
  const [closeClicked, setCloseClicked] = useState(false);
  const [mainButtonClicked, setMainButtonClicked] = useState(false);

  useEffect(() => {
    if (openModal && modalRef.current) {
      modalRef.current.focus();
    }
  }, [openModal]);

  const whileClose = () => {
    setCloseClicked(true);
    onRequestClose();
  };

  const whileMainAction = () => {
    setMainButtonClicked(true);
    buttonAction();
  };

  if (!openModal) {
    if (mainButtonClicked || closeClicked) {
      setCloseClicked(false);
      setMainButtonClicked(false);
    }
    return null;
  }

  return (
    <FocusTrap>
      <div
        className="chat-modal" 
        ref={modalRef}
        tabIndex="1"
      >
        <div className="modal-content">
          {showCloseIcon && (
            <span className="close" onClick={onRequestClose}>
              &times;
            </span>
          )}

          {bannerImg && (
            <div
              className="modal-banner"
              style={{ backgroundColor: bannerBgColor }}
            >
              <img
                src={bannerImg}
                className="modal-banner-img"
                alt={t("Please confirm")}
              />
            </div>
          )}
          {title && (
            <div className="modal-title">
              {title}
              {subTitle && <div className="modal-subtitle">{subTitle}</div>}
            </div>
          )}
          <div className="modal-body">
            {Children.toArray(children)}
            <div className="modal-button-container">
              <Button
                buttonClass="custom-button grey-button"
                onClick={whileClose}
                isDisabled={closeClicked || mainButtonClicked}
              >
                {cancelButtonLabel ? cancelButtonLabel : t("Cancel")}
              </Button>
              {buttonLabel && buttonAction && (
                <Button
                  buttonClass="custom-button primary-button"
                  onClick={whileMainAction}
                  isDisabled={closeClicked || mainButtonClicked}
                >
                  {buttonLabel}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

Modal.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonAction: PropTypes.func,
  openModal: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default Modal;
