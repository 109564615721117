import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { chatDiscussionRoutes } from "../../config/appRoutes";
import { useTranslation } from "react-i18next";
import history from "../../history";
import { CHAT_DISSCUSSION } from "../../config/routeMapping";
import Discussion from ".";
import DiscussionDetail from "./DiscussionDetail";
import { useAuth0 } from "../../react-auth0-spa";
import { useAuth } from "../../context/auth";

const ChatDiscussion = () => {
  const { t } = useTranslation("translation");
  const [thread, setThread] = useState("");
  const { getTokenSilently } =  useAuth0();
  const { authTokens, setAuthTokens } = useAuth();

  const getSub = async () => {
    const token = await getTokenSilently();
    token && setAuthTokens({
      sub: JSON.parse(atob(token.split(".")[1]))
        ?.sub,
    });
  }

  useEffect(() => {
    if(!authTokens?.sub){
      getSub();
    }
  }, [authTokens?.sub]);

  return (
    <div className="chat-discussion-container">
      <div className="pb-3">
        <b>{t("Question Board")}</b>
      </div>
      {!thread ? (
        <Discussion isTeam={false} setThread={setThread}/>
      ) : (
        <DiscussionDetail thread={thread} isChat={true} match={{params: {id: setThread}}} setThread={setThread}/>
      )}
      {/* <div>okela </div>
      <Route path="/chat">
        <Switch>
          {chatDiscussionRoutes.map((route, index) => {
            return <Route exact {...route} />;
          })}
        </Switch>
      </Route> */}
    </div>
  );
};
export default ChatDiscussion;
