import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";

let  localBaseUrl = 'https://locales.staging.noodlefactory.ai'
if(window?.ENVIRONMENT === 'production') { 
  localBaseUrl = 'https://locales.noodlefactory.ai'
}

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: "en",
    fallbackLng: false,
    debug: false, // windowObj.REACT_DEVELOPMENT_MODE === 'staging'

    // have a common namespace used around the full app
    ns: ["translation"],
    defaultNS: "translation",
    lowerCaseLng: true,
    load: 'currentOnly',
    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: `${localBaseUrl}/locales/seamless-chatbot/translations/{{lng}}/translation.json`,
    },
    
    react: {
      useSuspense: false, // TODO: enable React Suspense
    },
  });

const language_mapping = {
  en: "en",
  es: "es",
  "pt-pt": "pt-pt",
  id: "id",
};

export const getLangValue = (key) => {
 return language_mapping[key] ? language_mapping[key] : "en";
}

export default i18n;
