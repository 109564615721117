import React, { useEffect, useState } from "react";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";
import "./style.css";
import Snackbar from "../../components/Snackbar";
import IconLabelButton from "../../components/IconLabelButton";
import answerQuestionsIcon from "../../assets/images/svg/answerquestions.svg";
import chatIcon from "../../assets/images/svg/chat-icon-blue.svg";
import forwardIcon from "../../assets/images/svg/suggest-ans-icon.svg";

import { useTranslation } from "react-i18next";

const AllAnswerQuestions = (props) => {
  const { t } = useTranslation('translation');
  const makeApiCall = useFetch();
  const [showQuestions, setShowQuestions] = useState(true);
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const [acknowledgedAnswers, setAcknowledgedAnswers] = useState([]);
  const [selectedSuggestAnswer, setSelectedSuggestAnswer] = useState(-1);

  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    setInputValue("");
  }, [selectedSuggestAnswer]);

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    getUnansweredQuestions();
  }, []);

  // Fetching unanswered questions.
  const getUnansweredQuestions = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatbot/unanswered-questions",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setUnansweredQuestions(json.data.unanswered_questions);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching unanswered questions"),
            type: "error",
          });
        }
      });
  };

  // Fetching acknowledged answers.
  const getAcknowledgedAnswers = () => {
    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/chatbot/unanswered-questions" +
          "?is_acknowledged=1",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setAcknowledgedAnswers(json.data.unanswered_questions);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching acknowledged answers."),
            type: "error",
          });
        }
      });
  };

  const suggestAnswer = (unanswered_question_id, suggested_answer) => {
    makeApiCall
      .request(
        window.CHAT_API +
          `/api/v1/chatbot/unanswered-questions/${unanswered_question_id}/suggested-answers`,
        {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({
            suggested_answer,
          }),
        }
      )
      .then((json) => {
        if (json.status.code === 200) {
          getUnansweredQuestions();
          setSnackbar({
            isOpen: true,
            message: t("Your answer is sent successfully."),
            type: "success",
          });
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });
  };

  return (
    <div className="all-answerquestions-container">
      <div className="all-answerquestions-header flex-wrap">
        <div className="left-partition">
          <div className="header-heading">
            {showQuestions ? t("Answer Questions") : t("Acknowledged Answers")}
          </div>
        </div>
        <div className="right-partition">
          <IconLabelButton
            buttonLabel={
              showQuestions ? t("Acknowledged Answers") : t("Answer Questions")
            }
            onClick={() => {
              setShowQuestions(!showQuestions);
              if (showQuestions) {
                getAcknowledgedAnswers();
              }
            }}
          />
        </div>
        <div className="w-100">{showQuestions ? t("Suggest answers to fellow learners’ queries.") : t("Responses you've proposed for fellow learners' questions, which have been reviewed and approved by the admin")}</div>
      </div>
      <div className="chat-feature-panel-body px-0">
        {showQuestions ? (
          <div>
            {unansweredQuestions.map((element, idx) => {
              return (
                <>
                  <div className="answerquestion-item">
                    <div className="left-partition">
                      <img
                        className="answerquestion-item-icon"
                        alt={t("answerQuestionIcon")}
                        src={answerQuestionsIcon}
                      />
                      <div>
                        <div className="answerquestion-item-class">
                          {(element.class_name) ? element.class_name : t("No Class")}
                        </div>
                        <div className="answerquestion-item-question">
                          {(element.text) ? element.text : t("No Question")}
                        </div>
                      </div>
                    </div>
                    <div className="right-partition">
                      <IconLabelButton
                        buttonLabel={
                          element.suggested_answer === null
                            ? t("Suggest Answer")
                            : t("View Suggested Answer")
                        }
                        onClick={() => {
                          setSelectedSuggestAnswer(idx);
                          if (selectedSuggestAnswer === idx) {
                            setSelectedSuggestAnswer(-1);
                          }
                        }}
                      />
                      <button 
                        className="btn p-0 sugest-ques-btn"
                        onClick={() => {
                          setSelectedSuggestAnswer(idx);
                          if (selectedSuggestAnswer === idx) {
                            setSelectedSuggestAnswer(-1);
                          }
                        }}
                      >
                        <img src={chatIcon} alt="" />
                      </button>
                    </div>
                  </div>
                  {selectedSuggestAnswer !== -1 &&
                  selectedSuggestAnswer === idx &&
                  element.suggested_answer !== null ? (
                    <div className="answerquestion-item-suggest">
                      <div className="answerquestion-item-title">
                        {t("Suggested Answer")}
                      </div>
                      <div className="answerquestion-item-suggest-inputarea">
                        <div className="answerquestion-item-suggest-answer">
                          {element.suggested_answer}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {selectedSuggestAnswer !== -1 &&
                  selectedSuggestAnswer === idx &&
                  element.suggested_answer === null ? (
                    <>
                      <div className="answerquestion-item-suggest">
                        <div className="answerquestion-item-title">
                          {t("Suggested Answer")}
                        </div>
                        <div className="answerquestion-item-suggest-inputarea">
                          <input
                            onChange={(e) => {
                              setInputValue(e.target.value);
                            }}
                            className="answerquestion-item-suggest-input"
                            placeholder={t("Enter suggested answer here")}
                          ></input>
                          <button
                            onClick={() => {
                              suggestAnswer(element._id, inputValue);
                            }}
                            className="answerquestion-item-suggest-confirm"
                          >
                            {t("Suggest")}
                          </button>
                          <button
                            onClick={() => {
                              suggestAnswer(element._id, inputValue);
                            }}
                            className="btn py-0 px-2 answerquestion-item-suggest-confirm-mobile"
                          >
                            <img src={forwardIcon} alt="" />
                          </button>
                          <button
                            onClick={() => {
                              setSelectedSuggestAnswer(-1);
                            }}
                            className="answerquestion-item-suggest-cancel"
                          >
                            {t("Cancel")}
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              );
            })}
          </div>
        ) : (
          <div>
            {acknowledgedAnswers.map((element, idx) => {
              return (
                <>
                  <div className="answerquestion-item">
                    <div className="left-partition">
                      <img
                        className="answerquestion-item-icon"
                        alt={t("answerQuestionIcon")}
                        src={answerQuestionsIcon}
                      />
                      <div>
                        <div className="answerquestion-item-class">
                          {(element.class_name) ? element.class_name : t("No Class")}
                        </div>
                        <div className="answerquestion-item-question">
                          {(element.text) ? element.text : t("No Question")}
                        </div>
                      </div>
                    </div>
                    <div className="right-partition">
                      <IconLabelButton
                        buttonLabel={t("View Acknowledged Answer")}
                        onClick={() => {
                          setSelectedSuggestAnswer(idx);
                          if (selectedSuggestAnswer === idx) {
                            setSelectedSuggestAnswer(-1);
                          }
                        }}
                      />
                      
                    </div>
                  </div>
                  {selectedSuggestAnswer !== -1 &&
                  selectedSuggestAnswer === idx &&
                  element.suggested_answer !== null ? (
                    <div className="answerquestion-item-suggest">
                      <div className="answerquestion-item-title">
                        {t("Acknowledged Answer")}
                      </div>
                      <div className="answerquestion-item-suggest-inputarea">
                        <div className="answerquestion-item-suggest-answer">
                          {element.suggested_answer}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>
        )}
        <div className="acknowledge-btn-bottom acknowledge-btn-bottom text-center mt-3">
          <IconLabelButton
            buttonLabel={
              showQuestions ? t("Acknowledged Answers") : t("Answer Questions")
            }
            onClick={() => {
              setShowQuestions(!showQuestions);
              if (showQuestions) {
                getAcknowledgedAnswers();
              }
            }}
          />
        </div>
      </div>
      <Snackbar
        isOpen={snackbar.isOpen}
        primaryMessage={snackbar.message}
        type={snackbar.type}
        onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
      />
    </div>
  );
};

export default AllAnswerQuestions;