import { useCallback, useRef, useState } from "react";


export const useStateRef = (initialState) => {
  const [state, setState] = useState(initialState);
  const ref = useRef(state);

  const dispatch = useCallback((setStateAction) => {
    ref.current =  typeof setStateAction === 'function' ? setStateAction(ref.current)
      : setStateAction;

    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};
