import React, { useEffect, useState } from "react";
import "react-day-picker/lib/style.css";

// import "./style.css";


import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import * as _ from "lodash";

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "#5F5E5E",
  }),
};

const SearchableCheckbox = (props) => {
  const { t } = useTranslation("translation");
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [selectedSearchableValues, setSelectedSearchableValues] = useState(props.value ? props.value.map((item, index) => ({
    label: item,
    value: item,
    index: index + 1,
  })) : []);

  useEffect(() => {
    setCheckboxOptions(
      props?.options?.enumOptions
        ? props?.options?.enumOptions?.map((item, index) => {
            return { ...item, index: index };
          })
        : []
    );
  }, []);

  const handleSelection = (item) => {
    const allValue = [...selectedSearchableValues];
    allValue.push(item);
    setSelectedSearchableValues(allValue);
    const options = [...checkboxOptions];
    setCheckboxOptions(options.filter((opt) => opt.index !== item.index));
    props.onChange([...new Set(allValue.map((d) => d.value))]);
  };

  const removeValueObject = (item, index) => {
    let newList = [...selectedSearchableValues];
    newList.splice(index, 1);
    setSelectedSearchableValues(newList);

    const options = [...checkboxOptions];
    options.push(item);
    setCheckboxOptions(_.orderBy(options, "index", "asc"));
    props.onChange([...new Set(newList.map((d) => d.value))]);
  };

  return (
    <div className={`dropdown_wrapper zindex-ini`}>
      <ReactSelect
        classNamePrefix="checkbox-dropdown"
        menuPosition="fixed"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={customStyles}
        value={selectedSearchableValues}
        required={props.required}
        onChange={handleSelection}
        options={checkboxOptions}
        placeholder={props.placeHolder}
        isSearchable={true}
        controlShouldRenderValue={false}
        isOptionSelected={(option, selectValue) =>
          selectValue.some((i) => i === option)
        }
      />

      <div className="mt-3">
        {selectedSearchableValues &&
          selectedSearchableValues.map((item, index) => (
            <span className="selected-date-preview-item">
              {item?.value}{" "}
              <button
                type="button"
                onClick={() => removeValueObject(item, index)}
                className="selected-date-preview-item-delete"
              >
                <i className="icon-close" />
              </button>
            </span>
          ))}
      </div>
    </div>
  );
};

export default SearchableCheckbox;
