import React, { useState } from "react";

import PropTypes from "prop-types";
import Moment from "react-moment";

import NotificationCheckbox from "../NotificationCheckbox";
import IconLabelButton from "../IconLabelButton";

import "./style.css";

import { useTranslation } from "react-i18next";

const NotificationItem = ({
  index,
  key,
  content,
  onClick,
  onCheckboxChange,
  onDelete,
  isOutbox,
  onResend,
  showPointer,
  showFullDescription,
  onCustomButtonClick,
  showNotificationButtons,
  closeNotificationPanel,
}) => {
  const { t } = useTranslation("translation");
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickMore = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`notification-container ${
        content.is_read ? "notification-read" : "notification-unread"
      } ${index === 0 ? "border-top" : ""}`}
      onClick={onClick}
      id={key}
      style={{ cursor: showPointer ? "pointer" : "default" }}
      role="alert"
    >
      <div className="notification-icon-container">
        <i className={"notification-icon icon-bell"}></i>
      </div>

      <div className="notification-content-container">
        <div className="flex-container notification-content-header">
          <div className="notification-title">{content.title}</div>

          <div className="flex-container">
            {!isOutbox && (
              <>
                <button className="option-delete-btn" onClick={onDelete}>
                  <i className="icon-delete" />
                </button>
                <NotificationCheckbox
                  onCheckboxChange={onCheckboxChange}
                  checked={!content.is_read}
                />
              </>
            )}

            {isOutbox && (
              <>
                <IconLabelButton
                  buttonLabel={t("EDIT AND RESEND")}
                  onClick={onResend}
                />
              </>
            )}
          </div>
        </div>

        <div>
          {showFullDescription && (
            <div className="notification-content">
              {content?.content_html ? content.content_html : content.content}
            </div>
          )}
          {!showFullDescription && (
            <>
              {isExpanded === true && content.content_html && (
                <div className="notification-content">
                  <div
                    key={content._id}
                    className="content-format"
                    dangerouslySetInnerHTML={{ __html: content.content_html }}
                  ></div>
                  {content.content !== content.excerpt && (
                    <>
                      <button
                        className="notification-view-more"
                        onClick={(event) => onClickMore(event)}
                      >
                        {isExpanded === true
                          ? t("view less")
                          : t("view more")}
                      </button>
                    </>
                  )}
                </div>
              )}
              {isExpanded === true && !content.content_html && (
                <div className="notification-content">
                  {content.content}
                  {content.content !== content.excerpt && (
                    <>
                      <button
                        className="notification-view-more"
                        onClick={(event) => onClickMore(event)}
                      >
                        {isExpanded === true
                          ? t("view less")
                          : t("view more")}
                      </button>
                    </>
                  )}
                </div>
              )}

              {!isExpanded && (
                <div className="notification-content">
                  <p dangerouslySetInnerHTML={{ __html: content.excerpt }}></p>
                  {content.content !== content.excerpt && (
                    <>
                      <button
                        className="notification-view-more"
                        onClick={(event) => onClickMore(event)}
                      >
                        {isExpanded === true
                          ? t("view less")
                          : t("view more")}
                      </button>
                    </>
                  )}
                </div>
              )}

              {/* <div className="notification-content">
              <p dangerouslySetInnerHTML={{ __html: isExpanded === true ? (content?.content_html ? content.content_html : content.content) : content.excerpt }}></p>
              {content.content !== content.excerpt && (
                <>
                  <button
                    className="notification-view-more"
                    onClick={(event) => onClickMore(event)}
                  >
                    {isExpanded === true ? t("view less") : t("...view more")}
                  </button>
                </>
              )}
            </div> */}
            </>
          )}
        </div>
        {showNotificationButtons && (
          <div className="notification-btn-wrapper">
            {content.buttons.map((button, index) => (
              <button
                key={index}
                className="notification-btn"
                onClick={() => {
                  closeNotificationPanel();
                  onCustomButtonClick(button, "custom");
                }}
              >
                {button.label}
              </button>
            ))}
          </div>
        )}
        <div className="notification-time">
          {
            <Moment format="DD/MM/YYYY HH:mm" utc local>
              {content.created_at}
            </Moment>
          }
        </div>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  content: PropTypes.object,
};

export default NotificationItem;
