import React, { useState } from "react";

import "./style.css";

import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/auth";

import bookmarkImg from "../../assets/images/svg/bookmark-outline.svg";
import bookmarkDarkImg from "../../assets/images/svg/bookmark-dark.svg";

const UserChatBubble = ({
  avatarIcon,
  message,
  messageTime,
  isButtonContainer,
  bookmarkAction,
  copyAction,
}) => {
  const [showFavBar, setShowFavBar] = useState(false);
  const { t } = useTranslation("translation");
  const userReplyDivRef = React.useRef(null);
  const { bubbleIcon } = useAuth();
  React.useEffect(() => {
    if (userReplyDivRef && userReplyDivRef.current) {
      // userReplyDivRef.current.scrollIntoView();
    }
  }, []);

  return (
    <div
      className={
        isButtonContainer
          ? "user-chat-bubble-container user-chat-bubble-hasbutton-container "
          : "user-chat-bubble-container"
      }
      role="alert"
      ref={userReplyDivRef}
    >
      <div
        className="user-chat-message-container"
        onClick={() => {
          setShowFavBar(!showFavBar);
        }}
      >
        {!isButtonContainer && (
          <div className="user-chat-message">
            {message}
            {showFavBar && (
              <div className="userbubble-bookmark">
                {typeof message !== "object" && (
                  <span
                    className={`bot-reply-feedback-icon${
                      "feedback" === 0
                        ? " bot-reply-feedback-icon-selected"
                        : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      // setBookmarkFeedback( true )
                      bookmarkAction("bot", message);
                    }}
                    title="bookmark"
                  >
                    <img
                      src={
                        "bookmarkFeedback" === "boolean"
                          ? bookmarkDarkImg
                          : bookmarkImg
                      }
                      alt=" "
                    />
                  </span>
                )}
              </div>
            )}
          </div>
        )}
        {isButtonContainer &&
          (typeof message !== "object" ? (
            <div
              className="user-button-container "
              dangerouslySetInnerHTML={{
                __html: message.replace(/\^/gim, "<br>"),
              }}
            ></div>
          ) : (
            <div className="user-button-container">{message}</div>
          ))}

        {messageTime && <div className="user-chat-time">{messageTime}</div>}
      </div>
      {!isButtonContainer && (
        <div className="user-chat-avatar-container" style={{ backgroundImage: `url(${bubbleIcon || avatarIcon})` }}>
        </div>
      )}

      {/* {!isButtonContainer &&
        (typeof message !== "object" ? (
          <div className="more-menu-wrapper">
            <button
              className="more-menu-button"
              onClick={() => bookmarkAction("user", message)}
              title={t("Menu")}
            >
              <i className="icon-star-outline"></i>
            </button>
          </div>
        ) : null)} */}
    </div>
  );
};

export default UserChatBubble;
