import React, { useState, useEffect } from "react";
import SuggestionButton from "../SuggestionButton";
import "./style.css";

const ButtonPaging = ({ buttons, onCustomButtonClick, disabled }) => {
  const [isMobile, setIsMobile] = useState(false);
  const maxRow = 3;
  const perRow = isMobile ? 1 : 3;

  const [paging, setPaging] = useState({
    currentPage: 1,
    totalPage: 1,
  });

  const handleResize = () => {
    if (window.innerWidth && window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth && window.innerWidth < 769) {
      setIsMobile(true);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const pageCount = Math.ceil(buttons.length / (maxRow * perRow));
    
    setPaging({
      ...paging,
      totalPage: pageCount,
    });
  }, [buttons?.length, isMobile]);

  const renderButtons = () => {
    const buttonRows = [[], [], []];
    buttonRows.forEach((row, rowIndex) => {
      for (let i = 0; i < perRow; i++) {
        const btnIdex =
          rowIndex * perRow + (paging.currentPage - 1) * maxRow * perRow + i;
        buttons[btnIdex] && row.push(buttons[btnIdex]);
      }
    });
    const btnRender = buttonRows.map((row, rowI) => (
      <div className="button-row">
        {row.map((btn, i) => (
          <SuggestionButton
            disabled={disabled}
            key={rowI + "_" + i}
            buttonLabel={btn?.label}
            buttonAction={() => onCustomButtonClick(btn)}
          />
        ))}
      </div>
    ));
    return btnRender;
  };

  const renderPreBtn = () => {
    const { currentPage, totalPage } = paging;

    return (
      <>
        {currentPage !== 1 && (
          <SuggestionButton
            disabled={disabled}
            key="prev"
            buttonLabel={`Previous Page ${currentPage - 1}/${totalPage}`}
            buttonAction={() =>
              setPaging({
                ...paging,
                currentPage: currentPage - 1,
              })
            }
          />
        )}
      </>
    );
  };

  const rednerNextBtn = () => {
    const { currentPage, totalPage } = paging;

    return (
      <>
        {currentPage !== totalPage && (
          <SuggestionButton
            disabled={disabled}
            key="next"
            buttonLabel={`Next Page ${currentPage + 1}/${totalPage}`}
            buttonAction={() =>
              setPaging({
                ...paging,
                currentPage: currentPage + 1,
              })
            }
          />
        )}
      </>
    );
  };

  const renderPagingButton = () => {
    const { totalPage } = paging;
    if (totalPage === 1) {
      return null;
    }
    if (isMobile) {
      return (
        <>
          <div> {renderPreBtn()}</div>
          <div> {rednerNextBtn()}</div>
        </>
      );
    } else {
      return (
        <>
          {renderPreBtn()}
          {rednerNextBtn()}
        </>
      );
    }
  };

  return (
    <div className="buttons-paging">
      {renderButtons()}
      {renderPagingButton()}
    </div>
  );
};

export default ButtonPaging;
