import React, { useState, useEffect } from "react";
import { EditorState, Modifier } from "draft-js";

import MathSymbolPicker from "../MathSymbolPicker";
import math from "../../assets/images/editor/math.png";

import { useTranslation } from "react-i18next";

const SymbolOption = (props) => {
  const { t } = useTranslation('translation');
  const [expanded, setExpanded] = useState(false);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const onSymbolSelect = (symbol) => {
    const { editorState, onChange } = props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      symbol,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));

    doCollapse();
  };

  const renderModal = () => {
    return (
      <div onClick={stopPropagation} className="rdw-emoji-modal" role="region">
        <MathSymbolPicker onSelect={onSymbolSelect} />
      </div>
    );
  };

  const expandCollapse = () => {
    if (!expanded) {
      props.modalHandler.closeAllModals();
    }
    setExpanded((expanded) => !expanded);
  };

  const doCollapse = () => {
    setExpanded(false);
  };

  return (
    <div
      aria-haspopup="true"
      aria-label="rdw-link"
      className="rdw-link-wrapper"
    >
      <div
        onClick={expandCollapse}
        className="rdw-option-wrapper markdown-toolbar-option"
        role="button"
        aria-label="Insert symbol"
        aria-expanded={expanded}
        title="Insert symbol"
      >
        <img src={math} alt={t("S")} />
      </div>
      {expanded ? renderModal() : undefined}
    </div>
  );
};

export default SymbolOption;
