import React from "react";

import { useAuth0 } from "../../react-auth0-spa";
import { useAuth } from "../../context/auth";
import AgentClassService from "../../helpers/AgentClassService"
import Button from "../Button";
import HeroImage from "../../assets/images/empty-data.png";
import "./style.css";

import { useTranslation } from "react-i18next";

const NoAgentFound = ({ hideLogout }) => {
  const { t } = useTranslation("translation");
  const { logout, isAuthenticated } = useAuth0();
  const { setAuthTokens } = useAuth();

  return (
    <div className="no-agent-found-page">
      <div className="sub-heading">Oh no :(</div>
      <div className="page-description">
        {t("Looks like you do not have access to any chat agent yet.")}
      </div>
      <div className="page-description">
        {t("Please reach out to your administrator or contact us on {")}
        {
          <a href="mailto:support@noodlefactory.ai">
            "support@noodlefactory.ai"
          </a>
        }
        {"}."}
      </div>
      {!hideLogout && (
        <div className="logout-btn-container">
          <Button
            buttonClass="custom-button primary-button"
            onClick={
              process.env && process.env.REACT_APP_TYPE === "login_widget"
                ? () => {
                    let initialToken = localStorage.getItem(
                      "widget_initial_token"
                    );
                    setAuthTokens(false);
                    localStorage.clear();
                    AgentClassService.clear();
                    window.location.href = `/?t=${initialToken}`;
                  }
                : window.SSO_PROVIDER === "auth0"
                ? () => {
                    localStorage.clear();
                    AgentClassService.clear();
                    logout({
                      returnTo: window.location.origin,
                    });
                  }
                : () => {
                    localStorage.clear();
                    AgentClassService.clear();
                    setAuthTokens(false);
                    window.location.href =
                      window.SSO_DOMAIN +
                      "/logout?redirect_uri=" +
                      window.location.origin;
                  }
            }
          >
            LOGOUT
          </Button>
        </div>
      )}
      <div className="hero-image-container">
        <img className="hero-image" src={HeroImage} alt={t("Access Denied")} />
      </div>
    </div>
  );
};

export default NoAgentFound;
