import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useAuth } from "../context/auth";
import WidgetLoginPage from "../containers/WidgetLoginPage";
import { useHistory } from "react-router-dom";
import useFetch from "../helpers/remote";
import { authHeader } from "../helpers/auth-header";
import LoadingAnimation from "../components/LoadingAnimation";
import SpinnerLoader from "../components/SpinderLoader";
import { loginWidgetRoutes, teamsRoutes } from "../config/appRoutes";
import { Instruction } from "../containers/TeamDiscussion/Instruction";
import AgentClassService from "../helpers/AgentClassService";

function TeamRouteAuth({ component: Component, path, ...rest }) {
  let history = useHistory();
  // let makeApiCall = useFetch();
  // const mockToken =
  //   "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik1EQTNNVFJEUlVReVFrVkZPRU5DUlVNNFFVRTROekUyTVVaRk1ETTFSamhCTWtNNE9FRTRNdyJ9.eyJodHRwczovL25vb2RsZWZhY3RvcnkuYWkvcGFzc3dvcmRfZXhwaXJlc19zb29uIjp0cnVlLCJpc3MiOiJodHRwczovL2Rldi1uZi5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NjJmZGUyMWQ4MTk5ZTJkZmRjZWQ4YTY1IiwiYXVkIjpbImh0dHBzOi8vYXBpLnN0YWdpbmcubm9vZGxlZmFjdG9yeS5haS8iLCJodHRwczovL2Rldi1uZi5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNjk3MTc1ODU3LCJleHAiOjE2OTcxNzYxNTcsImF6cCI6IjNFR09lQ0NsT3hWVEpMQ1pvV1ZvSXdzMkRXRGtocURDIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCJ9.LWsZ4FOzzWTBOvUN5_Zn1Lrc-TI6n-WfknV7krs6ruIzGNAfqB7fyq0JiM0eGCXBhHmujhTbTpirifsLvDCYvJL2dJcOIgOGT1P6LNh-llPhJTVUXdU_0yvFMpzPp-uQ6cddkhAMIb3D7Xf7UYRXgalbyH_bHvIQ2GNBtTV6SX8Aidi-iCOvpn-dsPW9iyr4Tr04Voh8oU9M88YenNGFFtIiL-IfQYQHtbfPcCqypliYNBpk0RLdLK5NhsWr4ORawwXse93Q0XzqOpF5nOweb7Rcp90RFD9csh4fw7Daq--eTDKGQk0JewynFmKjrhzUtCMuAi8zr5hnCzodVFCrTg";
  const { authTokens, setAuthTokens } = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const authenQuery = window.location.search.split("?")[1];

  const fetchToken = async () => {
    try {
      const res = await fetch(
        window.CHAT_API + "/api/v1/msteams/register-channel-tab?" + authenQuery,
        {
          method: "POST",
        }
      );
      // .then((res) => {

      if (!res.ok) {
        const json = await res.json();
        throw new Error(`${res.status} ${json?.status?.message} `);
      }
      // })
      const json = await res.json();
      if (json?.data?.token) {
        setAuthTokens({
          access_token: json?.data?.token?.access_token,
          scope: "openid profile email",
          token_type: "Bearer",
          sub: JSON.parse(atob(json?.data?.token?.access_token.split(".")[1]))
            ?.sub,
        });
        AgentClassService.setSelectedAgent(json?.data?.agent_id);
        AgentClassService.setSelectedClass(json?.data?.connection_id);
        // setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  useEffect(() => {
    if (authTokens?.access_token) {
      setLoading(false);
    }
  }, [authTokens?.access_token]);

  useEffect(() => {
    localStorage.clear();
AgentClassService.clear();
    // if (!authTokens?.access_token) {
    setLoading(true);
    fetchToken();
    // }
    // setLoading(false);
  }, []);

  if (loading) {
    return <SpinnerLoader />;
  }

  if (error) {
    return <Instruction />;
  }

  return (
    // <Route exact path="/team">
    <Route>
      <Switch>
        {teamsRoutes.map((route, index) => {
          return <Route exact {...route} />;
        })}
      </Switch>
    </Route>
    // </Route>

    // <Route
    //   history={history}
    //   {...rest}
    //   render={(matchProps) => <Component {...matchProps} />}
    // />
  );
}

export default TeamRouteAuth;
