import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";
import Moment from "react-moment";

import "./style.css";

import { useTranslation } from "react-i18next";

const BadgePage = (props) => {
  const { t } = useTranslation('translation');
  const makeApiCall = useFetch();
  let history = useHistory();

  const [badgeData, setBadgeData] = useState({});

  useEffect(() => {
    getBadgeDetails();
  }, []);

  const getBadgeDetails = () => {
    if (props.match.params.badgeId) {
      makeApiCall
        .request(
          `${window.CHAT_API}/api/v1/badge/badges/${props.match.params.badgeId}`,
          {
            method: "GET",
            headers: authHeader(),
          },
          true
        )
        .then((json) => {
          if (json.status.code === 200) {
            setBadgeData(json.data.badge);
          }
        });
    }
  };

  return (
    <div className="trophy-room-page-container">
      <div className="page-header">
        <button
          className="header-btn flex-one"
          onClick={() => {
            history.push("/");
          }}
          role="link"
        >
          <i className="icon-left-chevron" />
          {t("Back to chat")}
        </button>
        <div className="header-heading flex-one">
          <h3 className="page-heading">
            {badgeData.badge_type
              ? badgeData.badge_type.name.split("_").join(" ")
              : t("Earned Badge")}
          </h3>
        </div>
        <div className="flex-one"></div>
      </div>
      <div className="badge-page-content">
        <div className="clearfix" />
        <div className="badge-page-img-container">
          <img
            className="badge-page-img"
            src={badgeData.badge_type ? badgeData.badge_type.image : ""}
          />
        </div>
        <p className="badge-page-description">
          {badgeData.badge_type && badgeData.badge_type.description}
        </p>
        <p className="badge-page-date">
          {t("Earned on")}{" "}
          <Moment format="DD/MM/YYYY HH:mm" utc local>
            {badgeData.created_at}
          </Moment>
        </p>
      </div>
    </div>
  );
};

export default BadgePage;
