import React from "react";

const SummaryCard = ({ title, value }) => {
  return (
    <div className="summary-card">
      <p className="summary-title">{title}</p>
      <p className="summary-value">{value}</p>
    </div>
  );
};

export default SummaryCard;
