import React from "react";

import Button from "../Button";
import LoadingGif from "../../assets/images/loading-circle.gif";
import "./style.css";

import { useTranslation } from "react-i18next";

const FormSubmissionMessage = ({
  type,
  heading,
  message,
  buttonLabel,
  buttonAction,
  mode,
}) => {
  const { t } = useTranslation("translation");
  return (
    <div className="form-submission-message-page">
      <div
        className={
          mode === "conversational"
            ? "message-content-wrapper-convo"
            : "message-content-wrapper"
        }
      >
        <div className="message-content">
          {type === "success" && <i className="icon-tick" />}
          {type === "error" && <i className="icon-warning" />}
          {type === "loading" && (
            <div className="form-success-gif-container">
              <img
                className="form-success-gif"
                src={LoadingGif}
                alt={t("Submitting...")}
              />
            </div>
          )}
          <div className="heading">{heading}</div>
          <div
            className="form-submission-message"
            dangerouslySetInnerHTML={{
              __html: message,
            }}
            role="alert"
          ></div>
        </div>
      </div>
      {buttonLabel && (
        <div className="footer">
          <Button
            buttonClass="custom-button primary-button"
            onClick={buttonAction}
          >
            {buttonLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FormSubmissionMessage;
