import React, { useEffect, useState, useRef } from "react";
import { draftjsToMd } from "draftjs-md-converter";
import { EditorState, convertToRaw } from "draft-js";

import QuizTableEditor from "../QuizTableEditor";
import MarkdownEditor from "../MarkdownEditor";
import Radio from "../Radio";
import Checkbox from "../Checkbox";
import BulletEditor from "../BulletEditor";
import DragMatch from "../DragMatch";

import "./style.css";
import HeaderDescriptionEditor from "../HeaderDescriptionEditor";
import clearFormatting from 'draft-js-clear-formatting'

import { useTranslation } from "react-i18next";

const SubQuestion = ({
    questionData,
    onAnswerInput,
}) => {
  const { t } = useTranslation('translation');
  const {id: questionId} = questionData;

  // initialize states
  const [textInputValue, setTextInputValue] = useState(
    EditorState.createEmpty()
  );
  const [options, setOptions] = useState([]);
  const optionsMap = useRef(null);
  const [tableInputValue, setTableInputValue] = useState([["", ""]]);
  const [bulletArray, setBulletArray] = useState([""]);
  const [enterComplete, setEnterComplete] = useState({
    status: false,
    identifier: "",
    index: "",
  });
//   const [matchAnswer, setMatchAnswer] = useState([]);

  useEffect(() => {
    if (
      questionData &&
      (questionData.type === "radio" || questionData.type === "checkbox")
    ) {
      let temp = {};
      questionData.options.forEach((option) => {
        temp[option.id] = option.text;
      });
      optionsMap.current = temp;
    }
  }, [questionData]);

  useEffect(() => {
    if (enterComplete.status) {
      let element = document.getElementById(
        `input${enterComplete.identifier.toString()}${enterComplete.index.toString()}`
      );
      if (element) {
        element.focus();
      }
    }
  }, [enterComplete]);

  const getBotQuestionHtml = (questionDataParam) => {
    return (
    <div
      className="quiz-question-frame"
      dangerouslySetInnerHTML={{
        __html: questionDataParam ? questionDataParam.text : questionData.text,
      }}
    />
  )};

  const onTextChange = (value) => {
    const newValue = clearFormatting(value, {
      inline: true,
      entities: true,
      lists: true,
    });
    setTextInputValue(newValue);
    onAnswerInput(convertToMarkdown(newValue), "text", questionId);
  };

  const onTableChange = (value) => {
    setTableInputValue(value);
    if (
        questionData.answer_key_settings &&
        questionData.answer_key_settings.enable_column_header
      ) {
        value.map((row) => row.shift());
      }
      onAnswerInput(JSON.stringify(value), "text", questionId);
  };

  const onMatchAnswerChange = (value) => {
    // setMatchAnswer(value);
    onAnswerInput(value, "matching", questionId);
  };

  const onCheckboxChange = (checked, optionId) => {
    let tempOptions = [...options];
    if (checked) {
      tempOptions.push(optionId);
    } else if (!checked) {
      tempOptions = removeValue(tempOptions, optionId);
    }
    setOptions(tempOptions);
    onAnswerInput(tempOptions, "checkbox", questionId);
  };

  const convertToMarkdown = (data) => {
    const content = data.getCurrentContent();
    let markup = draftjsToMd(convertToRaw(content));
    let splitMarkup = markup.replace("&nbsp;", " ");

    let transformedMarkup = splitMarkup.trim();
    return transformedMarkup;
  };

  const onRadioAnswerChange = (value) => {
    setOptions([value]);
    onAnswerInput([value], "radio", questionId);
  };

  const onBulletArrayChange = (value) => {
    setBulletArray(value);
    if (value.length === 1 && value[0] === "") {
        onAnswerInput("", "text", questionId);
    } else {
        let filtered = value.filter(Boolean);
        onAnswerInput("- " + filtered.join("\n- "), "text", questionId);
    }
  };

  const removeValue = (list, value) => {
    let newList = [...list];
    for (var i = 0; i < newList.length; i++) {
      if (newList[i] === value) {
        newList.splice(i, 1);
        i--;
      }
    }
    return newList;
  };

  const handleQuestionCardDelete = (id) => {
    let temp = [...bulletArray];

    temp.splice(id, 1);
    onBulletArrayChange(temp);
  };

  const onQuestionEdit = (event, id) => {
    let temp = event.target.value;
    let sentences = temp.split("\n");

    let tempAnswers = [...bulletArray];

    if (sentences.length <= 1) {
      tempAnswers[id] = event.target.value;
      onBulletArrayChange(tempAnswers);
    }
  };

  const handlePaste = (event, id) => {
    let temp = event.clipboardData
      ? event.clipboardData.getData("text")
      : event.target.value;
    let sentences = temp.split("\n");

    let tempAnswers = [...bulletArray];

    if (sentences.length > 1) {
      let count = 0;
      sentences.forEach((sentence) => {
        if (sentence.trim()) {
          tempAnswers.splice(id + count, 0, sentence.trim());
          count += 1;
        }
      });
    }
    onBulletArrayChange(tempAnswers);
  };

  const handleBulletInputEnter = (event, id) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      let tempAnswers = [...bulletArray];

      tempAnswers.splice(id + 1, 0, "");

      onBulletArrayChange(tempAnswers);
      setEnterComplete({
        status: true,
        identifier: questionId,
        index: id + 1,
      });
    }
  };

  const getQuizInputHtml = (questionDataParams) => {
    const questionDetails = questionDataParams
      ? questionDataParams
      : questionData;
    if (questionDetails.type === "text" || questionDetails.type === "essay") {
      return (
        <div
          className="quiz-text-input-frame"
          id={`text-input${questionDetails.id}`}
        >
          {getBotQuestionHtml(questionDetails)}
          <MarkdownEditor
            key={questionDetails.id}
            id={questionDetails.id}
            value={textInputValue}
            onChange={(value) => onTextChange(value)}
            placeholder={
              questionDetails.is_paragraph === true
                ? t("To maximize scoring result, please answer in paragraph format.")
                : questionDetails.type === "essay" ? t("Submit your answer here.")
                : t("To maximize scoring result, please answer in bulleted or numbered list.")
            }
            isQuizAnswer
          />
        </div>
      );
    } else if (
      questionDetails.type === "bullet" ||
      questionDetails.type === "newbullet"
    ) {
      return (
        <div
          className="quiz-text-input-frame"
          id={`bullet-input${questionDetails.id}`}
        >
          {getBotQuestionHtml(questionDetails)}
          <BulletEditor
            identifier={questionId}
            data={bulletArray}
            handleCardDelete={handleQuestionCardDelete}
            onInputEdit={onQuestionEdit}
            handlePaste={handlePaste}
            handleKeyPress={handleBulletInputEnter}
            handleKeyUp={handleBulletInputEnter}
            shouldAutoFocus={false}
          />
        </div>
      );
    } else if (questionDetails.type === "radio") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`radio-input${questionDetails.id}`}
        >
          {getBotQuestionHtml(questionDetails)}
          <Radio
            isQuiz
            options={{
              enumOptions: questionDetails.options.map((item) => {
                return { value: item.id, label: item.text };
              }),
            }}
            id={questionDetails.id}
            onChange={onRadioAnswerChange}
            selectedValues={options}
          />
        </div>
      );
    } else if (questionDetails.type === "checkbox") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`checkbox-input${questionDetails.id}`}
        >
          {getBotQuestionHtml(questionDetails)}
          <Checkbox
            isQuiz
            options={{
              enumOptions: questionDetails.options.map((item) => {
                return { value: item.id, label: item.text };
              }),
            }}
            onChange={onCheckboxChange}
            selectedValues={options}
          />
        </div>
      );
    } else if (questionDetails.type === "table") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`table-input${questionDetails.id}`}
        >
          {/* {questionData.options.map((option) => (
          <button className="quiz-option-btn">{option.text}</button>
        ))} */}
          {getBotQuestionHtml(questionDetails)}
          <QuizTableEditor
            spellCheck={false}
            headerValues={
              questionDetails.answer_key_settings
                ? questionDetails.answer_key_settings.headers
                : ["", ""]
            }
            columnHeaderValues={
              questionDetails.answer_key_settings &&
              "column_headers" in questionDetails.answer_key_settings
                ? questionDetails.answer_key_settings.column_headers
                : []
            }
            onTableChange={onTableChange}
            rowCount={
              questionDetails.answer_key_settings &&
              "row_count" in questionDetails.answer_key_settings
                ? questionDetails.answer_key_settings.row_count
                : 1
            }
            columnCount={
              questionDetails.answer_key_settings &&
              "column_count" in questionDetails.answer_key_settings
                ? questionDetails.answer_key_settings.column_count
                : 2
            }
          />
        </div>
      );
    } else if (questionDetails.type === "description") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`table-input${questionDetails.id}`}
        >
          {getBotQuestionHtml(questionDetails)}
          <HeaderDescriptionEditor
            spellCheck={false}
            // disableHeader
            onTableChange={onTableChange}
            // tableValues={JSON.parse(questionData.ideal_answer)}
          />
        </div>
      );
    } else if (questionDetails.type === "matching") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`match-input${questionDetails.id}`}
        >
          {getBotQuestionHtml(questionDetails)}
          <DragMatch
            headerValues={
              questionDetails.answer_key_settings
                ? questionDetails.answer_key_settings.headers
                : ["", ""]
            }
            terms={questionDetails.terms ? questionDetails.terms : []}
            definitions={
              questionDetails.definitions ? questionDetails.definitions : []
            }
            onChange={onMatchAnswerChange}
          />
        </div>
      );
    }
  };

  return (
    <div className="bot-quiz-input-container">
        {getQuizInputHtml()}
    </div>
  );
};

export default SubQuestion;
