import React, { useState } from "react";
import RcViewer from "@hanyk/rc-viewer";

import "./style.css";

import { useTranslation } from "react-i18next";

const ImagePreview = (props) => {
  const { t } = useTranslation('translation');
  const options = {
    toolbar: {
      zoomIn: 1,
      zoomOut: 1,
      oneToOne: 0,
      reset: 4,
      prev: 0,
      play: {
        show: 0,
      },
      next: 0,
      rotateLeft: 1,
      rotateRight: 1,
      flipHorizontal: 1,
      flipVertical: 1,
    },
    title: 0,
  };
  if (props.src) {
    return (
      // <a
      //   className="image-preview-anchor"
      //   href={props.src}
      //   target="_blank"
      //   rel="noopener noreferrer"
      // >
      //   <img
      //     className="image-preview-img"
      //     alt={props.altText ? props.altText : "Image"}
      //     src={props.src}
      //   />
      // </a>

      <div className="image-preview-anchor">
        <RcViewer options={options}>
          <img
            className="image-preview-img"
            alt={props.altText ? props.altText : t("Image")}
            src={props.src}
          />
        </RcViewer>
      </div>
    );
  } else if (props.html) {
    return (
      <a
        className="image-preview-anchor"
        // href={props.src}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className="image-preview-img"
          dangerouslySetInnerHTML={{ __html: props.html }}
        />
      </a>
    );
  }
};

export default ImagePreview;
