import React, { useState, useEffect } from "react";
import Select from "react-select";
import cx from "classnames";
import useFetch from "../../helpers/remote";
import { authHeader } from "../../helpers/auth-header";
import "./style.css";
import { useTranslation } from "react-i18next";
import { LearnerItem } from "./LearnerItem";
import downarrowblk from "../../assets/images/blue-caret.svg";

const tabs = {
  unanswered_question: {
    key: "unanswered_question",
    label: "Learner Questions",
  },
  discussion: {
    key: "discussion",
    label: "Discussion Questions",
  },
};

export const SORTS = [
  { value: "recent", label: "Most Recent" },
  { value: "old", label: "Oldest" },
  { value: "res", label: "Most Responses" },
];

export const QUESTION_TYPES = [
  { value: "", label: "All Questions" },
  { value: "unanswered", label: "Unanswered" },
  { value: "answered", label: "Answered" },
];

export const QUESTION_TYPES_DISCUSSTION = [
  { value: "", label: "All Questions" },
  { value: "unanswered", label: "Unanswered" },
  { value: "answered", label: "Answered" },
];

const Discussion = ({ isTeam = true, setThread }) => {
  const makeApiCall = useFetch();
  const { t } = useTranslation("translation");
  const [activeTab, setActiveTab] = useState(tabs.unanswered_question.key);
  const [questions, setQuestions] = useState([]);
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState(SORTS[0]);
  const [questionType, setQuestionType] = useState(QUESTION_TYPES[0]);
  const [questionOptions, setQuestionsOptions] = useState(QUESTION_TYPES);
  const [loading, setLoading] = useState(false);

  const fetchDiscussion = async () => {
    let fitler = `thread_type=${activeTab}`;
    if (questionType.value !== "") {
      fitler = fitler + `&answer_status=${questionType.value}`;
    }
    if (query !== "") {
      fitler = fitler + `&q=${query}`;
    }
    switch (sort.value) {
      case "recent":
        fitler = fitler + `&sort_field=created_at&sort_order=dsc`;
        break;
      case "old":
        fitler = fitler + `&sort_field=created_at&sort_order=asc`;
        break;
      case "res":
        fitler = fitler + `&sort_field=post_count&sort_order=dsc`;
        break;
      default:
    }
    setLoading(true);
    makeApiCall
      .request(
        window.CHAT_API + `/api/v1/discussion/threads?${fitler}`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true,
        isTeam
      )
      .then((json) => {
        if (json.status.code === 200) {
          setQuestions(json.data?.threads);
        }
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   setTab && setTab(activeTab);
  // }, [activeTab]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchDiscussion();
    }, 200);
    return () => clearTimeout(timer);
  }, [query]);

  useEffect(() => {
    fetchDiscussion();
  }, [sort.value, questionType.value]);

  useEffect(() => {
    if (activeTab === tabs.unanswered_question.key) {
      setQuestionsOptions(QUESTION_TYPES);
      setQuestionType(QUESTION_TYPES[0]);
    } else {
      setQuestionsOptions(QUESTION_TYPES_DISCUSSTION);
      setQuestionType(QUESTION_TYPES_DISCUSSTION[0]);
    }
    setSort(SORTS[0]);
    setQuery("");
    fetchDiscussion(activeTab);
  }, [activeTab]);

  return (
    <div className="team-discussion">
      <div className="py-3 sticky-top">
        <div className="d-flex">
          <button
            className={cx("custom-icon-label-button py-1 px-3 mx-2", {
              active: activeTab === tabs.unanswered_question.key,
            })}
            role="tab"
            aria-selected={activeTab === tabs.unanswered_question.key}
            onClick={() => setActiveTab(tabs.unanswered_question.key)}
          >
            {t("Learner Questions")}
          </button>
          <button
            className={cx("custom-icon-label-button py-1 px-3 mx-2", {
              active: activeTab === tabs.discussion.key,
            })}
            role="tab"
            aria-selected={activeTab === tabs.discussion.key}
            onClick={() => setActiveTab(tabs.discussion.key)}
          >
            {t("Discussion Questions")}
          </button>
        </div>
        <div className="search-filter d-flex pt-4 pb-1 is-hidden-mobile">
          <input
            className="flex-1 mr-5 search-questions"
            type="text"
            placeholder={t("Search Questions or User")}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Select
            className="select-sort select-target select-blue shadow-none radius-8 mr-2"
            classNamePrefix="select-target-prefix"
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => (
                <img src={downarrowblk} alt="dropdown arrow" />
              ),
            }}
            options={SORTS}
            onChange={(e) => {
              setSort(e);
            }}
            value={sort}
          />
          <Select
            className="select-sort select-target select-blue shadow-none radius-8"
            classNamePrefix="select-target-prefix"
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => (
                <img src={downarrowblk} alt="dropdown arrow" />
              ),
            }}
            options={questionOptions}
            onChange={(e) => {
              setQuestionType(e);
            }}
            value={questionType}
          />
        </div>
      </div>

      {loading && (
        <div className="spinner-container-dicussion">
          <div className="spinner-loader"></div>
        </div>
      )}
      {!loading && questions.length > 0 && (
        <div className="questions-container">
          {activeTab === tabs.unanswered_question.key &&
            questions.map((question) => (
              <LearnerItem
                key={question._id}
                question={question}
                isDicussion={false}
                onClick={setThread}
              />
            ))}
          {activeTab === tabs.discussion.key &&
            questions.map((question) => (
              <LearnerItem
                key={question._id}
                question={question}
                isDicussion={true}
                onClick={setThread}
              />
            ))}
        </div>
      )}
      {!loading && questions.length === 0 && (
        <div className="spinner-container-dicussion" role="alert">
          {t("No thread found")}
        </div>
      )}
    </div>
  );
};

export default Discussion;
