import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth0 } from "../../react-auth0-spa";
import { useAuth } from "../../context/auth";
import { v4 as uuidv4 } from "uuid";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

import { authHeader } from "../../helpers/auth-header";
import AgentClassService from "../../helpers/AgentClassService";
import useFetch from "../../helpers/remote";
import { getNRandomArrayIndices } from "../../helpers/formatFunctions";

import throttle from "lodash/throttle";
import ChatInput from "../../components/ChatInput";
import BotChatBubble from "../../components/BotChatBubble";
import UserChatBubble from "../../components/UserChatBubble";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import SuggestionButton from "../../components/SuggestionButton";
import Snackbar from "../../components/Snackbar";
import Form from "../../components/Form";
import FormSubmissionMessage from "../../components/FormSubmissionMessage";
import QuizGenerator from "../../components/QuizGenerator";
import QuizReport from "../../components/QuizReport";
import ChatFeaturePanel from "../ChatFeaturePanel";
import IntroPanel from "../../components/IntroPanel";
import ChatHeader from "../../components/ChatHeader";
import QuizReportReviewed from "../../components/QuizReportReviewed";
import NotificationPanel from "./notificationPanel";
import BotReply from "../../components/BotReply";
import PastSessionPanel from "../PastSessionPanel";
import RatingInput from "../../components/RatingInput";
import AgentSelection from "../AgentSelection";
import ResumeSessionModal from "../ResumeSessionModal";
import BotQuizBubble from "../../components/BotQuizBubble";
import BotQuizResultBubble from "../../components/BotQuizResultBubble";

import { getUserAvatarIcon } from "./data";
import { linkify, prettifyDate } from "../../helpers/formatFunctions";
import { useInterval } from "../../helpers/timer";

import NoodleLogo from "../../assets/images/logo-horizontal.png";
import AvatarIcon from "../../assets/images/walter-head-white.png";

import "./style.css";
import ChatFeedback from "../../components/ChatFeedback";
import OrganisationSwitcher from "../OrganisationSwitcher/OrganisationSwitcher";

const QuizReview = (props) => {
  const { t } = useTranslation("translation");
  const {
    setAuthTokens,
    showAgentSelection,
    setShowAgentSelection,
    selectedClass,
    setSelectedClass,
    showOrgSelection
  } = useAuth();

  const { logout } = useAuth0();
  const [feedbackMap, setFeedbackMap] = useState({});
  const [displayedQuestionsIdConvo, setDisplayedQuestionsIdConvo] = useState(
    []
  );
  const [quizReportData, setQuizReportData] = useState(null);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [sessionFeedbackComment, setSessionFeedbackComment] = useState("");
  const [feedbackId, setFeedbackId] = useState("");
  const lastLog = useRef(null);
  const [newMessage, setNewMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openFeedbackContainer, setOpenFeedbackContainer] = useState(false);
  const [suggestedQuestionsData, setSuggestedQuestionsData] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [form, setForm] = useState({});
  const [showFormSubmissionMessage, setShowFormSubmissionMessage] =
    useState(false);
  const [formSubmissionMessageDetails, setFormSubmissionMessageDetails] =
    useState({
      heading: "",
      message: "",
      buttonLabel: "",
      type: "",
    });

  const [messageCopy, setMessageCopy] = useState("");
  const [showQuiz, setShowQuiz] = useState(false);
  const [quiz, setQuiz] = useState({});
  const [disableQuizButton, setDisableQuizButton] = useState(false);
  const [showQuizReport, setShowQuizReport] = useState({
    open: false,
    type: "success",
    data: {},
  });
  const [title, setTitle] = useState(t("Chat"));
  const [chatContext, setChatContext] = useState("");

  // Settings variables
  const [companyLogo, setCompanyLogo] = useState(NoodleLogo);
  const [sidebarMessage, setSidebarMessage] = useState(
    t("<p>Hi there! 👋</p><p>I am Walter, your smart chat buddy.<br></p>")
  );
  const [botAvatar, setBotAvatar] = useState(AvatarIcon);
  const [botName, setBotName] = useState(t("Walter"));
  const [botDelay, setBotDelay] = useState(2000);
  const [customButtonData, setCustomButtonData] = useState([]);

  const [notificationToggle, setNotificationToggle] = useState(false);
  const [notificationSummary, setNotificationSummary] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [suggestionsExpanded, setSuggestionsExpanded] = useState(false);
  const [showHomePanel, setShowHomePanel] = useState(false);
  const [userAvatarIcon, setUserAvatarIcon] = useState(getUserAvatarIcon());
  const [hideChatInput, setHideChatInput] = useState(false);
  const [buttonReference, setButtonReference] = useState("");
  const [logoutAfterFeedback, setLogoutAfterFeedback] = useState(false);
  const [sessionFeedback, setSessionFeedback] = useState(0);
  const [openSessionEndModal, setOpenSessionEndModal] = useState(false);
  const [pastSessionData, setPastSessionData] = useState([]);
  const [enableLearning, setEnableLearning] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [sessionTime, setSessionTime] = useState(0);
  const [triggerBookmarkLoad, setTriggerBookmarkLoad] = useState(false);
  const [quizDisplayedQuestions, setQuizDisplayedQuestions] = useState([]);
  const [agentsMap, setAgentsMap] = useState({});
  const [selectedAgent, setSelectedAgent] = useState("");
  const [disableInput, setDisableInput] = useState(false);
  const [activeSession, setActiveSession] = useState({});

  const [showResumeSessionModal, setShowResumeSessionModal] = useState(false);
  const [showNewSessionAgentSelection, setShowNewSessionAgentSelection] =
    useState(false);
  const [disableCustomButton, setDisableCustomButton] = useState(false);
  const [isConversationalQuizActive, setIsConversationalQuizActive] =
    useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [pastSessionActiveTab, setPastSessionActiveTab] = useState(false);

  const chatMessagesRef = useRef(null);
  const historyChatRef = useRef(null);
  const currentQuiz = useRef(null);
  const convoQuizAnswers = useRef(null);
  const initialRender = useRef(true);
  const conversationalQuizStrategy = useRef("normal");

  const makeApiCall = useFetch();
  let history = useHistory();
  useInterval(() => {
    gaSendPing();
  }, 30000);

  useEffect(() => {
    getDefaultAgent("initial");
    getOrganisationName();
    checkAgentFeatureEnabled(
      "learning",
      () => {
        setEnableLearning(false);
      },
      () => {
        setEnableLearning(true);
      }
    );
    updateLocalSession();
    getNotifications();
    getAllNotifications();
  }, []);

  useEffect(() => {
    if (props.match.params.submissionId) {
      makeApiCall
        .request(
          window.CHAT_API +
            "/api/v1/quiz/quiz-submissions/" +
            props.match.params.submissionId,
          {
            method: "GET",
            headers: authHeader(),
          },
          true
        )
        .then((json) => {
          if (json.status.code === 200) {
            setQuizReportData(json.data.quiz_submission);
          } else {
            setSnackbar({
              isOpen: true,
              message: t("Error fetching data"),
              type: "error",
            });
          }
        });
    }
  }, []);

  // start GA functions
  const gaSendPing = () => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });
      window.gtag("event", "active_session", {
        event_category: "session",
        event_label: activeSession._id,
      });
    }
  };

  const gaEndSession = () => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });

      window.gtag("event", "end_session", {
        event_category: "session",
        event_label: activeSession._id,
      });
    }
  };

  const gaClickButton = (button_type, button_label) => {
    if (typeof window.gtag !== "function") return;

    if (activeSession) {
      window.gtag("set", {
        user_id: activeSession.user_id,
      });

      window.gtag("event", "click_button", {
        event_category: "conversation",
        event_label: activeSession._id + "::" + button_type,
        value: button_label,
      });
    }
  };
  // end GA functions

  const updateLocalSession = () => {
    getCurrentSession((ss) => {
      if (ss) {
        setActiveSession(ss);
        gaSendPing();
      }
    });
  };

  const getNotifications = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/summary",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setNotificationSummary(json.data.notifications);
          setNotificationCount(json.data.unread_count);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching notifications"),
            type: "error",
          });
        }
      });
  };

  const getAllNotifications = () => {
    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/notification/notifications?mail=inbox&page_size=1000",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          setAllNotifications(json.data.notifications);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching notifications"),
            type: "error",
          });
        }
      });
  };

  const onClickNotification = (link) => {
    if (link !== "" && link !== null) {
      let strippedDomain = link.replace(/https?:\/\/[^\/]+/i, "");
      var localDomains = [
        "chatbot.staging.noodlefactory.ai",
        "chatbot.noodlefactory.ai",
        "localhost",
      ];
      var isLocalDomain = localDomains.some((element) =>
        link.includes(element)
      );

      if (isLocalDomain === true) {
        history.push(strippedDomain);
      } else {
        window.open(link, "_newtab");
      }
    }
  };

  const onDeleteNotification = (event, key) => {
    event.stopPropagation();
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + key,
        {
          method: "DELETE",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          getAllNotifications();
        } else {
          setSnackbar({
            isOpen: true,
            message:
              t("Unable to delete notification: ") + t(json.status.message),
            type: "error",
          });
        }
      });
  };

  const onCheckboxChangeNotification = (event, key, isRead) => {
    event.stopPropagation();

    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/notification/notifications/" + key,
        {
          method: "PATCH",
          headers: authHeader(),
          body: JSON.stringify({
            is_read: isRead ? false : true,
          }),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          getAllNotifications();
        } else {
          setSnackbar({
            isOpen: true,
            message:
              t("Unable to update notification: ") + t(json.status.message),
            type: "error",
          });
        }
      });
  };

  const markAllRead = async () => {
    let notifications = [...allNotifications];

    await notifications.forEach((notification, i) => {
      if (notification.is_read === false) {
        makeApiCall
          .request(
            window.CHAT_API +
              "/api/v1/notification/notifications/" +
              notification._id,
            {
              method: "PATCH",
              headers: authHeader(),
              body: JSON.stringify({
                is_read: notification.isRead ? false : true,
              }),
            },
            true
          )
          .then((json) => {
            if (json.status.code === 200) {
            } else {
              setSnackbar({
                isOpen: true,
                message:
                  t("Unable to update notification: ") + t(json.status.message),
                type: "error",
              });
            }
          });
      }
    });

    getAllNotifications();
  };

  const clearAllNotification = async () => {
    let notifications = [...allNotifications];

    await notifications.forEach((notification, i) => {
      makeApiCall
        .request(
          window.CHAT_API +
            "/api/v1/notification/notifications/" +
            notification._id,
          {
            method: "DELETE",
            headers: authHeader(),
          },
          true
        )
        .then((json) => {
          if (json.status.code === 200) {
          } else {
            setSnackbar({
              isOpen: true,
              message:
                t("Unable to update notification: ") + t(json.status.message),
              type: "error",
            });
          }
        });
    });

    getAllNotifications();
  };

  const onChatInputFocus = () => {
    setSuggestionsExpanded(true);
  };

  const onChatInputBlur = () => {
    setSuggestionsExpanded(false);
  };

  const callStartSessionApi = () => {
    let currentAgent = AgentClassService.getSelectedAgent();
    applyAgentSetting(agentsMap[currentAgent].settings);
    getGreetings();
  };

  const startFeedbackTimer = (lastFeedbackDate) => {
    let timeDelay = [600000, 900000, 1200000];
    let timeDelay2 = [1000, 5000, 10000];
    let today = new Date();
    let lastFeedback = new Date(lastFeedbackDate);

    today.setHours(0, 0, 0, 0);
    lastFeedback.setHours(0, 0, 0, 0);

    if (today > lastFeedback) {
      setTimeout(() => {
        setOpenFeedbackContainer(true);
      }, timeDelay[Math.floor(Math.random() * timeDelay.length)]);
    }
  };

  const getLastFeedback = () => {
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatlog/feedbacks",
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        let error = false;
        if (json && json.status.code === 200) {
          if (
            json.data &&
            json.data.session_feedback &&
            json.data.session_feedback[0]
          ) {
            startFeedbackTimer(json.data.session_feedback[0].created_at);
          }
        } else {
          error = true;
        }

        if (error) {
          setSnackbar({
            isOpen: true,
            message: t("Error fetching feedback data"),
            type: "error",
          });
        }
      });
  };

  const getSummary = (sessionId) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    makeApiCall
      .request(
        window.CHAT_API +
          "/api/v1/learning/session-summary?session_id=" +
          sessionId,
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          setSummaryData(json.data.session_summary);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });
  };

  const getDefaultAgent = (type) => {
    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/states", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          let receivedSelectedAgent = json.data.states.selected_agent;

          makeApiCall
            .request(window.CHAT_API + "/api/v1/organisation/agents", {
              method: "GET",
              headers: authHeader(),
            }, null, null, true)
            .then((json) => {
              if (json.status.code === 200) {
                if (json.data.agents.length === 0) {
                  AgentClassService.setSelectedAgent("");
                  setSelectedClass("");
                  AgentClassService.setSelectedClass("");
                  setSelectedAgent("");
                  history.push("/no-agent-found");
                } else {
                  if (receivedSelectedAgent) {
                    let temp = json.data.agents.filter(
                      (agent) => agent._id === receivedSelectedAgent
                    );

                    if (temp.length) {
                      AgentClassService.setSelectedAgent(receivedSelectedAgent);
                      setSelectedAgent(receivedSelectedAgent);
                      if (type === "initial") {
                        getLastFeedback();
                        initialRender.current = false;
                      }
                      getSuggestedQuestions();
                    } else {
                      receivedSelectedAgent = "";
                    }
                  }

                  if (!receivedSelectedAgent && json.data.agents.length > 1) {
                    setShowAgentSelection(true);
                  } else {
                    if (!receivedSelectedAgent) {
                      AgentClassService.setSelectedAgent(
                        json.data.agents[0]._id
                      );
                      setSelectedAgent(json.data.agents[0]._id);
                      if (type === "initial") {
                        getLastFeedback();
                        initialRender.current = false;
                      }
                      getSuggestedQuestions();
                    }

                    let temp = {};
                    json.data.agents.forEach((agent) => {
                      temp[agent._id] = agent;
                    });
                    setAgentsMap(temp);

                    receivedSelectedAgent
                      ? applyAgentSetting(temp[receivedSelectedAgent].settings)
                      : applyAgentSetting(json.data.agents[0].settings);
                  }
                }
              }
            });
        }
      });
  };

  const checkAgentFeatureEnabled = (
    feature,
    default_callback,
    enabled_callback = null
  ) => {
    if (!AgentClassService.getSelectedAgent()) {
      return;
    }

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/agent-features", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        let is_feature_enabled = false;
        if (json.status.code === 200 && json.data.features) {
          for (let i = 0; i < json.data.features.length; i++) {
            if (json.data.features[i].name === feature) {
              is_feature_enabled = true;
              break;
            }
          }
        }
        if (is_feature_enabled) {
          if (
            enabled_callback !== null &&
            typeof enabled_callback === "function"
          ) {
            enabled_callback();
          }
        } else if (typeof default_callback === "function") {
          default_callback();
        }
      });
  };

  const applyAgentSetting = (settings) => {
    let avatarIcon = getUserAvatarIcon();
    setUserAvatarIcon(avatarIcon);

    let webchatSettings = settings;

    if (webchatSettings.chat_company_logo) {
      setCompanyLogo(webchatSettings.chat_company_logo);
    }

    if (webchatSettings.chat_short_message) {
      setSidebarMessage(webchatSettings.chat_short_message);
    }

    if (webchatSettings.chat_bot_avatar) {
      setBotAvatar(webchatSettings.chat_bot_avatar);
    }

    if (webchatSettings.chat_bot_name) {
      setBotName(webchatSettings.chat_bot_name);
    } else {
      setBotName("Walter");
    }

    if (webchatSettings.chat_bot_delay) {
      setBotDelay(webchatSettings.chat_bot_delay * 1000);
    } else {
      setBotDelay(2000);
    }

    const root = document.querySelector(":root");
    if (webchatSettings) {
      if (webchatSettings.chat_accent_color) {
        root.style.setProperty(
          "--primary-color",
          `#${webchatSettings.chat_accent_color}`
        );
      } else {
        root.style.setProperty("--primary-color", "#0923E6");
      }
    }

    if (webchatSettings.chat_text_color) {
      root.style.setProperty(
        "--primary-text-color",
        `#${webchatSettings.chat_text_color}`
      );
    }

    if (webchatSettings) {
      if (webchatSettings.chat_user_color) {
        root.style.setProperty(
          "--user-color",
          `#${webchatSettings.chat_user_color}`
        );
      } else {
        root.style.setProperty("--user-color", "#DCE5ED");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_user_text_color) {
        root.style.setProperty(
          "--user-text-color",
          `#${webchatSettings.chat_user_text_color}`
        );
      } else {
        root.style.setProperty("--user-text-color", "#0E0748");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_button_color) {
        root.style.setProperty(
          "--button-color",
          `#${webchatSettings.chat_button_color}`
        );
      } else {
        root.style.setProperty("--button-color", "#DCE5ED");
      }
    }

    if (webchatSettings) {
      if (webchatSettings.chat_button_text_color) {
        root.style.setProperty(
          "--button-text-color",
          `#${webchatSettings.chat_button_text_color}`
        );
      } else {
        root.style.setProperty("--button-text-color", "#0E0748");
      }
    }

    if (webchatSettings.customised_buttons) {
      setCustomButtonData(webchatSettings.customised_buttons);
    }
  };

  const onStartNewSession = () => {
    setSelectedAgent("");
    AgentClassService.setSelectedAgent("");

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/states", {
        method: "PATCH",
        headers: authHeader(),
        body: JSON.stringify({
          states: {
            selected_agent: "",
          },
        }),
      })
      .then((json) => {
        if (json.status.code === 200) {
          setShowResumeSessionModal(false);
          getLastFeedback();
          initialRender.current = false;
          getSuggestedQuestions();
          if (Object.keys(agentsMap).length === 1) {
            onAgentChange(Object.keys(agentsMap)[0], "new");
          } else {
            setShowNewSessionAgentSelection(true);
          }
        }
      });
  };

  const displayBotBubbles = (element, callBack) => {
    if (
      element.reply.object_data &&
      Object.keys(element.reply.object_data).length > 0
    ) {
      historyChatRef.current.unshift(
        <BotQuizResultBubble
          avatarIcon={botAvatar}
          submissionData={element.reply.object_data}
          // displayedQuestions={currentQuiz.current.questions}
          onClick={handleQuizReportAction}
          bubbleType="history"
          enableLearning={enableLearning}
        />
      );
    } else if (element.reply.bubbles) {
      element.reply.bubbles.reverse().forEach((bubble, index) => {
        historyChatRef.current.unshift(
          <BotReply
            avatarIcon={botAvatar}
            bubbleIndex={index}
            key={bubble.id}
            replyId={element._id}
            replyType={bubble.template}
            data={bubble}
            replyTime={
              element.reply && element.reply.timestamp ? (
                <Moment unix format="DD/MM/YY hh:mm A">
                  {element.reply.timestamp / 1000}
                </Moment>
              ) : (
                ""
              )
            }
            bookmarkAction={bookmarkAction}
            recordFeedback={recordFeedback}
            showFeedback={true}
            feedback={feedbackMap[element._id] || null}
          />
        );
      });
    } else if (element.reply.text) {
      historyChatRef.current.unshift(
        <BotChatBubble
          key={uuidv4()}
          avatarIcon={botAvatar}
          messageId={element._id}
          message={linkify(element.reply.text)}
          messageTime={
            element.reply && element.reply.timestamp ? (
              <Moment unix format="DD/MM/YY hh:mm A">
                {element.reply.timestamp / 1000}
              </Moment>
            ) : (
              ""
            )
          }
          feedback={feedbackMap[element._id] || null}
          showFeedback={true}
          recordFeedback={recordFeedback}
          bookmarkAction={bookmarkAction}
        />
      );
    }

    callBack();
  };

  const displayUserBubbles = (element) => {
    if (element.question && element.question.question) {
      historyChatRef.current.unshift(
        <UserChatBubble
          key={uuidv4()}
          avatarIcon={userAvatarIcon}
          message={element.question.question}
          messageTime={
            <Moment unix format="DD/MM/YY hh:mm A">
              {element.question.timestamp / 1000}
            </Moment>
          }
          bookmarkAction={bookmarkAction}
          copyAction={copyAction}
        />
      );
    }
  };

  const openPastSessionPanel = (activeTab) => {
    setPastSessionActiveTab(activeTab);
    let rightPanel = document.getElementById("past-session-panel");

    if (rightPanel) {
      rightPanel.classList += " open-panel-wrap";
    }
  };

  const onPastSessionClick = (lastLog, sessionId, time, activeTab) => {
    setSessionTime(Math.floor(time / 60));
    openPastSessionPanel(activeTab);
    getSummary(sessionId);
    let url;

    // if (lastLog._id) {
    //   url = `/api/v1/chatlog/logs?session_id=${sessionId}&last_log_id=${lastLog._id}`;
    // } else {
    url = `/api/v1/chatlog/logs?session_id=${sessionId}`;
    // }

    makeApiCall
      .request(window.CHAT_API + url, {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          setPastSessionData(json.data.logs);
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });

    setChatContext("");
  };

  const getCurrentSession = (callback) => {
    if (!AgentClassService.getSelectedAgent()) {
      return;
    }

    makeApiCall
      .request(window.CHAT_API + "/api/v1/chatlog/sessions/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        let error = false;
        if (json && (json.status.code === 200 || json.status.code === 404)) {
          if (json.data && json.data.session) {
            callback(json.data.session);
          } else {
            callback(null);
          }
        } else {
          error = true;
        }

        if (error) {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });
  };

  const getOrganisationName = () => {
    let avatarIcon = getUserAvatarIcon();
    setUserAvatarIcon(avatarIcon);

    makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/organisations/current", {
        method: "GET",
        headers: authHeader(),
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data.organisation && json.data.organisation.name) {
            setTitle(`${json.data.organisation.name} - Chat`);
          }
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });
  };

  const addBookmark = (text, isAgent) => {
    makeApiCall
      .request(window.CHAT_API + "/api/v1/bookmark/bookmarks", {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          text: text,
          is_agent: isAgent,
        }),
      })
      .then((json) => {
        if (json.status.code === 200) {
          setTriggerBookmarkLoad((data) => !data);
          setSnackbar({
            isOpen: true,
            message: t("Bookmark is added successfully."),
            type: "success",
          });
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });
  };

  const bookmarkAction = (referrer, message) => {
    addBookmark(message, referrer === "bot");
  };

  const copyAction = (message) => {
    // put action here
    alert(message);
  };

  const getGreetings = (isClear) => {
    let body = {
      is_greeting: true,
      source: "webchat",
    };

    if (isClear) {
      body = {
        ...body,
        is_refresh: true,
      };
    }

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(body),
    };

    makeApiCall
      .request(window.CHAT_API + "/api/v1/chatbot/questions", requestOptions)
      .then((json) => {
        if (json.status.code === 200) {
          if ("is_input_disabled" in json.data.context) {
            if (json.data.context.is_input_disabled === null) {
              setHideChatInput(false);
            } else {
              setHideChatInput(json.data.context.is_input_disabled);
            }
          }
          setChatData([]);
          createBotReply(json.data, chatData, false);
          updateLocalSession();
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });
  };

  const getSuggestedQuestions = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    let requestUrl = window.CHAT_API + "/api/v1/chatbot/suggested-questions";

    if (chatContext) {
      requestUrl = requestUrl + "?topic_ids=" + chatContext;
    }

    makeApiCall.request(requestUrl, requestOptions).then((json) => {
      if (json.status.code === 200) {
        setSuggestedQuestionsData(json.data.suggested_questions);
      } else {
        setSnackbar({
          isOpen: true,
          message: t("Oops! Something went wrong."),
          type: "error",
        });
      }
    });
  };

  const postFeedback = (feedback, key) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ type: feedback }),
    };
    makeApiCall
      .request(
        window.CHAT_API + "/api/v1/chatbot/replies/" + key + "/feedback",
        requestOptions
      )
      .then((json) => {
        if (json.status.code === 200) {
          // setSnackbar({
          //   isOpen: true,
          //   message: "Thank you for your feedback.",
          //   type: "success",
          // });
        } else {
          setSnackbar({
            isOpen: true,
            message: t("Oops! Something went wrong."),
            type: "error",
          });
        }
      });
  };

  const recordFeedback = (feedback, key) => {
    let feedbacks = { ...feedbackMap };
    feedbacks[key] = feedback;
    setFeedbackId(key);
    setFeedbackMap(feedbacks);
    if (feedback === 0) {
      setOpenModal(true);
    } else {
      postFeedback(feedback, key);
    }
  };

  const beforeLogout = () => {
    setOpenFeedbackModal(true);
    setLogoutAfterFeedback(true);
  };

  const logoutUser = async () => {
    await endSession();

    if (process.env && process.env.REACT_APP_TYPE === "login_widget") {
      let initialToken = localStorage.getItem("widget_initial_token");
      setAuthTokens(false);
      localStorage.clear();
AgentClassService.clear();
      window.location.href = `/?t=${initialToken}`;
    } else if (window.SSO_PROVIDER === "auth0") {
      localStorage.clear();
AgentClassService.clear();
      logout({
        returnTo: window.location.origin,
      });
    } else {
      localStorage.clear();
AgentClassService.clear();
      setAuthTokens(false);
      window.location.href =
        window.SSO_DOMAIN + "/logout?redirect_uri=" + window.location.origin;
    }
  };

  const endSession = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "PATCH",
        headers: authHeader(),
      };

      if (!AgentClassService.getSelectedAgent()) {
        return;
      }
      
      makeApiCall
        .request(
          window.CHAT_API + "/api/v1/chatlog/sessions/current",
          requestOptions
        )
        .then((json) => {
          if (json.status.code === 200) {
            gaEndSession();
            resolve(json);
          } else {
            if (process.env && process.env.REACT_APP_TYPE === "login_widget") {
              let initialToken = localStorage.getItem("widget_initial_token");
              setAuthTokens(false);
              localStorage.clear();
AgentClassService.clear();
              window.location.href = `/?t=${initialToken}`;
            } else if (window.SSO_PROVIDER === "auth0") {
              localStorage.clear();
AgentClassService.clear();
              logout({
                returnTo: window.location.origin,
              });
            } else {
              localStorage.clear();
AgentClassService.clear();
              setAuthTokens(false);
              window.location.href =
                window.SSO_DOMAIN +
                "/logout?redirect_uri=" +
                window.location.origin;
            }
          }
        });
    });
  };

  const sendFeedbackComment = () => {
    // postFeedback(feedbackMap[feedbackId], feedbackId);
    if (feedbackComment) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ type: 0, comment: feedbackComment }),
      };

      makeApiCall
        .request(
          window.CHAT_API +
            "/api/v1/chatbot/replies/" +
            feedbackId +
            "/feedback",
          requestOptions
        )
        .then((json) => {
          if (json.status.code === 200) {
            // setSnackbar({
            //   isOpen: true,
            //   message: "Thank you for your feedback.",
            //   type: "success",
            // });
          } else {
            setSnackbar({
              isOpen: true,
              message: t("Oops! Something went wrong."),
              type: "error",
            });
          }
        });
    }
    setFeedbackComment("");
    setOpenModal(false);
  };

  const sendSessionFeedback = () => {
    if (sessionFeedback) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          rating: sessionFeedback,
          comment: sessionFeedbackComment,
        }),
      };

      makeApiCall
        .request(window.CHAT_API + "/api/v1/chatlog/feedbacks", requestOptions)
        .then((json) => {
          if (json.status.code === 200) {
            setSnackbar({
              isOpen: true,
              message: t("Thank you for your feedback."),
              type: "success",
            });
            setOpenFeedbackModal(false);
            setOpenFeedbackContainer(false);
            setSessionFeedbackComment("");
            setSessionFeedback(0);

            if (logoutAfterFeedback === true) {
              setSnackbar({
                isOpen: true,
                message: t(
                  "Thank you for your feedback! You will be logged out in 3 seconds."
                ),
                type: "success",
              });

              setTimeout(function () {
                logoutUser();
              }, 3000);
            }
          } else {
            setSnackbar({
              isOpen: true,
              message: t("Oops! Something went wrong."),
              type: "error",
            });
          }
        });
    }
    setFeedbackComment("");
    setOpenModal(false);
  };

  const onCustomButtonClick = (button, origin) => {
    setDisableCustomButton(true);
    let type = "",
      referenceId = "";

    if (button.reference) {
      type = button.reference.split("::")[0];
      referenceId = button.reference.split("::")[1];
    } else if (button.reference_id && button.type) {
      type = button.type;
      referenceId = button.reference_id;
    }

    makeApiCall
      .request(
        window.CHAT_API + `/api/v1/chatbot/button-data/${type}::${referenceId}`,
        {
          method: "GET",
          headers: authHeader(),
        },
        true
      )
      .then((json) => {
        if (json.status.code === 200) {
          let temp = {};
          if ("form" in json.data.button_data) {
            temp = {
              form: json.data.button_data.form,
              type: "form",
            };
          } else if ("quiz" in json.data.button_data) {
            temp = {
              quiz: json.data.button_data.quiz,
              type: "quiz",
            };
          } else {
            temp = {
              question: json.data.button_data.question,
              reference: button.reference,
              type: "question",
            };
          }

          onChatButtonClick(temp, origin);
        }
      });
  };

  const onChatButtonClick = (button, origin) => {
    setDisableCustomButton(false);
    switch (button.type) {
      case "form":
        if (typeof button.form.layout === "string") {
          button.form.layout = JSON.parse(button.form.layout);
        }
        openFormScreen(button.form);
        break;
      case "quiz":
        setDisableQuizButton(false);
        openQuizGeneratorScreen(button.quiz);
        break;
      case "question":
        if (button.reference) {
          setButtonReference(button.reference);
        }
        if (chatMessagesRef.current.length > 0 && origin !== "custom") {
          chatMessagesRef.current.pop();
          setChatData(chatMessagesRef.current);
        }
        setMessageCopy(button.question);
        break;
      case "intent":
        if (button.reference) {
          setButtonReference(button.reference);
        }
        if (chatMessagesRef.current.length > 0 && origin !== "custom") {
          chatMessagesRef.current.pop();
          setChatData(chatMessagesRef.current);
        }
        setMessageCopy(button.question);
        break;
      default:
        break;
    }
  };

  const handleQuizReportAction = (data, origin) => {
    setShowQuizReport({
      open: false,
      type: "success",
      data: {},
    });
    setShowQuiz(false);
    if (origin !== "conversational") {
      // getSessions();
    }

    if (data) {
      onCustomButtonClick(data);
    }
  };

  const openFormScreen = (form) => {
    setForm(form);
    setShowForm(true);
  };

  const activateLoading = () => {
    let details = {
      message: t("Hang tight! Your form is being submitted."),
      heading: t("Submitting..."),
      buttonLabel: "",
      type: "loading",
    };
    setShowFormSubmissionMessage(true);
    setFormSubmissionMessageDetails(details);
  };

  const handleFormSubmit = async (formId, formDetails) => {
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    activateLoading();
    if (formDetails.formData && Object.keys(formDetails.formData).length > 0) {
      let json = await makeApiCall.request(
        window.CHAT_API + "/api/v1/form/forms/" + formId + "/submissions",
        {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify({ form_data: formDetails.formData }),
        }
      );
      if (json.status.code === 200) {
        let details = {
          message: json.data.form_reply.text
            ? json.data.form_reply.text
            : json.data.form_reply.error
            ? json.data.form_reply.error
            : t(
                "Looks like something is not right here. Please contact your admin."
              ),
          heading: json.data.form_reply.text
            ? ""
            : json.data.form_reply.error
            ? t("Oops! Submission was unsucessful.")
            : t("Oops! Something went wrong."),
          buttonLabel: t("GO BACK TO CHAT"),
          type: json.data.form_reply.text
            ? "success"
            : json.data.form_reply.error
            ? "error"
            : "error",
        };
        setShowFormSubmissionMessage(true);
        setFormSubmissionMessageDetails(details);
      } else {
        setSnackbar({
          isOpen: true,
          message: t("Oops! Something went wrong."),
          type: "error",
        });
      }
    }
  };

  const handleSubmissionMessageAction = () => {
    setShowFormSubmissionMessage(false);
    setShowForm(false);
  };

  const findAndSetNextBranchedQuestion = (questionId) => {
    // find the index of the question which is linked to the option and set the current index
    let newCurrentIndex = null;
    currentQuiz.current.questions.forEach((question, index) => {
      if (questionId === question.id) {
        newCurrentIndex = index;
      }
    });
    askConversationalQuizQuestion(newCurrentIndex, false);
  };

  const onAnswerInput = (value, type, questionIndex, sequenceIndex) => {
    if (type === "text") {
      let tempAnswers = [...convoQuizAnswers.current];
      tempAnswers[questionIndex] = {
        text: value,
      };
      convoQuizAnswers.current = tempAnswers;
    } else if (type === "radio" || type === "checkbox") {
      let tempAnswers = [...convoQuizAnswers.current];

      tempAnswers[questionIndex] = {
        options: value,
      };
      convoQuizAnswers.current = tempAnswers;
    }

    if (
      conversationalQuizStrategy.current === "branching" &&
      currentQuiz.current.questions[questionIndex].type === "radio"
    ) {
      currentQuiz.current.questions[questionIndex].options &&
        currentQuiz.current.questions[questionIndex].options.forEach(
          (option) => {
            if (
              option.id === convoQuizAnswers.current[questionIndex].options[0]
            ) {
              if (option.branch && option.branch.action) {
                if (option.branch.action === "end-quiz") {
                  askConversationalQuizQuestion(0, true);
                } else if (
                  option.branch.action === "jump-to-question" &&
                  option.branch.reference_id
                ) {
                  findAndSetNextBranchedQuestion(option.branch.reference_id);
                }
              } else if (
                sequenceIndex + 1 <= currentQuiz.current.sequence.length - 1 &&
                currentQuiz.current.questions
              ) {
                askConversationalQuizQuestion(sequenceIndex + 1, false);
              } else if (
                sequenceIndex + 1 ===
                currentQuiz.current.sequence.length
              ) {
                askConversationalQuizQuestion(0, true);
              }
            }
          }
        );
    } else if (
      sequenceIndex + 1 <= currentQuiz.current.sequence.length - 1 &&
      currentQuiz.current.questions
    ) {
      askConversationalQuizQuestion(sequenceIndex + 1, false);
    } else if (sequenceIndex + 1 === currentQuiz.current.sequence.length) {
      askConversationalQuizQuestion(0, true);
    }
  };

  const onConversationalQuizSubmit = async () => {
    let json = await makeApiCall.request(
      window.CHAT_API +
        "/api/v1/quiz/quizzes/" +
        currentQuiz.current._id +
        "/submissions",
      {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          selected_question_ids: displayedQuestionsIdConvo,
          answers: convoQuizAnswers.current,
          action: "submit",
        }),
      }
    );

    if (json.status.code === 200) {
      setTimeout(() => {
        setChatData((data) => data.slice(0, data.length - 1));
        updateScrollbar();

        setChatData((data) =>
          data.concat(
            <BotQuizResultBubble
              showLoading
              avatarIcon={botAvatar}
              submissionData={json.data.quiz_submission}
              displayedQuestions={currentQuiz.current.sequence}
              onClick={handleQuizReportAction}
              updateScrollbar={updateScrollbar}
              enableLearning={enableLearning}
            />
          )
        );
        setHideChatInput(false);
        setIsConversationalQuizActive(false);
      }, 2000);

      updateScrollbar();
    } else if (json.status.code < 500) {
      setSnackbar({
        isOpen: true,
        message: t("Unable to submit quiz: ") + json.status.message,
        type: "error",
      });
    } else {
      // error
      setSnackbar({
        isOpen: true,
        message: t("Oops! Something went wrong."),
        type: "error",
      });
    }
  };

  const askConversationalQuizQuestion = (
    currentSequenceIndex,
    isLastQuestion
  ) => {
    let currentQuestionIndex =
      currentQuiz.current.sequence[currentSequenceIndex];
    setChatData((data) =>
      data.concat(
        <BotQuizBubble
          avatarIcon={botAvatar}
          questionData={currentQuiz.current.questions[currentQuestionIndex]}
          questionIndex={currentQuestionIndex}
          sequenceIndex={currentSequenceIndex}
          isLastQuestion={isLastQuestion}
          onAnswerInput={onAnswerInput}
          onSubmit={onConversationalQuizSubmit}
          updateScrollbar={updateScrollbar}
        />
      )
    );
    updateScrollbar();
  };

  const openQuizGeneratorScreen = (selectedQuiz) => {
    if (selectedQuiz.display_mode === "conversational") {
      // chatMessagesRef.current.pop();
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);

      currentQuiz.current = selectedQuiz;

      setHideChatInput(true);
      setIsConversationalQuizActive(true);
      let temp = [];
      selectedQuiz.questions.forEach((question) => {
        if (
          question.type === "text" ||
          question.type === "bullet" ||
          question.type === "newbullet"
        ) {
          temp.push({
            text: "",
          });
        } else if (question.type === "table") {
          temp.push({
            text: "[]",
          });
        } else if (question.options) {
          temp.push({
            options: [],
          });
        }
      });
      convoQuizAnswers.current = temp;

      let displayCount = selectedQuiz.display_questions_count
        ? selectedQuiz.display_questions_count
        : selectedQuiz.questions.length;

      if (displayCount && displayCount < selectedQuiz.questions.length) {
        conversationalQuizStrategy.current = "random";
      }

      selectedQuiz.questions.forEach((question) => {
        if (question.options) {
          question.options.forEach((option) => {
            if (option.branch && option.branch.action) {
              conversationalQuizStrategy.current = "branching";
            }
          });
        }
      });

      if (conversationalQuizStrategy.current === "random") {
        let toDisplay = getNRandomArrayIndices(
          selectedQuiz.questions,
          displayCount
        );

        let result = selectedQuiz.questions.map((a) => a.id);
        let temp = toDisplay.map((item) => result[item]);
        setDisplayedQuestionsIdConvo(temp);

        currentQuiz.current.sequence = toDisplay;
        askConversationalQuizQuestion(0, false);
      } else {
        let toDisplay = new Array(displayCount);
        for (let i = 0; i < displayCount; i++) {
          toDisplay[i] = i;
        }
        currentQuiz.current.sequence = toDisplay;
        askConversationalQuizQuestion(0, false);
      }
    } else {
      setQuiz(selectedQuiz);
      setShowQuiz(true);
    }
  };

  const handleQuizQuit = (
    quizId,
    data,
    displayedQuestions,
    displayedQuestionsId
  ) => {
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    setQuizDisplayedQuestions(displayedQuestions);
    if (data) {
      quizSubmissionPost(quizId, data, "quit", displayedQuestionsId);
    }
  };

  const handleQuizSubmit = (
    quizId,
    data,
    displayedQuestions,
    displayedQuestionsId
  ) => {
    if (chatMessagesRef.current.length > 0) {
      chatMessagesRef.current.pop();
      setChatData(chatMessagesRef.current);
    }
    setQuizDisplayedQuestions(displayedQuestions);
    setDisableQuizButton(true);
    if (data) {
      quizSubmissionPost(quizId, data, "submit", displayedQuestionsId);
    }
  };

  const quizSubmissionPost = async (
    quizId,
    data,
    actionType,
    displayedQuestionsId
  ) => {
    let json = await makeApiCall.request(
      window.CHAT_API + "/api/v1/quiz/quizzes/" + quizId + "/submissions",
      {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          selected_question_ids: displayedQuestionsId,
          answers: data,
          action: actionType.toLowerCase(),
        }),
      }
    );

    if (json.status.code === 200) {
      // successfull
      if (actionType === "submit") {
        setShowQuizReport({
          open: true,
          type: "success",
          data: json.data.quiz_submission,
        });
        return json.status.quiz_submission;
      } else if (actionType === "quit") {
        setShowQuizReport({
          open: true,
          type: "quit",
          data: json.data.quiz_submission,
        });
        return json.status.quiz_submission;
      }
    } else if (json.status.code < 500) {
      setSnackbar({
        isOpen: true,
        message: t("Unable to submit quiz: ") + json.status.message,
        type: "error",
      });
    } else {
      // error
      setSnackbar({
        isOpen: true,
        message: t("Oops! Something went wrong."),
        type: "error",
      });
    }
  };

  const createBotReply = (newData, allData, showFeedback) => {
    setDisableInput(true);
    setChatData((data) =>
      data.concat(<BotReply avatarIcon={botAvatar} replyType={"loading"} />)
    );
    updateScrollbar();
    setTimeout(() => {
      setChatData((data) => data.slice(0, data.length - 1));

      updateScrollbar();

      let replyBubbles = newData.reply.bubbles;

      if (replyBubbles && replyBubbles.length > 1) {
        var j = 0;

        function delayBubble() {
          setTimeout(
            function () {
              if (j !== 0) {
                setChatData((data) => data.slice(0, data.length - 1));
              }

              setChatData((data) =>
                data.concat(
                  <BotReply
                    avatarIcon={botAvatar}
                    bubbleIndex={j}
                    key={replyBubbles[j].id}
                    replyId={newData.reply._id}
                    replyType={replyBubbles[j].template}
                    data={replyBubbles[j]}
                    replyTime={prettifyDate(new Date())}
                    bookmarkAction={bookmarkAction}
                    recordFeedback={recordFeedback}
                    showFeedback={showFeedback}
                    feedback={feedbackMap[newData.reply.id] || null}
                  />
                )
              );
              updateScrollbar();

              setChatData((data) =>
                data.concat(
                  <BotReply avatarIcon={botAvatar} replyType={"loading"} />
                )
              );

              j++;
              if (j < replyBubbles.length) {
                delayBubble();
                updateScrollbar();
              } else {
                setChatData((data) => data.slice(0, data.length - 1));
                displayButtons();
                setDisableInput(false);
              }
            },
            j == 0 ? 0 : replyBubbles[j].delay
          );
        }
        delayBubble();
      } else {
        if (replyBubbles && replyBubbles.length > 0) {
          setChatData((data) =>
            data.concat(
              <BotReply
                bubbleIndex={0}
                avatarIcon={botAvatar}
                key={replyBubbles[0].id}
                replyId={newData.reply._id}
                data={replyBubbles[0]}
                replyTime={prettifyDate(new Date())}
                bookmarkAction={bookmarkAction}
                recordFeedback={recordFeedback}
                showFeedback={showFeedback}
                feedback={feedbackMap[newData.reply._id] || null}
              />
            )
          );
        }

        displayButtons();
        setDisableInput(false);
      }

      updateScrollbar();

      function displayButtons() {
        if (
          newData.reply &&
          newData.reply.buttons &&
          newData.reply.buttons.length
        ) {
          setChatData((data) =>
            data.concat(
              <UserChatBubble
                isButtonContainer
                key={uuidv4()}
                avatarIcon={userAvatarIcon}
                // messageId={newData.reply._id}
                message={newData.reply.buttons.map((button, index) => (
                  <SuggestionButton
                    key={index}
                    buttonLabel={button.label}
                    buttonAction={() => onCustomButtonClick(button)}
                  />
                ))}
                showFeedback={false}
              />
            )
          );
          updateScrollbar();
        }
      }
    }, botDelay);
  };

  const onFieldChange = (value, label) => {
    if (label === "feedbackComment") {
      setFeedbackComment(value);
    } else if (label === "newMessage") {
      setNewMessage(value);
    } else if (label === "sessionFeedbackComment") {
      setSessionFeedbackComment(value);
    }
  };

  const sendMessage = async (newMessage) => {
    if (newMessage.trim()) {
      let conversation = [...chatData];

      setChatData((data) =>
        data.concat(
          <UserChatBubble
            key={uuidv4()}
            avatarIcon={userAvatarIcon}
            message={newMessage}
            messageTime={prettifyDate(new Date())}
            bookmarkAction={bookmarkAction}
            copyAction={copyAction}
          />
        )
      );

      conversation.push(
        <UserChatBubble
          key={uuidv4()}
          avatarIcon={userAvatarIcon}
          message={newMessage}
          messageTime={prettifyDate(new Date())}
          bookmarkAction={bookmarkAction}
          copyAction={copyAction}
        />
      );

      let payload = { question: newMessage, source: "webchat" };
      if (buttonReference) {
        payload.button_reference = buttonReference;
      }

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(payload),
      };
      setChatData(conversation);

      setNewMessage("");
      setButtonReference("");

      let json = await makeApiCall.request(
        window.CHAT_API + "/api/v1/chatbot/questions",
        requestOptions
      );
      if (json.status.code === 200) {
        createBotReply(json.data, conversation, true);
        if (json.data.context && json.data.context.topic_id) {
          setChatContext(json.data.context.topic_id);
        } else {
          setChatContext("");
        }

        if ("is_input_disabled" in json.data.context) {
          if (json.data.context.is_input_disabled === null) {
            setHideChatInput(false);
          } else {
            setHideChatInput(json.data.context.is_input_disabled);
          }
        }
      } else {
        setSnackbar({
          isOpen: true,
          message: t("Oops! Something went wrong."),
          type: "error",
        });
      }
    }
  };

  const handleSuggestionSelect = (key) => {
    setSelectedSuggestion(key);
    setMessageCopy(key.split("%%")[1]);
    setSuggestionsExpanded(false);
  };

  const updateScrollbar = () => {
    let messageElement = document.getElementsByClassName(
      "chat-messages-container"
    );
    if (messageElement[0]) {
      messageElement[0].scrollTop = messageElement[0].scrollHeight;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setMessageCopy(newMessage);
    }
  };

  const openNotificationPanel = () => {
    setNotificationToggle(true);
    let rightPanel = document.getElementById("notification-panel");

    if (rightPanel) {
      rightPanel.classList += " open-panel-wrap";
    }
  };

  const openFeedbackModalFunction = () => {
    setOpenFeedbackModal(true);
  };

  const onConversationClear = () => {
    setOpenSessionEndModal(false);
    // setOpenFeedbackContainer(true);
    setShowHomePanel(false);
    setChatData([]);
    getGreetings(true);
    // getSummary();
  };

  const getChatPanel = () => {
    return (
      <>
        <ChatHeader
          avatar={botAvatar}
          botName={botName}
          openFeedbackModal={openFeedbackModal}
          openNotificationPanel={openNotificationPanel}
          beforeLogout={beforeLogout}
          onBack={() => setShowHomePanel(true)}
          enableLearning={enableLearning}
        />

        <>
          <div className="quiz-review-container">
            <div className="left-partition">
              <button
                className="header-back-btn"
                onClick={() => history.push("/")}
              >
                <i className="icon-left-chevron" />
              </button>
              <div className="header-heading">{t("Go back to chat")}</div>
            </div>

            {quizReportData !== null && (
              <QuizReportReviewed
                submissionData={quizReportData}
                type={"success"}
              />
            )}
          </div>
        </>
      </>
    );
  };

  const onAgentChange = async (agentId, type) => {
    setChatData([]);
    setSuggestedQuestionsData([]);
    AgentClassService.setSelectedAgent(agentId);
    setSelectedClass("");
    AgentClassService.setSelectedClass("");
    setSelectedAgent(agentId);
    await makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/states", {
        method: "PATCH",
        headers: authHeader(),
        body: JSON.stringify({
          states: {
            selected_agent: agentId.toString(),
          },
        }),
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (type === "switch") {
            setShowAgentSelection(false);
            getDefaultAgent();
          } else {
            callStartSessionApi();
            setShowNewSessionAgentSelection(false);
            getSuggestedQuestions();
          }
        }
      });

    checkAgentFeatureEnabled(
      "learning",
      () => {
        setEnableLearning(false);
      },
      () => {
        setEnableLearning(true);
      }
    );
  };

  if (showNewSessionAgentSelection) {
    return (
      <AgentSelection
        onAgentChange={(agentId) => onAgentChange(agentId, "new")}
        hideAgentSelection={() => {
          setShowNewSessionAgentSelection(false);
          updateScrollbar();
        }}
      />
    );
  }

  if (showAgentSelection) {
    return (
      <AgentSelection
        selectedAgent={selectedAgent}
        onAgentChange={(agentId) => onAgentChange(agentId, "switch")}
        hideAgentSelection={() => {
          setShowAgentSelection(false);
          updateScrollbar();
        }}
      />
    );
  }

  
  if (showOrgSelection) {
    return (
      <OrganisationSwitcher />
    )
  }

  return (
    <div className="chat-page-container">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div
        className={
          hideSidebar
            ? "review-wrapper-collapsed p-0 is-hidden-mobile"
            : "review-wrapper p-0 is-hidden-mobile"
        }
      >
        {getChatPanel()}
      </div>

      <Snackbar
        isOpen={snackbar.isOpen}
        primaryMessage={snackbar.message}
        type={snackbar.type}
        onRequestClose={() => setSnackbar({ ...snackbar, isOpen: false })}
      />

      <NotificationPanel
        notificationCount={notificationCount}
        onClick={onClickNotification}
        onDelete={onDeleteNotification}
        onCheckboxChange={onCheckboxChangeNotification}
        allNotifications={allNotifications}
        setNotificationToggle={setNotificationToggle}
        markAllRead={markAllRead}
        clearAll={clearAllNotification}
      />
    </div>
  );
};

export default QuizReview;
