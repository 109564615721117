import React, { useEffect, useState } from "react";
import { Container, Draggable } from "react-smooth-dnd";

import "./style.css";

import { useTranslation } from "react-i18next";

const DragMatch = (props) => {
  const { t } = useTranslation('translation');
  const [targetData, setTargetData] = useState({});
  const [shuffledData, setShuffledData] = useState([]);

  useEffect(() => {
    setShuffledData(props.definitions);
    setTargetData({});
  }, [props.definitions]);

  useEffect(() => {
    props.onChange && props.onChange(targetData);
  }, [targetData]);

  const onDrop = (dropResult) => {
    if (dropResult.removedIndex !== null && dropResult.addedIndex === null) {
      let temp = [...shuffledData];
      temp.splice(dropResult.removedIndex, 1);
      setShuffledData(temp);
    } else if (
      dropResult.removedIndex === null &&
      dropResult.addedIndex !== null
    ) {
      let temp = [...shuffledData];
      temp.splice(dropResult.addedIndex, 0, dropResult.payload);
      let updatedTargetData = { ...targetData };
      updatedTargetData[dropResult.payload.id] = null;
      setShuffledData(temp);
      setTargetData(updatedTargetData);
    }
  };

  const renderItem = (item) => {
    return <div className="drag-card">{item.text}</div>;
  };

  const onTargetDrop = (row, dropResult) => {
    const { removedIndex, addedIndex, payload } = dropResult;
    if (dropResult.addedIndex !== null) {
      let temp = { ...targetData };
      if (!temp[row]) {
        temp[row] = payload;
      } else {
        let storeBuffer = temp[row];
        let updatedShuffledData = [...shuffledData];
        updatedShuffledData.push(storeBuffer);
        temp[row] = payload;
        setShuffledData(updatedShuffledData);
      }
      setTargetData(temp);
    }
    if (dropResult.removedIndex !== null) {
      let temp = { ...targetData };
      temp[row] = null;
      setTargetData(temp);
    }
  };

  const shouldAcceptDrop = (sourceContainerOptions, payload, targetQty) => {
    if (!targetQty) {
      return true;
    }
  };

  const onDragEnd = ({ isSource, payload, willAcceptDrop }, termId) => {
    if (isSource) {
      let temp = { ...targetData };
      temp[termId] = null;
      setTargetData(temp);
    }
  };

  return (
    <div className="drag-match-editor-wrapper">
      <div className="drag-match-definition-list">
        <h4 className="page-subheading">{t("Drag from here")}</h4>
        <Container
          groupName="1"
          shouldAcceptDrop={(sourceContainerOptions, payload) => true}
          onDrop={onDrop}
          orientation={"horizontal"}
          getChildPayload={(index) => shuffledData[index]}
        >
          {shuffledData.map((item) => {
            return <Draggable key={item.id}>{renderItem(item)}</Draggable>;
          })}
        </Container>
      </div>
      <div className="half-clearfix" />
      <h4 className="page-subheading">{t("Answer")}</h4>
      <div className="drag-match-editor">
        <table>
          {props.headerValues && props.headerValues.length > 0 && (
            <thead>
              <tr>
                <th>{props.headerValues[0]}</th>
                <th>{props.headerValues[1] ? props.headerValues[1] : ""}</th>
              </tr>
            </thead>
          )}
          {props.terms.map((term, rowIndex) => (
            <tr key={term.id}>
              <td>{term.text}</td>
              <td className="target-drop-cell">
                <div className="placeholder">{t("Drop here...")}</div>
                <Container
                  dropClass={"target-drop-container"}
                  dropPlaceholder={{ className: "target-drop-placeholder" }}
                  onDrop={(dropResult) => onTargetDrop(term.id, dropResult)}
                  groupName="1"
                  // shouldAcceptDrop={(sourceContainerOptions, payload) =>
                  //   shouldAcceptDrop(
                  //     sourceContainerOptions,
                  //     payload,
                  //     targetData[term.id]
                  //   )
                  // }
                  getChildPayload={() => targetData[term.id]}
                  // onDragEnd={({ isSource, payload, willAcceptDrop }) =>
                  //   onDragEnd({ isSource, payload, willAcceptDrop }, term.id)
                  // }
                >
                  {targetData[term.id] && (
                    <Draggable key={targetData[term.id]}>
                      {renderItem(targetData[term.id])}
                    </Draggable>
                  )}
                </Container>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default DragMatch;
