import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { authHeader } from "../../helpers/auth-header";
import useFetch from "../../helpers/remote";
import AgentClassService from "./../../helpers/AgentClassService";

import "./style.css";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import logoNF from "../../assets/images/logo-horizontal.png";
import noodleAva1 from "../../assets/images/profiles/noodleAva1.png";
import trophyImg from "../../assets/images/trophy-img.svg";
import EditProfileModal from "../EditProfileModal";
import i18n from "../Internationalisation/i18n";
import Modal from "../Modal";

const LANGUAGE_OPTIONS = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "pt-pt",
    label: "Português - Portugal",
  },
  {
    value: "id",
    label: "Bahasa Indonesia",
  },
  {
    value: "es",
    label: "Español",
  },
  {
    value: "tr",
    label: "Türkçe",
  },
];

const ChatHeader = ({
  avatar,
  botName,
  openNotificationPanel,
  onBack,
  beforeLogout,
  showSwitchAgent,
  customButtonData,
  onCustomButtonClick,
  disableCustomButton,
  isConversationalQuizActive,
  enableLearning,
  showUnread,
  isWidget,
  showProfileEdit,
  orgDefaultLang,
  botMode,
  brandAvatar,
  selectedSidebarOption,
  agentName,
  onStartNewSession,
  onQuizExitHandler,
  showQuiz,
}) => {
  const { t } = useTranslation("translation");
  let history = useHistory();
  const location = useLocation();
  const makeApiCall = useFetch();
  const {
    setAgentProfileImage,
    setShowAgentSelection,
    setLanguage,
    agentLanguage,
    agentProfileImage,
    userFullName,
    userRole,
    setUserRole,
    showChangeOrganisation,
    setShowChangeOrganisation,
    allOrganisations,
    setAllOrganisations,
    setShowOrgSelection,
  } = useAuth();
  const windowObj = window;
  const [showLanguageTranslateModal, setShowLanguageTranslateModal] =
    useState();
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: null,
    label: null,
  });

  const [isOpenProfileEdit, setIsOpenProfileEdit] = useState(false);
  const [userStateLang, setUserStateLang] = useState("");

  const [currentOrganisation, setCurrentOrganisation] = useState(false);
  const [adminPortalUrl, setAdminPortalUrl] = useState(`${windowObj.ADMIN_PORTAL}`);

  const changeLanguageSwitcher = () => {
    setShowLanguageTranslateModal(!showLanguageTranslateModal);
  };

  const switchLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
    const selectedOption = LANGUAGE_OPTIONS.filter(
      (option) => option.value === languageCode
    )[0];
    setSelectedLanguage(
      selectedOption || {
        value: null,
        label: null,
      }
    );
  };

  useEffect(() => {
    const appLang = userStateLang || agentLanguage || orgDefaultLang || "en";
    switchLanguage(appLang);
    localStorage.setItem("selectedLanguageCode", appLang);
  }, [orgDefaultLang, agentLanguage, userStateLang]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const widget = query.get("widgettype")?.split("?")[0];
    const getUserState = async () => {
      await makeApiCall
        .request(window.CHAT_API + "/api/v1/organisation/states", {
          method: "GET",
          headers: authHeader(),
        })
        .then((json) => {
          if (
            json.data &&
            json.data.states &&
            json.data.states.hasOwnProperty("selectedLanguageCode")
          ) {
            setUserStateLang(json.data.states.selectedLanguageCode);
          }
        })
        .catch((e) => {
          switchLanguage("en");
        });
    };

    const getAllOrgs = async () => {
      await makeApiCall
        .request(window.CHAT_API + "/api/v1/organisation/organisations", {
          method: "GET",
          headers: authHeader(),
        })
        .then((json) => {
          setShowChangeOrganisation(json.data.organisations.length > 1);
          setAllOrganisations(json.data.organisations || []);
        })
        .catch((e) => {});
    };

    const func = async () => {
      makeApiCall
        .request(
          window.CHAT_API + "/api/v1/organisation/organisations/current",
          {
            method: "GET",
            headers: authHeader(),
          }
        )
        .then((json) => {
          if (json.status.code === 200) {
            setCurrentOrganisation(json.data.organisation || null);
          }
        });
    };

    func();
    getAllOrgs();
    getUserState();
  }, []);

  useEffect(() => {
    const getUserInfo = async () => {
      let selectedAgent = AgentClassService.getSelectedAgent();
      if (!selectedAgent) {
        return;
      }
      setAdminPortalUrl(`${windowObj.ADMIN_PORTAL}?agent=${selectedAgent}`);
      await makeApiCall
        .request(window.CHAT_API + "/api/v1/learning/users/current", {
          method: "GET",
          headers: authHeader(),
        })
        .then((json) => {
          if (json?.data && json?.data?.user) {
            setAgentProfileImage(json.data.user?.profile_image);
            setUserRole(json.data.user?.role);
          }
        })
        .catch((e) => {});
    };
    getUserInfo();
  }, [AgentClassService.getSelectedAgent()]);

  const saveLanguageSetting = async () => {
    await makeApiCall
      .request(window.CHAT_API + "/api/v1/organisation/states", {
        method: "PATCH",
        headers: authHeader(),
        body: JSON.stringify({
          states: {
            selectedLanguageCode: selectedLanguage.value,
          },
        }),
      })
      .then((json) => {});
    setUserStateLang(selectedLanguage.value);
    changeLanguageSwitcher();
  };

  return (
    <>
      {showProfileEdit && (
        <EditProfileModal
          isOpenProfileEdit={isOpenProfileEdit}
          setIsOpenProfileEdit={(isOpen) => setIsOpenProfileEdit(isOpen)}
          isWidget={isWidget}
        />
      )}

      <Modal
        openModal={showLanguageTranslateModal}
        onRequestClose={changeLanguageSwitcher}
        buttonAction={() => saveLanguageSetting()}
        // title="Change Language"
        buttonLabel={t("Save Changes")}
      >
        <div className="mb-12">
          <p className="mb-1">{t("View Page in")}</p>
          <Select
            className="select-sort select-target select-blue shadow-none radius-2"
            classNamePrefix="select-target-prefix"
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder="Change Language"
            options={LANGUAGE_OPTIONS}
            onChange={(e) => {
              setSelectedLanguage(e);
            }}
            value={selectedLanguage}
          />
        </div>
      </Modal>

      <header className="chat-messages-header" role="banner">
        <h1 aria-hidden="true" style={{ display: "none" }}>{t("Noodle Factory Chatbot")}</h1>
        <div className="left-section">
          {onBack && !isWidget && (
            <button
              className="header-back-btn is-hidden-tablet"
              onClick={onBack}
            >
              <i className="icon-menu" />
            </button>
          )}
          {onBack && isWidget && (
            <button className="header-back-btn" onClick={onBack}>
              <i className="icon-menu" />
            </button>
          )}
          <div className="main-logo">
            {brandAvatar && (
              <img src={brandAvatar ? brandAvatar : logoNF} alt="logo" />
            )}
            <a href="#main-content" class="skip-to-main-content-link">{t("Skip to main content")}</a>
          </div>
        </div>

        <div className="right-section align-items-center">
          <div className="action-container">
            {(!isWidget ||
              (process.env && process.env.REACT_APP_TYPE !== "login_widget")) &&
              customButtonData &&
              customButtonData.length > 0 &&
              customButtonData.map((button) => (
                <button
                  className="admin-custom-btn"
                  title={button.label}
                  onClick={() => onCustomButtonClick(button)}
                  type="button"
                  disabled={disableCustomButton || isConversationalQuizActive}
                >
                  {button.label}
                </button>
              ))}
            {(isWidget ||
              (process.env && process.env.REACT_APP_TYPE == "login_widget")) &&
              selectedSidebarOption === "chat" &&
              customButtonData &&
              customButtonData.length > 0 &&
              customButtonData.map((button) => (
                <button
                  className="admin-custom-btn"
                  title={button.label}
                  onClick={() => onCustomButtonClick(button)}
                  type="button"
                  disabled={disableCustomButton || isConversationalQuizActive}
                >
                  {button.label}
                </button>
              ))}
          </div>
          {enableLearning && !isWidget && (
            <button
              className="more-menu-button trophy-btn ml-2 d-flex pl-2 pr-2"
              onClick={() => {
                history.push("/trophy-room");
              }}
              title={t("Trophy Room")}
              role="link"
            >
              <img src={trophyImg} alt="" />
            </button>
          )}

          {openNotificationPanel && (
            <button
              className="more-menu-button notification-btn ml-2 d-flex"
              onClick={openNotificationPanel}
              title={t("Notification")}
              aria-label={t("Notification")}
            >
              <i className="icon-bell"></i>
              {showUnread && <span className="unread-mark"></span>}
            </button>
          )}
          {(!isWidget ||
            (process.env && process.env.REACT_APP_TYPE !== "login_widget")) && (
            <div className="more-menu-wrapper ml-2" aria-label={t("Profile")} tabIndex={0} role="button">
              <button
                className="more-menu-button d-flex align-items-center pr-0 pl-2"
                title={t("Menu")}
              >
                <div className="header-avatar-container">
                  <img
                    src={!isWidget ? agentProfileImage || noodleAva1 : avatar}
                    alt=""
                  />
                </div>
                {/* <i className="icon-more"></i> */}
                <i className="icon-down-chevron"></i>
              </button>

              <div className="more-dropdown-menu" tabIndex={0}>
                <div className="d-flex align-items-center dropdown-head">
                  <div className="header-avatar-container">
                    <img
                      src={!isWidget ? agentProfileImage || noodleAva1 : avatar}
                      alt=""
                    />
                  </div>
                  <div
                    className="pl-2"
                    style={{
                      width: "calc(100% - 40px)",
                    }}
                  >
                    <div>{userFullName}</div>
                    <span className="d-block">{currentOrganisation?.name}</span>
                    <span className="d-inline-block">{agentName}</span>
                  </div>
                </div>
                {(userRole === "superadmin" || userRole === "admin") && (
                  <div
                    key={"view_as_admin"}
                    className="header-dropdown-menu-item"
                    onClick={() => {
                      window.open(adminPortalUrl, "_blank");
                    }}
                  >
                    <span className="menu-icons">
                      {/* <img src={viewAsAdminImg} alt="" /> */}
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M2.25 11.0877V13.9122L6.95775 11.0877H10.5C11.3272 11.0877 12 10.415 12 9.58774V3.58774C12 2.76049 11.3272 2.08774 10.5 2.08774H1.5C0.67275 2.08774 0 2.76049 0 3.58774V9.58774C0 10.415 0.67275 11.0877 1.5 11.0877H2.25ZM1.5 3.58774H10.5V9.58774H6.54225L3.75 11.2632V9.58774H1.5V3.58774Z"
                          fill="#6B7085"
                        />
                        <path
                          d="M12.5 0.087738H3.5C2.67275 0.087738 2 0.760488 2 1.58774H11C11.8272 1.58774 12.5 2.26049 12.5 3.08774V9.08774C13.3272 9.08774 14 8.41499 14 7.58774V1.58774C14 0.760488 13.3272 0.087738 12.5 0.087738Z"
                          fill="#6B7085"
                        />
                      </svg>
                    </span>
                    <span>{t("View as Admin")}</span>
                  </div>
                )}
                <hr />
                {showProfileEdit && !isWidget && (
                  <div
                    key={"profile"}
                    className="header-dropdown-menu-item"
                    onClick={() => {
                      setIsOpenProfileEdit(true);
                    }}
                  >
                    <span className="menu-icons">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M8.09091 10.5455H10.5455M10.5455 10.5455H13M10.5455 10.5455V8.09091M10.5455 10.5455V13M2.63636 5.90909H4.27273C4.70672 5.90909 5.12293 5.73669 5.42981 5.42981C5.73669 5.12293 5.90909 4.70672 5.90909 4.27273V2.63636C5.90909 2.20237 5.73669 1.78616 5.42981 1.47928C5.12293 1.1724 4.70672 1 4.27273 1H2.63636C2.20237 1 1.78616 1.1724 1.47928 1.47928C1.1724 1.78616 1 2.20237 1 2.63636V4.27273C1 4.70672 1.1724 5.12293 1.47928 5.42981C1.78616 5.73669 2.20237 5.90909 2.63636 5.90909ZM2.63636 13H4.27273C4.70672 13 5.12293 12.8276 5.42981 12.5207C5.73669 12.2138 5.90909 11.7976 5.90909 11.3636V9.72727C5.90909 9.29328 5.73669 8.87707 5.42981 8.57019C5.12293 8.26331 4.70672 8.09091 4.27273 8.09091H2.63636C2.20237 8.09091 1.78616 8.26331 1.47928 8.57019C1.1724 8.87707 1 9.29328 1 9.72727V11.3636C1 11.7976 1.1724 12.2138 1.47928 12.5207C1.78616 12.8276 2.20237 13 2.63636 13ZM9.72727 5.90909H11.3636C11.7976 5.90909 12.2138 5.73669 12.5207 5.42981C12.8276 5.12293 13 4.70672 13 4.27273V2.63636C13 2.20237 12.8276 1.78616 12.5207 1.47928C12.2138 1.1724 11.7976 1 11.3636 1H9.72727C9.29328 1 8.87707 1.1724 8.57019 1.47928C8.26331 1.78616 8.09091 2.20237 8.09091 2.63636V4.27273C8.09091 4.70672 8.26331 5.12293 8.57019 5.42981C8.87707 5.73669 9.29328 5.90909 9.72727 5.90909Z"
                          stroke="#6B7085"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span>{t("Manage Profile")}</span>
                  </div>
                )}

                <div
                  key={"new_chat_session"}
                  className="header-dropdown-menu-item"
                  onClick={() => {
                    if (showQuiz) {
                      onQuizExitHandler("newSession");
                    } else {
                      onStartNewSession();
                    }
                  }}
                >
                  <span className="menu-icons">
                    {/* <img src={newChatSessionImg} alt="" /> */}
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                      <path
                        d="M9.27273 9.36364V7.72728H7.63636V6.63637H9.27273V5H10.3636V6.63637H12V7.72728H10.3636V9.36364H9.27273ZM1.09091 9.90909C0.79091 9.90909 0.534001 9.80219 0.320183 9.58837C0.106365 9.37455 -0.00036271 9.11782 9.26069e-07 8.81818V1.18182C9.26069e-07 0.881822 0.10691 0.624913 0.320728 0.411095C0.534546 0.197276 0.791274 0.0905492 1.09091 0.0909128H8.72727C9.02727 0.0909128 9.28418 0.197822 9.498 0.41164C9.71182 0.625458 9.81855 0.882185 9.81818 1.18182V3.90909H8.72727V2.81819H1.09091V8.81818H8.18182V9.90909H1.09091ZM1.09091 1.72728H8.72727V1.18182H1.09091V1.72728Z"
                        fill="#6B7085"
                      />
                    </svg>
                  </span>
                  <span>{t("New Chat Session")}</span>
                </div>

                {showSwitchAgent && !isWidget && (
                  <div
                    key={"agent"}
                    className="header-dropdown-menu-item"
                    onClick={() => {
                      setShowAgentSelection(true);
                    }}
                  >
                    <span className="menu-icons">
                      {/* <img src={switchAgentImg} alt="" /> */}
                      <svg
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                      >
                        <path
                          d="M6.43313 0.175782C6.55033 0.0632287 6.70927 0 6.875 0C7.04073 0 7.19967 0.0632287 7.31687 0.175782L9.81688 2.57728C9.93404 2.68987 9.99987 2.84255 9.99987 3.00175C9.99987 3.16095 9.93404 3.31363 9.81688 3.42621L7.31687 5.82771C7.199 5.93708 7.04112 5.99759 6.87725 5.99622C6.71338 5.99486 6.55662 5.93172 6.44074 5.8204C6.32486 5.70909 6.25913 5.5585 6.2577 5.40109C6.25628 5.24367 6.31928 5.09202 6.43313 4.97878L7.86625 3.60212H0.625C0.45924 3.60212 0.300269 3.53887 0.183058 3.42628C0.0658481 3.31368 0 3.16098 0 3.00175C0 2.84252 0.0658481 2.68981 0.183058 2.57722C0.300269 2.46463 0.45924 2.40137 0.625 2.40137H7.86625L6.43313 1.02471C6.31596 0.912125 6.25013 0.759445 6.25013 0.600247C6.25013 0.441049 6.31596 0.288369 6.43313 0.175782ZM3.56687 6.17953C3.68404 6.29212 3.74987 6.4448 3.74987 6.604C3.74987 6.7632 3.68404 6.91588 3.56687 7.02846L2.13375 8.40512H9.375C9.54076 8.40512 9.69973 8.46838 9.81694 8.58097C9.93415 8.69356 10 8.84627 10 9.0055C10 9.16473 9.93415 9.31744 9.81694 9.43003C9.69973 9.54262 9.54076 9.60588 9.375 9.60588H2.13375L3.56687 10.9825C3.68072 11.0958 3.74372 11.2474 3.7423 11.4048C3.74087 11.5623 3.67514 11.7128 3.55926 11.8242C3.44338 11.9355 3.28662 11.9986 3.12275 12C2.95888 12.0013 2.801 11.9408 2.68313 11.8315L0.183125 9.42997C0.0659557 9.31738 0.000133514 9.1647 0.000133514 9.0055C0.000133514 8.8463 0.0659557 8.69362 0.183125 8.58103L2.68313 6.17953C2.80033 6.06698 2.95927 6.00375 3.125 6.00375C3.29073 6.00375 3.44967 6.06698 3.56687 6.17953Z"
                          fill="#6B7085"
                        />
                      </svg>
                    </span>
                    <span>{t("Switch Agent")}</span>
                  </div>
                )}

                {showChangeOrganisation && (
                  <div
                    key={"choose_organisation"}
                    className="header-dropdown-menu-item"
                    onClick={() => {
                      setShowOrgSelection(true);
                    }}
                  >
                    <span className="menu-icons">
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                      >
                        <path
                          d="M6.54545 2.2727H12V9.90906H0V0.0908813H6.54545V2.2727ZM3.81818 1.18179H5.45455V2.2727H3.81818V1.18179ZM5.45455 8.81815V7.72725H3.81818V8.81815H5.45455ZM5.45455 6.63634V5.54543H3.81818V6.63634H5.45455ZM5.45455 4.45452V3.36361H3.81818V4.45452H5.45455ZM10.9091 8.81815V3.36361H6.54545V4.45452H7.63636V5.54543H6.54545V6.63634H7.63636V7.72725H6.54545V8.81815H10.9091ZM1.09091 8.81815V7.72725H2.72727V8.81815H1.09091ZM1.09091 6.63634H2.72727V5.54543H1.09091V6.63634ZM2.72727 4.45452V3.36361H1.09091V4.45452H2.72727ZM1.09091 2.2727H2.72727V1.18179H1.09091V2.2727Z"
                          fill="#6B7085"
                        />
                      </svg>
                    </span>
                    <span>{t("Switch Organisation")}</span>
                  </div>
                )}

                <hr />

                <div
                  key={"change_language"}
                  className="header-dropdown-menu-item"
                  onClick={changeLanguageSwitcher}
                >
                  <span className="menu-icons">
                    {/* <img src={changeLangImg} alt="" /> */}
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <path
                        d="M5.994 0C2.682 0 0 2.688 0 6C0 9.312 2.682 12 5.994 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 5.994 0ZM10.152 3.6H8.382C8.19421 2.85687 7.91614 2.13954 7.554 1.464C8.64847 1.8408 9.5707 2.59903 10.152 3.6ZM6 1.224C6.498 1.944 6.888 2.742 7.146 3.6H4.854C5.112 2.742 5.502 1.944 6 1.224ZM1.356 7.2C1.26 6.816 1.2 6.414 1.2 6C1.2 5.586 1.26 5.184 1.356 4.8H3.384C3.336 5.196 3.3 5.592 3.3 6C3.3 6.408 3.336 6.804 3.384 7.2H1.356ZM1.848 8.4H3.618C3.81 9.15 4.086 9.87 4.446 10.536C3.35038 10.1613 2.4275 9.40249 1.848 8.4ZM3.618 3.6H1.848C2.4275 2.59751 3.35038 1.83874 4.446 1.464C4.08386 2.13954 3.80579 2.85687 3.618 3.6ZM6 10.776C5.502 10.056 5.112 9.258 4.854 8.4H7.146C6.888 9.258 6.498 10.056 6 10.776ZM7.404 7.2H4.596C4.542 6.804 4.5 6.408 4.5 6C4.5 5.592 4.542 5.19 4.596 4.8H7.404C7.458 5.19 7.5 5.592 7.5 6C7.5 6.408 7.458 6.804 7.404 7.2ZM7.554 10.536C7.914 9.87 8.19 9.15 8.382 8.4H10.152C9.5707 9.40097 8.64847 10.1592 7.554 10.536ZM8.616 7.2C8.664 6.804 8.7 6.408 8.7 6C8.7 5.592 8.664 5.196 8.616 4.8H10.644C10.74 5.184 10.8 5.586 10.8 6C10.8 6.414 10.74 6.816 10.644 7.2H8.616Z"
                        fill="#6B7085"
                      />
                    </svg>
                  </span>
                  <span>{t("Change Language")}</span>
                </div>

                <div
                  key={"privacy_policy"}
                  className="header-dropdown-menu-item"
                  onClick={() => {
                    window.open(
                      "https://www.noodlefactory.ai/privacy-policy",
                      "_blank"
                    );
                  }}
                >
                  <span className="menu-icons">
                    {/* <img src={newChatSessionImg} alt="" /> */}
                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none">
                      <path
                        d="M10.1429 6.71429V3.14229C10.1429 3.09718 10.134 3.05251 10.1168 3.01084C10.0995 2.96916 10.0742 2.9313 10.0423 2.89943L8.24343 1.10057C8.17919 1.03625 8.09204 1.00008 8.00114 1H1.34286C1.25193 1 1.16472 1.03612 1.10042 1.10042C1.03612 1.16472 1 1.25193 1 1.34286V12.0857C1 12.1766 1.03612 12.2639 1.10042 12.3282C1.16472 12.3924 1.25193 12.4286 1.34286 12.4286H6.14286M3.28571 5.57143H7.85714M3.28571 3.28571H5.57143M3.28571 7.85714H5M7.85714 1V2.94286C7.85714 3.03379 7.89327 3.121 7.95756 3.18529C8.02186 3.24959 8.10907 3.28571 8.2 3.28571H10.1429M10.1383 8.5L11.5989 8.87086C11.7509 8.90971 11.8577 9.048 11.8531 9.20457C11.7549 12.4949 9.85714 13 9.85714 13C9.85714 13 7.95943 12.4949 7.86114 9.20457C7.85938 9.12835 7.88359 9.05379 7.9298 8.99315C7.97601 8.9325 8.04147 8.88938 8.11543 8.87086L9.576 8.5C9.76057 8.45314 9.95372 8.45314 10.1383 8.5Z"
                        stroke="#6B7085"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span>{t("Privacy Policy")}</span>
                </div>
                {!isWidget && (
                  <div
                    key={"logout"}
                    className="header-dropdown-menu-item"
                    onClick={beforeLogout}
                  >
                    <span className="menu-icons">
                      <svg
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          d="M8.33333 3.33333V2C8.33333 1.64638 8.19286 1.30724 7.94281 1.05719C7.69276 0.80714 7.35362 0.666664 7 0.666664H2.33333C1.97971 0.666664 1.64057 0.80714 1.39052 1.05719C1.14048 1.30724 1 1.64638 1 2V10C1 10.3536 1.14048 10.6928 1.39052 10.9428C1.64057 11.1929 1.97971 11.3333 2.33333 11.3333H7C7.35362 11.3333 7.69276 11.1929 7.94281 10.9428C8.19286 10.6928 8.33333 10.3536 8.33333 10V8.66666"
                          stroke="#0E0748"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5 6H13M13 6L11 4M13 6L11 8"
                          stroke="#0E0748"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span>{t("Logout")}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {(isWidget ||
            (process.env && process.env.REACT_APP_TYPE === "login_widget")) && (
            <div className="more-menu-wrapper ml-2">
              <button
                className="more-menu-button d-flex align-items-center pr-0"
                title={t("Menu")}
              >
                <div className="header-avatar-container">
                  <img
                    src={!isWidget ? agentProfileImage || noodleAva1 : avatar}
                    alt=""
                  />
                </div>
                {/* <i className="icon-more"></i> */}
              </button>

              <div className="more-dropdown-menu">
                {showProfileEdit && !isWidget && (
                  <div
                    key={"profile"}
                    className="header-dropdown-menu-item"
                    onClick={() => {
                      setIsOpenProfileEdit(true);
                    }}
                  >
                    {t("Manage Profile")}
                  </div>
                )}
                {showSwitchAgent && !isWidget && (
                  <div
                    key={"agent"}
                    className="header-dropdown-menu-item"
                    onClick={() => {
                      setShowAgentSelection(true);
                    }}
                  >
                    {t("Switch Agent")}
                  </div>
                )}
                <div
                  key={"change_language"}
                  className="header-dropdown-menu-item"
                  onClick={changeLanguageSwitcher}
                >
                  <span className="menu-icons">
                    {/* <img src={changeLangImg} alt="" /> */}
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <path
                        d="M5.994 0C2.682 0 0 2.688 0 6C0 9.312 2.682 12 5.994 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 5.994 0ZM10.152 3.6H8.382C8.19421 2.85687 7.91614 2.13954 7.554 1.464C8.64847 1.8408 9.5707 2.59903 10.152 3.6ZM6 1.224C6.498 1.944 6.888 2.742 7.146 3.6H4.854C5.112 2.742 5.502 1.944 6 1.224ZM1.356 7.2C1.26 6.816 1.2 6.414 1.2 6C1.2 5.586 1.26 5.184 1.356 4.8H3.384C3.336 5.196 3.3 5.592 3.3 6C3.3 6.408 3.336 6.804 3.384 7.2H1.356ZM1.848 8.4H3.618C3.81 9.15 4.086 9.87 4.446 10.536C3.35038 10.1613 2.4275 9.40249 1.848 8.4ZM3.618 3.6H1.848C2.4275 2.59751 3.35038 1.83874 4.446 1.464C4.08386 2.13954 3.80579 2.85687 3.618 3.6ZM6 10.776C5.502 10.056 5.112 9.258 4.854 8.4H7.146C6.888 9.258 6.498 10.056 6 10.776ZM7.404 7.2H4.596C4.542 6.804 4.5 6.408 4.5 6C4.5 5.592 4.542 5.19 4.596 4.8H7.404C7.458 5.19 7.5 5.592 7.5 6C7.5 6.408 7.458 6.804 7.404 7.2ZM7.554 10.536C7.914 9.87 8.19 9.15 8.382 8.4H10.152C9.5707 9.40097 8.64847 10.1592 7.554 10.536ZM8.616 7.2C8.664 6.804 8.7 6.408 8.7 6C8.7 5.592 8.664 5.196 8.616 4.8H10.644C10.74 5.184 10.8 5.586 10.8 6C10.8 6.414 10.74 6.816 10.644 7.2H8.616Z"
                        fill="#6B7085"
                      />
                    </svg>
                  </span>
                  <span>{t("Change Language")}</span>
                </div>
                <div
                  key={"new_chat_session"}
                  className="header-dropdown-menu-item"
                  onClick={onStartNewSession}
                >
                  <span className="menu-icons">
                    {/* <img src={newChatSessionImg} alt="" /> */}
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none">
                      <path
                        d="M9.27273 9.36364V7.72728H7.63636V6.63637H9.27273V5H10.3636V6.63637H12V7.72728H10.3636V9.36364H9.27273ZM1.09091 9.90909C0.79091 9.90909 0.534001 9.80219 0.320183 9.58837C0.106365 9.37455 -0.00036271 9.11782 9.26069e-07 8.81818V1.18182C9.26069e-07 0.881822 0.10691 0.624913 0.320728 0.411095C0.534546 0.197276 0.791274 0.0905492 1.09091 0.0909128H8.72727C9.02727 0.0909128 9.28418 0.197822 9.498 0.41164C9.71182 0.625458 9.81855 0.882185 9.81818 1.18182V3.90909H8.72727V2.81819H1.09091V8.81818H8.18182V9.90909H1.09091ZM1.09091 1.72728H8.72727V1.18182H1.09091V1.72728Z"
                        fill="#6B7085"
                      />
                    </svg>
                  </span>
                  <span>{t("New Chat Sessio dsds n")}</span>
                </div>

                {!isWidget && (
                  <div
                    key={"logout"}
                    className="header-dropdown-menu-item"
                    onClick={beforeLogout}
                  >
                    {t("Logout")}
                  </div>
                )}
              </div>
            </div>
          )}
          {(isWidget ||
            (process.env && process.env.REACT_APP_TYPE === "login_widget")) && (
            <div className="is-widget">
              <i className="icon-down-chevron"></i>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default ChatHeader;
